app.filter('groupByFirstWord', ['$parse', function ($parse) {
  return function (collection, property) {
    if(!angular.isObject(collection) || angular.isUndefined(property)) {
      return collection;
    }

    var
      getter = $parse(property),
      result = {};

    angular.forEach(collection, function (elm) {
      var
        prop = getter(elm),
        words = prop.split(' '),
        key = words.shift();

      elm[property + '_orig'] = prop;

      if (words.length) {
        elm[property] = words.join(' ');
      }

      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(elm);
    });

    return result;
  };
}]);
