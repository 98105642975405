app.filter('nl2li', function () {
  return function (text, cssClass) {
  	var css = cssClass || '',
  		parts = [];
  	if(text !== undefined){
  		parts = text.split(/\n/ig);
  		if(parts.length > 0){
	  		return parts.map(function(part){
	  			return '<li class="'+css+'">' + part + '</li>';
	  		}).join('');
  		}else{
  			return '<li class="'+css+'">' + text + '</li>';
  		}
  	}
  };
});

