app.controller('Mail2Signup', ['$q', '$filter', '$scope', 'TessituraSDK', 'AuthenticationService', '$modal', '$sce', 'appConfig', '$attrs', '$location', function ($q, $filter, $scope, TessituraSDK, AuthenticationService, $modal, $sce, appConfig,$attrs, $location) {
  // Promises to wait for to consider the page "loaded" 
  var loadingPromises = [];
  // Used for syntactic sugar bellow
  var promise;
  // arrayWrap filter
  var arrayWrap = $filter('arrayWrap');

  var allowedLists = $attrs.allowedLists || '';

  $scope.allowedLists = allowedLists.split(',').map(function(id){
    if( isNaN(parseInt(id, 10)) ){
      id = '0';
    }
    return id.trim();
  }).join(',');


  // Is the initial page loading
  $scope.loading = true;

  // Is the user logged in
  $scope.isLoggedIn = null;

  // Has the user now subscribed, true after form submission
  $scope.subscribed = false;

  // Form email model
  $scope.email = "";

  // Check is the user is logged in
  promise = AuthenticationService.isUserRegistered()
  .then(function (isLoggedIn) {
    $scope.isLoggedIn = isLoggedIn;
  });
  loadingPromises.push(promise);

  /**
   * Ran on form submission.
   * Opens the modal and passes in the email
   */
  $scope.join = function () {

    var modalInstance = $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'mail2-signup-popup.html'),
      controller: 'Mail2SignupPopup',
      scope : $scope,
      resolve: {
        email: function () {
          return $scope.email;
        }
      }
    });

    modalInstance.result
    .then(function () {
      $scope.subscribed = true;
    });
  };

  /**
   * When all resources have loaded.
   * Setting loading to false.
   */
  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
      // Check if the newsletter query param has been set
      var searchVals = $location.search();
      if (searchVals.newsletter !== undefined) {
        $scope.join();
      }
    });
}]);


app.controller('Mail2SignupPopup', ['$scope', '$modalInstance', 'email', 'TessituraSDK', '$filter', function ($scope, $modalInstance, email, TessituraSDK, $filter) {
  // Is the widget loading
  $scope.loading = true;

  var toArray = $filter('toArray');
  // Form details of the user
  $scope.user = {
    email: email,
    first_name: "",
    last_name: ""
  };

  // Object of what the user is subscribed to
  $scope.subscriptions = {};

  // Load the subscriptions the user can subscribe to
  TessituraSDK.GetSubscriptions({
    email: email
  })
  .then(function (response) {
    // Store mailingLists
    // $scope.mailingLists = response.data.result.lists;

    var lists = toArray(response.data.result.lists || {});

    // Store the mailing lists the user can sign up for
    $scope.mailingLists = lists.filter(function (list) {
      return $scope.allowedLists.indexOf(parseInt(list.listId, 10)) !== -1;
    });

    // Add lists that the user is already subscribed
    // into the subscriptions object/
    var subscriptions = response.data.result.subscriptions;
    for (var i in subscriptions) {
      var listId = subscriptions[i];
      $scope.subscriptions[listId] = true;
    }

    // We have finished loading
    $scope.loading = false;
  });

  /**
   * Triggers on form submit.
   * Subscribed the user.
   */
  $scope.subscribe = function () {

    // Build the array of mailinglist ids
    var listIds = [];
    for (var i in $scope.subscriptions) {
      if ($scope.subscriptions[i]) {
        listIds.push(parseFloat(i));
      }
    }

    // Subscribe the user to the mailing list
    TessituraSDK.SetSubscriptions({
      email: $scope.user.email,
      first_name: $scope.user.first_name,
      last_name: $scope.user.last_name,
      lists: listIds,
      unsubscribe: false
    }).then(function(response){
        $scope.subscribed = true;
    });
  };

  /**
   * Closes the model
   */
  $scope.close = function () {
    $modalInstance.dismiss();
  };
}]);
