function PricingRuleMessagesDirective(UserMessagingService) {
  'use strict';

  return {
    restrict: 'E',
    scope: {},
    template: [
      '<div class="user-messages" ng-if="messages.length > 0">',
        '<h2>Messages</h2>',
        '<div class="user-message" ng-repeat="message in messages">',
          '<p class="message">{{ message.Message }}</p>',
        '</div>',
      '</div>'
    ].join(''),
    controller: ['$scope', function ($scope) {
      $scope.messages  = [];
      UserMessagingService.getCartMessages().then(function (messages) {
        $scope.messages = messages;
      }).catch(function (error) {
        $scope.messages = [];
      });
    }]
  };

}

PricingRuleMessagesDirective.$inject = ['UserMessagingService'];

app.directive('pricingRuleMessages', PricingRuleMessagesDirective);
