app.controller('ContributionHistory', ['$scope', '$q', '$filter', 'TessituraSDK', 'appConfig', 'AuthenticationService', function ($scope, $q, $filter, TessituraSDK, appConfig, AuthenticationService) {
  'use strict';

  $scope = angular.extend($scope, {
    loading: true,
    userIsGuest: false,
    contributions: null
  });

  var
    arrayWrap = $filter('arrayWrap'),
    filter = $filter('filter'),
    loginInfo = null;

  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      $scope.userIsGuest = true;
      $scope.loading = false;
      return;
    }

    AuthenticationService.getLoginInfo().then(function (loginInfoEx) {
      loginInfo = loginInfoEx;

      // start the loading
      constituentInfo();
    });
  });

  function constituentInfo() {
    $scope.loading = true;

    return TessituraSDK.GetConstituentInfoEx().then(function (response) {
      var
        result = response.data.result.GetConstituentInfoExResults,
        contribution = arrayWrap(result.Contribution);

      // Filter out passport memberships and 0 value donations
      contribution = contribution.filter(function (contrib) {
        return (
          contrib.fund_no != 'Passport Memberships' &&
          parseInt(contrib.cont_amt, 10) > 0
        );
      });

      if (!contribution.length) {
        $scope.contributions = false;
        return false;
      }

      // Sort by contribution date first
      contribution = $filter('orderBy')(contribution, '-cont_date');

      // Then group by campaign
      $scope.contributions = $filter('groupBy')(contribution, 'campaign');
    })
    .finally(function () {
      // Done loading the main bit
      $scope.loading = false;
    });
  };
}]);
