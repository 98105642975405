/* global moment */
app.controller('MyTicketsV2Controller', ['$scope', '$q', 'appConfig', 'MosSwitcher', 'AuthenticationService', 'UpcomingEventsService', 'Exchange', 'TessituraSDK', function ($scope, $q, appConfig, MosSwitcher, AuthenticationService, UpcomingEventsService, Exchange, TessituraSDK) {
    // Local variables
    var loginInfo;
  
    // Scope variables
    $scope = angular.extend($scope, {
      loading: true,
      isGuest: false,
      canExchange: false,
      canDonate: false,
      upcomingEvents: null
    });
  
    // Ask user to login if required
    AuthenticationService.requireLogin()
    .then(AuthenticationService.isUserRegistered)
    // Check the user is logged in first
    .then(function (isUserRegistered) {
      if (!isUserRegistered) {
        // This user is not registered
        $scope.isGuest = true;
  
        // No longer need to display loading
        $scope.loading = false;
  
        // No need to continue
        return $q.resolve(true);
      }
  
      // Try to gracefully switch to the default mode of sale for the user
      return MosSwitcher.defaultMos(true);
    })
    .then(function () {
      // Get the default sale mode for the user
      return MosSwitcher.getSaleMode();
    })
    .then(function (saleMode) {
      // Is the same mode subscriber?
      $scope.canExchange = saleMode.canExchange;
      $scope.canDonate = saleMode.canDonate;
  
      // Grab login info
      return AuthenticationService.getLoginInfo();
    })
    .then(function (loginInfoEx) {
      loginInfo = loginInfoEx;
  
      return upcomingEvents();
    })
    .finally(function () {
      $scope.loading = false;
    });
  
    function upcomingEvents() {
      return UpcomingEventsService.getUserUpcomingEvents(loginInfo)
      .then(function (upcomingEvents) {
        $scope.upcomingEvents = upcomingEvents;
      })
      .catch(function () {
        $scope.upcomingEvents = false;
      });
    };
  }]);
  