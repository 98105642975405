/* global trackEvent */

app.controller('CartController', ['$scope', '$attrs', '$q', '$filter', '$modal', '$sce', 'appConfig', 'TessituraSDK', '$window', 'CacheStorage', 'Donation', 'Notifications', 'AuthenticationService', 'Exchange', 'Cart', 'AccountCredit', 'Router', 'GoogleTagManager', 'UserMessagingService', 'MosSwitcher', '$injector', function ($scope, $attrs, $q, $filter, $modal, $sce, appConfig, TessituraSDK, $window, CacheStorage, Donation, Notifications, AuthenticationService, Exchange, Cart, AccountCredit, Router, GoogleTagManager, UserMessagingService, MosSwitcher, $injector) {

  var cartData = {},
    arrayWrap = $filter('arrayWrap'),
    loadingPromises = [],
    performances = {},
    packages = {},
    exchangeModes = [],
    upsoldItems = {
      prods: [],
      perfs: [],
      seasons: []
    },
    sessionTransferRequired = false;

  $scope.loading = true;
  $scope.packageHandlingDiscount = 0;

  $scope.updatingCart = false;

  loadingPromises.push(reloadData());
  loadingPromises.push(reloadExchangeModes());

  $scope.accountCredit = [];

  // Should the donate checkbox be checked
  $scope.donate = false;
  // Donation values in the drop down
  $scope.donationValues = [];
  // Currently selected donation amount
  $scope.selectedDonation = null;

  // Static ouput of the useDonationPopup pop function
  $scope.useDonationPopup = null;

  // Keys of the items being removed
  $scope.itemsBeingRemoved = {};

  // Removed items is cleared once GetCart confirms they
  // have been removed.
  $scope.itemsRemoved = {};

  // Is this an YAP application
  $scope.yapApplication = false;

  // Allocation reached
  $scope.allocationReached = [];

  GoogleTagManager.sendCheckoutStep(1);

  /**
   * Load the donation values for the select
   */
  loadingPromises.push(getDonationValues());

  /**
   * Find out if the user currently has a
   * donation and set it.
   */
  loadingPromises.push(getRecommendDonationAmount());

  /**
   * Check if allocation is being met
   */
  loadingPromises.push(checkAllocation());

  function getDonationValues() {
    return Donation.getDonationValues().then(function (values) {
      $scope.donationValues = values;
      var findDonation = values.filter(function(value) {
        return value.is_default;
      });

      $scope.selectedDonation = findDonation.length ? findDonation[0] : null;
    });
  }

  function getRecommendDonationAmount() {
    // Start loading the recommended donation
    // incase the user doesnt have one
    return Donation.getRecommendDonationAmount().then(function (amount) {
      $scope.selectedDonation = $scope.selectedDonation || amount;
    });
  }

  function checkAllocation() {
    return Cart.checkAllocation().then(function (illegal) {
      $scope.allocationReached = illegal;
    });
  }

  /**
   * Set the donation amount.
   * It will remove the current donation if there is one
   * It will then add the new one if needed, (not needed if setting to 0)
   */
  function updateDonationInCart() {
    var amount;

    if ($scope.donate === true) {
      amount = $scope.selectedDonation.amount;
    } else {
      amount = 0;
    }

    Donation.updateContributionInCart(amount, $scope.selectedDonation.fund)
      // Ignore errors
      .finally(reloadData);
  }
  $scope.updateDonationInCart = updateDonationInCart;

  /**
   * Same as above, but one bu one based on what's passed
   */
  function setDonation(donation) {
    $scope.donate = true;
    $scope.selectedDonation = donation;

    updateDonationInCart();
  }
  $scope.setDonation = setDonation;

  // Once everything on the page is loaded
  $q.all(loadingPromises)
    .then(function () {
      // We are done loading
      $scope.loading = false;
    });


  /**
   * Should we show the donation popup.
   * Theres 3 checks that decide if we should or not
   * @return {Promise}  Promise with bool as param
   */
  function useDonationPopup() {
    if (CacheStorage.has('dont-show-donation-popup') && CacheStorage.get('dont-show-donation-popup')) {
      return $q.resolve(false);
    }

    if (cartData && cartData.Contribution && cartData.Contribution.length > 0) {
      return $q.resolve(false);
    }

    return getDonationPopupMessage()
    .then(function (message) {
      if (message) {
        return true;
      } else {
        return false;
      }
    });

  }

  function getDonationPopupMessage() {
    return UserMessagingService.getCartMessages()
    .then(function (messages) {
      for (var i in messages) {
        var message = messages[i];
        if (message.MessageType.Description == 'DonationPopUp') {
          return message;
        }
      }
      return null;
    });
  }


  /**
   * Removes the currently applied gift certificate
   */
  function removeGiftCertificate(gc_no) {

    if(!removeGiftCerticateConfirm()){
      return false;
    }

    $scope.updatingCart = true;

    $scope.itemsBeingRemoved[gc_no] = true;

    var RemoveGiftCertificateParams = {
      RedemptionCode: gc_no
    };

    return TessituraSDK.RemoveGiftCertificate(RemoveGiftCertificateParams)
      .then(reloadData)
      .finally(function () {
        $scope.itemsBeingRemoved[gc_no] = false;
      });
  }
  $scope.removeGiftCertificate = removeGiftCertificate;

  function removeContribution(contribution) {

    if (!removeItemConfirm()) {
      return false;
    }

    $scope.updatingCart = true;

    if (CacheStorage.get('syosDonation')) {
      Cart.clear().then(function () {
        CacheStorage.remove('syosDonation');
        window.location.reload();
      });
    } else {
      $scope.itemsBeingRemoved[contribution.ref_no] = true;

      if ($scope.isDonatingAccountCredit()) {
        for (var i in $scope.accountCredit) {
          var credit = $scope.accountCredit[i];
          if (contribution.contribution_amt == credit.on_account) {
            return AccountCredit.updateCartFunds(credit.pmt_method, 'none')
            .then(function (response) {
              var
                result = response.data.result,
                cart = result && result.GetCartResults ? result.GetCartResults : {};

              return reloadData(
                Cart.processCartData(cart)
              );
            })
            .finally(function () {
              $scope.itemsBeingRemoved[contribution.ref_no] = false;
            });
          }
        }
      }

      var RemoveContributionParams = {
        iLineItemNumber: contribution.ref_no
      };

      return TessituraSDK.RemoveContribuion(RemoveContributionParams)
        .then(reloadData)
        .finally(function () {
          $scope.itemsBeingRemoved[contribution.ref_no] = false;
        });
    }
  }
  $scope.removeContribution = removeContribution;


  function reloadAccountCredit() {
    return AuthenticationService.isUserRegistered()
    .then(function (isUserRegistered) {
      if (isUserRegistered) {
        return TessituraSDK.GetOnAccountInfo();
      } else {
        return $q.resolve(false);
      }
    })
    .then(function (response) {
      if (response !== false) {
        $scope.accountCredit = arrayWrap(response.data.result.GetOnAccountResults.OnAccountItem);
      }
    })
    .catch(function () {
      $scope.accountCredit = [];
    });
  }


  function reloadExchangeModes() {
    Exchange.getExchangeModes()
    .then(function (_exchangeModes) {
      exchangeModes = _exchangeModes;
    });
  }

  /**
   * Reloads all of the cart data and runs any checks
   * to make sure the cart is correct, only 1 donation
   * gift card value isnt to high, etc...
   */
  function reloadData(passedCartDate) {
    if (passedCartDate) {
      passedCartDate = $q.resolve(passedCartDate);
    } else {
      passedCartDate = Cart.getCart(true);
    }

    return passedCartDate.then(function(_cartData){
        cartData = _cartData;
      })
      .then(function () {
        var promise = $q.resolve();

        var nothingInCart = (
          !cartData.LineItem.length &&
          !cartData.PackageLineItem.length &&
          !cartData.Payment.length &&
          !cartData.Contribution.length
        );

        if ($scope.updatingCart && (nothingInCart || sessionTransferRequired)) {
          promise = AuthenticationService.transferSession(function () {
            return Cart.getCart(true).then(function (_cartData) {
              cartData = _cartData;

              sessionTransferRequired = false;
            });
          }).finally(function () {
              window.location.reload();
          });
        }

        return promise;
      })
      // Get more account credit
      .then(reloadAccountCredit)
      .then(function(){
        $scope.cartData = cartData;

        var cartHasItems = cartData && (cartData.LineItem && cartData.LineItem.length) || (cartData.PackageLineItem && cartData.PackageLineItem.length);
        if (cartHasItems) {
          GoogleTagManager.sendCartContents($scope.cartData);
        }

        useDonationPopup()
        .then(function (usePopup) {
          $scope.useDonationPopup = usePopup;
        })

        Exchange.checkExchangeModeRules()
        .then(function (result) {
          $scope.showExchangeModeRuleError = !result;
        });

        // Check if this is a YAP application
        if ('Order' in cartData) {
          if ('notes' in cartData.Order && angular.isString(cartData.Order.notes)) { // Check for a note
            $scope.yapApplication = cartData.Order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1;
          } else if ('custom_6' in cartData.Order && angular.isString(cartData.Order.custom_6)) { // Check for a custom value
            $scope.yapApplication = cartData.Order.custom_6.length;
          }
        }

        // Contribution code
        if (cartData.Contribution.length) {
          // if they have a contribution, see it as the default
          for (var i = 0; i < cartData.Contribution.length; i++) {
            var contribution = cartData.Contribution[i];

            var findDonationInCart = $scope.donationValues.filter(function (donation) {
              return (
                parseInt(donation.fund, 10) === parseInt(contribution.fund_no, 10) &&
                parseInt(donation.amount, 10) === parseInt(contribution.contribution_amt, 10)
              );
            });

            var foundDonationInCart = findDonationInCart.length ? findDonationInCart[0] : undefined;

            if (foundDonationInCart === undefined) {
              continue;
            }

            $scope.selectedDonation = foundDonationInCart;
            $scope.donate = true;
          }

          GoogleTagManager.sendCartContents($scope.cartData);
        } else {
          $scope.donate = false;
        }

        // Get all the line items into one array
        var lineItems = cartData.PackageLineItem.concat(cartData.LineItem);

        // Get all the subLineItems into one array
        var subLineItems = cartData.PackageSubLineItem.concat(cartData.SubLineItem);

        // calculate the package subtotal discount
        var packageLineItems= $filter('groupBy')(cartData.PackageLineItem, 'line_source_no');
        var nonDYOSublineItems = [];
        var nonDYOLineItems = [];

        for(var k in packageLineItems){
          var packages = packageLineItems[k];
          packages.forEach(function(package){
            var result = cartData.PackageSubLineItem.filter(function (subLineItem) {
              // A different check of upgrade/renwals
              if (package.alt_upgrd_ind == 'U') {
                return (
                  // subline item correlates to package
                  subLineItem.li_seq_no === package.li_seq_no &&
                  // ignore first entry
                  package.perf_no !== '0' &&
                  // and is a primary package
                  package.primary_ind == 'Y' &&
                  // and not a flex package
                  package.flex_ind == 'N'
                );
              }

              return (
                // subline item correlates to package
                subLineItem.li_seq_no === package.li_seq_no &&
                // ignore first entry
                package.perf_no !== '0' &&
                // and is a primary package
                (('primary_ind' in package && package.primary_ind == 'Y') || (!('primary_ind' in package) && package.flex_ind == 'Y'))
              );
            });

            nonDYOSublineItems = nonDYOSublineItems.concat(result);
          });

          nonDYOLineItems = cartData.PackageSubLineItem.filter(function (subLineItem) {
            return !nonDYOSublineItems.some(function (nonDYOSli) {
              return subLineItem.li_seq_no === nonDYOSli.li_seq_no;
            });
          });

          // Remove all parking subline items
          nonDYOSublineItems = nonDYOSublineItems.filter(function (subLineItem) {
            // Looks like facility 13 is Self Parking so lets use that
            return parseInt(subLineItem.facility_no, 10) !== 13;
          });

          var packageItems = $filter('toArray')($filter('groupBy')(nonDYOSublineItems, 'li_seq_no'));
          var sublineitemFees = cartData.SubLineItemFee;

          if (packageItems.length > 0) {
            $scope.packageHandlingDiscount = packageItems.reduce(function (acc, packageItem) {
              var fee = sublineitemFees.filter(function (f) {
                return (
                  parseInt(f.li_seq_no, 10) === parseInt(packageItem[0].li_seq_no, 10) &&
                  parseInt(f.sli_no, 10) === parseInt(packageItem[0].sli_no, 10)
                );
              });

              if (fee.length) {
                fee = parseFloat(fee[0].fee_amt);
              } else {
                fee = 0;
              }

              acc += packageItem.length * fee;
              return acc;
            }, 0);

            // New fee calculations -.-
            if ($scope.packageHandlingDiscount === 0) {
              $scope.packageHandlingDiscount = nonDYOLineItems.reduce(function (acc, subLineItem) {
                var fee = sublineitemFees.filter(function (f) {
                  return (
                    parseInt(f.li_seq_no, 10) === parseInt(subLineItem.li_seq_no, 10) &&
                    parseInt(f.sli_no, 10) === parseInt(subLineItem.sli_no, 10)
                  );
                });

                if (fee.length) {
                  fee = parseFloat(fee[0].fee_amt);
                } else {
                  fee = 0;
                }

                return acc + fee;
              }, 0);
            }
          }
        }
        return $q.all([
          loadPackagesForLineItems(lineItems),
          loadPerformancesForSubLineItems(subLineItems)
        ]);
      })
      .then(addLongTitleToPackages)
      .then(getDonationPopupMessage)
      .then(hideRoundUpDonation)
      .then(hideCheckOffDonation)
      .then(checkAllocation)
      .then(MosSwitcher.notifyOfMos)
      // Remove the loading icon after any promises
      // in the cartReload have ran
      .finally(function () {
        $scope.updatingCart = false;
        trackCartView();
        $scope.$emit('cart.updated');
      });
  }

  function trackCartView() {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=2130164419763855750-EU&client_type=gtag#view_cart
    var totalPrice = 0;
    var cartItems = $scope.cartData.LineItem.map(function(item) {
        var sublineItems = $scope.cartData.SubLineItem.filter(function (subItem) {
            return subItem.li_seq_no === item.li_seq_no;
        });

        var lineTotal = sublineItems.reduce(function (carry, item) {
            return carry + parseFloat(item.due_amt);
        }, 0);

        totalPrice+= lineTotal;

        return {
          item_id: item.perf_no,
          item_name: item.perf_desc,
          coupon: $scope.cartData.Order.source_no,
          location_id: item.facil_no,
          price: lineTotal,
          quantity: sublineItems.length
        };
    });

    var data = {
      currency: 'USD',
      value: totalPrice,
      items: cartItems
    };

    trackEvent(
        'view_cart',
        data
    );
  }

  $scope.getSubTotal = function(){
    var subTotal = 0;

    if (cartData.Order && $scope.packageHandlingDiscount!==undefined) {
      subTotal = parseFloat(cartData.Order.SubTotal) + $scope.packageHandlingDiscount;
    }

    return subTotal;
  }

  function addLongTitleToPackages() {

    // For each line item, get the web content
    // Then try to override the line items pkg_desc with the long title
    var lineItems = $scope.getAllParentPackageLineItems();
    var packageWebContentPromises = lineItems.map(function (lineItem) {
      return TessituraSDK.GetWebContent({
        iPackageNumber: lineItem.pkg_no
      })
      .then(function (response) {

        var webContents = arrayWrap(response.data.result.GetWebContentResults.WebContent);

        lineItem.webContent = webContents;

        webContents.forEach(function (webContent) {
          // Long Title - Override tessi's title
          if (webContent.content_type == appConfig.webContentTypes.longTitle) {
            lineItem.pkg_desc = webContent.content_value;
          }

          // Bonus up - Parse the json and add it to the upsells array
          if (webContent.content_type == appConfig.webContentTypes.bonusUpsell) {
            var upsells;
            try {
               upsells = angular.fromJson(webContent.content_value);
              if (!angular.isArray(upsells)) {
                throw new Error('BONUS_UPSELLS for package isnt a json array');
              }
            } catch (e) {
              e.message = 'Failed to load upsell json.\n' + e.message;
              Raven.captureException(e, {tags: {key: 'tessitura-provided-json'}});
              return;
            }

            if (upsells) {
              upsells.forEach(function (upsell) {
                if (upsell.prodSeasonNo) {
                  upsoldItems.prods.push(upsell.prodSeasonNo);
                }

                if (upsell.perfNo) {
                  upsoldItems.perfs.push(upsell.perfNo);
                }

                if (upsell.seasonNo) {
                  upsoldItems.seasons.push(upsell.seasonNo);
                }
              });
            }

            upsells = upsells.filter(function (upsell) {
              return hasUpsellBeenForfilled(upsell, lineItem.pkg_no) == false;
            });

            upsells = upsells.map(function (upsell) {
              upsell.lineItem = lineItem.pkg_no;

              return upsell;
            });

            if ($scope.isDYOPackage(lineItem)) {
              var parentLineItem = getDYOParentPackage(lineItem);

              if (parentLineItem) {
                parentLineItem.upsells = parentLineItem.upsells || [];

                if (!parentLineItem.upsells.length) {
                  parentLineItem.upsells = upsells;
                } else {
                  var missing = upsells.filter(function (a) {
                    return parentLineItem.upsells.some(function (b) {
                      return (a.message == b.message) === false;
                    });
                  });

                  missing.forEach(function (item) {
                    parentLineItem.upsells.push(item);
                  });
                }
              } else {
                lineItem.upsells = upsells;
              }
            } else {
              lineItem.upsells = upsells;
            }
          }
        });
      });
    });

    // Ignore errors because the real name isnt that important
    return $q.all(packageWebContentPromises)
    .catch(function(){});
  }

  function compare(array1, array2, callback) {
    return array1.filter(function(a) {
      return array2.some(function(b) {
        return callback(a, b);
      });
    })
  }

  function isInArray(needle, haystack, haystackId) {
    return haystack.some(function (item) {
      return item[haystackId] == needle;
    });
  }


  function hasUpsellBeenForfilled(upsell, lineItem) {
    if (upsell.perfNo) {
      return isInArray(upsell.perfNo, cartData.SubLineItem, 'perf_no');
    }

    if (upsell.prodSeasonNo) {
      return isInArray(upsell.prodSeasonNo, cartData.SubLineItem, 'prod_season_no');
    }

    if (upsell.seasonNo) {
      var found = cartData.PackageLineItem.filter(function (packageLineItem) {
        return (
          packageLineItem.season_no == upsell.seasonNo &&
          'notes' in packageLineItem &&
          packageLineItem.notes == 'Parking for ' + lineItem
        );
      });

      return found.length;
    }

    return false;
  }

  function attachWebContentToPerformance(performances, webContents) {
    angular.forEach(arrayWrap(performances), function (performance) {
      performance.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == performance.inv_no;
      });

      var foundTba = performance.webContent.filter(function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.dateTba;
      });

      if (foundTba.length) {
        performance.dateTba = foundTba[0].content_value;
      }
    });
  }

  function attachWebContentToPackage(currentPackage, webContent) {
    angular.forEach(arrayWrap(currentPackage), function (currPackage) {
      currPackage.webContent = arrayWrap(webContent).filter(function (content) {
        return webContent.inv_no == currPackage.pkg_no;
      });
    });
  }

  /**
   * Takes in sub line items and loads all of the
   * performances with its pricing data locally so
   * it can be used to get the base pricing.
   *
   * @param  Array   subLineItems  Array of SubLineItem elements from Tessi
   * @return Promise               Promise for all the http requests
   */
  function loadPerformancesForSubLineItems(subLineItems) {
    return AuthenticationService.getLoginInfo()
    .then(function (loginInfo) {

      var promises = [];
      // Clear the current performances cache
      performances = {};

      // Loop over each sub line item and get its performance
      for(var i = 0; i < subLineItems.length; i++) {
        var subLineItem = subLineItems[i];

        // Dont load a number of 0 (happens in subPackageLineitems)
        // Dont get this performance if we already have it
        if (subLineItem.perf_no == "0" || performances[subLineItem.perf_no] !== undefined) {
          continue;
        }

        // Set it in the object so we dont make multiple requests
        performances[subLineItem.perf_no] = null;

        // Make the request to tessi to get the data
        var GetPerformanceDetailWithDiscountingExParams = {
          iPerf_no: subLineItem.perf_no,
          iModeOfSale: parseInt(loginInfo.MOS)
        };
        var promise = TessituraSDK.GetPerformanceDetailWithDiscountingEx(GetPerformanceDetailWithDiscountingExParams)
        .then(function (response) {
          var performance = response.data.result.GetPerformanceDetailWithDiscountingExResult;
          performance.AllPrice = arrayWrap(performance.AllPrice);
          performances[performance.Performance.inv_no] = performance;
          attachWebContentToPerformance(
            response.data.result.GetPerformanceDetailWithDiscountingExResult.Performance,
            response.data.result.GetPerformanceDetailWithDiscountingExResult.WebContent
          );
        });
        promises.push(promise);
      }

      return $q.all(promises);
    });
  }

  function loadPackagesForLineItems(lineItems) {
    return AuthenticationService.getLoginInfo().then(function (loginInfo) {
      var promises = [];

      lineItems.forEach(function (lineItem) {
        if ( !('pkg_no' in lineItem) || !angular.isString(lineItem.pkg_no) ) {
          return false;
        }

        var pkgNo = parseInt(lineItem.pkg_no, 10);

        if (pkgNo === 0 || pkgNo in packages) {
          return false;
        }

        packages[pkgNo] = null;

        var
          promise = null,
          modeOfSale = parseInt(loginInfo.MOS, 10);

        if ('flex_ind' in lineItem && lineItem.flex_ind === 'Y') {
          promise = TessituraSDK.GetNFSPackageDetailEx({
            PackageId: pkgNo,
            ModeOfSale: modeOfSale
          }).then(function (response) {
            var currentPackage = response.data.result.GetNFSPackageDetailExResult;

            packages[pkgNo] = currentPackage;

            attachWebContentToPackage(
              currentPackage,
              response.data.result.GetNFSPackageDetailExResult.WebContent
            );
          });
        } else {
          promise = TessituraSDK.GetPackageDetailWithDiscountingEx ({
            'PackageID': pkgNo,
            'ModeOfSale': modeOfSale
          }).then(function (response) {
            var currentPackage = response.data.result.GetPackageDetailWithDiscountingExResult;
            packages[pkgNo] = currentPackage;
            attachWebContentToPackage(
              currentPackage,
              response.data.result.GetPackageDetailWithDiscountingExResult.WebContent
            );
          });
        }

        promises.push(promise);
      });

      return $q.all(promises);
    });
  }

  $scope.isUpsellItem = function (performanceLineItem) {
    var isUpsellItem = false;

    upsoldItems.perfs.forEach(function (perf) {
      if (!isUpsellItem && performanceLineItem.inv_no == perf) {
        isUpsellItem = true;
      }
    });

    if (!isUpsellItem) {
      upsoldItems.prods.forEach(function (prod) {
        if (!isUpsellItem && performanceLineItem.prod_season_no == prod) {
          isUpsellItem = true;
        }
      });
    }

    return isUpsellItem;
  };

  /**
   * Takes in a lineitem that has a performance and returns
   * an actual Performance object for it
   */
  $scope.performanceFromLineitem = function (performanceLineItem) {
    if (performances[performanceLineItem.perf_no]) {
      return performances[performanceLineItem.perf_no].Performance;
    }
  };

  $scope.isDigitalPerformance = function (lineItem) {
    if (!lineItem) {
      return false;
    }
    return digitalPerformanceFilter(lineItem);
  }

  /**
   * @param {*} lineItem
   * @return boolean
   */
  $scope.showExtraPerformanceFacilityDetails = function (lineItem) {
    if (!lineItem) {
      return false;
    }

    if (digitalPerformanceFilter(lineItem)) {
      return false;
    }

    var performance = $scope.performanceFromLineitem(lineItem);

    if (!performance) {
      return false;
    }

    var sublineItems = $scope.getSubLineItemsForLineItem(lineItem);

    return true;
  }

  /**
   * Takes in a sublineitem and returns its base price
   *
   * @param  SubLineItem subLineItem  Sublineitem from Tessi
   * @return String
   */
  $scope.getBasePrice = function (subLineItem) {
    var performance = performances[subLineItem.perf_no];

    if (!(performance && performance.AllPrice)) {
      return -1;
    }

    for(var i = 0; i < performance.AllPrice.length; i++) {
      var price = performance.AllPrice[i];
      if (price.zone_no == subLineItem.zone_no && price.price_type == subLineItem.price_type) {
        return parseFloat(price.base_price);
      }
    }

    return -1;
  };

  /**
   * Calculates the total discount of all items
   * @return int
   */
  $scope.getDiscountAmount = function () {
    var subLineItems = cartData.SubLineItem;
    var amount = 0;

    if (angular.isArray(subLineItems)) {
      subLineItems.forEach(function (subLineItem) {
        var basePrice = $scope.getBasePrice(subLineItem);
        if (basePrice > -1 && subLineItem.due_amt > 0) {
          amount -= (basePrice - subLineItem.due_amt);
        }
      });
    }

    return false;
  };

  function getDYOGroups() {
    var dyoPackages = arrayWrap(cartData.PackageLineItem).filter(function (packageLineItem) {
      return 'notes' in packageLineItem && packageLineItem.notes.indexOf('Part of ') === 0 && packageLineItem.li_no == 0 && packageLineItem.notes.indexOf('Parking') === -1;
    });

    dyoPackages = $filter('groupBy')(dyoPackages, 'notes');

    return Object.keys(dyoPackages).reduce(function (carry, key) {
      var lineItemNumbers = dyoPackages[key].map(function (item) {
        return parseInt(item.li_seq_no, 10);
      });

      carry.push(lineItemNumbers);

      return carry;
    }, []);
  }

  /**
   * Is the package part of a DYO group
   * @param  {Object}  package PackageLineItem
   * @return {Boolean}
   */
  $scope.isDYOPackage = function(package) {
    var groups = getDYOGroups();

    if (!Array.isArray(groups)) {
      return false;
    }

    // Loop over the groups and simple package numbers
    // Do any of the numbers match the package number of
    // the package to check
    return groups.some(function (packgeNoGroup) {
      return packgeNoGroup.some(function (packageNo) {
        return packageNo == package.pkg_li_no;
      });
    });
  }

  /**
   * Is the package the start of a DYO group.
   * If DYOGroups is [[1], [5,6]]
   * This will return true for a package with line item number of 5 or 1
   *
   * @param  {Object}  package PackageLineItem
   * @return {Boolean}
   */
  function isRootDYOPackage(package) {
    var groups = getDYOGroups();

    if (!Array.isArray(groups)) {
      return false;
    }

    // Loop over the groups and simple package numbers
    // Do any of the numbers match the package number of
    // the package to check
    return groups.some(function (packgeNoGroup) {
      return packgeNoGroup.length && packgeNoGroup[0] == package.pkg_li_no;
    });
  }

  /**
   * Returns the group that a DYO package is in
   * If DYOGroups is [[1], [5,6]]
   * If the user pass in a package will line number 6 it will return [5,6]
   *
   * @param  {Object}  package PackageLineItem
   * @return {Array}
   */
  $scope.getDYOPackageGroup = function (package) {
    var groups = getDYOGroups();

    if (!Array.isArray(groups)) {
      return [];
    }

    for (var groupIndex in groups) {
      var packgeNoGroup = groups[groupIndex];
      for (var packageNoIndex in packgeNoGroup) {
        var packageNo = packgeNoGroup[packageNoIndex];
        if (packageNo == package.pkg_li_no) {
          return packgeNoGroup;
        }
      }
    }
  }

  /**
   * Does the same as $scope.getDYOPackageGroup
   * BUT returns the actual package objects, not the ids
   *
   * @param  {Object}  package PackageLineItem
   * @return {Array}
   */
  $scope.getDYOPackageGroupExpanded = function (parentPackageLineItem) {
    var packageIdsInGroup = $scope.getDYOPackageGroup(parentPackageLineItem);

    return packageIdsInGroup
    // Convert the ids in to the the parentPackageLineItems
    .map(function (packageLineNumber) {
      for (var i in cartData.PackageLineItem) {
        var package = cartData.PackageLineItem[i];
        if (package.pkg_li_no == packageLineNumber) {
          return package;
        }
      }
    })
  }

  /**
   * Grabs the parent DYO package item
   */
  function getDYOParentPackage(lineItem) {
    var group = $scope.getDYOPackageGroup(lineItem);

    if (!Array.isArray(group) || !group.length) {
      return null;
    }

    var allParents = $scope.getAllParentPackageLineItems();

    for (var i in allParents) {
      if (parseInt(allParents[i].pkg_li_no, 10) === group[0]) {
        return allParents[i];
      }
    }

    return null;
  }

  /**
   * Returns an array of all Packages
   */
  $scope.getAllParentPackageLineItems = function () {
    if (cartData.PackageLineItem) {
      return cartData.PackageLineItem
        .filter(function (packageLineItem) {
          return packageLineItem.pkg_li_no === packageLineItem.li_seq_no;
        });
    }
  };

  /**
   * Returns an array of all Packages
   */
  $scope.getParentPackageLineItems = function () {
    if (cartData.PackageLineItem) {
      return cartData.PackageLineItem
        .filter(function (packageLineItem) {
          return packageLineItem.pkg_li_no === packageLineItem.li_seq_no &&
            (!$scope.isDYOPackage(packageLineItem) || isRootDYOPackage(packageLineItem));
        });
    }
  };

  /**
   * Returns an array of all Packages
   */
  $scope.getPackageLineItemsForParentPackage = function (parentPackageLineItem) {

    if (!cartData.PackageLineItem) {
      return;
    }

    if ($scope.isDYOPackage(parentPackageLineItem)) {

      var packages = $scope.getDYOPackageGroupExpanded(parentPackageLineItem)
      // Now get the package line items for our parent packages
      .map(getPackageLineItemsForParentPackage)

      // Finally join this array together
      return Array.prototype.concat.apply([], packages);

    } else {
      return getPackageLineItemsForParentPackage(parentPackageLineItem);
    }

  };

  /**
   * Returns an array of all Packages
   */
  $scope.getAllPackageLineItemsForParentPackage = function (parentPackageLineItem) {
    if (!cartData.PackageLineItem) {
      return;
    }

    return getPackageLineItemsForParentPackage(parentPackageLineItem);
  };

  function getPackageLineItemsForParentPackage(parentPackageLineItem) {
    return cartData.PackageLineItem
      .filter(function (packageLineItem) {
        // We group flex and non flex packages differently
        if (parentPackageLineItem.flex_ind == "Y") {
          return packageLineItem.li_no === parentPackageLineItem.li_seq_no;
        } else {
          return packageLineItem.pkg_li_no  === parentPackageLineItem.li_seq_no && packageLineItem.perf_no !== '0';
        }
      });
  }

  /**
   * Returns if this is "super package" (identified by web content at the moment)
   */
  $scope.isSuperPackage = function (parentPackageLineItem) {
    return parentPackageLineItem.webContent.some(function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.webSYOS;
    });
  };

  /**
   * Returns an array of all Packages
   */
  $scope.getPackageSubLineItemsForPackage = function (package) {
    return cartData.PackageSubLineItem
      .filter(function (subPackage) {
        return subPackage.li_seq_no == package.li_seq_no;
      });
  };

  /**
   * Takes in a parent package line item and returns the
   * number of subscriptions that each sub package has
   */
  $scope.numberOfSubscriptionsInParentPackage = function (parentPackageLineItem) {
    var packageLineItems = $scope.getPackageLineItemsForParentPackage(parentPackageLineItem);
    var firstPackageLineItem = packageLineItems[0];
    var packageSubLineItems = $scope.getPackageSubLineItemsForPackage(firstPackageLineItem);
    return packageSubLineItems.length;
  };

  /**
   * Takes in a parent package line item and returns the
   * number of subscriptions that each sub package has
   */
  $scope.numberOfYAPAttendeesInParentPackage = function (parentPackageLineItem) {
    var packageLineItems = $scope.getPackageLineItemsForParentPackage(parentPackageLineItem);
    var firstPackageLineItem = packageLineItems[0];
    var packageSubLineItems = $scope.getPackageSubLineItemsForPackage(firstPackageLineItem);

    var priceTypes = cartData.PriceType.map(function (priceType) {
      return {
        description: priceType.description,
        quantity: packageSubLineItems.filter(function (subLineItem) {
          return parseInt(subLineItem.price_type, 10) === parseInt(priceType.price_type, 10);
        }).length
      };
    });

    // Remove 0 quantity values
    priceTypes = priceTypes.filter(function (priceType) {
      return priceType.quantity;
    });

    // Nice display
    priceTypes = priceTypes.map(function (priceType) {
      return priceType.quantity + ' x ' + priceType.description;
    });

    return priceTypes.join('<br>');
  };

  /**
   * Takes in a parent package line item and returns the total price
   * by summing up all the sub packages
   */
  $scope.totalPriceOfParentPackage = function (parentPackageLineItem) {
    if (isRootDYOPackage(parentPackageLineItem)) {
      return totalPriceOfDYOParentPackage(parentPackageLineItem);
    }

    return totalPriceOfParentPackage(parentPackageLineItem);
  };

  function totalPriceOfDYOParentPackage(parentPackageLineItem) {
    var parentLineItems = $scope.getDYOPackageGroupExpanded(parentPackageLineItem);

    return parentLineItems.reduce(function (carry, parentLineItem) {
      return carry += totalPriceOfParentPackage( parentLineItem );
    }, 0);
  }

  function totalPriceOfParentPackage(parentLineItem) {
    var addition = (parseInt(parentLineItem.facil_no, 10) === 13) ? 0 : packageSublineItemCost(parentLineItem);

    var packageLineItems = $scope.getAllPackageLineItemsForParentPackage(parentLineItem);

    var subLineItems = [];

    packageLineItems.forEach(function (packageLineItem) {
      subLineItems = subLineItems.concat( $scope.getPackageSubLineItemsForPackage(packageLineItem) );
    });

    return subLineItems.reduce(function (total, subLineItem) {
      return total + parseFloat(subLineItem.due_amt) + addition;
    }, 0);
  }

  /**
   * Returns an array of all the LineItems from the cart
   */
  $scope.getLineItems = function () {
    return cartData.LineItem;
  };

  /**
   * Returns all the subline items that are for a lineitem
   */
  $scope.getSubLineItemsForLineItem  = function (lineItem) {
    return cartData.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no;
    });
  };

  /**
   * Returns all the PriceType objects for LineItem
   * that the user has brought tickets for
   */
  $scope.getPriceTypesForLineItem = function (lineItem) {
    var priceTypeIds = cartData.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no;
    }).map(function(subLineItem){
      return subLineItem.price_type;
    });

    return cartData.PriceType.filter(function (priceType) {
      return priceTypeIds.indexOf(priceType.price_type) > -1;
    });
  };

  /**
   * Returns the sublineitems that are for a lineitem
   * and a pricetype
   */
  $scope.getSubLineItemsForPriceTypes = function (lineItem, priceType) {
    return cartData.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no && subLineItem.price_type == priceType.price_type;
    });
  };

  /**
   * Returns the ADA data for the given lineitem
   */
  $scope.getADADetailsForLineItem = function (lineItem, isPackage) {
    if (!lineItem) {
      return [];
    }

    var details = [];

    if (isPackage) {
      var adaCacheKey = 'PackageADAMeta-' + lineItem.pkg_no;

      if (CacheStorage.has(adaCacheKey)) {
        details = [].concat(details, CacheStorage.get(adaCacheKey).Notes.split("\n"));
      }
    } else {
      var cacheKey = 'PerformanceADAMeta-' + lineItem.perf_no;

      if (CacheStorage.has(cacheKey)) {
        var
          notes = JSON.parse(CacheStorage.get(cacheKey).Notes),
          options = 'options' in notes ? Object.keys(notes.options) : [],
          other = 'other' in notes && angular.isString(notes.other) ? notes.other : null;

        if (options.length) {
          details = [].concat(details, options);
        }

        if (other) {
          details.push( 'Other special request: ' + other );
        }
      }
    }

    return details.join(", ");
  };

  /**
   * Check if a gift certificate preview is available for the given payment
   */
  $scope.getGiftCertificatePreviewAvailable = function (payment) {
    if (!payment) {
      return false;
    }

    var cacheKey = 'BuyGiftCertificateMeta-' + payment.gc_no;

    if (CacheStorage.has(cacheKey)) {
      try {
        var giftCertificate = CacheStorage.get(cacheKey);

        return giftCertificate.emailGiftCertificate.giftType == 'gift_certificate';
      } catch (e) {
        return false;
      }
    }

    return false;
  };

  /**
   * Does the actual gift certificate preview
   */
  $scope.previewGiftCertificate = function (payment) {
    if (!payment) {
      return false;
    }

    // Make a scope for the modal so we can edit it
    var modalScope = $scope.$new();

    // Open the modal
    // This will show a loading wheel by default
    var modalInstance = $modal.open({
      backdrop: 'static',
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'gift-certificate-preview.html'),
      size: 'mg',
      scope: modalScope
    });

    modalScope.close = function () {
      modalInstance.close();
    };

    // Grab the gift certificate details
    var giftCertificate = CacheStorage.get('BuyGiftCertificateMeta-' + payment.gc_no);

    // Now fetch the preview from the server
    return TessituraSDK.GetGiftCertificatePrintout({
      to: giftCertificate.emailGiftCertificate.to,
      from: giftCertificate.emailGiftCertificate.from,
      message: giftCertificate.emailGiftCertificate.message,
      amount: giftCertificate.emailGiftCertificate.amount,
      code: 'ABC-123',
      format: 'png',
      base64: true,
      giftType: giftCertificate.emailGiftCertificate.giftType,
      occasion: giftCertificate.emailGiftCertificate.occasion,
      occasion_template: giftCertificate.emailGiftCertificate.occasion_template
    })
    .then(function (response) {
      modalScope.preview = response.data;
    });
  };

  /**
   * Go to the delivery page
   */
  $scope.proceedToCheckout = function () {

    useDonationPopup()
    .then(function (usePopup) {
      if (usePopup) {
        openDonationPopup();
      } else {
        goToNextStep();
      }
    })

  };

  /**
   * Logs the completion of this page in ga
   * Moves the user to the payment or shipping page depending
   * on if they are in exchange mode
   * @return {Promise}
   */
  function goToNextStep() {
    $window.location = $attrs.deliveryPageUrl;
  }

  /**
   * Opens the donation popup
   * @param  {Object} message PriceRule message
   * @return {Promise}
   */
  function openDonationPopup() {
    getDonationPopupMessage()
    .then(function (message) {

      var messageConfig = {};

      try {
        messageConfig = JSON.parse(message.Message);
      } catch(e) {
        e.message = 'Loading donation price rule message is not valid JSON.\n' + e.message;
        Raven.captureException(e, {tags: {key: 'tessitura-provided-json'}});
        return;
      }

      if ('title' in messageConfig && messageConfig.title.length) {
        messageConfig.title = messageConfig.title.replace(/&apos;/g, "'").replace(/&quot;/g, '"');
      }

      if ('text' in messageConfig) {
        messageConfig.text = messageConfig.text.replace(/&apos;/g, "'").replace(/&quot;/g, '"');

        messageConfig.text = $sce.trustAsHtml(messageConfig.text);
      }

      var modalInstance = $modal.open({
        templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'donation-popup.html'),
        controller: 'DonationPopupController',
        size: 'mg',
        windowClass: 'donation-popup-modal',
        resolve: {
          messageConfig: function () {
            return messageConfig;
          }
        }
      });

      // The model controller gives us an action back
      modalInstance.result
      .then(function (action) {
        switch (action) {
          case "goToNextStep":
            goToNextStep();
            break;
          case "addOtherDonation":
            // Enable and set the donation
            $scope.donate = true;
            $scope.selectedDonation = $filter('last')($scope.donationValues);
            // Apply the new values
            updateDonationInCart();
            break;
        }
      });
    });
  }

  function removeItemConfirm(){
    var message = (CacheStorage.get('syosDonation')) ?
      'Are you sure you want to remove this item from your cart? Removing this item will also remove all of the selected seats and will start your reservation process from the beginning.' :
      'Are you sure you want to remove this item from your cart? If this is the only item left in your cart, the removal will start your reservation process from the beginning and place you back in the waiting room if traffic warrants it.';

    return window.confirm(message);
  }


  function removeGiftCerticateConfirm(){
    var message = 'Are you sure you want to remove this gift certificate?';
    return window.confirm(message);
  }

  $scope.removeTickets = function (lineItem) {
    if(!removeItemConfirm()){
      return false;
    }

    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=2130164419763855750-EU&client_type=gtag#remove_from_cart
    var sublineItems = $scope.cartData.SubLineItem.filter(function (subItem) {
        return subItem.li_seq_no === lineItem.li_seq_no;
    });
    var lineTotal = sublineItems.reduce(function (carry, item) {
        return carry + parseFloat(item.due_amt);
    }, 0);

    var cartItem = {
        item_id: lineItem.perf_no,
        item_name: lineItem.perf_desc,
        coupon: $scope.cartData.Order.source_no,
        location_id: lineItem.facil_no,
        price: lineTotal,
        quantity: sublineItems.length
    };

    var data = {
        currency: 'USD',
        value: lineTotal,
        items: [cartItem]
      };
    trackEvent(
        'remove_from_cart',
        data
    );
    $scope.updatingCart = true;

    $scope.itemsBeingRemoved[lineItem.li_seq_no] = true;

    var ReleaseTicketsParams = {
      iPerformanceNumber: parseInt(lineItem.perf_no),
      iLineItemNumber:  parseInt(lineItem.li_seq_no)
    };

    TessituraSDK.ReleaseTickets(ReleaseTicketsParams)
      .then(function () {
        $scope.itemsRemoved[lineItem.li_seq_no] = true;
      })
      .finally(reloadData)
      .finally(getDonationValues)
      .finally(getRecommendDonationAmount)
      .finally(function () {
        $scope.itemsBeingRemoved[lineItem.li_seq_no] = false;
        $scope.itemsRemoved[lineItem.li_seq_no] = false;
      });
  };

  $scope.removeParentPackageLineItem = function (parentPackageLineItem) {
    if(!removeItemConfirm()){
      return false;
    }

    $scope.updatingCart = true;

    var parentPackageLineItems;

    if ($scope.isDYOPackage(parentPackageLineItem)) {
      parentPackageLineItems = $scope.getDYOPackageGroupExpanded(parentPackageLineItem);
    } else {
      parentPackageLineItems = [parentPackageLineItem];
    }

    var promises = parentPackageLineItems.map(removeParentPackageLineItem);

    $q.all(promises)
    .then(function () {
      return $q.all([
        Cart.hasPackageInCart(),
        MosSwitcher.getSaleMode(),
        Cart.hasRenewalPackageInCart(),
        Cart.isRenewalOrder()
      ]);
    })
    .then(function (responses) {
      var
        hasPackageInCart = responses[0],
        saleMode = responses[1],
        hasRenewalPackage = responses[2],
        isRenewalOrder = responses[3];

      if ((!hasPackageInCart && saleMode.defaultMos != appConfig.fixedSubscriberMos && saleMode.defaultMos != appConfig.flexSubscriberMos) || (isRenewalOrder && !hasRenewalPackage)) {
        Cart.clearRenewalPackagesInCart();
        sessionTransferRequired = true;
      }

      return $q.resolve(true);
    })
    .finally(reloadData)
    .finally(getDonationValues)
    .finally(getRecommendDonationAmount);
  };

  function removeParentPackageLineItem(parentPackageLineItem) {
    $scope.itemsBeingRemoved[parentPackageLineItem.li_seq_no] = true;

    var promise;

    if (parentPackageLineItem.flex_ind == "Y") {
      var RemoveNFSPackageParams = {
        NFSPackageLineItemId:  parseInt(parentPackageLineItem.li_seq_no)
      };

      promise = TessituraSDK.RemoveNFSPackage(RemoveNFSPackageParams);
    } else {
      var RemovePackageItemParams = {
        PackageNumber: parseInt(parentPackageLineItem.pkg_no),
        PackageLineItemID:  parseInt(parentPackageLineItem.li_seq_no)
      };

      CacheStorage.remove("PackageADAMeta-" + RemovePackageItemParams.PackageNumber);
      promise = TessituraSDK.RemovePackageItem(RemovePackageItemParams);
    }

    return promise
      .then(function () {
        $scope.itemsRemoved[parentPackageLineItem.li_seq_no] = true;
      })
      .finally(function () {
        $scope.itemsBeingRemoved[parentPackageLineItem.li_seq_no] = false;
        $scope.itemsRemoved[parentPackageLineItem.li_seq_no] = false;
      });
  };

  $scope.canUseAnyAccountCredit = function () {
    if (!$scope.accountCredit.length) {
      return false;
    }

    var canUse = false;

    $scope.accountCredit.forEach(function (onAccountItem) {
      if (!canUse && $scope.canUseAccountCredit(onAccountItem)) {
        canUse = true;
      }
    });

    return canUse;
  };

  $scope.canUseAccountCredit = function (onAccountItem, withPerformance) {
    if (!parseFloat(onAccountItem.on_account)) {
      return false;
    }

    var paymentMethod = parseInt(onAccountItem.pmt_method, 10);

    // Get the production season ids that can be paid with this credit type
    var prod_season_ids = exchangeModes
    .filter(function (mode){
      return parseInt(mode.payment_method, 10) === paymentMethod;
    })
    .map(function (mode) {
      return parseInt(mode.prod_season_no);
    });

    // Look for a subline item with that a matching prod id
    var canUseExchangeCredit = cartData.SubLineItem &&
      cartData.SubLineItem.some(function (subLineItem) {
        return prod_season_ids.indexOf(parseInt(subLineItem.prod_season_no, 10)) !== -1;
      });

    var allowedGeneralMethods = [];

    if ('generalOnAccountPaymentMethods' in appConfig && angular.isArray(appConfig.generalOnAccountPaymentMethods)) {
      allowedGeneralMethods = appConfig.generalOnAccountPaymentMethods;
    }

    var canUseGeneralCredit = allowedGeneralMethods.indexOf(paymentMethod) !== -1;

    if (withPerformance) {
      canUseGeneralCredit = canUseGeneralCredit && (cartData.SubLineItem.length || cartData.PackageSubLineItem.length);
    }

    return canUseExchangeCredit || canUseGeneralCredit;
  };

  $scope.donateAccountCredit = function (onAccountItem) {
    AccountCredit.donateAccountCredit(onAccountItem)
    .then(function (response) {
      var
        result = response.data.result,
        cart = result && result.GetCartResults ? result.GetCartResults : {};

      return reloadData(
        Cart.processCartData(cart)
      );
    });
  };

  $scope.useAccountCredit = function (onAccountItem) {
    AccountCredit.useAccountCredit(onAccountItem)
    .then(function (response) {
      var
        result = response.data.result,
        cart = result && result.GetCartResults ? result.GetCartResults : {};

      return reloadData(
        Cart.processCartData(cart)
      );
    });
  };

  $scope.removeAccountCredit = function (onAccountItem) {
    $scope.updatingCart = true;

    AccountCredit.removeAccountCredit(onAccountItem)
    .then(function (response) {
      var
        result = response.data.result,
        cart = result && result.GetCartResults ? result.GetCartResults : {};

      return reloadData(
        Cart.processCartData(cart)
      );
    });
  };

  $scope.isAccountCreditInUse = AccountCredit.isAccountCreditInUse;
  $scope.isDonatingAccountCredit = function () {
    return AccountCredit.isDonatingAccountCredit($scope.accountCredit, cartData);
  };

  /**
   * Takes in a line item or sub line item,
   * joins the seat row and column together.
   * Returns null if seat_* isnt set
   * @return {[type]} [description]
   */
  $scope.seatFromSubline = function (sublineItem) {
    if (sublineItem.seat_row && sublineItem.seat_num) {
      return sublineItem.seat_row + sublineItem.seat_num;
    } else {
      return null;
    }
  }

  /**
   * Hide the donation selection box if we are donating account credit
   * or
   * We are a contribution which isnt the default donation id
   */
  function hideDonationSelection() {
    if ($scope.useDonationPopup == null || $scope.useDonationPopup == true) {
      return true;
    }

    if ($scope.isDonatingAccountCredit()) {
      return true;
    }

    if (cartData && cartData.Contribution && cartData.Contribution.length) {
      return true;
    }
    return false;
  }

  $scope.hideRoundUpDonation = false;

  function hideRoundUpDonation() {
    if (hideDonationSelection()) {
      $scope.hideRoundUpDonation = true;
    } else {
      $scope.hideRoundUpDonation = hasPackageInCart();
    }
  }

  $scope.hideCheckOffDonation = false;

  function hideCheckOffDonation() {
    if (hideDonationSelection()) {
      $scope.hideCheckOffDonation = true;
    } else {
      $scope.hideCheckOffDonation = !hasPackageInCart();
    }
  }

  function hasPackageInCart() {
    return cartData && !!cartData.PackageLineItem.length;
  }

  function digitalPerformanceFilter(lineItem) {
    return appConfig.digital_prod_type_ids.indexOf(parseInt(lineItem.prod_type, 10)) > -1;
  }

  /**
   * Returns if a performance allows SYOS.
   * @param  {Performance} performance Performance object we are checking.
   * @return {Boolean}                 True or False
   */
  $scope.performanceAllowsSYOS = function (lineItem, isPackage) {
    if (!lineItem) {
      return false;
    }

    if (digitalPerformanceFilter(lineItem)) {
      return false;
    }

    var performance = $scope.performanceFromLineitem(lineItem);

    if (!performance) {
      return false;
    }

    var sublineItems = $scope.getSubLineItemsForLineItem(lineItem);

    var itemBeingExchanged = sublineItems.some(function (sublineItem) {
      return 'ret_parent_sli_no' in sublineItem;
    });

    var allowSYOS = performance.webContent.some(function (webContent) {
      return (
        webContent.content_type == appConfig.webContentTypes.webSYOS &&
        angular.isString(webContent.content_value) &&
        webContent.content_value.toLowerCase() == 'y' &&
        !itemBeingExchanged
      );
    });

    if (!isPackage) {
      return allowSYOS;
    }

    return $scope.packageAllowsSYOS(lineItem) && allowSYOS;
  };

  /**
   * Returns if a performance allows SYOS.
   * @param  {Performance} performance Performance object we are checking.
   * @return {Boolean}                 True or False
   */
  $scope.packageAllowsSYOS = function (lineItem) {
    if (!lineItem) {
      return false;
    }

    if ('facil_no' in lineItem && parseInt(lineItem.facil_no, 10) === 13) {
      return false;
    }

    if ('facility_no' in lineItem && parseInt(lineItem.facility_no, 10) === 13) {
      return false;
    }

    var package = packages[lineItem.pkg_no];

    if (!package) {
      return false;
    }

    var contentField = package.webContent.some(function (webContent) {
      return (
        webContent.content_type == appConfig.webContentTypes.webSYOS &&
        angular.isString(webContent.content_value) &&
        webContent.content_value.toLowerCase() == 'y'
      );
    });

    if (contentField) {
      return true;
    };

    return package.Package.seat_ind == 'Y';
  };

  /**
   * Returns true if this package line item is an upgrade
   * for a different package. Not if its being upgraded.
   * @param  {PackageLineItem}  PackageLineItem object
   * @return {Boolean}
   */
  $scope.isPackageUpgade =function (packageLineItem) {
    if (packageLineItem.alt_upgrd_ind !== 'U') {
      return false;
    }

    var packageIds = $filter('map')(cartData.PackageLineItem, 'pkg_li_no');
    packageIds = $filter('unique')(packageIds);
    packageIds = $filter('orderBy')(packageIds);

    if (packageIds.length >= 2 && packageIds[0] != packageLineItem.pkg_li_no) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns true if this package line item is being upgraded.
   * Not if it IS the upgrade.
   * @param  {PackageLineItem}  PackageLineItem object
   * @return {Boolean}
   */
  $scope.isPackageBeingUpgraded = function (packageLineItem) {
    if (packageLineItem.alt_upgrd_ind !== 'U') {
      return false;
    }

    return !$scope.isPackageUpgade(packageLineItem);
  }


  /**
   * Fired when a use clicks on a CTA
   * Removes the alert if removeOnClick is set
   * Triggers the onClose action
   */
  $scope.ctaClick = function (alert, cta) {
    if (cta.removeOnClick === true) {
      Notifications.remove(alert);
    }
    if (typeof cta.onClick === 'function') {
      $injector.invoke(cta.onClick, window, { alert: alert, cta: cta });
    } else if (typeof cta.onClick === 'string') {
      if (cta.onClick.indexOf('?') !== -1) {
        cta.onClick += '&lineItem=' + alert.lineItem;
      } else {
        cta.onClick += '?lineItem=' + alert.lineItem;
      }

      window.location = cta.onClick;
    }
  };

  function packageSublineItemCost (parentPackageLineItem) {
    if (!parentPackageLineItem) {
      return 0;
    }

    if (!parentPackageLineItem.webContent) {
      return 0;
    }

    var foundCost = parentPackageLineItem.webContent.filter(function (webContent) {
      return webContent.content_type == appConfig.webContentTypes.webPackageItemCost;
    });

    return foundCost.length ? parseFloat(foundCost[0].content_value) : 0;
  }

}]);
