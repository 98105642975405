/**
 * Takes in the users name and returns their
 * intials or the first few letters of their first name if they have no second name.
 */
app.filter('initials', function () {
  return function (fullName) {
    fullName = fullName || '';
    var parts = fullName.split(' ');
    if (parts.length == 0) {
      return '';
    }

    if (parts.length == 1) {
      return parts[0].substr(0, 2).toUpperCase();
    }

    if (parts.length >= 2) {
      return parts.map(function(name){
        return name.substr(0,1).toUpperCase();
      }).join('');
    }

  };
});
