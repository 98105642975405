app.filter('alertsForPage', ['$location', function ($location) {
  return function (alerts) {
    return alerts.filter(function (alert) {
      if (!alert.location) {
        return true;
      }
      
      if ($location.url().match(alert.location)) {
        return true;
      }

      return false;

    });
  };
}]);
