app.filter('sliStatus', function () {
  return function (array, sliStatusIds, invert) {
    if (!angular.isArray(array)) {
      return [];
    }

    return array.filter(function (lineItem) {
      var sliStatus = parseInt(lineItem.sli_status);

      var isMatchingSli = sliStatusIds.indexOf(sliStatus) > -1;

      return invert ? !isMatchingSli : isMatchingSli;
    });
  };
});
