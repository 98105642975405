angular.module('TessituraSDK')
  .config(['$provide', function ($provide) {
    $provide.decorator('TessituraSDK', ['$q', '$delegate', '$cookies', 'CacheStorage', function ($q, $delegate, $cookies, CacheStorage) {
      $delegate.sessionKeyCache = null;

      $delegate.getSessionKey = function () {
        if ($cookies.get('sessionkey')) {
          return $q.resolve($cookies.get('sessionkey'));
        }

        // Forcefully clear cache if a new session key is being requested
        CacheStorage.removeRegex(/^tsdk-id=.+/);

        if ($delegate.sessionKeyCache !== null) {
          return $delegate.sessionKeyCache;
        }

        $delegate.sessionKeyCache = $delegate.GetNewAnonymousSessionKey()
        .then(function () {
          return $q.resolve($cookies.get('sessionkey'));
        });

        return $delegate.sessionKeyCache;
      };

      /**
       * START handling `TTICookie` functionality
       *
       * The first call to Proxy will trigger setting the cookie
       */
      var ttiCallPromise;
      var callsToNotOverride = [
        'getOptions',
        'getOption',
        'setOptions',
        'storeOriginalParams',
        'post',
        'get',
        'getSessionKey',
        'extend',
        'options',
        'GetTTICookieValue',
      ];

      Object.keys($delegate).forEach(function(call) {
        if (callsToNotOverride.indexOf(call) !== -1) {
            return;
        }

        var fn = $delegate[call];

        if (fn) {
            $delegate[call] = function (args) {
                return fn.call($delegate, args).then(function(originalData) {
                    if (!ttiCallPromise) {
                        ttiCallPromise = $delegate.GetTTICookieValue()
                            .then(function(data) {
                                // Strips the lowest subdomain but leaves the trailing . (e.g. '.centertheatregroup.org')
                                var domain = document.location.host.replace(/^[^.]+(\.)/, '$1');
                                var cookieValue = data.data.result;

                                var cookieString = 'TNEW=' + cookieValue + '; domain=' + domain + '; path=/';
                                // console.log('Setting cookie for TTI sharing', cookieString);
                                document.cookie = cookieString;

                                return originalData;
                            });
                        // return ttiCallPromise;
                    }
                    return originalData;
                });
            };
        }
      });
      /**
       * END handling `TTICookie` functionality
       */

      return $delegate;
    }]);
  }]);

angular.module('TessituraV12CallConfig')
  .config(['$provide', function ($provide) {
    $provide.decorator('TessituraV12CallConfig', ['$delegate', '$cookies', 'CacheStorage', function ($delegate, $cookies, CacheStorage) {

      $delegate.GetPerformanceZoneAvailability = {};

      $delegate.StudentBodySignupForm = {
        defaults: {
          'sessionKey': '%sessionkey%'
        }
      };

      $delegate.UpdateAccountDetails = {
        defaults: {
          'sessionKey': '%sessionkey%'
        },
        cache: {
          invalidate: ['GetAccountInfo',  'LoginInfoEx', 'GetConstituentInfoEx']
        }
      };

      $delegate.GetDigitalContentEmbedData = {
        defaults: {
          'sessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.GetCart = {
        defaults: {
          'sWebSessionID': '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.RecalculateCartFunds = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        events: {
          onSuccess: function (response) {
            if (response.data.result.RecalculateCartFundsResults.Notices.length > 0) {
              CacheStorage.removeRegex(/^tsdk-id=.+(GetCart|GetOnAccountInfo).+/);
            }
          }
        },
        cache: {
          ttl: 1
        }
      };

      $delegate.ElementHostedPaymentCheckout = {
        cache: {
          'ttl': 0,
          invalidate: ['GetCart', 'GetOrdersEx', 'GetCustomOrdersEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        }
      };

      $delegate.Checkout = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          invalidate: ['GetCart', 'GetOrdersEx', 'GetCustomOrdersEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        }
      };

      $delegate.FreeCheckout = {
        defaults: {
          session: '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          invalidate: ['GetCart', 'GetOrdersEx', 'GetCustomOrdersEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        }
      };

      $delegate.TMSPaymentComponent = {
        defaults: {
          session: '%sessionkey%'
        },
        cache: {
          'ttl': 0
        }
      };

      $delegate.TMSReturnRedirect = {
        defaults: {
          session: '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['TMSSavedCards']
        }
      };

      $delegate.TMSSavedCards = {
        defaults: {
          session: '%sessionkey%'
        },
        cache: {
          'ttl': 0
        }
      };

      $delegate.TMSDeleteCard = {
        defaults: {
          session: '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['TMSSavedCards']
        }
      };

      $delegate.TransferSession = {
        defaults: {
          'sSessionKey': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'LoginInfo', 'LoginInfoEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        },
        events: {
          onSuccess: function (response) {
            $cookies.put('sessionkey', response.config.data.params.sNewKey, {
              path: '/'
            });

            CacheStorage.removeRegex(/^tsdk-id=.+/);
          }
        }
      };

      $delegate.GetGiftCertificatePrintout = {
      };

      $delegate.GetExchangeModes = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          ttl: 300
        }
      };

      $delegate.HasFacebookConnected = {
        method: 'get',
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 300
        }
      };

      $delegate.DisconnectFacebook = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['HasFacebookConnected', 'GetFacebookAccountDetails']
        }
      };


      $delegate.GetFacebookAccountDetails = {
        method: 'get',
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 300
        }
      };

      $delegate.DonateTickets = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetUpcomingEvents', 'GetOrderDetails', 'GetCustomOrderDetails', 'GetCart', 'LoginInfo', 'LoginInfoEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        }
      };

      $delegate.ReturnTickets = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetUpcomingEvents', 'GetOrderDetails', 'GetCustomOrderDetails', 'GetCart', 'LoginInfo', 'LoginInfoEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        }
      };

      $delegate.CTGPrintTickets = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetUpcomingEvents', 'GetOrderDetails', 'GetCustomOrderDetails', 'GetCart', 'LoginInfo', 'LoginInfoEx', 'GetConstituentPerformances', 'RecalculateCartFunds']
        }
      };

      $delegate.ApplyPromoCode = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetProductionDetailEx3', 'GetPerformanceDetailWithDiscountingEx', 'GetCart', 'LoginInfo', 'LoginInfoEx', 'RecalculateCartFunds', 'GetPerSeatFees']
        }
      };

      $delegate.RemovePromoCode = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetProductionDetailEx3', 'GetPerformanceDetailWithDiscountingEx', 'GetCart', 'LoginInfo', 'LoginInfoEx', 'RecalculateCartFunds', 'GetPerSeatFees']
        }
      };

      $delegate.GetMembershipRenewals = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 0
        },
        cache: {
          ttl: 300
        }
      };

      $delegate.GetOnAccountInfo = {
        defaults: {
          sSessionKey: '%sessionkey%',
          sPaymentMethods: ''
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.GetUserProfile = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.UpdateUserProfile = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetUserProfile']
        }
      };

      $delegate.GetDonationBands = {
        defaults: {
          SessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.GetPerformanceKeywords = {
        method: 'get',
        defaults: {
          SessionKey: '$sessionkey$'
        }
      };

      $delegate.GetPerformanceAvailabilitySPROC = {
        method: 'get',
        defaults: {
          sessionKey: '$sessionkey$'
        }
      };

      $delegate.GetUserMessages = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.ReadUserMessage = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetUserMessages']
        }
      };

      $delegate.CreateUserMessages = {
        defaults: {
          SessionKey: '%sessionkey%',
          NoSessionKey: 'true'
        },
        cache: {
          ttl: 600,
          invalidate: ['GetUserMessages']
        }
      };

      $delegate.UpdatePassportList = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.GetUserCards = {
        defaults: {
          sessionkey: '%sessionkey%'
        },
        cache: {
          'ttl': 0
        }
      };

      $delegate.DeleteUserCard = {
        defaults: {
          Sessionkey: '%sessionkey%'
        },
        cache: {
          'ttl': 0
        }
      };

      $delegate.PaymentWithSavedCard = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          'ttl': 0
        }
      };

      $delegate.GetOrdersEx = {
        defaults: {
          sWebSessionId: '%sessionkey%'
        },
        cache: {
          "ttl": 600
        }
      };

      $delegate.GetCustomOrdersEx = {
        defaults: {
          sessionkey: '%sessionkey%'
        },
        cache: {
          "ttl": 600
        }
      };

      $delegate.GetOrderDetails = {
        defaults: {
          SessionKey: '%sessionkey%'
        },
        cache: {
          "ttl": 600
        }
      };

      $delegate.GetCustomOrderDetails = {
        defaults: {
          sessionkey: '%sessionkey%'
        },
        cache: {
          "ttl": 600
        }
      };

      $delegate.GetAllowedSubscriptions = {
        method: 'get',
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.ReserveTicketsEx = {
        defaults: {
          'sWebSessionID': '%sessionkey%',
          'sSpecialRequests': 'LeaveSingleSeats=Y'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.ReleaseTickets = {
        defaults: {
          'sWebSessionID': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.ReserveTicketsUnseated = {
        defaults: {
          'sWebSessionID': '%sessionkey%',
          'sSpecialRequests': 'LeaveSingleSeats=Y'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.AddPackageItemSeated = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.AddPackageItem = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.RemovePackageItem = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.AddNFSPackagePerformanceItem = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.RemoveNFSPackage = {
        defaults: {
          'SessionKey': '%sessionkey%',
          'NFSPackageLineItemId': 0,
          'PerformanceLineItemId': 0,
          'PerformanceNumber': 0
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/Messages\/.*', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.SMaPGetSchoolDistricts = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.SMaPGetAssociableSchoolDistricts = {
        defaults: {
          SessionKey: '%sessionkey%',
          NoSessionKey: 'true'
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.SMaPGetSchools = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.SMaPTransportOptions = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.SMaPTeacherConferences = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.SMaPGrades = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.SMaPSubjects = {
        defaults: {
          SessionKey: '%sessionkey%',
          RetrievalKey: 1
        },
        cache: {
          'ttl': 3600
        }
      };

      $delegate.UpdateAssociation = {
        defaults: {
          sSessionKey: '%sessionkey%',
          iConstN1N2: 0,
          iAssociationID: 0,
          iCustomerNo: 0,
          sName: '',
          iAssocN1N2: 0,
          sStartDate: '',
          sEndDate: '',
          sNotes: '',
          sTitle: '',
          dSalary: 0,
          sGender: '',
          sBirthDate: '',
          sInactive: 'N'
        },
        cache: {
          'ttl': 0,
          'invalidate': [
            'GetConstituentInfo',
            'GetConstituentInfoEx',
          ]
        }
      };

      $delegate.SetShippingInformation = {
        defaults: {
          sSessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetRestCart', 'RecalculateCartFunds', 'RecalculateCartFunds']
        }
      };

      $delegate.GetLastOrderConfirmationEmail = {
        defaults: {
          sessionKey: '%sessionkey%'
        }
      };

      $delegate.SendLastOrderConfirmationEmail = {
        defaults: {
          sessionKey: '%sessionkey%'
        }
      };

      $delegate.RegisterFromGuest = {
        defaults: {
          sessionKey: '%sessionkey%'
        }
      };

      $delegate.GetCartDonationValues = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.WebCartPrice = {
        defaults: {
          'sessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.LoginInfo = {
        defaults: {
          sSessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.LoginInfoEx = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.UserLoggedIn = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.UpdateLineItemNote = {
        defaults: {
          sessionKey: '%sessionkey%',
          notes: ''
        },
        cache: {
          ttl: 0,
          invalidate: ['GetOrderDetails', 'GetCustomOrderDetails']
        }
      };

      $delegate.UpdateCustomOrderData = {
        defaults: {
          SessionKey: '%sessionkey%',
          RefNo: 0
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetOrderDetails', 'GetCustomOrderDetails', 'RecalculateCartFunds']
        }
      };

      $delegate.GetWebContent = {
        method: 'get',
        defaults: {
          sSessionKey: ''
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.GetTicketHistory = {
        defaults: {
          SessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.GetUpcomingEvents = {
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.SendMMS = {
        defaults: {
          session_key: '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.PrintTicketsWithPassbook = {
        method: 'get',
        defaults: {
          sSessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600,
          invalidate: ['ReturnTickets', 'DonateTickets', 'CTGPrintTickets']
        }
      };

      $delegate.GetConstituentAllocation = {
        method: 'get',
        defaults: {
          sessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 600
        }
      };

      $delegate.GetConstituentPerformances = {
        method: 'get',
        defaults: {
          sessionKey: '%sessionkey%'
        }
      };

      // @todo MOVE THIS TO JSDK properly
      $delegate.Register = {
        defaults: {
          'sSessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: [
            'GetAccountInfo',
            'GetCart',
            'GetConstituentInfo',
            'GetConstituentInfoEx',
            'GetPerformanceDetailWithDiscountingEx',
            'LoginInfo',
            'LoginInfoEx',
            'NotGuestUser',
            'CheckUserExists',
            'GetMemberships',
            'GetFacebookFriends',
            'GetUserCards',
            'TMSSavedCards',
            'GetVariables',
            'UserLoggedIn',
            'GetPackagesEx3',
            'GetProductionsEx3',
            'IsGuestUser',
            'RecalculateCartFunds',
            'GetPerSeatFees'
          ]
        }
      };

      $delegate.RegisterWithPromoCodeEx = {
        defaults: {
          'sSessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: [
            'GetAccountInfo',
            'GetCart',
            'GetConstituentInfo',
            'GetConstituentInfoEx',
            'GetPerformanceDetailWithDiscountingEx',
            'LoginInfo',
            'LoginInfoEx',
            'NotGuestUser',
            'CheckUserExists',
            'GetMemberships',
            'GetFacebookFriends',
            'GetUserCards',
            'TMSSavedCards',
            'GetVariables',
            'UserLoggedIn',
            'GetPackagesEx3',
            'GetProductionsEx3',
            'IsGuestUser',
            'RecalculateCartFunds',
            'GetPerSeatFees'
          ]
        }
      };

      $delegate.LoginEx2 = {
        defaults: {
          'sSessionKey': '%sessionkey%',
          'iLoginType': 1,
          'iCustomerNo': 0,
          'iN1N2': 0,
          'iPromotionCode': 0
        },
        cache: {
          'ttl': 0,
          'invalidate': [
            'GetAccountInfo',
            'GetCart',
            'GetConstituentInfo',
            'GetConstituentInfoEx',
            'GetPerformanceDetailWithDiscountingEx',
            'LoginInfo',
            'LoginInfoEx',
            'NotGuestUser',
            'CheckUserExists',
            'GetMemberships',
            'GetFacebookFriends',
            'GetUserCards',
            'TMSSavedCards',
            'GetVariables',
            'UserLoggedIn',
            'NotGuestUser',
            'GetPackagesEx3',
            'GetProductionsEx3',
            'RecalculateCartFunds',
            'GetPerSeatFees'
          ]
        }
      };

      $delegate.InvalidateCartCache = {
        defaults: {
          'sSessionKey': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.UpdateSourceCode = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': [
            'GetProductionDetailEx3',
            'GetPerformanceDetailWithDiscountingEx',
            'GetCart',
            'GetRestCart',
            'GetPriceRuleMessages',
            '\/Web\/Cart\/.*\/Messages',
            'LoginInfo',
            'LoginInfoEx',
            'GetProductionsEx3',
            'RecalculateCartFunds',
            'GetPerSeatFees'
          ]
        }
      };

      $delegate.ChangeModeOfSaleEx = {
        defaults: {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': [
            'LoginInfo',
            'LoginInfoEx',
            'GetProductionDetailEx3',
            'GetPerformanceDetailWithDiscountingEx',
            'GetCart',
            'GetRestCart',
            'GetPriceRuleMessages',
            '\/Web\/Cart\/.*\/Messages',
            'GetProductionsEx3',
            'RecalculateCartFunds'
          ]
        }
      };

      $delegate.AddContribution = {
        defaults: {
          'sWebSessionID': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.RemoveContribuion = {
        defaults: {
          'sWebSessionID': '%sessionkey%'
        },
        cache: {
          'ttl': 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.MaintainOrderInitiatorRecipient = {
        defaults: {
          sSessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.ApplyGiftCertificate = {
        'defaults': {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.RemoveGiftCertificate = {
        'defaults': {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.PurchaseGiftCertificate = {
        'defaults': {
          'SessionKey': '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.AddOrderCommentsEx2 = {
        defaults: {
          'SessionKey': '%sessionkey%',
          'LineItemID': 0,
          'LineItemType': 'O',
          'CustomerNo': 0
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.ApplyOnAccount = {
        defaults: {
          'sSessionKey': '%sessionkey%',
          'iPaymentNo': 0,
          'iPaymentMethod': 0,
          'dPaymentAmount': '0'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.LoadExistingOrder = {
        defaults: {
          SessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.ClearCart = {
        defaults: {
          sSessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          'invalidate': ['GetCart', 'GetRestCart', 'GetPriceRuleMessages', '\/Web\/Cart\/.*\/Messages', 'RecalculateCartFunds']
        }
      };

      $delegate.VideoEventTracking = {
        defaults: {
          session_key: '%sessionkey%'
        },
        cache: {
          ttl: 0
        }
      };

      $delegate.ResetTicketExpiration = {
        defaults: {
          sSessionKey: '%sessionkey%'
        },
        cache: {
          ttl: 0,
          invalidate: ['GetCart', 'GetTicketExpiration', 'RecalculateCartFunds']
        }
      };

      $delegate.DeleteVariable = {
        defaults: {
          sSessionKey: '%sessionkey%',
          sName: null,
        },
        cache: {
          'ttl': 0,
          invalidate: ['GetCart', 'GetVariable', 'RecalculateCartFunds']
        }
      };

      $delegate.GetTTICookieValue = {
        defaults: {
            sSessionKey: '%sessionkey%',
        },
        cache: {
          'ttl': 0,
        }
      };

      $delegate.GetPerSeatFees = {
        cache: {
          ttl: 600
        }
      };

      return $delegate;
    }]);
  }]);

angular.module('TessituraV12RESTCallConfig')
  .config(['$provide', function ($provide) {
    $provide.decorator('TessituraV12RESTCallConfig', ['$delegate', 'CacheStorage', function ($delegate, CacheStorage) {

      $delegate['/Web/Cart/.*/Messages'] = {
        method: 'get',
        cache: {
          ttl: 0
        }
      };

      $delegate['/Emails/ConstituentInfo/.*/Send'] = {
        method: 'post',
        cache: {
          ttl: 0
        }
      };

      return $delegate;
    }]);
  }]);
