/* globals Vimeo */
/* globals YT */
/* globals jQuery */

(function() {
    var injectedPlayerScripts = [];

    var oneMinute = 60;
    var oneHour = oneMinute * 60;
    var oneDay = oneHour * 24;
    var beginsInPrefix = '<span class="player-countdown__header player-countdown__header--begins-in">Begins in</span> ';
    var onsaleInPrefix = '<span class="player-countdown__header player-countdown__header--on-sale-in">On sale in</span> ';
    var offsaleInPrefix = '<span class="player-countdown__header player-countdown__header--on-sale-in">Off sale in</span> ';

    /**
     *
     */
    function injectVimeoPlayerScript() {
        if (injectedPlayerScripts.indexOf('vimeo') >= 0) {
            return;
        }
        var script = document.createElement('script');
        script.setAttribute('src', 'https://player.vimeo.com/api/player.js');
        document.body.append(script);
        injectedPlayerScripts.push('vimeo');
    }

    /**
     *
     */
    function injectYouTubePlayerScript() {
        if (injectedPlayerScripts.indexOf('youtube') >= 0) {
            return;
        }
        var script = document.createElement('script');
        script.setAttribute('src', 'https://www.youtube.com/player_api');
        document.body.append(script);
        injectedPlayerScripts.push('youtube');
    }

    /**
     * @param {Date} from
     * @param {Date} till
     *
     * @return {string}
     */
    function timerString(from, till, prefix) {
        var diff = parseInt((from - till) / 1000, 10);
        var str = '';
        var daysLeft = Math.floor(diff / oneDay);
        var hoursLeft = Math.floor((diff - (oneDay * daysLeft)) / oneHour);
        var minutesLeft = Math.floor((diff - (oneDay * daysLeft + hoursLeft * oneHour)) / oneMinute);
        var secondsLeft = (diff - (oneDay * daysLeft + hoursLeft * oneHour + minutesLeft * oneMinute));

        // Formats the amount of time left
        if (daysLeft > 1) {
            str+= '<span class="player-countdown player-countdown__days"><span class="player-countdown__count">' + daysLeft + '</span> <span class="player-countdown__duration">days</span></span> ';
        } else if (daysLeft) {
            str+= '<span class="player-countdown player-countdown__days"><span class="player-countdown__count">' + daysLeft + '</span> <span class="player-countdown__duration">day</span></span> ';
        }

        if (hoursLeft > 1) {
            str+= '<span class="player-countdown player-countdown__hours"><span class="player-countdown__count">' + hoursLeft + '</span> <span class="player-countdown__duration">hours</span></span> ';
        } else if (hoursLeft) {
            str+= '<span class="player-countdown player-countdown__hours"><span class="player-countdown__count">' + hoursLeft + '</span> <span class="player-countdown__duration">hour</span></span> ';
        }

        if (minutesLeft > 1) {
            str+= '<span class="player-countdown player-countdown__monutes"><span class="player-countdown__count">' + minutesLeft + '</span> <span class="player-countdown__duration">minutes</span></span> ';
        } else if (minutesLeft) {
            str+= '<span class="player-countdown player-countdown__monutes"><span class="player-countdown__count">' + minutesLeft + '</span> <span class="player-countdown__duration">minute</span></span> ';
        }

        if (secondsLeft > 1) {
            str+= '<span class="player-countdown player-countdown__seconds"><span class="player-countdown__count">' + secondsLeft + '</span> <span class="player-countdown__duration">seconds</span></span> ';
        } else if (secondsLeft) {
            str+= '<span class="player-countdown player-countdown__seconds"><span class="player-countdown__count">' + secondsLeft + '</span> <span class="player-countdown__duration">second</span></span> ';
        }

        return str ? prefix + str : '<span class="player-countdown__loading">Loading&hellip;</span>';
    }

    app.controller(
        'DigitalContentController',
        ['$scope', '$interval', '$filter', '$cookies', '$attrs', '$location', '$q', 'Router', 'TessituraSDK', function (
                $scope,
                $interval,
                $filter,
                $cookies,
                $attrs,
                $location,
                $q,
                Router,
                TessituraSDK
            ) {
                var player;
                var perfNo;

                var usersVideoActiveState = 'unavailable';
                var videoAvailable = false;
                var arrayWrap = $filter('arrayWrap');
                var priceData;
                var videoPageLink = $attrs.videoPageLink ? $attrs.videoPageLink : window.location.href;

                $scope.showChat = false;
                $scope.showAddToCart = false;
                $scope.canShowAddToCart = !$attrs.onsaleStartDate && !$attrs.onsaleEndDate;
                $scope.bookButtonText = '';
                $scope.priceText = '';
                $scope.loggedIn = false;
                $scope.showFooter = false;
                $scope.hasPromoPrice = false;
                $scope.processing_add_to_cart = false;
                $scope.livechat_link = '';
                $scope.soldOut = false;
                $scope.alreadyPurchased = false;
                $scope.otherVideos = null;

                TessituraSDK.GetDigitalContentEmbedData({requestData: $attrs.requestData}).then(function (response) {
                    var responseData = response.data.result;

                    $scope.loggedIn = responseData.user_state === 'logged_in';
                    $scope.alreadyPurchased = responseData.already_purchased;

                    // Using a switch for future proofing of different providers
                    switch(responseData.video_type) {
                        case 'YouTube':
                            if (responseData.video_data && responseData.video_data.id) {
                                usersVideoActiveState = 'visible';
                                $scope.videoId = responseData.video_data.id;
                                videoAvailable = true;

                                $scope.$watch('state', function (newValue, oldValue) {
                                    if (newValue === 'visible') {
                                        injectYouTubePlayerScript();

                                        var playerInitInterval = $interval(function () {
                                            if (window.YT) {
                                                var playerEl = document.createElement('div');
                                                playerEl.id = 'yt-video-event-player';
                                                document.getElementById('video-event-player').appendChild(playerEl);

                                                var trackEvent = function(title, event) {
                                                    TessituraSDK.VideoEventTracking({
                                                        'perf_no': perfNo,
                                                        'video_id': $scope.videoId,
                                                        'event_type': event,
                                                        'event_data': null,
                                                        'video_title': title,
                                                    });
                                                }

                                                window.YT.ready(function() {
                                                    player = new window.YT.Player('yt-video-event-player', {
                                                        videoId: $scope.videoId,
                                                        events: {
                                                            'onReady': function (ev) {
                                                                var width = (ev.target.h || ev.target.i.i).width;
                                                                var height = (ev.target.h || ev.target.i.i).height;
                                                                document.getElementById('video-event-player').style.paddingBottom = (height / width * 100) + '%';
                                                            },
                                                            'onStateChange': function (ev) {
                                                                switch (ev.data) {
                                                                    case YT.PlayerState.BUFFERING:
                                                                        return trackEvent(ev.target.getVideoData().title, 'loaded');
                                                                    case YT.PlayerState.PAUSED:
                                                                        return trackEvent(ev.target.getVideoData().title, 'pause');
                                                                    case YT.PlayerState.PLAYING:
                                                                        return trackEvent(ev.target.getVideoData().title, 'play');
                                                                    case YT.PlayerState.ENDED:
                                                                        return trackEvent(ev.target.getVideoData().title, 'ended');
                                                                }
                                                            }
                                                        }
                                                    });
                                                });

                                                $interval.cancel(playerInitInterval);
                                            }
                                        }, 100);
                                    } else if (player && player.destroy) {
                                        // Destroy the player if it's not visible
                                        player.destroy()
                                    }
                                });
                                countdownInterval();
                            }

                            break;
                        case 'VimeoStream':
                            $scope.showChat = $attrs.showChat;
                            if (responseData.video_data.id) {
                                $scope.livechat_link = 'https://vimeo.com/live-chat/' + responseData.video_data.id;
                            }
                            /* falls through */
                        case 'VimeoAlbum':
                            if (responseData.video_data.videos) {
                                $scope.otherVideos = responseData.video_data.videos;

                                responseData.video_data = responseData.video_data.videos[0];
                            }

                            /* falls through */
                        case 'VimeoVideo':
                            if (responseData.performance_details && responseData.performance_details.perf_no) {
                                // No Video ID means the video has not been purchased
                                $scope.showAddToCart = !responseData.video_data.id;

                                perfNo = responseData.performance_details.perf_no;

                                if (responseData.performance_details.price) {
                                    // There is price data
                                    priceData = responseData.performance_details.price;

                                    var availabilityPromise;

                                    if (perfNo) {
                                        availabilityPromise = TessituraSDK.GetPerformanceZoneAvailability({perf_no: perfNo})
                                            .then(function (response) {
                                                return response.data.result.reduce(function (carry, zone) {
                                                    return carry + zone.AvailableCount;
                                                }, 0);
                                            });
                                    } else {
                                        availabilityPromise = $q.defer().resolve(1);
                                    }

                                    var buildBookButton = function () {
                                        if (
                                            responseData.performance_details.base_price > 0 &&
                                            priceData.price > 0 &&
                                            responseData.performance_details.base_price > priceData.price
                                        ) {
                                            $scope.priceText = '<span class="digital-content-book-button__price-with-discount"><span class="digital-content-book-button__discount-price"><span class="digital-content-book-button__currency">$</span><span class="digital-content-book-button__price">' +
                                            responseData.performance_details.base_price +
                                            '</span></span> <span class="digital-content-book-button__purchase-price"><span class="digital-content-book-button__currency">$</span><span class="digital-content-book-button__price">' +
                                            priceData.price +
                                            '</span></span></span>';
                                            $scope.bookButtonText = 'Buy';
                                            $scope.hasPromoPrice = true;
                                            $scope.hasPrice = false;
                                            $scope.isFree = false;
                                        } else if (priceData.price > 0) {
                                            $scope.priceText = '<span class="digital-content-book-button__price"><span class="digital-content-book-button__currency">$</span><span class="digital-content-book-button__price">' +
                                            priceData.price +
                                            '</span></span>';
                                            $scope.bookButtonText = 'Buy';
                                            $scope.hasPromoPrice = false;
                                            $scope.hasPrice = true;
                                            $scope.isFree = false;
                                        } else if (priceData.price === 0) {
                                            $scope.priceText = '<span class="digital-content-book-button__free">Free</span>';
                                            $scope.bookButtonText = 'Reserve';
                                            $scope.hasPromoPrice = false;
                                            $scope.hasPrice = false;
                                            $scope.isFree = true;
                                        }
                                    }

                                    availabilityPromise.then(function (count) {
                                        if (count > 0) {
                                            if ($location.search().add_to_cart) {
                                                $scope.addToCart().catch(function () {
                                                    buildBookButton();
                                                });
                                            } else {
                                                buildBookButton();
                                            }
                                        } else {
                                            $scope.soldOut = true;
                                        }
                                    });
                                }
                            }
                            if (responseData.video_data && responseData.video_data.id) {
                                usersVideoActiveState = 'visible';
                                $scope.videoId = responseData.video_data.id;
                                videoAvailable = true;

                                $scope.$watch('state', function (newValue, oldValue) {
                                    if (newValue === 'visible') {
                                        injectVimeoPlayerScript();

                                        var playerInitInterval = $interval(function () {
                                            if (window.Vimeo) {

                                                var playerOptions = {
                                                    id: $scope.videoId,
                                                };

                                                player = new Vimeo.Player('video-event-player', playerOptions);

                                                var getEventCallback = function (event) {
                                                    return function (data) {
                                                        return player.getVideoTitle().then(function (title) {
                                                            return TessituraSDK.VideoEventTracking({
                                                                'perf_no': perfNo,
                                                                'video_id': $scope.videoId,
                                                                'event_type': event,
                                                                'event_data': data,
                                                                'video_title': title,
                                                            });
                                                        });
                                                    }
                                                }

                                                player.on('loaded', getEventCallback('loaded'));
                                                player.on('pause', getEventCallback('pause'));
                                                player.on('play', getEventCallback('play'));
                                                player.on('ended', getEventCallback('ended'));
                                                player.on('seeked', getEventCallback('seeked'));

                                                player.on('ended', function() {
                                                    // if we have another video to play in scope.videos, start to play it
                                                    // when this one has ended

                                                    // Get the next video

                                                    var currentVideoIndex = $scope.otherVideos.map(function(vid) { return vid.id }).indexOf($scope.videoId);
                                                    if (currentVideoIndex === -1) {
                                                        return;
                                                    }

                                                    if (currentVideoIndex+1 >= $scope.otherVideos.length) {
                                                        // this is the last video in the list
                                                        return;
                                                    }

                                                    // wait half a second before starting the next vid
                                                    setTimeout(function() {
                                                        $scope.selectVideo($scope.otherVideos[currentVideoIndex+1]);
                                                    }, 500);

                                                })

                                                $interval.cancel(playerInitInterval);
                                                $q.all({width: player.getVideoWidth(), height: player.getVideoHeight()}).then(function (dimensions) {
                                                    // Maximise the player window to use as much space as possible
                                                    player.element.parentElement.style.paddingBottom = (dimensions.height/dimensions.width * 100) + '%';
                                                });
                                            }
                                        }, 100);
                                    } else if (player && player.destroy) {
                                        // Destroy the player if it's not visible
                                        player.destroy()
                                    }
                                });
                                countdownInterval();
                            }
                            break;
                        default:
                            throw 'Invalid Video Type [' + responseData.video_type + ']';
                    }

                    $scope.showFooter = true;
                });

                /**
                 * Checks if the performance is in the cart (the button shouldn't be showing if it is but better to be safe)
                 * Then redirects to the Cart page
                 */
                $scope.addToCart = function () {
                    $scope.processing_add_to_cart = true;

                    if ($scope.loggedIn && perfNo && priceData) {
                        return TessituraSDK.GetCart()
                            .then(function (cartResponse) {
                                var sublineItems = arrayWrap(cartResponse.data.result.GetCartResults.SubLineItem);
                                var inCart = sublineItems.filter(function (sublineItem) {return parseInt(sublineItem.perf_no, 10) === perfNo}).length > 0;

                                if (!inCart) {
                                    return TessituraSDK.ReserveTicketsEx({
                                        sPriceType: priceData.price_type_id,
                                        iPerformanceNumber: perfNo,
                                        iNumberOfSeats: 1,
                                        iZone: 0,
                                    }).then(function (response) {
                                        if (response.data.result && response.data.result['0'] && parseInt(response.data.result['0'], 10) > 0) {
                                            window.location = Router.getUrl('booking.basket');
                                        } else {
                                            throw 'No tickets added to cart';
                                        }
                                    }).catch(function (err) {
                                        console.log('Error adding to cart: ', err);
                                        $scope.processing_add_to_cart = false;
                                    });
                                } else {
                                    return $q(function (resolve, reject) {
                                        window.location = Router.getUrl('booking.basket');
                                        return resolve();
                                    });
                                }
                            });
                    } else if (!$scope.loggedIn) {
                        // Login and redirect back with query param to add to cart if not already purchased
                        var querySeparator = window.location.href.indexOf('?') > -1 ? '&' : '?';
                        window.location = Router.getUrl('auth.login', {
                            'redirect-to': videoPageLink + querySeparator + 'add_to_cart=true',
                        });
                    }
                };

                if (!$attrs.perfNo && document.querySelector('#video-event-signup') && !$cookies.get('video-signup-viewed')) {
                    // Only show Event Signup form if NO perfNo (Free) AND not already dismissed
                    jQuery.magnificPopup.open({
                        items: {
                            src: '#video-event-signup',
                            type: 'inline',
                        },
                        callbacks: {
                            afterClose: function () {
                                $cookies.put('video-signup-viewed', 1);
                            },
                        },
                    });
                }

                $scope.state = 'processing';
                $scope.upcomingTimer = '';
                var onsaleStartDate = null;
                var onsaleEndDate = null;
                var videoStart = null;
                var videoEnd = null;

                // Prep for the interval checking of the dates
                var dates = [];
                if ($attrs.onsaleStartDate) {
                    onsaleStartDate = new Date($attrs.onsaleStartDate);
                    dates.push({type: 'on-sale-start', date: onsaleStartDate});
                }
                if ($attrs.onsaleEndDate) {
                    onsaleEndDate =  new Date($attrs.onsaleEndDate);
                    dates.push({type: 'on-sale-end', date:onsaleEndDate});
                }
                if ($attrs.videoStartDate) {
                    videoStart = new Date($attrs.videoStartDate);
                    dates.push({type: 'video-start', date: videoStart});
                }
                if ($attrs.videoEndDate) {
                    videoEnd = new Date($attrs.videoEndDate);
                    dates.push({type: 'video-end', date: videoEnd});
                }

                // Order the dates
                dates = dates.sort(function (a, b) {return a.date > b.date ? 1 : a.date < b.date ? -1 : 0});

                /**
                 * The below logic is that On/Off sale dates take precedence for messaging
                 * If doesn't have On/Off sale dates then `canShowAddToCart` is true
                 * If has just On sale date and in the future
                 */
                function countdownInterval() {
                    var now = new Date();
                    $scope.canShowAddToCart = true;


                    while(dates.length && dates[0].date < now) {
                        // Remove past dates
                        dates.shift();
                    }

                    if (!dates.length) {
                        $interval.cancel(counterTimerInterval);
                        $scope.upcomingTimer = '';
                        if (videoEnd) {
                            // If there was an end date then it must be in the past to be at this stage
                            $scope.state = 'event-finished';
                            $scope.canShowAddToCart = false;
                        } else {
                            // If there is no End Date then even if there was a start date it is now in the past
                            $scope.state = usersVideoActiveState;
                        }
                        return;
                    }

                    // Handle the countdown informatiomn
                    switch(dates[0].type) {
                        case 'on-sale-start':
                            $scope.canShowAddToCart = false;
                            $scope.upcomingTimer = timerString(dates[0].date, now, onsaleInPrefix);
                            break;
                        case 'on-sale-end':
                            $scope.canShowAddToCart = true;
                            // Only show the Off Sale timer if the buy button is visible
                            $scope.upcomingTimer = (perfNo && $scope.canShowAddToCart) ? timerString(dates[0].date, now, offsaleInPrefix) : '';
                            break;
                        case 'video-start':
                            $scope.upcomingTimer = timerString(dates[0].date, now, beginsInPrefix);
                            break;
                        case 'video-end':
                            $scope.canShowAddToCart = (!onsaleEndDate || onsaleEndDate > now);  // If there is no onSaleEndDate OR if there is an onSaleEndDate then it MUST be in the future
                            $scope.state = 'event-finished';
                            $scope.upcomingTimer = '';
                            break;
                    }

                    if (videoStart) {
                        if (videoStart < now) {
                            // In order to get to this point if there were a videoEnd then it would still be in the future
                            $scope.state = videoAvailable ? usersVideoActiveState : 'unavailable';
                        } else {
                            // Video start date is in the future
                            $scope.state = 'pending';
                        }
                    }
                };

                var counterTimerInterval = $interval(countdownInterval, 500);

                $scope.toggleChat = function () {
                    $scope.showChat = !$scope.showChat;
                };

                $scope.selectVideo = function(data, autoplay) {

                    // only es6 can have default param values, so this just says if autoplay is not set, default = true
                    if (typeof(autoplay) === 'undefined') {
                        autoplay = true;
                    }

                    $scope.videoId = data.id;
                    player.loadVideo(data.id).then(function(){
                        if (autoplay) {
                            player.play();
                        }
                    });
                    
                }
            }
        ]
    )
}());
