function service($filter, $q, $http, TessituraSDK, Router, AuthenticationService, InventoryService, OrderService, appConfig, proxyEndpoint) {
    var arrayWrap = $filter('arrayWrap');
    var oxford = $filter('oxford');
    var filter = $filter('filter');
    var orderBy = $filter('orderBy');
    var find = $filter('find');
    var performanceLongTitle = $filter('performanceLongTitle');
    var concatDateTime = $filter('concatDateTime');
    var unique = $filter('unique');
    var groupBy = $filter('groupBy');

    var now = moment();
    var validSliStatuses = [
        3,  // Seated Paid
        6,  // Unseated Paid
        12  // Ticketed Paid
    ];
    var timeLimit = 30;

    return {
        getEvents: getEvents,
        getGroupedEvents: getGroupedEvents
    };

    function getEvents() {
        var endpoint = Router.getUrl('cms.digital_events');

        return $q.all([
            AuthenticationService.getLoginInfo(),
            TessituraSDK.GetUpcomingEvents(),
            $http.get(endpoint),
        ]).then(function (responses) {
            var loginInfo = responses[0];
            var events = arrayWrap(responses[1].data.result.ExecuteLocalProcedureResults.LocalProcedure);
            var digitalEvents = responses[2].data;

            // Only paid and seated items
            events = events.filter(function (event) {
                return validSliStatuses.indexOf(parseInt(event.sli_status, 10)) !== -1;
            });

            // Remove parking
            events = events.filter(function (event) {
                return parseInt(event.facility_no, 10) !== 13;
            });

            events = events.map(function (event) {
                event.perf_no = parseInt(event.perf_no, 10);
                event.prod_season_no = parseInt(event.prod_season_no, 10);
                event.pkg_no = parseInt(event.pkg_no, 10);
                event.perf_type = parseInt(event.perf_type, 10);
                event.customer_no = parseInt(event.customer_no, 10);
                event.delivery = parseInt(event.delivery, 10);
                event.delivery_desc = event.delivery_desc.trim();

                event.is_digital = false;
                event.digital_start_dt = null;
                event.digital_end_dt = null;

                var qrCode = null;

                if (event.sli_nscan_barcode) {
                    qrCode = proxyEndpoint + '/generateqr?text=' + event.sli_nscan_barcode + '&format=svg';
                }

                event.qr_code = qrCode;

                if (appConfig.digital_prod_type_ids.indexOf(event.perf_type) !== -1) {
                    // Is digital event
                    var digitalPerf = find(digitalEvents, function (digitalEvent) {
                        return parseInt(digitalEvent.perf_no, 10) === event.perf_no;
                    });

                    if (digitalPerf) {
                        event.is_digital = true;
                        event.digital_start_dt = digitalPerf.start_date;
                        event.digital_end_dt = digitalPerf.end_date;
                    }
                }

                return event;
            });

            events = filter(events, function (event) {
                // Digital events check the dates from CMS data (if end_date is null then it has no end date)
                if (event.is_digital) {
                    return event.end_date === null || moment.parseZone(event.end_date) > now;
                }

                var difference = now.diff(moment.parseZone(event.perf_dt), 'minutes');

                // Non-digital events just need to be in the future
                return moment.parseZone(event.perf_dt) > now || difference < timeLimit;
            });

            var performanceIds = unique(events.map(function (event) {
                return event.perf_no;
            }));

            return InventoryService.GetPerformancesEx4({
                iModeOfSale: loginInfo.MOS,
                sPerformanceIds: performanceIds.join(',')
            }).then(function (response) {
                events = events.reduce(function (carry, event) {
                    var performance = augmentPerformance(event, response.Performance, response.WebContent);

                    if (performance) {
                        carry.push(performance);
                    }

                    return carry;
                }, []);

                return orderBy(events, ['+perf_dt']);
            });
        });
    }

    function getGroupedEvents(limit) {
        return getEvents().then(function (events) {
            events = groupBy(events, 'perf_no');

            events = Object.keys(events).reduce(function (carry, perfNo) {
                var performances = events[perfNo];
                var performance = performances[0];

                var orders = unique(performances.map(function (item) {
                    return item.order_no;
                }));

                var seats = performances.map(function (item) {
                    return {
                        order_no: item.order_no,
                        qr_code: item.qr_code,
                        seat_num: item.seat_num,
                        seat_row: item.seat_row,
                        section_add_text: item.section_add_text,
                        section_desc: item.section_desc,
                        sli_no: item.sli_no,
                        sli_nscan_barcode: item.sli_nscan_barcode,
                        sli_status: item.sli_status,
                        ticket_no: item.ticket_no,
                        zone_desc: item.zone_desc
                    };
                });

                var printable = performances.some(function (item) {
                    return item.print_ind === 'Y';
                });

                var seated = performances.some(function (item) {
                    return item.is_seated === 'Y';
                });

                var printed = performances.some(function (item) {
                    return item.is_printed === 'Y';
                });

                var event = {
                    can_email: (printable || printed) && moment.utc(performance.perf_dt).subtract(1, 'hour').isAfter(now),
                    can_exchange: seated && (printable || printed) && moment.utc(performance.perf_dt).subtract(1, 'day').isAfter(now),
                    can_print: (printable || printed) && moment.utc(performance.perf_dt).subtract(1, 'hour').isAfter(now),
                    customer_no: performance.customer_no,
                    description: performance.description,
                    details_url: performance.details_url,
                    digital_dt_desc: performance.digital_dt_desc,
                    digital_end_dt: performance.digital_end_dt,
                    digital_start_dt: performance.digital_start_dt,
                    facility_desc: performance.facility_desc,
                    image_url: performance.image_url,
                    is_digital: performance.is_digital,
                    perf_dt: performance.perf_dt,
                    perf_dt_desc: performance.perf_dt_desc,
                    perf_no: performance.perf_no,
                    perf_type: performance.perf_type,
                    pkg_no: performance.pkg_no,
                    print_ind: performance.print_ind,
                    prod_season_no: performance.prod_season_no,
                    orders: performances.map(function (item) {
                        return {
                            delivery: item.delivery,
                            delivery_desc: item.delivery_desc,
                            is_printed: item.is_printed,
                            is_seated: item.is_seated,
                            order_dt: item.order_dt,
                            order_no: item.order_no
                        };
                    }),
                    orders_str: oxford(orders),
                    printed_seats: seats.filter(function (seat) {
                        return seat.ticket_no;
                    }),
                    unprinted_seats: seats.filter(function (seat) {
                        return !seat.ticket_no;
                    })
                };

                carry.push(event);

                return carry;
            }, []);

            if (limit && events.length > limit) {
                events = events.slice(0, limit);
            }

            return events;
        });
    }

    function augmentPerformance(event, performances, webContents) {
        var performance = find(performances, function (performance) {
            return parseInt(performance.perf_no, 10) === event.perf_no;
        });

        if (!performance) {
            return false;
        }

        performance.webContent = filter(webContents, function (webContent) {
            return parseInt(webContent.orig_inv_no, 10) === event.perf_no;
        });

        var tbaText = false;

        var tbaTextContent = find(performance.webContent, function (webContent) {
            return parseInt(webContent.content_type, 10) === parseInt(appConfig.webContentTypes.dateTba, 10);
        });

        if (tbaTextContent) {
            tbaText = tbaTextContent.content_value;
        }

        var digital_dt_desc = null;

        if (event.is_digital) {
            var digitalStartDate = moment.utc(event.digital_start_dt);
            var digitalEndDate = moment.utc(event.digital_end_dt);

            if (event.digital_start_dt && digitalStartDate.isAfter(now)) {
                // Starts in future "Available from ..."
                digital_dt_desc = concatDateTime(event.digital_start_dt, "'Available from' EEEE, MMMM d, yyyy", {
                    showtime: true,
                    tbaText: tbaText
                });
            } else if (event.digital_end_dt && digitalEndDate.isAfter(now)) {
                // Ends in future "Available until ..."
                digital_dt_desc = concatDateTime(event.digital_end_dt, "'Available until' EEEE, MMMM d, yyyy", {
                    showtime: true,
                    tbaText: tbaText
                });
            } else {
                digital_dt_desc = 'Currently Available';
            }
        }

        return angular.extend(event, {
            description: performanceLongTitle(performance),
            facility_desc: performance.facility_desc,
            details_url: event.is_digital ? Router.getUrl('cms.digital_link', { perf_no: event.perf_no }) : Router.getUrl('cms.link', { prod_no: event.prod_season_no }),
            image_url: Router.getUrl('cms.image2', { prod_no: event.prod_season_no, perf_no: event.perf_no }),
            perf_dt_desc: concatDateTime(event.perf_dt, 'EEEE, MMMM d, yyyy', {
                showtime: true,
                tbaText: tbaText
            }),
            digital_dt_desc: digital_dt_desc,
        });
    }
}

app.service('TicketManagementService', [
    '$filter', '$q', '$http', 'TessituraSDK', 'Router', 'AuthenticationService', 'InventoryService', 'OrderService', 'appConfig', 'proxyEndpoint', service
]);
