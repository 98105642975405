/* global moment */
app.service('GoogleTagManager', ['$filter', '$q', 'TessituraSDK', 'appConfig', function ($filter, $q, TessituraSDK, appConfig) {

  var arrayWrap = $filter('arrayWrap');
  var sum = $filter('sumOfValue');
  var groupBy = $filter('groupBy');
  var find = $filter('find');
  var map = $filter('map');
  var unique = $filter('unique');

  return {
    sendTransaction: sendTransaction,
    sendCheckoutStep: sendCheckoutStep,
    sendCheckoutEvents: sendCheckoutEvents,
    sendCartContents: sendCartContents,
  };

  function sendCartContents(cart) {

    // Getting data for an incomplete cart is almost identical to getting it for a checked out cart
    // so i've reused as much code as possible, and just modified it so that i can change the names
    $q.all([
      cartEvent(cart),
      subscriptionCartEvents(cart, 'cart.subscription'),
      performanceCheckoutEvents(cart, 'cart.performance'),
      productionSeasonCheckoutEvents(cart, 'cart.production_season'),
      contributionCheckoutEvents(cart, 'cart.contribution')
    ])
    .then(function (eventLists) {

      // Reduce the array of event arrays into 1 array.
      var stuff = eventLists.reduce(function (totalEvents, list) {
        if (!angular.isArray(list)) {
          return totalEvents;
        }
        
        return totalEvents.concat(list);
      }, []);

      return stuff;
    }).then(pushEventsToDataLayer);
    
  }

  /**
   * Measures a checkout step.
   * https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
   * @param  {int} step      Step number
   * @param  {string} option Optional extra param
   * @return {Promise}
   */
  function sendCheckoutStep(step, option) {
    var object = {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: step
          },
        }
      }
    };

    if (option) {
      object.ecommerce.checkout.actionField.option = option;
    }

    return pushEventToDataLayer(object);

  }

  /**
   * Sends a load of GTM events which are used for tracking pixels.
   * If any of themes parameters change, please check all GTM tags and inform CTG>
   * Events include:
   * 
   *   checkout.order
   *     - order_no
   *     - revenue
   *     - revenue_inc_fees
   *     - total_tickets
   *     
   *   checkout.subscription 
   *     - order_no
   *     - revenue
   *     - revenue_inc_fees
   *     - amount_of_performances
   *     - amount_of_subscribers
   *     - amount_of_tickets
   *     - package_no
   *     - package_code
   *     - package_desc
   *   
   *   checkout.production_season
   *     - order_no
   *     - revenue
   *     - revenue_inc_fees
   *     - amount_of_tickets
   *     - season_no
   *     - production_season_no
   *     - performance_desc
   *     - is_part_of_package - (yes|no)
   *   
   *   checkout.performance
   *     - order_no
   *     - revenue
   *     - revenue_inc_fees
   *     - amount_of_tickets
   *     - season_no
   *     - production_season_no
   *     - performance_no
   *     - performance_desc
   *     - is_part_of_package - (yes|no)
   *   
   *   checkout.contribution
   *     - order_no
   *     - campaign_desc
   *     - fund_no
   *     - fund_desc
   *     - cont_amt
   *     - recd_amt
   *
   * @param  {[int]} orderNo Order number to get the data from
   * @return {Promise}
   */
  function sendCheckoutEvents(orderNo) {
    return getOrderDetails(orderNo)
    .then(orderDetailsToCheckoutEvents)
    .then(pushEventsToDataLayer);
  };

  /**
   * Takes in order details and returns an array of checkout events.
   * @param  {[type]} orderDetails [description]
   * @return {[type]}              [description]
   */
  function orderDetailsToCheckoutEvents(orderDetails) {
    return $q.all([
      orderCheckoutEvent(orderDetails),
      subscriptionCheckoutEvents(orderDetails),
      performanceCheckoutEvents(orderDetails),
      productionSeasonCheckoutEvents(orderDetails),
      contributionCheckoutEvents(orderDetails)
    ])
    .then(function (eventLists) {

      // Reduce the array of event arrays into 1 array.
      return eventLists.reduce(function (totalEvents, list) {
        if (!angular.isArray(list)) {
          return totalEvents;
        }
        
        return totalEvents.concat(list);
      }, [])
    });
  }

  function orderCheckoutEvent(orderDetails) {
    var event = {
      event: 'checkout.order',
      order_no: orderDetails.Order.order_no,
      total_tickets: orderDetails.SubLineItem.length 
    };

    event.revenue = sum(orderDetails.SubLineItem, 'due_amt') +
      sum(orderDetails.Contribution, 'contribution_amt');
    
    event.revenue_inc_fees = event.revenue +
      sum(orderDetails.SubLineItemFee, 'fee_amt')
      sum(orderDetails.Fee, 'fee_amt');

    return [event];
  }

  function cartEvent(cartDetails, eventName) {
    // This is not es6 so we can't use parameter defaults
    eventName = eventName || 'cart.order';

    var event = {
      event: eventName,
      order_no: cartDetails.Order.order_no,
      total_tickets: cartDetails.SubLineItem.length,
      total: sum(cartDetails.SubLineItem, 'due_amt')
    };

    return [event];
  }

  function subscriptionCartEvents(orderDetails, eventName) {
    // This is not es6 so we can't use parameter defaults
    eventName = eventName || 'cart.subscription';

    // Remove sublineitems that arent werent brought in a package
    var subLineItemsFromPackage = orderDetails.PackageSubLineItem.filter(function (s) { return parseFloat(s.pkg_no) > 0; });

    // Attach lineitem data to each sublineitem
    subLineItemsFromPackage = subLineItemsFromPackage.map(function (subLineItem) {
      return angular.extend({}, 
        find(orderDetails.PackageLineItem, { li_seq_no: subLineItem.li_seq_no }),
        subLineItem);
    });

    // Group the performances by package
    var packages = groupBy(subLineItemsFromPackage, 'pkg_li_no');
    packages = objectValues(packages);


    return packages.map(function (subLineItems) {
      var subLineItem = subLineItems[0];
      // Get the sublineitem fees
      var subLineItemFees = getFeesFromSublineItems(subLineItems, orderDetails.SubLineItemFee);

      var event = {
        event: eventName,
        order_no: orderDetails.Order.order_no,
      };

      // Revenues
      event.revenue = sum(subLineItems, 'due_amt');
      event.revenue_inc_fees = event.revenue + sum(subLineItemFees, 'fee_amt');

      // Counts
      event.amount_of_performances = unique(map(subLineItems, 'perf_no')).length;
      event.amount_of_tickets = subLineItems.length;
      event.amount_of_subscribers = event.amount_of_tickets / event.amount_of_performances;

      // Package details
      event.package_no = subLineItem.pkg_no;
      event.package_desc = subLineItem.pkg_desc.trim();
      event.package_code = subLineItem.pkg_code.trim();

      return event;
    });
  }

  function subscriptionCheckoutEvents(orderDetails, eventName) {
    // This is not es6 so we can't use parameter defaults
    eventName = eventName || 'checkout.subscription';

    // Remove sublineitems that arent werent brought in a package
    var subLineItemsFromPackage = orderDetails.SubLineItem.filter(function (s) { return parseFloat(s.pkg_no) > 0; });

    // Attach lineitem data to each sublineitem
    subLineItemsFromPackage = subLineItemsFromPackage.map(function (subLineItem) {
      return angular.extend({}, 
        find(orderDetails.LineItem, { li_seq_no: subLineItem.li_seq_no }),
        subLineItem);
    });

    // Group the performances by package
    var packages = groupBy(subLineItemsFromPackage, 'pkg_li_no');
    packages = objectValues(packages);


    return packages.map(function (subLineItems) {
      var subLineItem = subLineItems[0];
      // Get the sublineitem fees
      var subLineItemFees = getFeesFromSublineItems(subLineItems, orderDetails.SubLineItemFee);

      var event = {
        event: eventName,
        order_no: orderDetails.Order.order_no,
      };

      // Revenues
      event.revenue = sum(subLineItems, 'due_amt');
      event.revenue_inc_fees = event.revenue + sum(subLineItemFees, 'fee_amt');

      // Counts
      event.amount_of_performances = unique(map(subLineItems, 'perf_no')).length;
      event.amount_of_tickets = subLineItems.length;
      event.amount_of_subscribers = event.amount_of_tickets / event.amount_of_performances;

      // Package details
      event.package_no = subLineItem.pkg_no;
      event.package_desc = subLineItem.pkg_desc.trim();
      event.package_code = subLineItem.pkg_code.trim();

      return event;
    });
  }

  function performanceCheckoutEvents(orderDetails, eventName) {
    // This is not es6 so we can't use parameter defaults
    eventName = eventName || 'checkout.performance';

    var perfNos = map(orderDetails.SubLineItem, 'perf_no');

    // Get performance details
    return getPerformances(perfNos)
    .then(function (performances) {


      // Generate a key to group the performances by
      var subLineItemsGroups = orderDetails.SubLineItem.map(function (subLineItem) {
        return angular.extend({}, subLineItem, {
          group_id: subLineItem.perf_no + '-' + subLineItem.pkg_no
        });
      });

      subLineItemsGroups = groupBy(subLineItemsGroups, 'group_id');
      subLineItemsGroups = objectValues(subLineItemsGroups);

      // For each group of performances, generator a GTM event
      return subLineItemsGroups.map(function (subLineItemsGroup) {

        var subLineItem = subLineItemsGroup[0];
        // Get the sublineitem fees
        var subLineItemFees = getFeesFromSublineItems(subLineItemsGroup, orderDetails.SubLineItemFee);

        // Stock event
        var event = {
          event: eventName,
          order_no: orderDetails.Order.order_no,
          amount_of_tickets: subLineItemsGroup.length,
          is_part_of_package: parseFloat(subLineItem.pkg_no) > 0 ? 'yes':'no',
        };

        // Revenue
        event.revenue = sum(subLineItemsGroup, 'due_amt');
        event.revenue_inc_fees = event.revenue + sum(subLineItemFees, 'fee_amt');

        // Performance details
        event.performance_no =  subLineItem.perf_no;
        event.season_no = performances[subLineItem.perf_no].season_no;
        event.production_season_no = performances[subLineItem.perf_no].prod_season_no;
        event.performance_desc = performances[subLineItem.perf_no].description.trim();

        return event;
      });

    });

  }



  function productionSeasonCheckoutEvents(orderDetails, eventName) {
    // This is not es6 so we can't use parameter defaults
    eventName = eventName || 'checkout.production_season';

    var perfNos = map(orderDetails.SubLineItem, 'perf_no');

    // Get performance details
    return getPerformances(perfNos)
    .then(function (performances) {

      // Generate a key to group the performances by
      var subLineItemsGroups = orderDetails.SubLineItem.map(function (subLineItem) {
        return angular.extend({}, subLineItem, {
          group_id: performances[subLineItem.perf_no].prod_season_no + '-' + subLineItem.pkg_no
        });
      });

      subLineItemsGroups = groupBy(subLineItemsGroups, 'group_id');
      subLineItemsGroups = objectValues(subLineItemsGroups);

      // For each group of performances, generator a GTM event
      return subLineItemsGroups.map(function (subLineItemsGroup) {

        var subLineItem = subLineItemsGroup[0];
        // Get the sublineitem fees
        var subLineItemFees = getFeesFromSublineItems(subLineItemsGroup, orderDetails.SubLineItemFee);

        // Stock event
        var event = {
          event: eventName,
          order_no: orderDetails.Order.order_no,
          amount_of_tickets: subLineItemsGroup.length,
          is_part_of_package: parseFloat(subLineItem.pkg_no) > 0 ? 'yes':'no',
        };

        // Revenue
        event.revenue = sum(subLineItemsGroup, 'due_amt');
        event.revenue_inc_fees = event.revenue + sum(subLineItemFees, 'fee_amt');

        // Performance details
        event.season_no = performances[subLineItem.perf_no].season_no;
        event.production_season_no = performances[subLineItem.perf_no].prod_season_no;
        event.performance_desc = performances[subLineItem.perf_no].description.trim();

        return event;
      });

    });

  }

  function contributionCheckoutEvents(orderDetails, eventName) {
    // This is not es6 so we can't use parameter defaults
    eventName = eventName || 'checkout.contribution';

    return orderDetails.Contribution.map(function (contribution) {

        // Stock event
        var event = {
          event: eventName,
          order_no: orderDetails.Order.order_no,
          campaign_desc: contribution.campaign_desc,
          fund_no: contribution.fund_no,
          fund_desc: contribution.fund_desc,
          cont_amt: contribution.contribution_amt,
          recd_amt: contribution.recd_amt,
        };

      return event;
    })
  }

  function getFeesFromSublineItems(subLineItems, subLineItemFees) {

    var subLineItem = subLineItems[0];
    return subLineItemFees.filter(function (subLineItemFee) {
      var searchParams = {
        li_seq_no: subLineItemFee.li_seq_no
      };
      return find(subLineItems, searchParams) ? true:false;
    });

  }

  function objectValues(object) {
    var values = [];
    angular.forEach(object, function (item) {
      values.push(item);
    });

    return values;
  }


  function getPerformances(performanceNos) {
    return $q.all(performanceNos.map(getPerformance))
    .then(function (performances) {
      var performancesMap = {};

      performances.forEach(function (performance) {
        performancesMap[performance.inv_no] = performance;
      });

      return performancesMap;
    });
  }

  function getPerformance(perfNo) {
    return TessituraSDK.GetPerformanceDetailEx({
      iPerf_no: perfNo,
      iModeOfSale: appConfig.generalMos
    })
    .then(function (response) {
      return response.data.result.GetPerformanceDetailExResult.Performance;
    });
  }


  /**
   * Adds a transaction purchased event 
   * into the datalayer for GTM.
   * https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
   * @param  {[int]} orderNo Order number to get the data from
   * @return {Promise}
   */
  function sendTransaction(orderNo) {
    return getOrderDetails(orderNo)
    .then(orderDetailsToTransaction)
    .then(pushEventToDataLayer);
  };

  /**
   * Takes in the OrderDetails from GetOrderDetails 
   * and converts it into a dataLayer object.
   * @param  {OrderDetails} orderDetails OrderDetails from GetOrderDetails request
   * @return {Object}
   */
  function orderDetailsToTransaction(orderDetails) {
    var object = {
      event: 'purchase',
      
      // Tell GTM we are doing ecommerce
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          // Array of products brought
          products: [],

          // Transaction details
          actionField: {
            id: orderDetails.Order.order_no,
            revenue: orderDetails.Order.tot_due_amt
          }
        }
      }
    };

    // Standard tickets
    var products = {};

    orderDetails.LineItem.forEach(function (lineItem) {
      orderDetails.SubLineItem.forEach(function (subLineItem) {

        if (subLineItem.li_seq_no !== lineItem.li_seq_no) {
          return;
        }

        var id = 'Ticket: ' + lineItem.perf_no + ' - ' + subLineItem.price_type;

        if (!products[id]) {
          products[id] = {
            id: id,
            name: lineItem.perf_desc,
            price: subLineItem.due_amt,
            category: 'Tickets',
            quantity: 1,

            // Time of performance, ignores time zone
            dimension1: moment(lineItem.perf_dt, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'),
            
            // Other standard performance details
            dimension2: subLineItem.zone_desc,
            dimension3: subLineItem.price_type_desc,
            dimension4: lineItem.facility_desc,

            // Time of performance, ignores time zone
            dimension5: moment(lineItem.perf_dt, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')
          };
        } else {
          products[id].quantity++;
        }

      });
    });

    for (var i in products) {
      object.ecommerce.purchase.products.push(products[i]);
    }


    // Subline item fees
    orderDetails.SubLineItemFee.forEach(function (subLineItemFee) {

      var product = {
        id: 'Fee: ' + subLineItemFee.id,
        name: subLineItemFee.fee_desc,
        price: subLineItemFee.fee_amt,
        category: subLineItemFee.category_desc
      };

      object.ecommerce.purchase.products.push(product);

    });

    // Order fees
    orderDetails.Fee.forEach(function (fee) {

      var product = {
        id: 'Fee: ' + fee.category_desc,
        name: fee.category_desc,
        price: fee.total_fees,
        category: 'Order Fees'
      };

      object.ecommerce.purchase.products.push(product);

    });

    // Contributions
    orderDetails.Contribution.forEach(function (contribution) {

      var product = {
        id: 'Contribution: ' + contribution.ref_no,
        name: contribution.fund_desc,
        price: contribution.contribution_amt,
        category: 'Donations',
      };

      object.ecommerce.purchase.products.push(product);

    });


    return object;
  }

  /**
   * Takes an order number and returns the orderDetails from tessi
   * @param  {int} orderNo Tessitura order number
   * @return {Promise}
   */
  function getOrderDetails (orderNo) {
    return TessituraSDK.GetOrderDetails({
      OrderNumber: orderNo
    })
    .then(function (response) {
      var orderDetails = response.data.result.GetOrderDetailsInfoResults;

      orderDetails.LineItem = arrayWrap(orderDetails.LineItem);
      orderDetails.SubLineItem = arrayWrap(orderDetails.SubLineItem);
      orderDetails.UnfilteredSubLineItem = arrayWrap(orderDetails.SubLineItem);
      orderDetails.SubLineItemFee = arrayWrap(orderDetails.SubLineItemFee);
      orderDetails.Fee = arrayWrap(orderDetails.Fee);
      orderDetails.Payment = arrayWrap(orderDetails.Payment);
      orderDetails.Contribution = arrayWrap(orderDetails.Contribution);

      return orderDetails;
    });
  }

  function pushEventsToDataLayer(events) {
    var promises = arrayWrap(events).map(pushEventToDataLayer);
    return $q.all(promises);
  }

  /**
   * Adds an object to GTMs dataLayer.
   * Also registers an event callback to the dataLayer
   * object which returns a promise.
   * @return {Promise}
   */
  function pushEventToDataLayer(object) {
    window.dataLayer = window.dataLayer || [];

    var differ = $q.defer();
    object.eventCallback = function() {
      differ.resolve();
    }

    window.dataLayer.push(object);

    return differ.promise;
  }

}]);
