app.service('Donation', ['$q', '$filter', 'appConfig', 'TessituraSDK', 'AuthenticationService', 'Cart', function ($q, $filter, appConfig, TessituraSDK, AuthenticationService, Cart) {
  'use strict';

  var
    arrayWrap = $filter('arrayWrap'),
    groupBy = $filter('groupBy');

  var getCartDonationValuesCache = null;

  return {
    updateContributionInCart: updateContributionInCart,
    getRecommendDonationAmount: getRecommendDonationAmount,
    getDonationValues: getDonationValues,
    getDonationBands: getDonationBands,
    addDefaultDonationAmount: addDefaultDonationAmount,
    donationValueIsBetween: donationValueIsBetween
  };

  function getDonationValues(){
    return getDonationValuesTable();
  }

  function getDonationBands(){
    return TessituraSDK.GetDonationBands()
      .then(function(response) {
        var
          results = response.data.result.ExecuteLocalProcedureResults.LocalProcedure,
          grouped = groupBy(arrayWrap(results), 'fund_no'),
          scopelevels = [];

        angular.forEach(grouped, function (levels) {
          var fund = {
            fund: levels[0].fund_no,
            title: levels[0].band,
            levels: []
          };

          angular.forEach(levels, function (level) {
            fund.levels.push({
              level: level.membership_level,
              min: level.level_min,
              max: level.level_max,
              value: level.market_value,
              description: level.description,
              code: level.memb_code,
              is_default: level.is_default,
              org_no: level.memb_org_no
            });
          });

          // Sort the levels by min
          fund.levels.sort(function (a, b) {
            return parseFloat(a.min) > parseFloat(b.min) ? 1:-1;
          });

          scopelevels.push(fund);
        });

        return $q.resolve(scopelevels);
      })
  }

  function donationValueIsBetween(val, min, max){
    val = parseFloat(val);
    min = parseFloat(min);
    max = parseFloat(max);

    return val <= max && val >= min;
  }

  function getRecommendDonationAmount(){
    return getDonationValuesTable()
      .then(function (donationValesTable) {

        for (var i = 0; i < donationValesTable.length; i++) {
          var value = donationValesTable[i];

          if (value.is_default == 1) {
            return $q.resolve(value.amount);
          }
        }

        return $q.resolve(0);
      });
  }

  function hasMembershipInCart() {
    return Cart.getCart()
    .then(function (cartDetails) {
      return cartDetails.Contribution.some(function (contribution) {
        return contribution.renew_upgrade_ind == 'R';
      });
    });
  }


  function hasPackageInCart() {
    return Cart.getCart()
    .then(function (cartDetails) {
      return cartDetails.PackageLineItem.length > 0;
    });
  }


  /**
   * Decides which donation values table to return.
   * The source changes based on if the user as a renewal in their cart
   * @return {Promise} [description]
   */
  function getDonationValuesTable() {
    // Change this true for if the user has a membership in their cart

    return hasPackageInCart()
    .then(function (hasPackageInCart) {
      if (hasPackageInCart) {
        return getCartDonationValuesFromSproc();
      } else {
        return getRoundUpDonationValuesTable();
      }
    })
    .then(function (donationTable) {

      // Add the users current donation amount to the list
      return getCurrentDonationAmount()
      .then(function (currentDonationAmount) {

        // Only add the amount if its not 0
        if (currentDonationAmount != 0) {

          // Unselect the default
          donationTable.forEach(function (donationRow) {
            donationRow.is_default = 0;
          });

          donationTable.unshift({
            amount: currentDonationAmount,
            is_default: 1
          });
        }

        return donationTable;
      });

    })

    // Post processing on the donation values
    .then(function (donationTable) {

      // Put selected rows first
      donationTable = donationTable.sort(function (row) {
        return row.is_default ? -1:1;
      });

      // Filter out duplicates
      var seenValues = [];
      donationTable = donationTable.filter(function (row) {
        var amount = parseFloat(row.amount);
        if (seenValues.indexOf(amount) == -1) {
          seenValues.push(amount);
          return true;
        } else {
          return false;
        }
      });

      // Sort the values by amount high to low
      donationTable = donationTable.sort(function (a, b) {
        return a.amount > b.amount ? 1:-1;
      });

      return donationTable;
    });
  }

  /**
   * Returns the total value of the carts contents
   * minus any contribution with the default id.
   * @return {[type]} [description]
   */
  function cartTotalMinusDefaultDonations() {
    return Cart.getCart(true)
    .then(function (cartDetails) {
      if (!cartDetails.Order) {
        return 0;
      }

      return parseInt(cartDetails.Order.balance_to_charge, 10);
    });
  }

  /**
   * Gets the donation table which is calculated using
   * the round up donation logic. Basically round up to the nearest values.
   * @return {Promise} Promise returns an array of the donation rows
   */
  function getRoundUpDonationValuesTable() {

    return cartTotalMinusDefaultDonations()
    .then(function (cartTotal) {

      var donationAmounts = appConfig.roundUpDonationValues

      // Map the round up amounts to final figures
      .map(function (amount) {

        // How much do we need to add to hit the rounding amount
        var newTotal = cartTotal + amount;

        return {
          total: newTotal,
          amount: amount,
          fund: appConfig.roundUpDonationFundId,
          is_default: 0
        };
      })
      .filter(function (amount) {
        return amount.total != cartTotal;
      });

      var firstDonation = $filter('first')(donationAmounts);
      if(firstDonation){
        firstDonation.is_default = 1;
      }

      return donationAmounts;
    });
  }

  function getCartDonationValuesFromSproc() {
    if (getCartDonationValuesCache !== null) {
      return getCartDonationValuesCache;
    }

    // To be used if the user has a membership in the cart
    var promise = TessituraSDK.GetCartDonationValues()
      .then(function (response) {
        var valuesFromServer = response.data.result.ExecuteLocalProcedureResults.LocalProcedure;

        valuesFromServer.forEach(function (donationAmount) {
          donationAmount.amount = parseFloat(donationAmount.amount);
          donationAmount.is_default = parseFloat(donationAmount.is_default || '0');
          donationAmount.fund = parseFloat(donationAmount.fund);
        });

        getCartDonationValuesCache = null;

        return valuesFromServer;
      });

      getCartDonationValuesCache = promise;

      return promise;
  }

  /**
   * Returns the current donation amount in the users cart.
   *
   * @return {Promise<int>} Returns a promise that returns an float of the amount
   */
  function getCurrentDonationAmount(){
    return TessituraSDK.GetCart(true)
      .then(function (response) {
        var cartData = response.data.result.GetCartResults || {};

        cartData.Contribution = arrayWrap(cartData.Contribution);

        var currentAmount = false;
        // Remove the current contribution, if there is one
        for (var i in cartData.Contribution) {
          var contribution = cartData.Contribution[i];
          if (appConfig.donationFundId == contribution.fund_no) {
            currentAmount = parseFloat(contribution.contribution_amt);
            break;
          }
        }

        return $q.resolve(currentAmount);
      });
  }

  /**
   * Sets the current donation amount in
   * the cart to the current
   */
  function addDefaultDonationAmount(){
    // We are no longer using this functionality
    // we were adding the default donation when they added the item to
    // the cart. With the new donation popup logic, this isnt needed, but
    // we may want ti back again, so leave it here.
    return;

    // return getRecommendDonationAmount()
    //   .then(function (amount) {
    //     return updateContributionInCart(amount, appConfig.donationFundId);
    //   });
  }

  /**
   * Set the donation amount.
   * It will remove the current donation if there is one
   * It will then add the new one if needed, (not needed if setting to 0)
   */
  function updateContributionInCart(amount, fund_no) {
    var contributionPromises = [];

    return TessituraSDK.GetCart()
      .then(function (response) {
        var cartData = response.data.result.GetCartResults || {};
        var RemoveContribuionParams = {};

        cartData.Contribution = arrayWrap(cartData.Contribution);

        // Remove the current contribution, if there is one
        if (cartData.Contribution.length > 0) {
          // Find the cart contribution to remove
          for (var i = 0; i < cartData.Contribution.length; i++) {
            var contribution = cartData.Contribution[i];
            if (contribution.fund_no == fund_no) {
              RemoveContribuionParams = {
                iLineItemNumber: contribution.ref_no
              };

              // Add to the promise chain
              contributionPromises.push(TessituraSDK.RemoveContribuion(RemoveContribuionParams));
            }
          }
        }

        // If the amount if over 0, add a new contribution
        if (amount > 0) {
          var AddContributionParams = {
              Amount: amount,
              Fund: fund_no,
              AccountMethod: 0,
              Upgrade: 'false',
              Renew: 'false'
          };

          // Add to the promise chain
          contributionPromises.push(TessituraSDK.AddContribution(AddContributionParams));
        }

        // After all requests finish, reload the data
        return $q.all(contributionPromises);
      });
  }

}]);
