app.controller('Passbook2Controller', ['$q', '$scope', '$modalInstance', 'OrderService', 'event', function ($q, $scope, $modalInstance, OrderService, event) {
  // Scope variables
  $scope = angular.extend($scope, {
    passes: null,
    error: false,
    getSection: getSection,
    getRow: getRow,
    getSeatNo: getSeatNo,
    close: $modalInstance.dismiss
  });

  OrderService.PrintTicketsWithPassbook({
    iOrderNo: event.orderNo,
    lineItems: event.li_seq_no,
    performanceNo: event.perf_no,
    subLineItems: ''
  }).then(function (passes) {
    $scope.passes = passes.reduce(function (acc, pass) {
      if (pass.url) {
        acc.push(pass)
      }
      return acc;
    }, []);
    if (!$scope.passes.length) {
      $q.reject({data: {error: 'No passes with URLs'}});
    }
  }).catch(function (error) {
    $scope.error = true;
  });

  function getSection(pass) {
    return event.sublineItems.filter(function (sublineItem) {
      return parseInt(sublineItem.sli_no, 10) === parseInt(pass.sli_no, 10);
    })[0].section_desc;
  };

  function getRow(pass) {
    return event.sublineItems.filter(function (sublineItem) {
      return parseInt(sublineItem.sli_no, 10) === parseInt(pass.sli_no, 10);
    })[0].seat_row;
  };

  function getSeatNo(pass) {
    return event.sublineItems.filter(function (sublineItem) {
      return parseInt(sublineItem.sli_no, 10) === parseInt(pass.sli_no, 10);
    })[0].seat_num;
  };
}]);
