/* global moment */
app.controller('RecommendationsController', ['$scope', '$http', '$attrs', '$filter', 'TessituraSDK', 'AuthenticationService', 'CacheStorage', 'EventService', 'PerformanceSearchService', '$q', 'Router', 'appConfig', function ($scope, $http, $attrs, $filter, TessituraSDK, AuthenticationService, CacheStorage, EventService, PerformanceSearchService, $q, Router, appConfig) {
  'use strict';
  // Stuff
  var prodSeasonNo = $attrs.prodSeasonNo;
  var event_prod_season_no;
  var userKeywords = CacheStorage.get('personalised');
  var
    arrayWrap = $filter('arrayWrap'),
    orderBy = $filter('orderBy'),
    groupBy = $filter('groupBy'),
    allowedCategories = (appConfig.calendarCategories || []),
    allowedProductionTypes = (appConfig.productionTypes || []);

  if (!userKeywords) {
    userKeywords = {
      keywords: {},
      pages: {}
    };
  }

  if($attrs.hasOwnProperty('eventNo')){
    // wait for eventNo to propagate from parent controller
    $attrs.$observe('eventNo', function(value){
      if(value !== undefined && value !== ''){
        var eventNo = value;
        var eventKeywords = [];

        try{
          eventKeywords = JSON.parse($attrs.eventKeywords);
        }catch(e){
            console.warn(e);
        }

        if(parseInt(eventNo, 10) > 0){
          event_prod_season_no = eventNo;
          userKeywords = {};
          userKeywords.keywords = {};
          for( var i = 0, len = eventKeywords.length; i < len; i++){
            userKeywords.keywords[eventKeywords[i].keyword.toLowerCase()] = 10;
          }
          getRecommendations();
        }
      }
    });
  }else{
      getRecommendations();
  }

  // Tell scope we're loading
  $scope = angular.extend($scope, {
    loading: true,
    productions: null
  });

  function getRecommendations(){
    // Get the login info to grab the MOS
    AuthenticationService.getLoginInfo().then(function (loginInfo) {
      // Grab productions with the given keywords
      var proms = [
        PerformanceSearchService.getEvents({
          sStartDate: moment().format('YYYY-MM-DDTHH:00:00Z'),
          sEndDate: moment().add(2, 'years').format('YYYY-MM-DDTHH:00:00Z'),
          iVenueID: -1,
          iModeOfSale: loginInfo.MOS,
          iBusinessUnit: 1,
          iKeywordAndOrStatement: 1
        }),
        PerformanceSearchService.getPerformanceKeywords({
          mos : loginInfo.MOS,
          categories: allowedCategories.join(',')
        })
      ];

      $q.all(proms).then(
        function(responses){

          var mainEvents = [];
          var events = responses[0].Performance,
              webContents = responses[0].WebContent,
              keywords = arrayWrap(responses[1].data.result.ExecuteLocalProcedureResults.LocalProcedure),
              keyWordsByProdSeasonNumber = $filter('groupBy')(keywords,'perf_no'),
              productionsArray = [],
              prodSeasonNumbers = [];
              
          var webContentsBySeason = groupBy(webContents, 'inv_no');
          var eventsGrouped = groupBy(events, 'prod_type');

          var filteredEvents = events.reduce(function(acc, event) {
            if(  allowedProductionTypes.indexOf(Number(event.prod_type)) !== -1) {
              var webContent = webContents.filter(function(webContent){
                return parseInt(webContent.inv_no, 10) === parseInt(event.prod_season_no, 10);
              });
              event.webContent = webContent.reduce(function(acc, content){
                acc[content.content_type_desc] =  content.content_value;
                return acc;
              }, {});
              acc[event.prod_season_no] = event;
            }
            return acc;
          }, {});

          for(var i in filteredEvents) {
            prodSeasonNumbers.push(i);
            productionsArray.push(filteredEvents[i]);
          }

          prodSeasonNumbers = prodSeasonNumbers.reduce(function (acc, prodSeasonNo) {
            acc.push('event[]=' + prodSeasonNo);
            return acc;
          }, []).join('&');

          var eventsServicePromises = [];
          var productionsArrayWithKeywords = productionsArray.map(function(production){
            production.weight = 0;
            var prod_keywords = keyWordsByProdSeasonNumber[production.perf_no];

            if(prod_keywords){
              production.keywords = prod_keywords.reduce(function(previousValue, curr, i, arr){
                var key = prod_keywords[i].description.toLowerCase();
                if(userKeywords.keywords[key]){
                  production.weight =  production.weight + parseInt(userKeywords.keywords[key], 10);
                }
                previousValue.push( key );
                return previousValue;
              },[]);
            }
            return production;
          });

          var eventsServicePromise = $http.get( Router.getUrl('cms.event', { prod_no: '' }) + '?' + prodSeasonNumbers );
          
          eventsServicePromise.then(
            function(eventsServicePromiseResult){
              var cmsData = eventsServicePromiseResult.data;
              productionsArrayWithKeywords.forEach(function(production, index){
                if(cmsData[production.prod_season_no]){
                  for( var key in cmsData[production.prod_season_no]){
                    if( cmsData[production.prod_season_no] ) {
                      production[key] = cmsData[production.prod_season_no][key];
                    }

                    if(production.TitlePrefix === '{{ event.webContent.titlePrefix }}'){
                      production.TitlePrefix = production.webContent.titlePrefix;
                    }

                    if(production.TitleSuffix === '{{ event.webContent.titleSuffix }}'){
                      production.TitleSuffix = production.webContent.titleSuffix;
                    }

                    production.facility = production.facility_desc;
                    production.venueClass = production.facility.toLowerCase().split(' ').join('-');

                    if(cmsData[production.prod_season_no].Link){
                      production.bookingURL = cmsData[production.prod_season_no].Link;
                    }

                  } 
                } else {
                  delete productionsArrayWithKeywords[index];
                }
              });
              setScopeProductions(productionsArrayWithKeywords);
          },
          function(){
              setScopeProductions(productionsArrayWithKeywords);
          });
        },
        function(error){
          console.log(error);
        }
      );
    });
  };

  function setScopeProductions(productions){
    if(event_prod_season_no !== undefined){
      productions = productions.filter(function(production){
        return parseInt(production.prod_season_no, 10) !== parseInt(event_prod_season_no, 10);
      })
    }

    $scope.productions = orderBy(productions, '-weight');
    $scope.loading = false;
  }
}]);
