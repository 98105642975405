
app.controller('PasswordChangeController', ['$scope', '$q', '$rootScope', 'TessituraSDK', 'Notifications', 'AuthenticationService', function ($scope, $q, $rootScope, TessituraSDK, Notifications, AuthenticationService) {

  $scope.formError = false;
  $scope.processing = false;
  $scope.loading = true;
  $scope.userIsGuest = false;


  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      $scope.userIsGuest = true;
      $scope.loading = false;
      return $q.reject();
    }

    return TessituraSDK.GetConstituentInfoEx();
  })
  .then(function (GetConstituentInfoExResponse) {
    $scope.loading = false;
    $scope.constituentInfo = GetConstituentInfoExResponse.data.result.GetConstituentInfoExResults;
  });

  $scope.changePassword = function () {
    if (!$scope.currentPassword) {
      $scope.formError = true;
      broadcastAlert('warning', 'Your must enter your current password.');
      return;
    }
    if (!$scope.newPassword) {
      $scope.formError = true;
      broadcastAlert('warning', 'Your must enter a new password.');
      return;
    }

    var primaryEmail = $scope.getPrimaryEmail($scope.constituentInfo.EmailAddresses);

    $scope.processing = true;

    TessituraSDK.ConfirmPassword({
      "email": primaryEmail.address,
      "password": $scope.currentPassword
    })
    .then(function (ConfirmPasswordResponse){
      if (ConfirmPasswordResponse.data.result) {
        TessituraSDK.UpdateLoginWithPromoCode({
          "sUID": primaryEmail.address,
          "sNewUID": primaryEmail.address,
          "sNewPwd": $scope.newPassword,
          "sEmail": primaryEmail.address,
          "sNewEmail": primaryEmail.address
        })
        .then(function (response) {
          if (response.data.result) {
            broadcastAlert('success', 'Your password was changed successfully.');
          } else {
            $scope.formError = true;
            broadcastAlert('warning', 'The password you entered does not match our records.');
          }
          $scope.processing = false;
        });
      } else {
        $scope.formError = true;
        broadcastAlert('warning', 'Sorry, your password could not be changed.');
        $scope.processing = false;
      }
    });
  };

  $scope.getPrimaryEmail = function (emailAddresses) {
    if (emailAddresses.constructor === Object) {
      emailAddresses = [emailAddresses];
    }

    for (var i = 0; i < emailAddresses.length; i++) {
      if (emailAddresses[i].address_type_desc === 'Primary Email Address') {
        return emailAddresses[i];
      }
    }
  };

  function broadcastAlert(alertType, msg) {
    var alert = {
      message   : msg,
      style     : alertType,
      container : 'change-password-alert'
    };
    Notifications.create(alert, 'changePassword');
  }
}]);
