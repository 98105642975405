app.filter('filterInArray', function () {
  return function (arrayToFilter, propInArray, arrayToFindIn) {
    if (arrayToFilter === undefined) {
      return [];
    }

    return arrayToFilter.filter(function (arrayElement) {
      // Does the property exist in the object
      if (!arrayElement[propInArray]) {
        return false;
      }

      // does the value of the property exist in the array
      return arrayToFindIn.some(function (item) {
        // Yes do a lazy compare
        return item == arrayElement[propInArray];
      });
    });
  };
});
