
app.service('Router', ['$http', 'toQuerystring', '$location', '$window', '$q', function ($http, toQuerystring, $location, $window, $q) {
  var paramRegex = /\{[a-zA-Z0-9]+\}/g;
  var routes;



  return {
    setRoutes: setRoutes,
    isCurrentRoute: isCurrentRoute,
    getUrl: getUrl,
    goTo: goTo
  };

  function goTo(key, params){
    $window.location = getUrl(key, params);
    return $q.defer().promise;
  }

  function setRoutes(_routes){
    routes = _routes;
  }
  
  function isCurrentRoute(route) {
    return getUrl(route) == $location.$$absUrl;
  }

  function getUrl(key, params){
    if (routes === undefined) {
      return '#';
    }

    params = params || {};
    params = angular.copy(params);

    // Check the URL route exists
    if(routes[key] == void(0)) {
      throw new Error(key + ' is not a known route');
    }

    // Replace URL variables with the param values
    var filledRoute = routes[key].replace(/\{[a-zA-Z0-9\-_]+\}/g, function(match){
      var paramKey = match.substr(1, match.length-2);
      if(params[paramKey] == void(0)) {
        throw new Error('URL ' + key + ' requires the parameter ' + paramKey);
      }

      var paramValue = params[paramKey];

      // delete the params used so the rest go into get
      delete params[paramKey];

      return encodeURIComponent(paramValue);
    });

    //and attach the left over params as get
    var getParams;
    if(Object.keys(params).length > 0) {
      if (filledRoute.indexOf('?') == -1) {
        getParams = '?' + toQuerystring(params);
      } else {
        getParams = '&' + toQuerystring(params);
      }
    } else {
      getParams = '';
    }

    // Return the base, the url path, and get params
    return routes.base + filledRoute + getParams;
  }

}]);
