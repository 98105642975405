app.controller('FriendsAttendingController', ['$scope', '$attrs', 'PerformanceSearchService', 'AuthenticationService', function ($scope, $attrs, PerformanceSearchService, AuthenticationService) {

  var GetFacebookFriendsParams = {};

  if ($attrs.perfNo) {
    GetFacebookFriendsParams['perf_no'] = $attrs.perfNo;
  } else if ($attrs.prodSeasonNo) {
    GetFacebookFriendsParams['prod_no'] = $attrs.prodSeasonNo;
  }

  $scope.facebookFriends = [];
  $scope.loading = true;

  PerformanceSearchService
    .getFacebookFriends(GetFacebookFriendsParams)
      .then(function (response) {
        if (response.data && response.data.result) {
          var
          result = response.data.result,
          facebookFriendIds = [];

          angular.forEach(result, function (user) {
            if (facebookFriendIds.indexOf(user.fid) === -1) {
              facebookFriendIds.push(user.fid);
              $scope.facebookFriends.push(user);
            }
          });
        }
      })
      .finally(function () {
        $scope.loading = false;
      });
}]);
