app.service('Cart', ['$q', '$filter', 'TessituraSDK', 'Notifications', 'AuthenticationService', 'APIService', 'CacheStorage', 'appConfig', function ($q, $filter, TessituraSDK, Notifications, AuthenticationService, APIService, CacheStorage, appConfig) {

  var
    arrayWrap = $filter('arrayWrap'),
    unique = $filter('unique'),
    orderBy = $filter('orderBy'),
    where = $filter('where');

  var getCartCache = null;
  var recalculateCartCache = null;

  var PARKING_FACILITIES = ["13"];

  return {
    isCartEmpty: isCartEmpty,
    clear: clear,
    confirmAndClear: confirmAndClear,
    getCart: getCart,
    hasTicketsOrPackageInCart: hasTicketsOrPackageInCart,
    hasPackageInCart: hasPackageInCart,
    loadOrder: loadOrder,
    getPriceTypes: getPriceTypes,
    hasRenewalPackageInCart: hasRenewalPackageInCart,
    clearRenewalPackagesInCart: clearRenewalPackagesInCart,
    isRenewalOrder: isRenewalOrder,
    getLimits: getLimits,
    checkAllocation: checkAllocation,
    clearOrder: clearOrder,
    processCartData: processCartData
  };

  /**
   * Loads a order into the cart. If the current order is already
   * loaded it does nothing.
   * @param  {Int} orderNo  Order number to try and load
   * @return Promise
   */
  function loadOrder(orderNo, resetIfActive) {
    resetIfActive = resetIfActive !== undefined ? resetIfActive:false;

    return getCart(true)
    .then(function (cart) {

      // We DONT have this  orderNo loaded, so load it.
      if (!cart || !cart.Order || parseFloat(cart.Order.order_no) != parseFloat(orderNo)) {
        return loadExistingOrder(orderNo);
      }

      // We DO currently have this orderNo loaded, does the user want it reset ?
      if (resetIfActive) {
        // Yes, clear the order and reload it
        return clearOrder(orderNo)
        .then(function () {
          return loadOrder(orderNo);
        });
      } else {
        // No, just resolve
        return $q.resolve();
      }

    });
  }

  function clearOrder(orderNo) {
    return TessituraSDK.ClearCart({
      iOrderNumber: orderNo
    });
  }

  function loadExistingOrder(orderNo) {
    var orderData = null;

    return TessituraSDK.LoadExistingOrder({
      OrderID: orderNo
    })
    .then(function () {
      return getCart(true);
    })
    .then(function (orderData) {
      var order = orderData.Order;
      trackRenewalPackageLineItems(orderData.PackageLineItem)

      if ('MOS' in order) {
        return TessituraSDK.ChangeModeOfSaleEx({
          NewModeOfSale: order.MOS
        });
      }

      // Just return the cart!
      return $q.resolve();
    })
    .then(function () {
      return $q.resolve(orderData);
    });
  }

  function trackRenewalPackageLineItems(packageLineItems) {
    var masterPackageLineItemsIds = packageLineItems.reduce(function (acc, packageLineItem) {
      if (packageLineItem.li_seq_no === packageLineItem.pkg_li_no) {
        acc.push(packageLineItem.li_seq_no);
      }
      return acc;
    }, []);

    CacheStorage.set('renewal-package-ids', JSON.stringify(masterPackageLineItemsIds));
  }

  /**
   * Returns the GetCartResult
   *
   * @return Promise
   */
  function getCart(recalculateCartFunds) {
    if (recalculateCartFunds && recalculateCartCache !== null) {
      return recalculateCartCache;
    } else if (getCartCache !== null) {
      return getCartCache;
    }

    var promise;

    // Choose the correct cart method
    // First one is not cached
    // Second one is cached
    if (recalculateCartFunds) {
      promise = TessituraSDK.RecalculateCartFunds();
    } else {
      promise = APIService.call('GetCart');
    }

    promise = promise.then(function (response) {
      var
        result = response.data.result,
        cart = result && result.GetCartResults ? result.GetCartResults : {},
        RecalculateCartFundsResults = response.RecalculateCartFundsResults || {};

      if (RecalculateCartFundsResults.Notices && RecalculateCartFundsResults.Notices.indexOf('gift-card.removed')) {
        Notifications.create({
          message: 'Your gift certificate has been removed. You can re-apply this as you checkout.',
          style: 'info'
        });
      }

      if (recalculateCartFunds) {
        recalculateCartCache = null;
      } else {
        getCartCache = null;
      }

      return $q.resolve(
        processCartData(cart)
      );
    });

    if (recalculateCartFunds) {
      recalculateCartCache = promise;
    } else {
      getCartCache = promise;
    }

    return promise;
  }

  function processCartData(cart) {
    return {
      Order: 'Order' in cart ? cart.Order : {},
      Payment: arrayWrap(cart.Payment),
      LineItem: arrayWrap(cart.LineItem),
      PriceType: arrayWrap(cart.PriceType),
      SubLineItem: arrayWrap(cart.SubLineItem),
      Contribution: arrayWrap(cart.Contribution),
      SubLineItemFee: arrayWrap(cart.SubLineItemFee),
      PackageLineItem: arrayWrap(cart.PackageLineItem),
      PackageSubLineItem: arrayWrap(cart.PackageSubLineItem)
    }
  }

  /**
   * Tells you if the current cart is empty or not
   * @return {Boolean} [description]
   */
  function isCartEmpty(){
    return getCart(true)
      .then(function(cart) {
        return (
          // Are there any standard subline items?
          arrayWrap(cart.SubLineItem).length === 0 &&
          // Are there any contributions?
          arrayWrap(cart.Contribution).length === 0 &&
          // Are there any package subline items?
          arrayWrap(cart.PackageSubLineItem).length === 0 &&
          // Are there any payments (i.e. gift certificates)?
          arrayWrap(cart.Payment).length === 0
        );
      });
  }


  /**
   * Removes all items from the cart
   *
   * @return Promise
   */
  function clear(){
    return AuthenticationService.transferSession({shiftToSubscriber: false});
  }

  /**
   * Check if the user wants to clear the cart
   * then empties the cart.
   * @param  String  message  The message to to show the user,
   *                          should ask if its ok to clear cart
   * @return Promise
   */
  function confirmAndClear(message) {

    message = message || 'You have currently have items in your cart. These will be removed if you continue.';

    return isCartEmpty()
      .then(function (cartEmpty) {

        // Check and return if cart is empty
        if (cartEmpty) {
          return $q.resolve();
        }

        // Check if the user wants to empty the cart
        var approved = confirm(message);
        if (!approved) {
          return $q.reject(false);
        }

        return clear();
      });
  }

  function hasTicketsOrPackageInCart() {
    return getCart()
    .then(function (cartDetails) {
      return cartDetails.LineItem.length > 0 || cartDetails.PackageLineItem.length > 0;
    });
  }

  function hasPackageInCart() {
    return getCart(true)
    .then(function (cartDetails) {
      return cartDetails.PackageLineItem.filter(function (packageLineItem) {
        // Ignore packages for parking
        return PARKING_FACILITIES.indexOf(packageLineItem.facil_no) === -1;
      }).length > 0;
    });
  }

  function clearRenewalPackagesInCart() {
    CacheStorage.remove('renewal-package-ids');
  }

  function isRenewalOrder() {
    return getCart().then(function (cartDetails) {
      return cartDetails.Order.solicitor === 'RollOver';
    });
  }

  function hasRenewalPackageInCart() {
    return getCart(true)
      .then(function (cartDetails) {
        if (cartDetails.PackageLineItem.length === 0) {
          return false;
        }
        var renewalPackageIdsString = CacheStorage.get('renewal-package-ids');
        if (!renewalPackageIdsString) {
          return false;
        }
        var renewalPackageIds = [];
        try {
          renewalPackageIds = JSON.parse(renewalPackageIdsString);
        } catch (e) {}

        return cartDetails.PackageLineItem.filter(function (packageLineItem) {
          return renewalPackageIds.indexOf(packageLineItem.li_seq_no) > -1;
        }).length > 0;
      });
  }

  function getPriceTypes() {
    return getCart(true).then(function (cartDetails) {
      var sublinePriceTypes = cartDetails.SubLineItem.filter(function (sublineItem) {
        return 'ret_parent_sli_no' in sublineItem;
      }).map(function (sublineItem) {
        return parseInt(sublineItem.price_type, 10);
      });

      var packageSublinePriceTypes = cartDetails.PackageSubLineItem.filter(function (packageSublineItem) {
        return 'ret_parent_sli_no' in packageSublineItem;
      })
      .map(function (packageSublineItem) {
        return parseInt(packageSublineItem.price_type, 10);
      });

      return [].concat(sublinePriceTypes, packageSublinePriceTypes);
    });
  }

  function getLimits() {
    var loggedIn = false;
    var allocations = [];
    var performances = [];

    return AuthenticationService.loggedIn().then(function (response) {
      loggedIn = response;

      return TessituraSDK.GetConstituentAllocation();
    })
    .then(function (response) {
      var data = arrayWrap(response.data.result.ExecuteLocalProcedureResults.LocalProcedure);

      if (angular.isArray(data) && data.length) {
        allocations = data;
      }

      // Get rid of bad price types
      allocations = allocations.filter(function (allocation) {
        return appConfig.allocationPriceType.indexOf(parseInt(allocation.price_type, 10)) === -1;
      });

      if (loggedIn) {
        return TessituraSDK.GetConstituentPerformances();
      }

      return $q.resolve(false);
    })
    .then(function (response) {
      if (response) {
        var data = arrayWrap(response.data.result.ExecuteLocalProcedureResults.LocalProcedure);

        if (angular.isArray(data) && data.length) {
          performances = data;
        }
      }

      // Remove returned items or blacklisted price types
      performances = performances.filter(function (performance) {
        return (
          appConfig.returnedSliStatuses.indexOf(parseInt(performance.sli_status, 10)) === -1 &&
          appConfig.allocationPriceType.indexOf(parseInt(performance.price_type, 10)) === -1
        );
      });

      return getCart(true);
    })
    .then(function (cartDetails) {
      var inCartPurchases = cartDetails.SubLineItem.filter(function (sli) {
        if ('sli_status' in sli) {
          return appConfig.returnedSliStatuses.indexOf(parseInt(sli.sli_status, 10)) === -1;
        }

        return appConfig.allocationPriceType.indexOf(parseInt(sli.price_type, 10)) === -1;
      });

      var inCartExchanges = cartDetails.SubLineItem.filter(function (sli) {
        if ('sli_status' in sli) {
          return appConfig.returnedSliStatuses.indexOf(parseInt(sli.sli_status, 10)) !== -1;
        }

        return false;
      });

      // Update with brought information
      allocations = allocations.map(function (allocation) {
        var prodSeasonNo = parseInt(allocation.prod_season_no, 10);
        var priceType = parseInt(allocation.price_type, 10);
        var isGlobal = parseInt(allocation.is_global, 10) === 1;

        // Grab number of items in the order history
        var history = performances.filter(function (performance) {
          if (isGlobal) {
            return parseInt(performance.prod_season_no, 10) === prodSeasonNo;
          }

          return (
            parseInt(performance.price_type, 10) === priceType &&
            parseInt(performance.prod_season_no, 10) === prodSeasonNo
          );
        });

        // Update with brought items
        allocation.brought = history.length;

        // Grab number of items in the current cart
        var current = inCartPurchases.filter(function (sli) {
          if (isGlobal) {
            return parseInt(sli.prod_season_no, 10) === prodSeasonNo;
          }

          return (
            parseInt(sli.prod_season_no, 10) === prodSeasonNo &&
            parseInt(sli.price_type, 10) === priceType
          );
        });

        // Update with current items
        allocation.brought += current.length;

        // Grab number of items in the current cart
        var exchanges = inCartExchanges.filter(function (sli) {
          return parseInt(sli.prod_season_no, 10) === prodSeasonNo;
        });

        // Update with current items
        allocation.brought -= exchanges.length;

        // Set an allowed value
        allocation.allowed = parseInt(allocation.allocation, 10) - allocation.brought;

        return allocation;
      });

      // Strip out constituency allocations if we're not logged in
      if (!loggedIn) {
        allocations = allocations.filter(function (allocation) {
          if ('constituency' in allocation && allocation.constituency) {
            return false;
          }

          return true;
        });
      }

      // Order by ranks & returns
      return orderBy(allocations, function (allocation) {
        return parseInt(allocation.rank, 10);
      });
    });
  }

  function checkAllocation() {
    var cartDetails;

    return getCart().then(function (response) {
      cartDetails = response;

      return getLimits();
    }).then(function (response) {
      var allocations = response;

      var sublinesAndPriceTypes = cartDetails.SubLineItem.map(function (sli) {
        return {
          perf: parseInt(sli.perf_no, 10),
          price: parseInt(sli.price_type, 10)
        };
      });

      var sublineAllocations = allocations.filter(function (allocation) {
        var match = {
          perf: parseInt(allocation.perf_no, 10),
          price: parseInt(allocation.price_type, 10)
        };

        return where(sublinesAndPriceTypes, match).length;
      });

      sublineAllocations = orderBy(sublineAllocations, function (item) {
        return parseInt(item.rank, 10);
      });

      var illegal = sublineAllocations.reduce(function (carry, allocation) {
        var perf = parseInt(allocation.perf_no, 10);

        if (perf in carry) {
          return carry;
        }

        if (parseInt(allocation.allowed, 10) < 0)  {
          carry[perf] = allocation;
        }

        return carry;
      }, {});

      return Object.keys(illegal).map(function (key) {
        return illegal[key];
      });
    })
  }
}]);
