/* global trackEvent */

app.controller('OrderConfirmationController', ['$scope', '$q', 'TessituraSDK', 'GetParameters', '$http', '$sce', 'GoogleTagManager', 'AuthenticationService', 'Notifications', '$filter', 'appConfig', 'CacheStorage', 'TessituraRESTSDK', function ($scope, $q, TessituraSDK, GetParameters, $http, $sce, GoogleTagManager, AuthenticationService, Notifications, $filter, appConfig, CacheStorage, TessituraRESTSDK) {

    GoogleTagManager.sendCheckoutStep(4);

    var arrayWrap = $filter('arrayWrap');

    var unique = $filter('unique');

    // Order we need to load to display
    var orderNo = parseInt(GetParameters['order-no']);

    // Is this an YAP order?
    var isYAPOrder = false;

    // Place to store the email html
    $scope.confirmationEmail = '';

    // Is user registered?
    $scope.isUserRegistered = false;

    // Loading?
    $scope.loading = true;

    var promise = $q.resolve();

    if (CacheStorage.has('cartData')) {
        var cartData = CacheStorage.get('cartData');

        promise = postCheckoutProcessing(cartData).then(function () {
            var deferred = $q.defer();

            TessituraSDK.SendOrderConfirmationEmail({
                iOrderNo: cartData.Order.order_no
            }).then(function () {
                deferred.resolve(true);
            }).catch(function () {
                deferred.resolve(true);
            });

            return deferred.promise;
        }).then(function () {
            return sendDigitalTickets(cartData);
        }).then(function () {
            return resetSession();
        }).then(function () {
            CacheStorage.remove('cartData');
            CacheStorage.remove('emailAddress');
        });
    }

    promise.then(function () {
        return AuthenticationService.isUserRegistered();
    }).then(function (isUserRegistered) {
        $scope.isUserRegistered = isUserRegistered;

        return TessituraSDK.GetCustomOrderDetails({
            order_no: orderNo
        });
    }).then(function (response) {
        var result = response.data.result.ExecuteLocalProcedureResults;

        return {
            Order: result.LocalProcedure,
            LineItem: arrayWrap(result.LocalProcedure1),
            SubLineItem: arrayWrap(result.LocalProcedure2),
            SubLineItemFee: arrayWrap(result.LocalProcedure3),
            Fee: arrayWrap(result.LocalProcedure4),
            Contribution: arrayWrap(result.LocalProcedure5),
            Payment: arrayWrap(result.LocalProcedure6),
            PaymentPlan: arrayWrap(result.LocalProcedure7)
        };
    }).then(function (orderDetails) {
        var totalPrice = 0;

        var cartItems = orderDetails.LineItem.map(function (item) {
            var sublineItems = orderDetails.SubLineItem.filter(function (subItem) {
                return subItem.li_seq_no === item.li_seq_no;
            });

            var lineTotal = sublineItems.reduce(function (carry, item) {
                return carry + parseFloat(item.due_amt);
            }, 0);

            totalPrice += lineTotal;

            return {
                item_id: item.perf_no,
                item_name: item.perf_desc,
                coupon: orderDetails.Order.source_no,
                location_id: item.facil_no,
                price: lineTotal,
                quantity: sublineItems.length,
            };
        });

        var contributions = orderDetails.Contribution.map(function (item) {
            return {
                item_id: item.fund_no,
                item_name: item.campaign_desc,
                price: parseFloat(item.recd_amt),
            }
        });

        contributions.forEach(function (contribution) {
            cartItems.push(contribution)
            totalPrice += contribution.price
        });

        var data = {
            currency: "USD",
            value: totalPrice,
            items: cartItems,
        };

        trackEvent('add_payment_info', data);

        isYAPOrder = (
            (
                'notes' in orderDetails.Order &&
                angular.isString(orderDetails.Order.notes) &&
                orderDetails.Order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
            ) || (
                'custom_6' in orderDetails.Order &&
                angular.isString(orderDetails.Order.custom_6) &&
                orderDetails.Order.custom_6.length
            )
        );

        if ($scope.isUserRegistered) {
            if (isYAPOrder) {
                // Get the html from the server with specific template
                return TessituraSDK.GetOrderConfirmationEmail({
                    iOrderNo: orderNo,
                    iTemplateNo: appConfig.yapOrderConfirmation
                });
            }

            // Get the html from the server with default template
            return TessituraSDK.GetOrderConfirmationEmail({
                iOrderNo: orderNo
            });
        } else {
            return TessituraSDK.GetLastOrderConfirmationEmail();
        }
    }).then(function (response) {
        var emailBodyparts = arrayWrap(response.data.result.Email.BodyParts.EmailBodyPart);

        // Loop over and look for the HTML version
        for (var i = 0; i < emailBodyparts.length; i++) {
            if (emailBodyparts[i].ContentType == 'text/html') {
                $scope.confirmationEmail = angular.element(emailBodyparts[i].Body).find('table')[0].outerHTML;
            }
        }
    }).finally(function () {
        $scope.loading = false;
    });

    GoogleTagManager.sendTransaction(orderNo);
    GoogleTagManager.sendCheckoutEvents(orderNo);

    $scope.sendEmail = function () {
        if ($scope.isUserRegistered) {
            TessituraSDK.SendOrderConfirmationEmail({
                iOrderNo: orderNo
            });
        } else {
            TessituraSDK.GetLastOrderConfirmationEmail();
        }

        Notifications.create({
            message: 'Your confirmation has been emailed to you.'
        });
    };

    function postCheckoutProcessing(cartData) {
        var promises = [];

        if (CacheStorage.has('CovidTerms')) {
            var covidCSI = angular.copy(appConfig.CSISettings.covidTerms);

            covidCSI.PerformanceNumber = 0;

            covidCSI.Notes = angular.toJson({
                PatronID: cartData.Order.customer_no,
                OrderNumber: cartData.Order.order_no
            });

            promises.push(sendCSI(covidCSI));
        }

        if (CacheStorage.has('syosDonation')) {
            var csi = angular.copy(appConfig.CSISettings.donationPrintRecognition);

            csi.PerformanceNumber = 0;

            csi.Notes = angular.toJson({
                PatronID: cartData.Order.customer_no,
                OrderNumber: cartData.Order.order_no
            });

            promises.push(sendCSI(csi));

            CacheStorage.remove('syosDonation');
        }

        // Check all the payment methods and see if theres an gift card
        // Post a CSI with the JSON
        arrayWrap(cartData.Payment).forEach(function (payment) {
            var cacheKey = 'BuyGiftCertificateMeta-' + payment.gc_no;

            if (CacheStorage.has(cacheKey)) {
                var giftCardData = CacheStorage.get(cacheKey);

                promises.push(sendCSI(giftCardData.csi));

                if (giftCardData.emailGiftCertificate) {
                    promises.push(giftCertificatePrintout(giftCardData.emailGiftCertificate));
                }

                CacheStorage.remove(cacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^BuyGiftCertificateMeta\-.*/);

        // Check is we had any CSI data to be submitted for contributions
        arrayWrap(cartData.Contribution).forEach(function (contribution) {
            // key the meta data may be under
            var cacheKey = 'ContributionCheckoutMeta-' + contribution.ref_no;

            // Check if we have the key
            if (CacheStorage.has(cacheKey)) {
                // Get the data from local sotrage
                var checkoutData = CacheStorage.get(cacheKey);

                // It should be an array of objects that are either
                // csi or emailGiftCertficate objects
                if (angular.isArray(checkoutData)) {
                    // For each object, work out what it is
                    // and pass it to the correct handler.
                    checkoutData.forEach(function (data) {

                        switch (data.type) {
                            case 'csi':
                                promises.push(sendCSI(data));
                                break;
                            case 'emailGiftCertificate':
                                promises.push(giftCertificatePrintout(data));
                                break;
                        }

                    });
                }

                // Delete any extra abandoned data
                CacheStorage.remove(cacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^ContributionCheckoutMeta\-.*/);

        // Check all performances to see if there's an ADA request
        // Post a CSI with the JSON
        var subLineItems =  arrayWrap(cartData.SubLineItem);

        // Grab performance IDs of unique subline items
        subLineItems = unique(subLineItems, 'perf_no').map(function (subLineItem) {
            return subLineItem.perf_no;
        });

        // Check if ADA exists for these performances
        subLineItems.forEach(function (perfNo) {
            var cacheKey = 'PerformanceADAMeta-' + perfNo;

            if (CacheStorage.has(cacheKey)) {
                promises.push(sendCSI( CacheStorage.get(cacheKey) ));

                // Remove it from local storage
                CacheStorage.remove(cacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^PerformanceADAMeta\-.*/);

        // Check all packages to see if there's an ADA request
        var packageSubLineItems =  arrayWrap(cartData.PackageSubLineItem);

        // Grab performance IDs of unique subline items
        packageSubLineItems = unique(packageSubLineItems, 'pkg_no').map(function (packageSubLineItem) {
            return packageSubLineItem.pkg_no;
        });

        // Check if ADA exists for these packages
        packageSubLineItems.forEach(function (pkgNo) {
            var
                adaCacheKey = 'PackageADAMeta-' + pkgNo,
                renewCacheKey = 'PackageRenewMeta-' + pkgNo;

            if (CacheStorage.has(adaCacheKey)) {
                promises.push(sendCSI( CacheStorage.get(adaCacheKey) ));

                // Remove it from local storage
                CacheStorage.remove(adaCacheKey);
            }

            if (CacheStorage.has(renewCacheKey)) {
                promises.push(sendCSI( CacheStorage.get(renewCacheKey) ));

                // Remove it from local storage
                CacheStorage.remove(renewCacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^PackageADAMeta\-.*/);
        CacheStorage.removeRegex(/^PackageRenewMeta\-.*/);

        // Check if we have any generic CSI
        if (CacheStorage.has('AdditionalMeta')) {
            var additionalMeta = CacheStorage.get('AdditionalMeta');

            if (angular.isArray(additionalMeta)) {
                additionalMeta.forEach(function (csi) {
                    promises.push(sendCSI(csi));
                });
            }

            CacheStorage.remove('AdditionalMeta');
        }

        return $q.all(promises);
    }

    function sendCSI(data) {
        return TessituraSDK.AddCustomerServiceIssue(data);
    }

    function giftCertificatePrintout(data) {
        return TessituraSDK.GetGiftCertificatePrintout(data);
    }

    function sendDigitalTickets(cartData) {
        var deferred = $q.defer();

        if (parseInt(cartData.Order.shipping_method, 10) !== appConfig.digital_tickets_delivery_method) {
            deferred.resolve(true);
        } else {
            TessituraRESTSDK.post('/Emails/ConstituentInfo/' + cartData.Order.customer_no + '/Send', {
                TemplateId: appConfig.digital_tickets_template_id,
                EmailAddress: CacheStorage.get('emailAddress'),
                EmailProfileId: appConfig.digital_tickets_email_profile_id,
                NameValues: [
                    {
                        Name: 'OrderId',
                        Value: cartData.Order.order_no
                    }
                ]
            }).then(function () {
                deferred.resolve(true);
            }).catch(function () {
                deferred.resolve(true);
            });
        }

        return deferred.promise;
    }

    function resetSession() {
        // Transfer the old session to the new key
        return AuthenticationService.transferSession().then(function () {
            // Shift back into standard mode of sale for customer
            return AuthenticationService.subscriberShift();
        });
    }
}]);
