app.service('AccountCredit', ['$filter', '$q', 'appConfig', 'Cart', 'TessituraSDK', 'Exchange', function ($filter, $q, appConfig, Cart, TessituraSDK, Exchange) {

  var arrayWrap = $filter('arrayWrap');

  return {
    donateAccountCredit:      donateAccountCredit,
    useAccountCredit:         useAccountCredit,
    removeAccountCredit:      removeAccountCredit,
    updateCartFunds:          updateCartFunds,
    isAccountCreditInUse:     isAccountCreditInUse,
    isDonatingAccountCredit:  isDonatingAccountCredit,
    getOnAccountItems:        getOnAccountItems,
    getUsableOnAccountItems:  getUsableOnAccountItems
  };

  function getUsableOnAccountItems() {

    return $q.all([
      TessituraSDK.GetOnAccountInfo(),
      Exchange.getExchangeModes()
    ])
    .then(function (response) {
      var onAccountItems = arrayWrap(response[0].data.result.GetOnAccountResults.OnAccountItem);
      var exchageModes = response[1];

      var allowedGeneralMethods = [];

      if ('generalOnAccountPaymentMethods' in appConfig && angular.isArray(appConfig.generalOnAccountPaymentMethods)) {
        allowedGeneralMethods = appConfig.generalOnAccountPaymentMethods;
      }

      var allowedExchangeMethods = exchageModes.map(function (mode) {
        return parseInt(mode.payment_method, 10);
      });

      onAccountItems = onAccountItems.map(function (onAccountItem) {
        onAccountItem.general_credit = false;
        onAccountItem.exchange_credit = false;

        var paymentMethod = parseInt(onAccountItem.pmt_method, 10);

        if (allowedGeneralMethods.indexOf(paymentMethod) !== -1) {
          onAccountItem.general_credit = true;
        }

        if (allowedExchangeMethods.indexOf(paymentMethod) !== -1) {
          onAccountItem.exchange_credit = true;
        }

        return onAccountItem;
      });

      return onAccountItems.filter(function (onAccountItem) {
        if (!onAccountItem.general_credit && !onAccountItem.exchange_credit) {
          return false;
        }

        return parseFloat(onAccountItem.on_account);
      });
    });
  }

  /**
   * Returns the account credit items for the current user
   * @return Array<OnAccoutnItem>
   */
  function getOnAccountItems() {
    return TessituraSDK.GetOnAccountInfo()
    .then(function (response) {
      return arrayWrap(response.data.result.GetOnAccountResults.OnAccountItem);
    });
  }

  /**
   * Says is the input is numeric
   */
  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }


  /**
   * Get the payment method from input which can be
   * either an OnAccountItem or a payment method id
   */
  function getPaymentMethod(item) {
    if (isNumeric(item)) {
      return item;
    } else {
      return item.pmt_method;
    }
  }

  /**
   * Marks s payment method to be donated in the cart
   * @param  OnAccountItem|int item The payment method that will be marked for donation
   * @return Promise
   */
  function donateAccountCredit(item) {
    return updateCartFunds(getPaymentMethod(item), 'donate');
  }

  /**
   * Marks s payment method to be used to pay for tickets
   * @param  OnAccountItem|int item The payment method that will be used to pay with
   * @return Promise
   */
  function useAccountCredit(item) {
    return updateCartFunds(getPaymentMethod(item), 'tickets');
  }


  /**
   * Marks s payment method to be used in the cart
   * @param  OnAccountItem|int item The payment method that will removed
   * @return Promise
   */
  function removeAccountCredit(item) {
    return updateCartFunds(getPaymentMethod(item), 'none');
  }

  /**
   * Updates the server and tells it to use some account for a certain purpose.
   *
   * @param  int    paymentMethodId Payment method id to update
   * @param  string use             How to use the account credit
   * @return Promise
   */
  function updateCartFunds(paymentMethodId, use) {
    var RecalculateCartFundsParams = {
      useAccountCredit: {}
    };
    RecalculateCartFundsParams.useAccountCredit[paymentMethodId] = use;

    return $q.all([
      getOnAccountItems(),
      Cart.getCart()
    ]).then(function (response) {
      var accountCredit = response[0];
      var cartData = response[1];

      var promise = $q.resolve();

      if (use == 'donate' && isDonatingAccountCredit(accountCredit, cartData) == false) {
        var toRemove = cartData.Contribution.filter(function (contribution) {
          return contribution.fund_no == appConfig.donationFundId;
        });

        var onAccount = accountCredit.map(function (credit) {
          return credit.on_account;
        });

        toRemove = toRemove.filter(function (contribution) {
          return onAccount.indexOf( contribution.contribution_amt ) !== -1;
        });

        if (toRemove.length) {
          promise = $q.all(toRemove.map(removeContribution));
        }
      }

      return promise;
    }).then(function () {
        return TessituraSDK.RecalculateCartFunds(RecalculateCartFundsParams);
    });
  }

  /**
   * Removes a contribution from the cart
   * @param  Object contribution Contribution object
   * @return Promise
   */
  function removeContribution(contribution) {

    var RemoveContributionParams = {
      iLineItemNumber: contribution.ref_no
    };

    return TessituraSDK.RemoveContribuion(RemoveContributionParams);
  }

  /**
   * Takes an OnAccountItem and returns whether its being current
   * used in this cart.
   * @param  OnAccountItem onAccountItem [description]
   * @return {Boolean}               [description]
   */
  function isAccountCreditInUse(onAccountItem) {
    return parseFloat(onAccountItem.used_in_session) < 0;
  }

  /**
   * Is the cart currently donating any account credit.
   * Allows the user to pass in a caches of api calls
   * @return Boolean
   */
  function isDonatingAccountCredit(accountCredit, cartData) {

    return cartData.Contribution && cartData.Contribution.some(function (contribution) {

      for(var i in accountCredit) {
        var credit = accountCredit[i];
        if (contribution.contribution_amt == credit.on_account && isAccountCreditInUse(credit)) {
          return true;
        }
      }

      return false;
    });
  }


}]);
