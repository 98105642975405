/* global moment, Slider */
/**
 * There are a bunch of arrays at the bottom of this file
 * that I'm using to populate the dropdowns while we wait
 * for the sProc
 */
app.controller('PerformanceSearchController', ['$scope', '$attrs', '$sce', '$window', 'TessituraSDK', 'AuthenticationService', '$filter', '$location', '$timeout', 'Notifications', '$q', 'PerformanceSearchService', 'Router', 'CacheStorage', 'MosSwitcher', 'appConfig', 'Cart', function ($scope, $attrs, $sce, $window, TessituraSDK, AuthenticationService, $filter, $location, $timeout, Notifications, $q, PerformanceSearchService, Router, CacheStorage, MosSwitcher, appConfig, Cart) {
  'use strict';

  // initial $scope variables
  $scope.displayResults = false;
  $scope.eventsLoaded = false;
  $scope.showMoreFilters = false;
  $scope.moreFiltersTitle = 'More filters';
  $scope.eventTitle = 'any show';
  $scope.events = [];
  $scope.sortOrders = [];
  $scope.fixFilters = false;
  $scope.shouldShowShowingDropdown = true;

  $scope.getFullUrl = function () {
    return $location.url();
  };

   // initialize the facetFilters model
  var searchFilters = {},
      facetFilters = {},
      baseResults,
      sliderRange = {min:0, max:10000},
      catSortOrder = [];

  var mobileFilterElement;
  var sidebarFilters;
  var sidebarFiltersContainer;

  window.a = searchFilters;
  var extraFacetsSearchObj = {};
  var arrayWrap = $filter('arrayWrap');
  var find = $filter('find');
  var orderBy = $filter('orderBy');

  var toplevelfacets = {
    events : undefined,
    times : undefined,
    prices : undefined
  };

  var initializeSearchFilters = function(){
    searchFilters.tickets = {};
    searchFilters.event = {};
    searchFilters.time = {};
    searchFilters.price = {};
  };

  initializeSearchFilters();

  function resetSearchFiltersEvent(){
    searchFilters.event = PerformanceSearchService.getAnyShowObject();
  }

  var initializeFacets = function(){
    facetFilters.timeSlots = [];
    facetFilters.ageAppropriate = [];
    facetFilters.genres = [];
    facetFilters.themes = [];
    facetFilters.priceRange = {};
    facetFilters.sortOrder = {};
    facetFilters.days = [];

    $scope.saved = {
      facetFilters : facetFilters
    }
  };

  initializeFacets();

  // Stores a map of perf_desc to WebContent Long Titles
  var longTitleMap = {};

  $scope.bestAvailable = directToBestAvailableUrl;
  $scope.bestAvailableURL = getBestAvailableUrl;

  // Using this object to store 2 filtered lists.
  // filteredEventList for the initial filter
  // facetFilteredEventList for the filteredEventList that is filtered by the facets
  $scope.ps = {
    filteredEventList : undefined,
    facetFilteredEventList: undefined,
    currentPage : 1,
    pageNumber : 0,
    resultsLimit : 10,
    itemsPerPage : 10
  }

  // chosen filters are used to maintain the state of the top level
  // ticket search dropdowns
  // tickets; show; time; price
  $scope.chosenFilters = {
    tickets : 0,
    event : undefined,
    time : undefined,
    price : undefined
  };

  var
    filterPriceTypes = {
      general: [1, 106, 129, 275, 276, 277, 168, 169, 95],
      subscriber: [106, 111, 138],
      passport: [6, 106, 111, 128, 138, 152, 162],
      other: false,
      exchange: false
    },
    modeOfSale = null,
    priceTypeMode = null,
    allocations = [];

  $scope.buyOrExchange = 'Buy';

  // Try switching the user to mode of sale for this page
  MosSwitcher.attemptGeneralMos()
  .then(function (response) {
    // Set the mode of sale so we can use later
    modeOfSale = response.mos;

    // Keep track of the sale mode we're in
    priceTypeMode = response.mode;

    // Do we need special price type filtering?
    if (priceTypeMode === 'exchange') {
      filterPriceTypes['exchange'] = response.priceTypes;
      $scope.buyOrExchange = 'Exchange';
    }

    return $q.resolve(true);
  })
  .then(
    function(){
      var categories = $attrs.hasOwnProperty('categories') ? $attrs.categories.split(',') : [],
          categoriesParam = $attrs.hasOwnProperty('categories') ? $attrs.categories : [],
          keywordParams = {
            mos : modeOfSale,
            categories: categoriesParam
          },
          eventParams = {
            sStartDate: moment().startOf('hour').format('YYYY-MM-DDTHH:00:00Z'),
            sEndDate: moment().add(2, 'y').format('YYYY-MM-DDTHH:00:00Z'),
            iModeOfSale: modeOfSale,
          },
          sProcParams = {
            mos : modeOfSale
          },
          promises = [],
          q_promise;

      promises = [
        PerformanceSearchService.getCMSEvents(eventParams),
        PerformanceSearchService.getSortOrders(),
        PerformanceSearchService.getTimes(),
        PerformanceSearchService.getPerformanceAvailabilitySPROC(sProcParams),
        PerformanceSearchService.getPrices(),
        PerformanceSearchService.getPerformanceKeywords(keywordParams),
        AuthenticationService.getLoginInfo(),
        PerformanceSearchService.getDOW(),
        Cart.getLimits()
      ];

      q_promise = $q.all(promises);

      // call the GetPerformancesEx4 service
      // when successful we store the events
      // and kick off the ui


      q_promise.then(function(data){
          allocations = data[8];

          if(data[0] === undefined){
            $scope.events = [];
            $scope.eventsLoaded = true;
            $scope.loadingError = true;

            return false;
          }

          attachWebContentToPerformance(
            arrayWrap(data[0].Performance),
            arrayWrap(data[0].WebContent)
          );

          arrayWrap(data[0].Performance).forEach(function (perf) {
            var original_description = perf.description;
            perf.description = $filter('performanceLongTitle')(perf);
            longTitleMap[original_description] = perf.description;
          });

          var perfs = arrayWrap(data[0].Performance),
              events = {},
              priceTypesFromSproc = arrayWrap(data[3].data.result.ExecuteLocalProcedureResults.LocalProcedure);

              perfs = perfs.filter(function(perf){
                if(parseInt(perf.zmap_no, 10) !== 48){
                  return perf;
                }
              });

          $scope.sortOrders = data[1];

          $scope.facets = {
            times : data[2],
            genres : [], // get from sproc
            ageAppropriate : [], // get from sproc
            themes : [], // get from sproc
            prices : data[4],
            // venues : [],
            events : [],
            dow : []
          };

          toplevelfacets.times = data[2];
          toplevelfacets.prices = data[4];


          var keywords = data[5].data.result.ExecuteLocalProcedureResults.LocalProcedure || [];
          var keywordsByPerfNo = $filter('groupBy')(keywords,'perf_no');

          var userInfo = data[6];

          var extraFacets = {};

          for(var index=0, len=categories.length; index<len; index++){
            catSortOrder.push({
              cat : categories[index],
              sortOrder : index
            });
          }

          $scope.s_categoriesToUse = [];

          for (var i = 0, catLen = categories.length; i < catLen; i++) {
            var category_desc_obj = $filter('where')(keywords, {category: categories[i]})[0];
            if(category_desc_obj){
              var category_desc = category_desc_obj.category_desc;
              extraFacets[categories[i]] = {
                id : categories[i],
                category_desc : category_desc
              };
              $scope.s_categoriesToUse[categories[i]] = category_desc;
            }
          }

          perfs.map(function(perf){
              if (!moment().isAfter(moment(perf.perf_date))) {
                events[perf.perf_no] = {
                  prod_season_no : perf.prod_season_no,
                  facility_desc : perf.facility_desc,
                  prod_type_desc : perf.prod_type_desc,
                  season_no : perf.season_no,
                  perf_description : perf.description,
                  gross_availbility : perf.gross_availbility,
                  time_slot_desc : perf.time_slot_desc,
                  time_slot : perf.time_slot,
                  syos_enabled: perf.syos_enabled,
                  facility_no: perf.facility_no
                };
              }
          });

          // We'll assume these are the price types we want to use
          var priceTypesToUse = priceTypesFromSproc;

          if (priceTypeMode != 'other') { // We're in a mode of sale we know about
            // This is the definitive list of price types to use
            var filteredPriceTypes = filterPriceTypes[priceTypeMode];

            // Grab IDs of price types
            priceTypesToUse = priceTypesFromSproc.filter(function (priceType) {
              return filteredPriceTypes.indexOf(parseInt(priceType.price_type, 10)) !== -1;
            });

            // If in exchnage mode but exchange price types are no longer available
            if (priceTypeMode === 'exchange' && !priceTypesToUse.length) {
              // Find and use the default price type
              priceTypesToUse = priceTypesFromSproc.filter(function (priceType) {
                return 'is_default' in priceType && priceType.is_default === 'Y';
              });
            }
          }

          // add a id_price property so we can group by that property
          angular.forEach(priceTypesToUse, function(priceGroup, key){

            priceTypesToUse[key].id_price = priceGroup.perf_no + '' + parseFloat(priceGroup.price);
            priceTypesToUse[key].facets = {};

            var keywordsGroup = (keywordsByPerfNo[priceGroup.perf_no.toString()]!==undefined) ? keywordsByPerfNo[priceGroup.perf_no.toString()]: [];

            for (var i = keywordsGroup.length - 1; i >= 0; i--) {
              if(extraFacets[keywordsGroup[i].category] !== undefined){
                if(priceTypesToUse[key][keywordsGroup[i].category] === undefined){
                  priceTypesToUse[key].facets[keywordsGroup[i].category] = [];
                }
                priceTypesToUse[key].facets[keywordsGroup[i].category].push(keywordsGroup[i].description);
              }
            };

          });

          // and now group
          var groupedEvents = $filter('groupBy')(priceTypesToUse, 'id_price');

          var id = 0;
          var now = moment();
          // loop through the groups, adding relevant properties and add to events
          angular.forEach(groupedEvents, function(idPriceGroup, key){
            idPriceGroup = groupPrices(idPriceGroup);

            var perf_no = idPriceGroup[0].perf_no,
                price = Number(idPriceGroup[0].price),
                perf = events[perf_no],
                perf_dt = idPriceGroup[0].perf_dt,
                facets = idPriceGroup[0].facets

            if (!perf) {
              return;
            }

            var next = moment(perf_dt);
            var diff = next.diff(now, 'days');

            var obj = {
                  id : id,
                  perf_dt : perf_dt,
                  timestamp : moment(perf_dt, "YYYY-MM-DDTHH:mm:ssZZ").valueOf(),
                  price : price,
                  formattedPrice : $filter('currency')(price, '$'),
                  id_price : key,
                  priceGroup : idPriceGroup,
                  perf_no : perf_no,
                  facility_desc : perf.facility_desc,
                  gross_availbility : perf.gross_availbility,
                  time_slot : perf.time_slot,
                  time_slot_desc : perf.time_slot_desc,
                  perf_description : perf.perf_description,
                  facets : facets,
                  month : $filter('dateUTC')(perf_dt, 'MMMM'),
                  days : next.diff(now, 'days'),
                  formattedDate : $filter('dateUTC')(perf_dt, "h.mma on EEEE, MMMM d yyyy "),
                  day : $filter('dateUTC')(perf_dt, "dddd"),
                  prod_season_no: perf.prod_season_no,
                  price_desc: idPriceGroup.length > 0 ? idPriceGroup[0].price_desc:"",
                  addToCartError: false,
                  addingToCart: false,
                  syos_enabled: perf.syos_enabled,
                  facility_no: perf.facility_no
                };

            $scope.events.push(obj);
            id++;

          });


          var groupedByPerfDescription = $filter('groupBy')($scope.events, 'perf_description');
          var anyshow = [{title:'any show', value:0}];
          var tempTitles = [];
          for(var x in groupedByPerfDescription){
            tempTitles.push({title:x, value: groupedByPerfDescription[x][0].prod_season_no});
          }
          tempTitles = orderBy(tempTitles, 'title');

          var eventTitles = anyshow.concat(tempTitles);

          $scope.facets.dow = data[7].map(function(dow){
            return moment().day(dow).format('dddd');
          });

          toplevelfacets.events = eventTitles;
          $scope.facets.events = eventTitles;
          $scope.eventsLoaded = true;

          // now the data is all available we can run the initial filtering
          init();
        }, function(error){
          console.log('error', error);
        }
      );
    }

  );

function groupPrices(allPrices) {
  // Firsty, array wrap it all
  allPrices = arrayWrap(allPrices);

  // Store zones to use on front end
  var priceZones = [];

  // Keep track of zones we've already added with the same name
  var existingZones = [];

  // Group by description - really heavy, only should be done in JS
  var sections = $filter('groupByFirstWord')(allPrices, 'description');

  // Because group by first word actually groups, we need to flatten this out
  angular.forEach(sections, function (zones, section) {
    // Which means looping throug things a few times *sigh*
    zones.forEach(function (zone) {
      var zoneDesc = zone.description + ' - ' + $filter('currency')(zone.price);

      // Skip any unavailable, or if we already have one of the same name
      if (zone.available == 'N' || existingZones.indexOf(zoneDesc) !== -1) {
        return;
      }

      zone.section = section;
      zone.label = zoneDesc;

      priceZones.push(zone);

      existingZones.push(zoneDesc);
    });
  });

  return priceZones;
}

function filterTimeDropdown(){

  if($scope.chosenFilters.event !== undefined){
    var min = 0;
    var max = 400;
    // var events = getEventsForTopLevelFacets();

    var events = orderBy(getEventsForTopLevelFacets(), 'days');

    if(events){
      min = $filter('first')(events).days;
      max = $filter('last')(events).days;
    }

    var range = {
      min : min,
      max : max
    }

    $scope.facets.times = toplevelfacets.times.map(function(time){
      if ( (range.min  <=  time.diff.max) && (range.max  >=  time.diff.min) ) {
        return time;
      }
    });

    $scope.chosenFilters.time = $scope.facets.times[0];
    searchFilters.time = $scope.facets.times[0];

  }
}

function filterPriceDropdown(event){
  if(parseInt(event, 10) !== 0){

    var min = 0;
    var max = 1000;
    var events = orderBy(getEventsForTopLevelFacets(), 'price');

    if(events){
      min = $filter('first')(events).price;
      max = $filter('last')(events).price;
    }

    var range = {
      min : min,
      max : max
    };

    $scope.facets.prices = toplevelfacets.prices.filter(function(price){
      return ( (range.min  <=  price.val[1] && range.max >= price.val[0]) || price.val.length === 1 );
    });

    PerformanceSearchService.setPrices($scope.facets.prices);

    $scope.chosenFilters.price = $scope.facets.prices[0];
    searchFilters.price = $scope.facets.prices[0];

  } else {
    $scope.facets.prices = PerformanceSearchService.resetPrices();

    $scope.chosenFilters.price = $scope.facets.prices[0];
    searchFilters.price = $scope.facets.prices[0];
  }
}

function getEventsForTopLevelFacets(){
  if($scope.chosenFilters.event.description === 'any show'){
    return $scope.events;
  }else{
    return $scope.events.filter(function(event){
      return event.prod_season_no === $scope.chosenFilters.event.prod_season_no;
    });
  }
}

var updateTopLevelFacets = function(type, event_perf){
  switch(type){
    case 'title' :
      filterTimeDropdown();
      filterPriceDropdown(event_perf);
    break;
  }
}


$scope.getExtraFacets = function(id){
  if($scope.extraFacets){
    return $filter('first')($filter('where')($scope.extraFacets, {id:id}));
  }
}

function init(){
    // If the url is an empty object we have no url params to use
    // so set default chosen filters
    var params = getURLParams();
    if(  !angular.equals({}, params )){

      var startTime,
          endTime,
          searchTitle = (params.eventT !== 'undefined' && !params.eventT && params.eventT !== 0) ? params.eventT: undefined;

      // We get performance descriptions passed in sometimes.
      // We need to upgrade it to longTitle
      if (longTitleMap[params.eventT]) {
        params.eventT = longTitleMap[params.eventT];
      }

      if(params.eventT){
        var found_event = PerformanceSearchService.getEventByProdSeasonNumber(params.eventT);
        if(found_event){
          searchFilters.event = found_event;
          $scope.chosenFilters.event = found_event;
        }
      }else{
        resetSearchFiltersEvent();
        $scope.chosenFilters.event = searchFilters.event;
      }

      if(params.timeT){
        searchFilters.time.title = params.timeT;
        $scope.chosenFilters.time = searchFilters.time.title;
      }

      searchFilters.time.val = {};
      if(params.timeS && params.timeE){
        searchFilters.time.val.start = moment(params.timeS);
        searchFilters.time.val.end = moment(params.timeE);
      }else{
        searchFilters.time = $scope.facets.times[0];
      }

      $scope.chosenFilters.time = searchFilters.time;

      if(params.quantity !== undefined){
        $scope.chosenFilters.tickets = {quantity:params.quantity};
      }else{
        $scope.chosenFilters.tickets = {quantity:2};
      }

      if(params.order !== undefined){
        facetFilters.sortOrder = getSortOrderById(params.order);
      }else{
        facetFilters.sortOrder = $scope.sortOrders[2];
      }

      if(params.priceMin !== undefined && params.priceMax !== undefined){
        if(params.priceMax !== undefined){
          searchFilters.price.value = [Number(params.priceMin), Number(params.priceMax)];
        }
      }

      if(params.priceId !== undefined ){
        searchFilters.price = $filter('where')($scope.facets.prices, {id: params.priceId})[0];
        $scope.chosenFilters.price = searchFilters.price;
      }else{
        searchFilters.price = $scope.facets.prices[0];
        $scope.chosenFilters.price = searchFilters.price;
      }

      $scope.updateResultsBySearchFilters();
      // updateTopLevelFacets('title', $scope.chosenFilters.event);

      if(params.facets !== undefined ){
        facetFilters = params.facets;
      }

      if(params.xfacets !== undefined ){
        extraFacetsSearchObj = params.xfacets;
      }

      $scope.saved = {
        facetFilters: params.facets,
        xfacetFilters: params.xfacets
      }

      $scope.updateResultsByFacetFilters();
    }else{
      // set default search filters
      searchFilters.tickets = {quantity:2};
      resetSearchFiltersEvent();
      searchFilters.time = $scope.facets.times[0];
      searchFilters.price = $scope.facets.prices[0];

      $scope.chosenFilters.tickets = {quantity:2};
      $scope.chosenFilters.event = searchFilters.event;

      $scope.chosenFilters.time = searchFilters.time;
      $scope.chosenFilters.price = searchFilters.price;
    }


    setupMobileFiltersUI();


  };


  function displayExtraFacets(){

    var counter = 0;
    var catsToDisplay = [];
    angular.forEach(catSortOrder, function(category){

      if(parseInt(category.cat, 10) !== 9){ // this is the venue cat id
        catsToDisplay.push(category);
      }

      var res = $scope.getExtraFacets(category.cat);
      if(res && res.facets.length){
        counter++;
      }
    });

    $scope.catSortOrder = catsToDisplay;

    if(counter > 0){
      $scope.displayExtraFacets = true;
    }else{
      $scope.displayExtraFacets = false;
    }
  }

  $scope.mainlineView = function($index, $inview, $inviewpart, $event){

    $scope.dimensions = getViewportDimensions();

    if($scope.dimensions.width > 990){
        if($inviewpart !== 'neither' && $inviewpart !== 'bottom'){
          $scope.fixFilters = false;
        }else{
          $scope.fixFilters = true;
        }
    }
  }

  function getViewportDimensions(){
    var element = document.querySelectorAll(".mm_widget")[0];
    return {
      width : element.clientWidth,
      height : element.clientHeight
    };
  }

  $scope.setExtraFacetFilters = function(searchObject){
    extraFacetsSearchObj[searchObject.catid] = searchObject.vals;
    $scope.updateURL();
    $scope.$apply(function () {
      $scope.updateResultsByFacetFilters();
    }, 100);

  }


  $scope.setFacetFilters = function(searchObject){
    switch(searchObject.type){
      case 'Genres' :
      facetFilters.genres = searchObject.vals;
      break;
      case 'Themes' :
      facetFilters.themes = searchObject.vals;
      break;
      case 'Age Appropriate' :
      facetFilters.ageAppropriate = searchObject.vals;
      break;
      // case 'Venues' :
      // facetFilters.venues = searchObject.vals;
      // break;
      case 'SortOrder' :
      facetFilters.sortOrder = searchObject.vals;
      break;
      case 'Time Slots' :
      facetFilters.timeSlots = searchObject.vals;
      break;
      case 'Day' :
      facetFilters.days = searchObject.vals;
      break;
    }

    $scope.updateURL();

    $scope.$apply(function () {
      $scope.updateResultsByFacetFilters();
    }, 100);

  }

  $scope.setSearchFilters = function(searchObject){
    switch(searchObject.type){
      case 'Tickets' :
        searchFilters.tickets = searchObject.vals;
        $scope.chosenFilters.tickets = {quantity:2};
      break;
      case 'Event' :
        if(searchObject.vals === '0'){
          $scope.chosenFilters.event = PerformanceSearchService.getAnyShowObject();
        }else{
          $scope.chosenFilters.event = PerformanceSearchService.getEventByProdSeasonNumber(searchObject.vals);
        }
        searchFilters.event = $scope.chosenFilters.event;
        // If we are showing any event, allow the user to filter by time
        // If we are showing a specific event, disable time.
        updateTopLevelFacets('title', searchFilters.event.prod_season_no);
      break;
      case 'Time' :
        searchFilters.time = PerformanceSearchService.getTimeByIndex(parseInt(searchObject.vals, 10));
        $scope.chosenFilters.time = searchFilters.time;
      break;
      case 'Price' :
        searchFilters.price = PerformanceSearchService.getPriceByIndex(parseInt(searchObject.vals, 10));
        $scope.chosenFilters.price = searchFilters.price;
      break;
    }
  }


  $scope.updateResultsByFacetFilters = function(){
    var results = $filter('filterByFacets')(baseResults, facetFilters);
    results = $filter('filterByExtraFacets')(results, extraFacetsSearchObj);
    results = orderBy(results, facetFilters.sortOrder.exp.predicate, facetFilters.sortOrder.exp.reverse);
    $scope.currentSortOrderTitle = facetFilters.sortOrder.title;
    $scope.ps.facetFilteredEventList = results;
    $scope.resultsLength = results.length;

    displayExtraFacets();

  }

  $scope.updateResultsBySearchFilters = function(){
    baseResults = $filter('filterBySearch')($scope.events, searchFilters);
    baseResults = $filter('filterByTicketAvailability')(baseResults, $scope.chosenFilters.tickets.quantity);

    // If GET priceType is set, filter out rows without that price type
    if (getURLParams().priceType) {
      var priceType = getURLParams().priceType;
      baseResults = baseResults.filter(function (result) {
        return result.priceZoneSelected.price_type == priceType;
      });
    }

    if(baseResults.length){

      $scope.baseResultsFound = true;

      var timeSlotGroup = $filter('groupBy')(baseResults, 'time_slot_desc'),
          timeSlots = [];

      for (var slot in timeSlotGroup) {
        timeSlots.push(slot);
      };

      $scope.facets.timeSlots = timeSlots;

      $scope.baseResultsFound = true;

    }else{
      $scope.baseResultsFound = false;
    }

    $timeout(function(){
      $scope.$apply(function () {

        $scope.extraFacets = [];
        angular.forEach($scope.s_categoriesToUse, function(val, key){
          $scope.extraFacets.push(
            { id : key, title : val, facets : [] }
          );
        });

        angular.forEach(baseResults,function(event, key){
          angular.forEach(event.facets, function(facet, index){
            if($filter('where')($scope.extraFacets, {id: index})){
              $filter('where')($scope.extraFacets, {id: index})[0].facets.push($filter('flatten')(facet));
            }
          })
        });

        angular.forEach($scope.extraFacets, function(extraFacet, index){
          $scope.extraFacets[index].facets = $filter('unique')($filter('flatten')($scope.extraFacets[index].facets));
        });


        sliderRange.max = undefined;
        sliderRange.min = undefined;

        var floorObj = $filter('min')(baseResults, 'price'),
            ceilObj = $filter('max')(baseResults, 'price'),
            floor,
            ceil;

        if(floorObj){
          floor = Math.floor(
              Number(
                floorObj.price
              )
            )
        }

        if(ceilObj){
          ceil = Math.ceil(
              Number(
                ceilObj.price
              )
            )
        }

        if(floor !== undefined){
          sliderRange.min = (floor/5)*5;
        }

        if(ceil !== undefined ){
          sliderRange.max = (ceil/5)*5;
        }

        // http://stackoverflow.com/questions/18953384/javascript-round-up-to-the-next-multiple-of-5
        // sliderRange.max = Math.ceil(Number($filter('max')(baseResults, 'price').price)/5)*5;

        if(sliderRange.min !== undefined && sliderRange.max !== undefined){
          facetFilters.priceRange = {start:sliderRange.min, end:sliderRange.max};
          defer(buildPriceSlider); // waiting until jQuery is available on window.
        }

        $scope.updateResultsByFacetFilters();
        $scope.displayResults = true;

        setupMobileFiltersUI();

      }, 100);
    });
  }

  // wait until jQuery is available on window
  var defer = function(method) {
    if (window.jQuery){
      method();
    }else{
      setTimeout(function() { defer(method) }, 50);
    }
  }

  $scope.search = function(){
    initializeFacets();
    extraFacetsSearchObj = {};
    setSearchOrder();
    $scope.updateURL();
    $scope.updateResultsBySearchFilters();
  };

  function setSearchOrder (){
    if($scope.chosenFilters.order){
      facetFilters.sortOrder = getSortOrderById($scope.chosenFilters.order);
    }else{
      facetFilters.sortOrder = $scope.sortOrders[2];
    }
  }

  function getSortOrderById(id){
    var obj = $filter('where')($scope.sortOrders, {id: id})[0];
    return $filter('where')($scope.sortOrders, {id: id})[0];
  }

  $scope.updateURL = function(){
    var surl = PerformanceSearchService.updateSearchURL(searchFilters, $scope.chosenFilters, facetFilters, extraFacetsSearchObj);
    $location.search(surl);
  };

  $scope.setSliderRange = function(aRange){
    facetFilters.priceRange.start = aRange[0];
    facetFilters.priceRange.end = aRange[1];
  }

  function getURLParams(){
    var params = PerformanceSearchService.decodeParams($location.search());
    return params;
  }

  $scope.getTimeSlotIcon = function(timeSlot){
    switch (timeSlot) {
      case 'Matinee':
        return 'fa fa-sun-o';
      case "Evening":
        return 'fa fa-moon-o fa-flip-horizontal';
      default:
        return 'fa fa-sun-o';
      }
  }

  $scope.toggleFilters = function(){
    if($scope.showMoreFilters){
      $scope.showMoreFilters = false;
    }else{
      $scope.showMoreFilters = true;
    }
  }

  $scope.increaseQuantity = function(){
    $scope.chosenFilters.tickets.quantity = parseInt($scope.chosenFilters.tickets.quantity, 10) + 1;
  };

  $scope.reduceQuantity = function(){
    if( $scope.chosenFilters.tickets.quantity > 0 ){
      $scope.chosenFilters.tickets.quantity = parseInt($scope.chosenFilters.tickets.quantity, 10) - 1;
    }
  };

  var updateFacetFilterPriceRange = function(values){
      facetFilters.priceRange.start = values[0];
      facetFilters.priceRange.end = values[1];
      $timeout(function(){
        $scope.updateResultsByFacetFilters();
      });
  }

  var updateRange = function(values){
    $timeout(function(){
      $scope.displayedRange = '$'+values[0]+' - $'+values[1];
    });
  }

  function buildPriceSlider(){
      // Instantiate a slider
      var range = facetFilters.priceRange;
      if($scope.priceSlider !== undefined){
        $scope.priceSlider.destroy();
      }

      $scope.priceSlider = new Slider("input.slider", {
        // initial options object
        min : sliderRange.min,
        max : sliderRange.max,
        value : [sliderRange.min,sliderRange.max],
        step : 1,
        tooltip : 'hide'
      })
      .on('slide', function(values){updateRange(values);})
      .on('slideStop' , function(values){updateFacetFilterPriceRange(values)});

      updateRange([sliderRange.min, sliderRange.max])
  };

  $scope.showallTitle = 'Show All';
  $scope.bShowAll = false;

  $scope.showAll = function(){

    if($scope.bShowAll === false){
      $timeout(
        function(){
          $scope.$apply(
            function(){
              $scope.ps.itemsPerPage = -1;
              $scope.ps.resultsLimit = $scope.ps.facetFilteredEventList.length;
              $scope.showallTitle = 'Show Less';
              $scope.bShowAll = true;
            }
          );
        }
      );
    }else{
      $timeout(
        function(){
          $scope.$apply(
            function(){
              $scope.ps.itemsPerPage = 10;
              $scope.ps.resultsLimit = 10;
              $scope.showallTitle = 'Show All';
              $scope.bShowAll = false;
            }
          );
        }
      );

    }
  }


  function directToBestAvailableUrl(perf_no){
    $window.location = Router.getUrl('booking.best_available', { 'perf_no': perf_no });
  }

  function getBestAvailableUrl(){
    return $attrs.bestAvailablePageUrl + '#?perf_no=';
  }

  $scope.setSelectedZone = function(event, zone){
    event.priceZoneSelected = zone;
  }

  $scope.paginate = function(data){
    $scope.ps.pageNumber = ( $scope.ps.currentPage * $scope.ps.resultsLimit ) - $scope.ps.resultsLimit;
  }

  $scope.paginationRange = function(){

    if($scope.ps.facetFilteredEventList){

      var startNumber = ( ($scope.ps.currentPage * $scope.ps.resultsLimit) - $scope.ps.resultsLimit + 1 ),
          endNumber = (startNumber + $scope.ps.resultsLimit - 1);

          if(  $scope.ps.facetFilteredEventList.length < $scope.ps.resultsLimit){
             endNumber =  $scope.ps.facetFilteredEventList.length;
          }

          if(  $scope.ps.currentPage === $scope.ps.numPages){
             endNumber =  $scope.ps.facetFilteredEventList.length;
          }
      return   startNumber + ' - ' + endNumber;
    }
  }

  $scope.addToCart = function(event){
    var priceType = parseInt(event.priceZoneSelected.price_type, 10);

    var allocation = find(allocations, function (item) {
      return (
        parseInt(event.perf_no, 10) === parseInt(item.perf_no, 10) &&
        parseInt(priceType, 10) === parseInt(item.price_type, 10)
      );
    });

    if (!allocation) {
      return doAddToCart(event);
    }

    var quantity = parseInt($scope.chosenFilters.tickets.quantity, 10);
    var allowed = parseInt(allocation.allowed, 10);

    if (allowed === 0) {
      return window.alert("You have reached the maximum allocation of " + allocation.allocation + " tickets for this performance.");
    }

    return doAddToCart(event);
  }

  function doAddToCart(event){
    event.addToCartError = false;
    event.addingToCart = true;

    return TessituraSDK.ReserveTicketsEx({
      iNumberOfSeats: $scope.chosenFilters.tickets.quantity,
      iPerformanceNumber: event.perf_no,
      sPriceType: event.priceZoneSelected.price_type,
      iZone: event.priceZoneSelected.zone_no
    })
    .then(function (response) {
      // Did we reserve exact number of requested tickets
      if (parseFloat(response.data.result[0], 10) == $scope.chosenFilters.tickets.quantity) {
        return TessituraSDK.ResetTicketExpiration().then(function () {
          $window.location = $attrs.afterAddedToCartUrl;
        });
      }

      event.addToCartError = true;
      event.addingToCart = false;
    })
    .catch(function (error) {
      var hasTicketLimit = error.data.error.match(/This request will exceed the ticket limit of (\d)/mi);

      if (angular.isArray(hasTicketLimit) && hasTicketLimit.length === 2) {
        event.addToCartError = 'You may select up to ' + hasTicketLimit[1] + ' tickets for this performance.';
      } else {
        event.addToCartError = true;
      }

      event.addingToCart = false;
    });
  }

  function attachWebContentToPerformance(performances, webContents) {
    angular.forEach(arrayWrap(performances), function (performance) {
      performance.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == performance.perf_no;
      });

      var syosEnabled = $filter('filter')(performance.webContent, function (content) {
        return content.content_type == appConfig.webContentTypes.webSYOS;
      });

      if (syosEnabled.length) {
        performance.syos_enabled = syosEnabled[0].content_value == 'Y';
      } else {
        performance.syos_enabled = false;
      }
    });
  }

  function setupMobileFiltersUI(){
    // using timeout method so we can be sure that the elements
    // are in the DOM.
    $timeout(function(){
        mobileFilterElement = angular.element(document.getElementById('mobileFilters'));
        sidebarFiltersContainer = angular.element(document.getElementById('sidebar-filters-container'));
        sidebarFilters = document.getElementById('sidebar-filters');
    }, 1000);

  }

  $scope.showFilters = function(){
    if(!mobileFilterElement.hasClass('is-active')){
      mobileFilterElement.addClass('is-active').append(sidebarFilters);
    }
  }

  $scope.closeFilters = function(){
    mobileFilterElement.removeClass('is-active');
    sidebarFiltersContainer.append(sidebarFilters);
  }

}]);
