/* global moment */
app.controller('MiniCalendarController', ['$scope', '$filter', 'Calendar', 'MosSwitcher', function ($scope, $filter, Calendar, MosSwitcher) {
  $scope = angular.extend($scope, {
    loading: true,
    previousMonth: previousMonth,
    today: today,
    nextMonth: nextMonth,
    weekDays: Calendar.getWeekDays(),
    weeks: null,
    event: null,
    events: null,
    displayEvents: displayEvents,
    currentMonth: 0,
    disablePreviousMonth: true,
    disableNextMonth: false,
    visibleMonth: moment([
      moment().year(),
      moment().month()
    ]).format(),
    closePopup: closePopup
  });

  Calendar.loadEvents().then(function (responses) {
    $scope.weeks = Calendar.getWeeks();

    $scope.loading = false;
  });

  function displayEvents(date) {
    clearEvents();

    if (date.events.length > 0) {
      $scope.event = date;
      $scope.events = $filter('groupBy')(date.events, 'type');
    }
  };

  function closePopup() {
    clearEvents();
  };

  function clearEvents() {
    $scope.event = null;
    $scope.events = null;
  };

  function previousMonth() {
    $scope.weeks[$scope.currentMonth].forEach(function (day) {
        day.currentMonth = false;
    });

    $scope.currentMonth--;

    var visibleMonth = moment($scope.visibleMonth).subtract(1, 'M');

    $scope.weeks[$scope.currentMonth].forEach(function (day) {
      if (day.day.format('YYYYMM') === visibleMonth.format('YYYYMM')) {
        day.currentMonth = true;
      }
    });

    $scope.visibleMonth = visibleMonth.format();

    clearEvents();
    updateButtons();
  };

  function nextMonth() {
    $scope.weeks[$scope.currentMonth].forEach(function (day) {
      day.currentMonth = false;
    });

    $scope.currentMonth++;

    var visibleMonth = moment($scope.visibleMonth).add(1, 'M');

    $scope.weeks[$scope.currentMonth].forEach(function (day) {
      if (day.day.format('YYYYMM') === visibleMonth.format('YYYYMM')) {
        day.currentMonth = true;
      }
    });

    $scope.visibleMonth = visibleMonth.format();

    clearEvents();
    updateButtons();
  };

  function today() {
    $scope.weeks[$scope.currentMonth].forEach(function (day) {
      day.currentMonth = false;
    });

    $scope.currentMonth = 0;

    var visibleMonth = moment([
      moment().year(),
      moment().month()
    ]);

    $scope.weeks[$scope.currentMonth].forEach(function (day) {
      if (day.day.format('YYYYMM') === visibleMonth.format('YYYYMM')) {
        day.currentMonth = true;
      }
    });

    $scope.visibleMonth = visibleMonth.format();

    clearEvents();
    updateButtons();
  };

  function updateButtons() {
    $scope.disablePreviousMonth = false;
    $scope.disableNextMonth = false;

    if ($scope.currentMonth == 0) {
      $scope.disablePreviousMonth = true;
    }

    if ($scope.currentMonth + 1 == $scope.weeks.length) {
      $scope.disableNextMonth = true;
    }
  };
}]);
