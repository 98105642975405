app.controller(
    'CovidTermsController',
    ['$scope', 'agreedToTerms', 'terms', function (
            $scope,
            agreedToTerms,
            terms
        ) {
            'use strict';
            $scope.termsAccepted = false;
            $scope.terms = terms;

            $scope.agreedToTerms = function () {
                agreedToTerms($scope.termsAccepted);
            }
        }
    ]
);
