/**
 * Filter that expects an OnAccountItem from tessitura to be passed it.
 * It will return the real production name.
 */
app.filter('productionProdSeasonNoFromAccountItem', ['Exchange', '$q', function (Exchange, $q) {
  
  var exchangeModesByPaymentMethod = null;


  function resolveExchangeModes(accountItem){
      var deferred = $q.defer(),
          res = Exchange.getExchangeModes();

      res.then(
        function(exchangeModes){
          exchangeModesByPaymentMethod = {};

          for(var i in exchangeModes) {
            var mode = exchangeModes[i];
            exchangeModesByPaymentMethod[mode.payment_method] = mode;
          }

          var returnVal = '0';
          
          // Check if we have an exchageMode for this payment method
          if (exchangeModesByPaymentMethod[accountItem.pmt_method]) {
            returnVal = exchangeModesByPaymentMethod[accountItem.pmt_method].prod_season_no;
          }

          deferred.resolve(returnVal);

        }
      );

      return deferred.promise;
  }

  function productionProdSeasonNoFromAccountItem(accountItem) {
    return resolveExchangeModes(accountItem).then(
      function(result){
        return result;
      }
    );
  };
  
  productionProdSeasonNoFromAccountItem.$stateful = true;


  return productionProdSeasonNoFromAccountItem;
}]);
