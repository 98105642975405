app.filter('chunk', ['md5', function (md5) {
  var cache = [];

  return function (collection, size) {
    if (!angular.isArray(collection)) {
      return collection;
    }

    var hash = md5.createHash(angular.toJson(arguments));

    if (cache.hasOwnProperty(hash)) {
      return cache[hash];
    }

    var sets = [];

    for (var i = 0, j = 0; i < collection.length / size; i++, j += size) {
      sets[i] = collection.slice(j, j + size);
    }

    cache[hash] = sets;

    return sets;
  };
}]);