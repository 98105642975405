app.filter('oxford', function () {
    return function (array, separator) {
        if (!separator) {
            separator = 'and';
        }

        if (array.length === 0) {
            return '';
        }

        if (array.length === 1) {
            return array[0];
        }

        if (array.length === 2) {
            return array.join(' ' + separator + ' ');
        }

        return array.slice(0, -1).join(', ') + ', ' + separator + ' ' + array.slice(-1);
    };
});
