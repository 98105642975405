/* global moment */
var mm_sameMonth_functions = {
  mm_getMomentUTCDateNoFormat : function(input){
    return moment(
      moment(input, moment.ISO_8601).add(
        moment.parseZone(input).utcOffset(), 'minutes'
      )
    );
  }
};

app.filter('muteByFacets', ['$filter', function($filter){
  return function (input, search, muteClass, highlightClass){

    if (!input) {
      return false;
    }
    if (!search) {
      return false;
    }

    var numOfFilters = 0;

    // are we deailing with a single filter or multiple?
    angular.forEach(search, function(facet, index){
      angular.forEach(facet, function(values, i){
        if(values.value.length){
          numOfFilters++;
        }
      });
    });

    angular.forEach(input, function(eventDay, index){
      angular.forEach(input[index], function(event, i){

        if(numOfFilters === 0){
          event.highlight = false;
          event.mute = false;
        }else{
          event.highlight = false;
          event.mute = true;
        }
      });
    });

    // no filters - stop
    if(numOfFilters === 0){
      return;
    }


    // 1 filter. Just add class to that event
    if(numOfFilters === 1){
      // get the filter category
      angular.forEach(search, function(val, key){
        if(val.length>0){
          var filter = val[0].value[0];
          angular.forEach(input, function(eventDay, index){
            angular.forEach(input[index], function(event, i){
              var facet = event.facets[key];
              if(facet){
                if(facet[0].description === filter){
                  event.highlight = true;
                }
              }
            });
          });
        }
      });
      return;
    }

    if(numOfFilters>1){

      // find the numer of used filters
      var numberOfUsedFilters = 0;
      angular.forEach(search, function(val, key){
        if(val.length>0){
          numberOfUsedFilters = numberOfUsedFilters + val.length;
        }
      });

      angular.forEach(input, function(eventDay, key){
        angular.forEach(eventDay, function(event, index){
          var numberOfFoundFacets = 0;
          var facets = event.facets;
          if(facets){
            angular.forEach(facets, function(facet,i){
              // find the corresponding filter
              var _filter = search[i];
              if(_filter){
                angular.forEach(_filter, function(f, j){
                  var value = f.value[0];
                  if(facet[0].description ===  value){
                    numberOfFoundFacets++;
                  }
                });
              }
            });
          }
          if (numberOfFoundFacets === numberOfUsedFilters){
            event.highlight = true;
          }
        });
      });

      return;
    }

  };
}]);


app.filter('filterByTicketAvailability', ['$filter', function($filter){

  return function(input, search){
    if (!input) {
      return input;
    }
    if (!search) {
      return input;
    }

    var results = [];

    angular.forEach(input, function(perf, key){
      input[key].priceGroupFiltered = input[key].priceGroup.filter(
        function(item){
          return Number(item.avail_count) >= Number(search);
        }
      );

      input[key].singlePriceGroup = false;
      if(input[key].priceGroupFiltered.length === 1){
        input[key].singlePriceGroup = true;
      }

      if(input[key].priceGroupFiltered.length > 0){
        input[key].priceZoneSelected = input[key].priceGroupFiltered[0];
        results.push(input[key])
      }

      input[key].multiplePriceTypes = Object.keys($filter('groupBy')(input[key].priceGroupFiltered, 'price_type')).length > 1;

    });

    return results;
  }

}]);

app.filter('filterBySearch', function(){
  return function(input, search){
    if (!input) {
      return input;
    }
    var results = input;

    results = filterByTitle(results, search.event);
    results = filterByTime(results, search.time);

    if(search.price.val.length > 0){
      var priceObj = {start:search.price.val[0], end:search.price.val[1]};
      results = filterByPriceRange(results, priceObj);
    }
    return results;
  }
});

function filterByTitle(input, search){

  if (!input) {
    return input;
  }
  if (!search || search.prod_season_no === 0) {
    return input;
  }

  var results = [];
  angular.forEach(input, function(event, key){
    if ( event.prod_season_no === search.prod_season_no ){
      results.push(event);
    }
  });
  return results;
}

function filterByTime(input, search){

  if (!input) {
    return input;
  }

  if (!search.val) {
    return input;
  }

  if (!search.val.start && !search.val.end || search.title === 'any time') {
    return input;
  }

  var results = [];
  angular.forEach(input, function(value, key) {
    if (moment(mm_sameMonth_functions.mm_getMomentUTCDateNoFormat(value.perf_dt)).isBetween(
      search.val.start,
      search.val.end,
      'hour')
    ) {
      results.push(value);
    }
  });
  return results;

}


app.filter('filterByFacets', function(){
  return function(input, search){
    if (!input) {
      return input;
    }
    var results = input;
    results = filterByPriceRange(results, search.priceRange);
    results = filterByTimeSlots(results, search.timeSlots);
    results = filterByDays(results, search.days);
    return results;
  }
});

app.filter('filterByExtraFacets', function(){
  return function(input, search){
    if (!input) {
      return input;
    }
    var results = input;
    angular.forEach(search,(function(searchObj, key){
      results = filterByExtraFacets(results, searchObj, key);
    }))

    return results;
  }
});

function filterByExtraFacets(input, searchObj, key){
  if (!input) {
    return input;
  }
  if (!searchObj) {
    return input;
  }
  if(searchObj.length < 1) {
    return input;
  }

  var results = [];
  angular.forEach(input, function(event, index){
    var facet = event.facets[key];
    var found = false;

    if(facet !== undefined && facet.length > 0){
      for (var i = 0; i < facet.length; i++) {
        if( searchObj.indexOf(facet[i]) > -1 ){
          found = true;
        }
      }
    }

    if(found){
      results.push(event);
    }
  });

  return results;
}



function filterByPriceRange(input, search){
  if (!input) {
    return input;
  }
  if (!search) {
    return input;
  }
  if (search.start === undefined || search.end === undefined) {
    return input;
  }

  var results = input.reduce(function(results, event){
      if( event.price >= search.start && event.price <= search.end ){ results.push(event); }
      return results
  }, []);

  return results;
}

function filterByVenue(input, search){
  if (!input ) {
    return input;
  }
  if (search.length === 0) {
    return input;
  }
  if (!search) {
    return input;
  }

  var results = input.reduce(function(results, event){
      if( search.indexOf(event.facility_desc) > -1 ){ results.push(event); }
      return results
  }, []);

  return results;

}

function filterByDays(input, search){
  if (!input ) {
    return input;
  }
  if (search.length === 0) {
    return input;
  }
  if (!search) {
    return input;
  }
  var results = [];
  

  angular.forEach(input, function(event, key){
    var day = moment(mm_sameMonth_functions.mm_getMomentUTCDateNoFormat(event.perf_dt)).format('dddd');
    if ( search.indexOf(day) > -1) {
      results.push(event);
    }
  });

  return results;
}

function filterByTimeSlots(input, search){
  if (!input ) {
    return input;
  }
  if (search.length === 0) {
    return input;
  }
  if (!search) {
    return input;
  }

  var results = [];
  angular.forEach(input, function(event, key){
    if (search.indexOf(event.time_slot_desc) > -1) {
      results.push(event);
    }
  });

  return results;
}
