/* global moment */
app.controller('OrderAndTicketDetailsController', ['$scope', '$attrs', '$q', '$filter', '$modal', '$sce', 'appConfig', 'TessituraSDK', '$window', 'CacheStorage', 'Donation', 'Notifications', 'GetParameters', 'AuthenticationService', 'Router', 'Cart', 'Exchange', 'deviceDetector', 'MosSwitcher', function ($scope, $attrs, $q, $filter, $modal, $sce, appConfig, TessituraSDK, $window, CacheStorage, Donation, Notifications, GetParameters, AuthenticationService, Router, Cart, Exchange, deviceDetector, MosSwitcher) {
  var
    arrayWrap = $filter('arrayWrap'),
    isPositive = $filter('isPositive'),
    filter = $filter('filter'),
    groupBy = $filter('groupBy'),
    orderNo = GetParameters['order_no'],
    subLineItemsForPriceTypesCache = {},
    getPriceTypesForLineItemCache = {},
    seatingDetailsCache = {},
    orderDetails,
    exchangeMOS = [];

  $scope.loading = true;
  $scope.userIsGuest = false;
  $scope.errorLoading = false;
  $scope.orderNo = orderNo;
  $scope.today = moment().format('YYYY-MM-DD HH:mm:ss');

  $scope.iCalCompatible = deviceDetector.browser != 'safari';

  $scope.canExchange = false;

  AuthenticationService.requireLogin()
  .then(function () {
    return AuthenticationService.isUserRegistered();
  })
  .then(function (isUserRegistered) {
    if (isUserRegistered) {
      return MosSwitcher.defaultMos(true);
    } else {
      $scope.userIsGuest = true;
      $scope.loading = false;
      return $q.reject();
    }
  })
  .then(function () {
    return MosSwitcher.getSaleMode();
  })
  .then(function (saleMode) {
    $scope.canExchange = saleMode.canExchange;

    Exchange.getExchangeModes().then(
      function(ExchangeModesResult) {

        exchangeMOS = ExchangeModesResult.reduce(function(acc, mosObj) {
          acc.push(mosObj.mode_of_sale);
          return acc;
        },[]);
        
        loadOrder(orderNo);
      }
    );

  });

  /**
   * [reloadData description]
   * @return {[type]} [description]
   */
  function loadOrder(orderNo) {
    subLineItemsForPriceTypesCache = {};
    getPriceTypesForLineItemCache = {};
    seatingDetailsCache = {};

    TessituraSDK.GetCustomOrderDetails({
      order_no: parseInt(orderNo)
    })
    .then(function (response) {
      var promises = [];

      var result = response.data.result.ExecuteLocalProcedureResults;

      orderDetails = {
        Order: result.LocalProcedure,
        LineItem: result.LocalProcedure1,
        SubLineItem: result.LocalProcedure2,
        SubLineItemFee: result.LocalProcedure3,
        Fee: result.LocalProcedure4,
        Contribution: result.LocalProcedure5,
        Payment: result.LocalProcedure6,
        PaymentPlan: result.LocalProcedure7
      };

      orderDetails.exchange = (exchangeMOS.indexOf(orderDetails.Order.MOS) !== -1) ? true : false;

      $scope.seatedOrder = (orderDetails.Order.seated === 'No') ? false : true;

      orderDetails.LineItem = arrayWrap(orderDetails.LineItem);
      orderDetails.SubLineItem = arrayWrap(orderDetails.SubLineItem);
      orderDetails.SubLineItemFee = arrayWrap(orderDetails.SubLineItemFee);
      orderDetails.Fee = arrayWrap(orderDetails.Fee);
      orderDetails.Payment = arrayWrap(orderDetails.Payment);
      orderDetails.Contribution = arrayWrap(orderDetails.Contribution);

      $scope.orderDetails = orderDetails;

      return canBePrinted();
    })

    // Once we have the order details, get the performanceDetaisl for those orders
    .then(function () {
      // Get the performance ids we need to fetch
      var performanceIdsToGet = [];
      orderDetails.LineItem.forEach(function (lineItem) {
        performanceIdsToGet.push(lineItem.perf_no);
      });

      // Make sure theres no doubles
      performanceIdsToGet = $filter('unique')(performanceIdsToGet);

      // Now get the performances from the server
      return TessituraSDK.GetPerformancesEx4({
        iModeOfSale: $scope.orderDetails.Order.MOS,
        sPerformanceIds: performanceIdsToGet.join(',')
      })
      // Map 
      .then(function (response) {

        // Attach the WebContent objects to the performance objects
        attachWebContentToPerformance(
          orderDetails.LineItem,
          response.data.result.GetPerformancesEx4Result.WebContent
        );

        // Get all of package longe title name 

        var lineItems = $scope.getParentPackageLineItems();
        
        // For each line item, get the web content
        // Then try to override the line items pkg_desc with the long title
        var packageWebContentPromises = lineItems.map(function (lineItem) {
          return TessituraSDK.GetWebContent({
            iPackageNumber: lineItem.pkg_no
          })
          .then(function (response) {

            var webContents = arrayWrap(response.data.result.GetWebContentResults.WebContent);
            
            webContents.forEach(function (webContent) {
              if (webContent.content_type == appConfig.webContentTypes.longTitle) {
                lineItem.pkg_desc = webContent.content_value;
              }
            });
          });
        });

        // Ignore errors because the real name isnt that important
        return $q.all(packageWebContentPromises).catch(function(){});
      });
    })
    .catch(function () {
      $scope.errorLoading = true;
    })
    .finally(function () {
      // We are done loading
      $scope.loading = false;
    });
  }

  function canBePrinted() {
    var
      flexPackagesToLoad = [],
      fixedPackagesToLoad = [],
      performancesToLoad = [];

    $scope.orderDetails.LineItem.forEach(function (lineItem) {
      if (lineItem.pkg_no != 0 && lineItem.pkg_code.toLowerCase().indexOf('dyo') !== -1) {
        flexPackagesToLoad.push(lineItem.pkg_no);
      } else if (lineItem.pkg_no != 0) {
        fixedPackagesToLoad.push(lineItem.pkg_no);
      } else {
        performancesToLoad.push(lineItem.perf_no);
      }
    });

    flexPackagesToLoad = $filter('unique')(flexPackagesToLoad).map(function (packageNo) {
      return TessituraSDK.GetNFSPackageDetailEx({
        PackageID: packageNo,
        ModeOfSale: $scope.orderDetails.Order.MOS
      });
    });

    fixedPackagesToLoad = $filter('unique')(fixedPackagesToLoad).map(function (packageNo) {
      return TessituraSDK.GetPackageDetailWithDiscountingEx({
        PackageID: packageNo,
        ModeOfSale: $scope.orderDetails.Order.MOS
      });
    });

    performancesToLoad = $filter('unique')(performancesToLoad).map(function (perfNo) {
      return TessituraSDK.GetPerformanceDetailWithDiscountingEx({
        iPerf_no: perfNo,
        iModeOfSale: $scope.orderDetails.Order.MOS
      });
    });

    return $q.all(flexPackagesToLoad)
      .then(function (responses) {
        responses.forEach(function (response) {
          var
            packageDetails = response.data.result.GetNFSPackageDetailExResult.Package,
            packageNo = packageDetails.pkg_no;

          $scope.orderDetails.LineItem.forEach(function (package, i) {
            if (package.pkg_no != packageNo) {
              return;
            }

            $scope.orderDetails.LineItem[i].print_ind = packageDetails.print_ind.toLowerCase() === 'y';
          });
        });

        return $q.all(fixedPackagesToLoad);
      })
      .then(function (responses) {
        responses.forEach(function (response) {
          var
            packageDetails = response.data.result.GetPackageDetailWithDiscountingExResult.Package,
            packageNo = packageDetails.pkg_no;

          $scope.orderDetails.LineItem.forEach(function (package, i) {
            if (package.pkg_no != packageNo) {
              return;
            }

            $scope.orderDetails.LineItem[i].print_ind = packageDetails.print_ind.toLowerCase() === 'y';
          });
        });

        return $q.all(performancesToLoad);
      })
      .then(function (responses) {
        responses.forEach(function (response) {
          var
            performanceDetails = response.data.result.GetPerformanceDetailWithDiscountingExResult.Performance,
            performanceNo = performanceDetails.inv_no;

          $scope.orderDetails.LineItem.forEach(function (performance, i) {
            if (performance.perf_no != performanceNo) {
              return;
            }

            $scope.orderDetails.LineItem[i].print_ind = performanceDetails.print_ind.toLowerCase() === 'y';
          });
        });

        return $q.resolve(true);
      });
  }

  /**
   * Returns an array of all Packages
   */
  $scope.getParentPackageLineItems = function () {
    var
      packages = $scope.orderDetails.LineItem.filter(function (lineItem) {
        return lineItem.pkg_no != 0;
      }),

      groupedPackages = groupBy(packages, 'pkg_li_no'),

      packageLineNumbers = Object.keys(groupedPackages),

      uniquePackages = [];

      for (var k in packageLineNumbers) {
        uniquePackages.push( groupedPackages[packageLineNumbers[k]][0] );
      }

    return uniquePackages;
  };

  /**
   * Opens the modal to allow the user
   * to return tickets.
   */
  $scope.donateTickets = function (subLineItems) {

    var modalInstance = $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'sublineitem-selector.html'),
      controller: 'SubLineItemSelectorModalController',
      size: 'md',
      resolve: {
        title: function () {
          return 'Donate';
        },
        subLineItems: function () {
          return subLineItems;
        },
        action: function () {
          return donateSubLineItems;
        }
      },
    });
  };

  /**
   * Donates subline items and then reloads the order.
   */
  function donateSubLineItems(subLineItems) {

    var tickets  = [];

    for (var i in subLineItems) {
      tickets.push(subLineItems[i].sli_no);
    }

    return TessituraSDK.DonateTickets({
      field: 'sSubLineItems',
      tickets: tickets.join(',')
    })
    .finally(function () {
        return loadOrder(orderNo);
    });
  }


  /**
   * Opens the modal to allow the user
   * to return tickets.
   */
  $scope.exchangeTickets = function (lineItem, subLineItems) {

    var modalInstance = $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'sublineitem-selector.html'),
      controller: 'SubLineItemSelectorModalController',
      size: 'md',
      resolve: {
        title: function () {
          return 'Exchange';
        },
        subLineItems: function () {
          return subLineItems;
        },
        action: function () {
          return function (subLineItems) {
            return Exchange.exchangeSubLineItems(orderNo, lineItem, subLineItems);
          };
        }
      },
    });
  };



  /**
   * Returns an array of all Packages
   */
  $scope.getPackageLineItemsForParentPackage = function (parentPackageLineItem) {
    return $scope.orderDetails.LineItem.filter(function (lineItem) {
      return lineItem.pkg_li_no === parentPackageLineItem.pkg_li_no;
    });
  };

  /**
   * Returns an array of all Packages
   */
  $scope.getPackageSubLineItemsForPackage = function (package) {
    return $scope.orderDetails.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no === package.li_seq_no;
    });
  };

  /**
   * Takes in a parent package line item and returns the
   * number of subscriptions that each sub package has
   */
  $scope.numberOfSubscriptionsInParentPackage = function (parentPackageLineItem) {
    var packageLineItems = $scope.getPackageLineItemsForParentPackage(parentPackageLineItem);
    var firstPackageLineItem = packageLineItems[0];
    var packageSubLineItems = $scope.getPackageSubLineItemsForPackage(firstPackageLineItem);
    return packageSubLineItems.length;
  };

  /**
   * Takes in a parent package line item and returns the total price
   * by summing up all the sub packages
   */
  $scope.totalPriceOfParentPackage = function (parentPackageLineItem) {
    var packageLineItems = $scope.getPackageLineItemsForParentPackage(parentPackageLineItem);
    var subLineItems = [];
    packageLineItems.forEach(function (packageLineItem) {
      subLineItems = subLineItems.concat($scope.getPackageSubLineItemsForPackage(packageLineItem));
    });
    return $filter('sumOfValue')(subLineItems, 'due_amt');
  };

  /**
   * Returns an array of all the LineItems from the cart
   */
  $scope.getLineItems = function () {
    return $scope.orderDetails.LineItem.filter(function (lineItem) {
      return lineItem.pkg_no == 0;
    });
  };

  /**
   * Returns all the subline items that are for a lineitem
   */
  $scope.getSubLineItemsForLineItem  = function (lineItem) {
    return $scope.orderDetails.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no;
    });
  };

  /**
   * Returns all the PriceType objects for LineItem
   * that the user has brought tickets for
   */
  $scope.getPriceTypesForLineItem = function (lineItem) {
    if (getPriceTypesForLineItemCache.hasOwnProperty(lineItem.$$hashKey)) {
      return getPriceTypesForLineItemCache[lineItem.$$hashKey];
    }

    var priceTypeIdsFound = [];
    var priceTypes = [];

    for (var i = 0; i < $scope.orderDetails.SubLineItem.length; i++) {
      var subLineItem = $scope.orderDetails.SubLineItem[i];

      if (subLineItem.li_seq_no != lineItem.li_seq_no) {
        continue;
      }

      if (priceTypeIdsFound.indexOf(subLineItem.price_type) > -1) {
        continue;
      }

      priceTypeIdsFound.push(subLineItem.price_type);
      priceTypes.push({
        description: subLineItem.price_type_desc,
        price_type: subLineItem.price_type,
      });
    }

    getPriceTypesForLineItemCache[lineItem.$$hashKey] = priceTypes;

    return getPriceTypesForLineItemCache[lineItem.$$hashKey];
  };


  /**
   * Returns the sublineitems that are for a lineitem
   * and a pricetype
   */
  $scope.getSubLineItemsForPriceTypes = function (lineItem, priceType) {
    if (subLineItemsForPriceTypesCache.hasOwnProperty(lineItem.$$hashKey + priceType.$$hashKey)) {
      return subLineItemsForPriceTypesCache[lineItem.$$hashKey + priceType.$$hashKey];
    }

    subLineItemsForPriceTypesCache[lineItem.$$hashKey + priceType.$$hashKey] = $scope.orderDetails.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no === lineItem.li_seq_no && subLineItem.price_type === priceType.price_type;
    });

    return subLineItemsForPriceTypesCache[lineItem.$$hashKey + priceType.$$hashKey];
  };

  $scope.showPassbook = function (lineItem) {
    var subLineItems = $scope.getSubLineItemsForLineItem(lineItem);

    var modalInstance = $modal.open({
      animation: true,
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'passbook.html'),
      size: 'md',
      controller: 'PassbookController',
      resolve: {
        lineItem: function () {
          return lineItem;
        },
        subLineItems: function () {
          return subLineItems;
        }
      }
    });
  };

  $scope.sendMMS = function (subLineItems) {
    var sli_nos = subLineItems.map(function (subLineItem) {
      return subLineItem.sli_no;
    })
    .join(',');
    var sendMMSParams = {
      order_no: orderNo,
      sub_line_items: sli_nos,
      phone_no: ""
    };

    var modalInstance = $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'phonenumber-selector.html'),
      controller: 'PhoneNumberModalController',
      size: 'md'
    });

    modalInstance.result
      .then(function (phoneNumber) {
        sendMMSParams.phone_no = phoneNumber;

        Notifications.create({
          message: 'A text has been send to ' + phoneNumber
        });

        return TessituraSDK.SendMMS(sendMMSParams);
      });
  };

  $scope.sendEmail = function () {
    TessituraSDK.RequestReprint({
      iOrderNumber: orderNo
    })
    .then(function (response) {
      Notifications.create({
        message: 'Your tickets have been emailed to you.'
      });
    })
    .catch(function (errors) {
      alert('We could not send your tickets at this time. Please try again later.')
    });
  };

  $scope.getSeatingDetails = function (lineItem) {
    if (seatingDetailsCache.hasOwnProperty(lineItem.$$hashKey)) {
      return seatingDetailsCache[lineItem.$$hashKey];
    }

    var sublineItems = $scope.orderDetails.SubLineItem.filter(function (sublineItem) {
      return sublineItem.li_seq_no === lineItem.li_seq_no;
    }).map(function (sublineItem) {
      return {
        seat_num: sublineItem.seat_num,
        seat_row: sublineItem.seat_row,
        section_add_text: sublineItem.section_add_text,
        section_add_text2: sublineItem.section_add_text2,
        section_desc: sublineItem.section_desc,
        section_print_desc: sublineItem.section_print_desc,
        section_short_desc: sublineItem.section_short_desc,
        zone_desc: sublineItem.zone_desc,
        zone_no: sublineItem.zone_no
      }
    });

    var managed = [];

    // Group by section
    angular.forEach(groupBy(sublineItems, 'section_desc'), function (zones, section_desc) {
      section_desc = section_desc === 'undefined' ? '' : section_desc;
      var details = {
        section: section_desc
      };

      // Group by zone
      angular.forEach(groupBy(zones, 'zone_desc'), function (seats, zone_desc) {
        details.zone = zone_desc === 'undefined' ? '' : zone_desc;

        // Group by row
        angular.forEach(groupBy(seats, 'seat_row'), function (seat, seat_row) {
          seat_row = seat_row === 'undefined' ? '' : seat_row;

          details.row = seat_row;

          // Just grab the seat numbers
          details.seats = seat.map(function (s) {
            return s.seat_num;
          });

          // Also store strings
          details.seatsString = details.seats.join(', ');
        });
      });

      managed.push(details);
    });

    seatingDetailsCache[lineItem.$$hashKey] = managed;
    return managed;
  };

  $scope.lineItemItemsCanBeReturned = function (lineItem) {
    // Check theres some sublimeitems that can be returned
    var sublineItems = $scope.getSubLineItemsForLineItem(lineItem);
    sublineItems = arrayWrap(sublineItems);
    sublineItems = $filter('subLineItemReturned')(sublineItems, true);
    if (sublineItems.length == 0 ) {
      return;
    }

    // Check the production hasnt already been shown
    var prefDate = moment(lineItem.perf_dt);
    return prefDate.diff() > 0;
  };

  $scope.isParkingVoucher = function (lineItem) {
    return lineItem.zmap_no == 48;
  };

  function attachWebContentToPerformance(performances, webContents) {
    angular.forEach(arrayWrap(performances), function (performance) {
      performance.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == performance.perf_no;
      });

      performance.attachedMedia = performance.webContent.filter(function (webContent) {
        return webContent.content_type == 59;
      });

      var foundTba = performance.webContent.filter(function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.dateTba;
      });

      if (foundTba.length) {
        performance.dateTba = foundTba[0].content_value;
      }
    });
  }

  $scope.getBasenameFromURL = function (url) {
    return url.replace(/\\/g,'/').replace(/.*\//, '');
  }

  /**
   * Makes an ics file and downloads it from the lineItem
   * @param  {Object} lineItem  LineItem
   */
  $scope.downloadICal = function (lineItem) {
    
    // Map the date
    var subject = $filter('performanceLongTitle')(lineItem);
    var description = "";

    // Cut of the timezone
    var start = moment(lineItem.perf_dt.substr(0, lineItem.perf_dt.lastIndexOf('-')));
    var end = start.clone().add(2, 'hour');
    var location = lineItem.facility_desc;

    // Make a new event file
    var cal = window.ics();

    // Add the event
    cal.addEvent(subject, description, location, start, end);

    // Set the file name to the name of the event
    cal.download(subject);
  }

  /**
   * This function takes the user back to the orders and tickets page.
   * By using history.back(); we can restore the previous state which will
   * keep what page they were one
   */
  $scope.goBackToOrders = function () {
    if (history && document.referrer == Router.getUrl('orders')) {
      history.back();
    } else {
      $window.location = Router.getUrl('orders');
    }
  };

  $scope.sanitisePaymentDescription = function (title) {
    return title.replace('Web ', '').replace('zEMV ', '');
  }

}]);

app.controller('PhoneNumberModalController', ['$modalInstance', '$scope', 'TessituraSDK', function ($modalInstance, $scope, TessituraSDK) {

  $scope.loading = true;

  $scope.number = "";

  TessituraSDK.GetAccountInfo()
    .then(function (response) {
      $scope.number = response.data.result.GetAccountInfoResults.AccountInformation.phone;
      $scope.loading = false;
    });


  // Button actions
  $scope.close = $modalInstance.dismiss;
  $scope.confirm = function () {
    $modalInstance.close($scope.number);
  };
}]);

/**
 * Passbook modal controller for handling processes related to Passbook.
 */
app.controller('PassbookController', ['$scope', '$filter', '$modalInstance', '$cookies', 'lineItem', 'subLineItems', 'TessituraSDK', 'proxyEndpoint', 'proxyClient', function ($scope, $filter, $modalInstance, $cookies, lineItem, subLineItems, TessituraSDK, proxyEndpoint, proxyClient) {
  $scope.lineItem = lineItem;
  $scope.subLineItems = subLineItems;

  $scope.getPassbook = function () {
    var passbookRequest = {
      iOrderNo: $scope.lineItem.order_no,
      lineItems: $scope.lineItem.li_seq_no,
      performanceNo: $scope.lineItem.perf_no,
      subLineItems: ''
    };

    TessituraSDK.PrintTicketsWithPassbook(passbookRequest).then(function (passbookResult) {
      $scope.passes = $filter('arrayWrap')(passbookResult.data.result);
    });
  };

  $scope.getPassbookLink = function (lineItem, ticket) {
    var jSDKKey = $cookies.get('sessionkey'),
    key = jSDKKey.replace(/"/g, ''),
    passbookRequest = {
      sSessionKey: key,
      ticketNo: ticket.ticket_no,
      lineItems: lineItem.li_seq_no,
      performanceNo: lineItem.perf_no
    },
    param_string = window.btoa(JSON.stringify(passbookRequest));
    param_string = encodeURIComponent(param_string);
    return proxyEndpoint + '?method=GetPassBookTicket&params=' + param_string + '&id=' + proxyClient;
  };

  $scope.getSection = function (ticket) {
    return $filter('filter')($scope.subLineItems, { sli_no: ticket.sli_no })[0].section_desc;
  };

  $scope.getRow = function (ticket) {
    return $filter('filter')($scope.subLineItems, { sli_no: ticket.sli_no })[0].seat_row;
  };

  $scope.getSeatNo = function (ticket) {
    return $filter('filter')($scope.subLineItems, { sli_no: ticket.sli_no })[0].seat_num;
  }

  $scope.close = $modalInstance.dismiss;

  $scope.getPassbook();
}]);
