/* global moment */
app.service('OrderService', ['$q', '$filter', 'AuthenticationService', 'APIService', 'appConfig', function ($q, $filter, AuthenticationService, APIService, appConfig) {
  // Tools
  var
    arrayWrap = $filter('arrayWrap');

  return {
    GetCustomOrdersEx: GetCustomOrdersEx,
    GetCustomOrderDetails: GetCustomOrderDetails,
    PrintTicketsWithPassbook: PrintTicketsWithPassbook,
    RequestReprint: RequestReprint,
    DonateTickets: DonateTickets
  };

  function GetCustomOrdersEx(params) {
    return APIService.call('GetCustomOrdersEx', params).then(function (response) {
      var result = response.data.result.ExecuteLocalProcedureResults;

      return $q.resolve(arrayWrap(result.LocalProcedure));
    });
  };

  function GetCustomOrderDetails(params) {
    return APIService.call('GetCustomOrderDetails', params).then(function (response) {
      var result = response.data.result.ExecuteLocalProcedureResults;

      return $q.resolve({
        Order: result.LocalProcedure,
        LineItem: arrayWrap(result.LocalProcedure1),
        SubLineItem: arrayWrap(result.LocalProcedure2),
        SubLineItemFee: arrayWrap(result.LocalProcedure3),
        Fee: arrayWrap(result.LocalProcedure4),
        Contribution: arrayWrap(result.LocalProcedure5),
        Payment: arrayWrap(result.LocalProcedure6),
        PaymentPlan: arrayWrap(result.LocalProcedure7)
      });
    });
  };

  function PrintTicketsWithPassbook(params) {
    return APIService.call('PrintTicketsWithPassbook', params).then(function (response) {
      if (response.data.result) {
        return $q.resolve(arrayWrap(response.data.result));
      }
      return $q.reject({data: {error: 'No passes returned'}});
    }).catch(function (response) {
      return $q.reject(response.data.error);
    });
  };

  function RequestReprint(orderNo) {
    return APIService.call('RequestReprint', {
      iOrderNumber: orderNo
    });
  };

  function DonateTickets(params) {
    return APIService.post('DonateTickets', params);
  };
}]);
