app.directive("youtubeVideo", function() {
  return {
    template: '<iframe type="text/html" width="100%" src="{{embedSrc}}" frameborder="0" ng-show="height" ng-style="{height: height}"></iframe>',

    scope: {
      src: '@',
    },
    controller: ['$scope', '$window', '$element', '$http', function ($scope, $window, $element, $http) {

      var iframe = angular.element($element)[0].children[0];
      var w = angular.element($window);

      var videoId = $scope.src.match(/v=([^?&]+)/)[1];
      $scope.embedSrc = "https://www.youtube.com/embed/" + videoId + "?autoplay=1";

      $http({
        url: 'https://noembed.com/embed',
        method: 'get',
        params: {
          url: $scope.src
        }
      })
      .then(function (result) {
        $scope.ratio = result.data.height / result.data.width;
        $scope.height = (iframe.clientWidth * $scope.ratio) + 'px';
      });

      $scope.$watch(function () {
        return {
          'h': iframe.clientHeight,
          'w': iframe.clientWidth
        };
      }, function () {
        $scope.height = (iframe.clientWidth * $scope.ratio) + 'px';
      }, true);

      w.bind('resize', function () {
          $scope.$apply();
      });

    }],
    link: function(scope, element, attrs) {
      // Title element
      //var title = angular.element(element.children()[0]);
      // ...
    }
  };
});
