/* global moment */
app.controller('MyAccountController', ['$scope', '$window', '$filter', '$q', '$attrs', 'TessituraSDK', 'AuthenticationService', 'Router', 'Notifications', 'Donation', 'AccountCredit', 'Cart', 'MosSwitcher', 'appConfig', 'TicketManagementService', function ($scope, $window, $filter, $q, $attrs, TessituraSDK, AuthenticationService, Router, Notifications, Donation, AccountCredit, Cart, MosSwitcher, appConfig, TicketManagementService) {
  var
    arrayWrap = $filter('arrayWrap'),
    filter = $filter('filter'),
    find = $filter('find'),
    orderBy = $filter('orderBy'),
    performanceLongTitle = $filter('performanceLongTitle'),
    concatDateTime = $filter('concatDateTime'),
    loginInfo = null,
    allowedMemberships = [
      2, // Active
      4, // Awaiting Payment
      7  // Lapsed
    ];

  $scope.acc = {
    loading: true,
    user: null,
    canExchange: false,
    canDonate: false,
    yapApplications: null,
    upcomingEvents: null,
    membershipsAndSubscriptions: null,
    connectToFacebook: connectToFacebook,
    disconnectFromFacebook: disconnectFromFacebook,
    facebookConnectError: false,
    disconnectingFacebook: false,
    logout: AuthenticationService.logout,
    renewMembership: renewMembership,
    renewPackage: renewPackage,
    donateAccountCredit: donateAccountCredit,
    useAccountCredit: useAccountCredit
  };

  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      $scope.userIsGuest = true;
      $scope.acc.loading = false;
      return;
    }

    MosSwitcher.defaultMos(true)
    .then(function () {
      return MosSwitcher.getSaleMode();
    })
    .then(function (saleMode) {
      $scope.acc.canExchange = saleMode.canExchange;
      $scope.acc.canDonate = saleMode.canDonate;

      return AuthenticationService.getLoginInfo();
    })
    .then(function (loginInfoEx) {
      loginInfo = loginInfoEx;

      constituentInfo();
    });
  });

  MosSwitcher.notifyOfMos();

  function constituentInfo() {
    $scope.acc.loading = true;

    return TessituraSDK.GetConstituentInfoEx().then(function (response) {
      var
        result = response.data.result.GetConstituentInfoExResults,
        constituentHeader = result.ConstituentHeader,
        constituencies = arrayWrap(result.Constituency),
        attributes = arrayWrap(result.ConstituentAttribute),
        emailAddresses = arrayWrap(result.EmailAddresses),
        phones = arrayWrap(result.Phones),
        promises = [];

      // Set up basic info
      $scope.acc.user = {
        customerNo: constituentHeader.customer_no,
        name: constituentHeader.full_name1,
        facebookConnected: null
      };

      var promise = AuthenticationService.facebookHasConnected()
      .then(function (isConnected) {
        $scope.acc.user.facebookConnected = isConnected;
      });
      promises.push(promise);

      // Skip inavtive email addresses
      var activeEmailAddresses = filter(emailAddresses, function (emailAddress) {
        return emailAddress.inactive.toLowerCase() !== 'y';
      });

      // We only need the primary email address at this point
      $scope.acc.user.email = filter(activeEmailAddresses, function (emailAddress) {
        return emailAddress.primary_ind.toLowerCase() === 'y';
      })[0].address; // Because this is an array of object, just use the address of the first one

      var phoneNumber;
      if (phones.length) {
        // Fetch the phone that's part of the primary address
        phoneNumber = filter(phones, function (phone) {
          return phone.primary_adr.toLowerCase() === 'y';
        });

        // Still no phone? Use the available daytime number
        if (!phoneNumber.length) {
          phoneNumber = filter(phones, function (phone) {
            return angular.isString(phone.day_ind) && phone.day_ind.toLowerCase() === 'd';
          });
        }
      }

      // Use phone if available
      $scope.acc.user.phone = phones.length && phoneNumber.length ? phoneNumber[0].phone : null;

      // Initial YAP application
      yapApplications()

      // Initiate loading events
      upcomingEvents();

      // Initiate loading memberships and subscriptions
      membershipsAndSubscriptions(constituencies, attributes);

      // Initiate on acocunt
      onAccount();

      return $q.all(promises);
    })
    .finally(function () {
      // Done loading the main bit
      $scope.acc.loading = false;
    });
  };

  function onAccount() {
    return AccountCredit.getUsableOnAccountItems()
    .then(function (onAccountItems) {
      $scope.acc.user.onAccount = onAccountItems;
    });
  };

  function upcomingEvents() {
    return TicketManagementService.getGroupedEvents(3)
    .then(function (upcomingEvents) {
      $scope.acc.upcomingEvents = upcomingEvents;
    })
    .catch(function () {
      $scope.acc.upcomingEvents = false;
    });
  };

  function orderDetails(orderNumber) {
    return TessituraSDK.GetCustomOrderDetails({
      order_no: orderNumber
    }).then(function (response) {
      var result = response.data.result.ExecuteLocalProcedureResults;
      return {
        Order: result.LocalProcedure,
        LineItem: arrayWrap(result.LocalProcedure1),
        SubLineItem: arrayWrap(result.LocalProcedure2),
        SubLineItemFee: arrayWrap(result.LocalProcedure3),
        Fee: arrayWrap(result.LocalProcedure4),
        Contribution: arrayWrap(result.LocalProcedure5),
        Payment: arrayWrap(result.LocalProcedure6),
        PaymentPlan: arrayWrap(result.LocalProcedure7)
      };
    });
  };

  function membershipsAndSubscriptions(constituencies, attributes) {
    $q.all([
      // Load memberships
      memberships(),
      // Load subscriptions
      subscriptions(constituencies, attributes)
    ]).then(function (responses) {
      var
        memberships = responses[0],
        subscriptions = responses[1];

      if (memberships || subscriptions) {
        $scope.acc.membershipsAndSubscriptions = {
          memberships: responses[0],
          subscriptions: responses[1]
        };
      } else {
        $scope.acc.membershipsAndSubscriptions = false;
      }
    });
  }

  function memberships() {
    return $q.all([
      TessituraSDK.GetMemberships({
        RetrievalKey: loginInfo.customer_no
      }),
      Donation.getDonationBands()
    ])
    .then(function (responses) {
      var
        getMembershipsResult = responses[0].data.result,
        donationBands = responses[1],
        memberships = arrayWrap(getMembershipsResult.GetLocalDataResults.LocalData);

      /**
       * Takes in a membership object from tessi and returns
       * and object that containers both the band and the level
       * that the membership has.
       * @param  {[type]} membership [description]
       * @return {[type]}            [description]
       */
      var getBandAndLevel = function (membership) {
        var amountPaid = parseFloat(membership.recog_amt);

        for (var bandIndex in donationBands) {
          var band = donationBands[bandIndex];
          for (var levelIndex in band.levels) {
            var level = band.levels[levelIndex];
            if (level.code == membership.memb_level && level.org_no == membership.memb_org_no) {
              return {
                level: level,
                band: band
              };
            }
          }
        }
        return null;
      };

      // Exit is the user has no memberships
      if (memberships.length === 0) {
        $scope.acc.memberships = false;
        return false;
      }

      // Remove memberships that has expired more than 90 days ago
      memberships = filter(memberships, function (membership) {
        return allowedMemberships.indexOf(parseInt(membership.current_status, 10)) !== -1;
      });

      // Keep track of "active" memberships
      var activeMemberships = {};

      memberships.forEach(function (membership) {
        // If membership isn't in the list of "active" memberships, place it there
        if (!activeMemberships.hasOwnProperty(membership.memb_org_no)) {
          activeMemberships[membership.memb_org_no] = membership;
        } else {
          // Otherwise grab what's currently in the list
          var currentMembership = activeMemberships[membership.memb_org_no];

          // Check if "new" one expires after the "old" one
          // 2 is active membership
          if (membership.current_status == 2) {
            // Use it otherwise ignore it
            activeMemberships[membership.memb_org_no] = membership;
          }
        }
      });

      memberships = [];

      for (var k in activeMemberships) {
        memberships.push(activeMemberships[k]);
      }

      // Attach the band and level of the membership
      memberships = memberships.map(function (membership) {
        var bandAndLevel = getBandAndLevel(membership);
        return angular.extend({}, membership, bandAndLevel);
      });

      return memberships.length ? memberships : false;
    });
  };

  function subscriptions(constituencies, attributes) {
    if (!constituencies && !attributes) {
      return $q.resolve(false);
    }

    // Load allowed subscriptions or attributes
    return TessituraSDK.GetAllowedSubscriptions().then(function (response) {
      var
        result = response.data.result.GetLocalDataResults,
        allowed = arrayWrap(result.LocalData);

      var
        allowedConstituencies = allowed.reduce(function (carry, item) {
          if (item.attribute_or_constituency === 'CT') {
            carry[item.att_const_id] = item;
          }

          return carry;
        }, {}),

        allowedAttributes = allowed.reduce(function (carry, item) {
          if (item.attribute_or_constituency === 'AT') {
            carry[item.att_const_id] = item;
          }

          return carry;
        }, {});

      var
        allowedConstituenciesKeys = Object.keys(allowedConstituencies),
        allowedAttributesKeys = Object.keys(allowedAttributes),
        toReturn = [];

      // Only grab the allowed constituencies
      constituencies.forEach(function (item) {
        if (allowedConstituenciesKeys.indexOf(item.id) !== -1) {
          toReturn.push( angular.extend(allowedConstituencies[item.id], item) );
        }
      });

      // Ditto with attributes
      attributes.forEach(function (item) {
        if (allowedAttributesKeys.indexOf(item.keyword_no) !== -1) {
        toReturn.push( angular.extend(allowedAttributes[item.keyword_no], item) );
        }
      });

      return $q.resolve(toReturn.length ? toReturn : false);
    });
  }

  function renewPackage(order) {
    Cart.loadOrder(order.orderNo, true)
    .then(function () {
      Router.goTo('booking.basket');
    });
  }

  function renewMembership(membership) {
    TessituraSDK.AddContribution({
      Amount: membership.recog_amt,
      Fund: membership.band.fund,
      AccountMethod: 0,
      Upgrade: 'false',
      Renew: 'true'
    })
    .then(function () {
      // Change the expiration time
      return TessituraSDK.ResetTicketExpiration();
    })
    .then(function () {
      Router.goTo('booking.basket');
    });
  }

  function connectToFacebook() {
    AuthenticationService.facebookConnect({
      joinLoginToCurrentCustomer: true
    })
    .then(function () {
      $scope.acc.facebookConnectError = false;
      $scope.acc.user.facebookConnected = true;
    })
    .catch(function () {
      $scope.acc.facebookConnectError = true;
    });
  }

  function disconnectFromFacebook() {
    $scope.acc.disconnectingFacebook = true;
    AuthenticationService.facebookDisconnect()
    .then(function (result) {
      $scope.acc.user.facebookConnected = false;
    })
    .catch(function (response) {
      if (response.data.error == 'no_alternative_login') {
        Notifications.create({
          style: 'danger',
          message: 'Please set a password before you disconnect your Facebook account from your Center Theatre Group account.'
        }, 'facebook.disconnect_error');
      }
    })
    .finally(function () {
      $scope.acc.disconnectingFacebook = false;
    });
  }

  function donateAccountCredit(onAccountItem) {
    AccountCredit.donateAccountCredit(onAccountItem)
    .then(function () {
      $window.location = $attrs.cartUrl;
    });
  }

  function useAccountCredit(onAccountItem) {
    AccountCredit.useAccountCredit(onAccountItem).then(function () {
      $filter('productionProdSeasonNoFromAccountItem')(onAccountItem).then(function (eventId) {
        if (parseInt(eventId || 0, 10)) {
          return redirectToSearch(eventId);
        }

        window.location = Router.getUrl('tickets');
      });
    });
  }

  function redirectToSearch(eventId){
    $window.location = Router.getUrl('search', {
      event: eventId,
      quantity: 1
    });
  }

  function yapApplications() {
    return TessituraSDK.GetCustomOrdersEx({
      renewals_only: 'N',
      perf_start_dt: moment().format('YYYY-MM-DDTHH:00:00'),
      print_only: 'N',
      customer_no: loginInfo.customer_no
    }).then(function (response) {
      var
        result = response.data.result,
        orders = arrayWrap(result.ExecuteLocalProcedureResults.LocalProcedure);

      // grab yap applications
      orders = orders.filter(function (order) {
        return ((
          // order has a note
          'notes' in order &&
          // note is a string
          angular.isString(order.notes) &&
          // the note is yap flag
          order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
        ) || (
          // order has a custom_6 value
          'custom_6' in order &&
          // custom_6 is a string
          angular.isString(order.custom_6) &&
          // value doesn't matter, so long as it exists
          order.custom_6.length
        ));
      });

      // unique orders only
      orders = $filter('unique')(orders, 'order_no');

      // no applications?
      if (!orders.length) {
        $scope.acc.yapApplications = false;

        return false;
      }

      // order by date
      orders = orderBy(orders, function (order) {
        return moment.utc(order.order_dt).unix();
      }, true);

      $scope.acc.yapApplications = [];

      // process yap applications
      var promises = orders.map(function (order) {
        return processYAPApplication(order);
      });

      return $q.all(promises).then(function (applications) {
        $scope.acc.yapApplications = orderBy(applications, '-statusInt');
      });
    });
  }

  function processYAPApplication(order) {
    return orderDetails(order.order_no).then(function (details) {
      var
        application = {
          orderNo: order.order_no,
          status: 'pending',
          statusInt: 0,
          events: {},
          printing: false,
          survey: Router.getUrl('yap.survey', {
            orderNo: order.order_no
          }),
          printApplication: function () {
            // check if there's a print iframe already
            if (!$window.frames['print-frame']) {
              // create an empty one if not
              var elm = document.createElement('iframe');
              elm.setAttribute('id', 'print-frame');
              elm.setAttribute('name', 'print-frame');
              elm.setAttribute('style', 'width: 1px; height: 1px; position: absolute; top: 0; left: 0; visibility: hidden; border: none;');
              // attach it to body
              document.body.appendChild(elm);
            }

            var iframe = document.getElementById('print-frame');

            var doc = iframe.contentWindow || iframe.contentDocument;

            if (doc.document) {
              doc = doc.document;
            }

            this.printing = true;

            // Get content first
            TessituraSDK.GetOrderConfirmationEmail({
              iOrderNo: this.orderNo,
              iTemplateNo: appConfig.yapApplicationConfirmation
            })
            .then(function (response) {
              var
                emailBodyparts = arrayWrap(response.data.result.Email.BodyParts.EmailBodyPart),
                confirmationEmail;

              // Loop over and look for the HTML version
              for (var i = 0; i < emailBodyparts.length; i++) {
                if (emailBodyparts[i].ContentType == 'text/html') {
                  confirmationEmail = emailBodyparts[i].Body;
                }
              }

              doc.write(confirmationEmail);
              doc.close();
              if ($window.navigator.userAgent.indexOf('MSIE') > 0) {
                iframe.contentWindow.document.execCommand('print', false, null);
              } else {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
              }
              application.printing = false;
            });
          },
          payInvoice: function () {
            return Cart.loadOrder(this.orderNo, true)
            .then(function () {
              Router.goTo('booking.checkout');
            });
          },
          emailInvoice: function () {
            TessituraSDK.SendOrderConfirmationEmail({
              iOrderNo: this.orderNo,
              iTemplateNo: appConfig.yapOrderConfirmation
            })
            .then(function (response) {
              alert('Your invoice has been emailed to you.');
            })
            .catch(function (errors) {
              alert('We could not send your invoice at this time. Please try again later.')
            });
          }
        },
        totalDue = parseFloat(details.Order.tot_due_amt),
        totalPaid = parseFloat(details.Order.tot_paid_amt);

      if (totalDue > 0 && totalDue === totalPaid) {
        application.status = 'completed';
        application.statusInt = 2;
      } else if (totalDue > 0) {
        application.status = 'approved';
        application.statusInt = 1;
      }

      // performance line items
      var events = arrayWrap(details.LineItem);

      application.events = events.reduce(function (carry, lineItem) {
        carry[lineItem.perf_no] = {
          perf_no: lineItem.perf_no,
          pkg_no: lineItem.pkg_no,
          perf_desc: lineItem.perf_desc,
          perf_dt: lineItem.perf_dt,
          facility_desc: lineItem.facility_desc,
          prod_season_no: lineItem.prod_season_no,
          li_seq_no: lineItem.li_seq_no,
          webContent: [],
          conference: false,
          rsvp: 'notes' in lineItem && angular.isString(lineItem.notes) && lineItem.notes === appConfig.yapRSVPCompleted
        };

        return carry;
      }, {});

      var promises = events.map(function (lineItem) {
        return getWebContent(lineItem.perf_no).then(function (webContent) {
          application.events[lineItem.perf_no].webContent = webContent;
        });
      });

      promises.push(getConferenceDates().then(function (conferenceDates) {
        var conferences = conferenceDates.reduce(function (carry, conference) {
          if (!(conference.Production in carry)) {
            carry[conference.Production] = [];
          }
          carry[conference.Production].push(conference);

          return carry;
        }, {});

        Object.keys(application.events).forEach(function (performanceNo) {
          var event = application.events[performanceNo];

          if (!conferences[event.prod_season_no]) {
            return;
          }

          application.events[performanceNo].conferences = conferences[event.prod_season_no];
          application.events[performanceNo].niceConferenceDates = application.events[performanceNo].conferences.map(function(conf) {return $filter('concatDateTime')(conf.Conference_Date, 'MMM d, yyyy')}).join("; ");

          var conference = angular.copy(conferences[event.prod_season_no]);

          application.events[performanceNo].rsvpURL = Router.getUrl('yap.conference', {
            orderNo: order.order_no,
            perfNo: performanceNo,
            liSeqNo: event.li_seq_no
          });
        });
      }));

      return $q.all(promises).then(function () {
        return application;
      });
    });
  }

  function getWebContent(performanceNo) {
    return TessituraSDK.GetWebContent({
      iInventoryNumber: performanceNo,
      iPackageNumber: 0
    }).then(function (response) {
      return arrayWrap(response.data.result.GetWebContentResults.WebContent);
    });
  }

  function getConferenceDates() {
    return TessituraSDK.SMaPTeacherConferences().then(function (response) {
      return arrayWrap(response.data.result.GetLocalDataResults.LocalData);
    });
  }
}]);
