app.controller('GiftCertificatesController', ['$scope', '$attrs', '$location', 'TessituraSDK', '$filter', '$q', '$window', function ($scope, $attrs, $location, TessituraSDK, $filter, $q, $window) {
  'use strict';

  var
    defaultAddressStreet1 = 'required',
    defaultCountry = 209,
    arrayWrap = $filter('arrayWrap'),
    methodsRequiresShipping = [1];


  $scope.formDetails = {
    donationValue : 0
  }

  $scope.user = {
    name: null
  };

  var params = $location.search();
  if(params.amount){
    $scope.formDetails.donationValue = params.amount;
  }

  $scope.delivery = {
    availableMethods : [],
    selectedShippingMethod : null,
    defaultShippingAddress : null,
    availableAddresses : []
  }

  function addressString(address) {
    var parts = [];

    parts.push(address.street1);

    if (angular.isString(address.street2) && address.street2) {
      parts.push(address.street2);
    }

    parts.push(address.city);

    if (angular.isString(address.state) && address.state) {
      parts.push(address.state);
    }

    parts.push(address.postal_code);

    parts.push(address.country);

    return parts.join(', ');
  }


  TessituraSDK.GetAddressAndShippingMethod().then(function (response) {
      var
        result = response.data.result,
        methods = arrayWrap(result.GetAddressAndShippingMethodResults.ShippingMethod),
        addresses = arrayWrap(result.GetAddressAndShippingMethodResults.Address);

      angular.forEach(methods, function (method) {
        // Something we don't want?
        if (method.description.indexOf('*') !== -1) {
          return;
        }

        $scope.delivery.availableMethods.push(method);

        if (!$scope.delivery.selectedShippingMethod && method.default == 'Y') {
          $scope.delivery.selectedShippingMethod = method;
        }
      });

      angular.forEach(addresses, function (address) {
        // Is this a default Tessitura address?
        if (address.street1 == defaultAddressStreet1) {
          $scope.delivery.defaultShippingAddress = address;
          // Skip it
          return;
        }

        // Convert address to pretty, comma separated string
        address.toString = addressString(address);

        $scope.delivery.availableAddresses.push(address);

        // Set the billing address
        if (!$scope.delivery.selectedBillingAddress && address.primary == 'Y') {
          $scope.delivery.selectedBillingAddress = address;
        }
      });

      if (!$scope.delivery.availableAddresses.length) {
        $scope.delivery.addNewBillingAddress = true;
      }
    });

}]);
