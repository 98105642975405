(function () {
  'use strict';


  app.directive('perfSearchSortOrder', ['$compile', function ($compile) {


    return {
      scope: {
        order : '=',
        update : '&'
      },
      link : function(scope, element){

        var cancel = scope.$watch('order', function(newVal){

          if(scope.order === undefined || scope.order.length === 0){
            return;
          }

          var htmlStr = [];
          for (var i = 0; i < scope.order.length; i++) {
            htmlStr.push('<li><a href data-index="'+i+'">' + scope.order[i].title + '</a></li>');
          }

          element = element.html(htmlStr.join(''));

          var links = element.find('a'),
              linksLength = links.length;

          var _scope = scope, index;

          for (var j = 0; j < linksLength; j++) {
            links[j].addEventListener("click", function(e){
              e.preventDefault();
              scope.update()({type:'SortOrder', vals:scope.order[e.target.getAttribute('data-index')]});
            }, false);
          }

          cancel();

        });

      },
      restrict: 'A'
    };
  }]);
}());
