app.controller('GiftCertificateBalance', ['$scope', '$modal', '$sce', 'TessituraSDK', 'Notifications', function ($scope, $modal, $sce, TessituraSDK, Notifications) {
    $scope = angular.extend($scope, {
        // vars
        loadingCode: false,
        certificateCode: '',
        error: null,
        certificateDetails: null,
        // functions
        checkCode: checkCode
    });

    var alerts = [];

    function checkCode() {

        removeAlerts();

        if (!$scope.certificateCode || $scope.certificateCode.length === 0) {

            var alert = {
              message: 'Please enter a valid certificate code',
              closable: true,
              style: 'danger'
            };
            alerts.push(alert);
            showAlerts();
            return false;
        }

        $scope.loadingCode = true;
        $scope.certificateDetails = null;

        TessituraSDK.GetGiftCertificateInfo({
            RedemptionCode: $scope.certificateCode
        }).then(function (response) {
            var GiftCertificate = response.data.result.GiftCertificateInfoResults.GiftCertificate;

            // Store the certificate information
            $scope.certificateDetails = {
                amountRemaining: parseFloat(GiftCertificate.gc_balance),
                code: GiftCertificate.gc_no
            };
        }).catch(function () {
            var alert = {
              message: 'The certificate code you entered is incorrect. Please review your gift certificate and try again.',
              closable: true,
              style: 'danger'
            };

            alerts.push(alert);
            showAlerts();
            // Show code isnt valid error
            // $scope.error = 'The certificate code you entered is incorrect';
        }).finally(function () {
            $scope.loadingCode = false;
        });
    }

    function removeAlerts(){
        alerts.map(function(alert){
            Notifications.remove(alert);
        });
        alerts = [];
    }

    function showAlerts(){
        alerts.map(function(alert){
            Notifications.create(alert);
        });
    }
}]);
