(function () {
  'use strict';


  app.directive('facetFilters', ['$compile', function ($compile) {


    return {
      scope: {
        filters : '=',
        facettitle : '=',
        update : '&',
        catid : '=',
        initialstate: '='
      },
      link : function(scope, element){

        var cancel = scope.$watch('filters', function(newVal){

          element = element.html('');

          if(scope.filters ===  undefined || scope.filters.length === 0){
            return;
          }

          var savedState = scope.initialstate || [];

          if(scope.catid){
            var catOptions = savedState[scope.catid];
            savedState = (catOptions) ? catOptions : [];
          }

          var htmlStr = [];
          htmlStr.push('<h4 class="h4">' + scope.facettitle + '</h4>');
          for (var i = 0; i < scope.filters.length; i++) {
            var checked = false;
            if(scope.initialstate){
              checked = (savedState.indexOf(scope.filters[i]) !== -1) ? 'checked="checked"' : false;
            }

            htmlStr.push('<div class="checkbox">');
            htmlStr.push('<label>');
            htmlStr.push('<input type="checkbox"');
            if(checked){
              htmlStr.push(' checked="checked" ');
            }
            htmlStr.push(' name="' + scope.filters[i] + '">' + scope.filters[i]);
            htmlStr.push('</label>');
            htmlStr.push('</div>');
          }
          element = element.html(htmlStr.join(''));

          var checkboxes = element.find('input'),
              checkboxesLength = checkboxes.length;

          for (var j = checkboxesLength - 1; j >= 0; j--) {
            checkboxes[j].addEventListener('change', handleChange, false);
          }

          function handleChange() {
            var vals = [];
            for (var k = checkboxesLength - 1; k >= 0; k--) {
              if(angular.element(checkboxes[k]).prop('checked')){
                vals.push(angular.element(checkboxes[k]).attr('name'));
              }
            }
            scope.update()({type:scope.facettitle, vals:vals, catid:scope.catid});
          }

        });

      },
      restrict: 'A'
    };
  }]);
}());
