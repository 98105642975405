app.filter('subLineItemReturned', ['appConfig', function (appConfig) {
  return function (array, invert) {
    if (!angular.isArray(array)) {
      return [];
    }

    return array.filter(function (lineItem) {
      var sliStatus = parseInt(lineItem.sli_status);

      var isMatchingSli = appConfig.returnedSliStatuses.indexOf(sliStatus) > -1;

      return invert ? !isMatchingSli : isMatchingSli;
    });
  };
}]);
