/* global moment */
app.controller('UpdateAccountController', ['$scope', '$window', '$filter', '$q', 'TessituraSDK', 'AuthenticationService', 'Router', 'CacheStorage', 'Notifications', function ($scope, $window, $filter, $q, TessituraSDK, AuthenticationService, Router, CacheStorage, Notifications) {
  var dateOfBirthKeywordNo = 1;

  var
    arrayWrap = $filter('arrayWrap'),
    date = $filter('date'),
    filter = $filter('filter');

  $scope.acc = {
    loading: true,
    userIsGuest: false,
    savingChanges: false,
    details: {
      prefix: null,
      firstName: null,
      lastName: null,
      email: null,
      originalEmail: null,
      phone: null,
      originalPhone: null,
      dateOfBirth: null,
      originalDateOfBirth: null
    },
    minDate: moment().subtract(100, 'years').format(),
    maxDate: moment().format(),
    prefixes: null,
    saveChanges: saveChanges,
    logout: AuthenticationService.logout,

    facebookAccount: false,
    facebookConnectError: false,
    disconnectFromFacebook: disconnectFromFacebook,
    disconnectingFacebook: false,

    needsConfirmPassword: needsConfirmPassword
  };

  $scope.onlyNumbers = '^[0-9]+$';


  // User must be registered
  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      $scope.acc.userIsGuest = true;
      return $q.reject();
    }
  })
  // Check the user is logged in first
  .then(function (response) {
    return $q.all([
      loadFacebook(),
      prefixes(),
      accountInfo()
    ])
  })
  .finally(function () {
    $scope.acc.loading = false;
  });

  function loadFacebook() {
    return TessituraSDK.GetFacebookAccountDetails()
    .then(function (response) {
      $scope.acc.facebookAccount = response.data.result;
    })
    .catch(function () {
      $scope.acc.facebookAccount = false;
    });
  }

  function prefixes() {
    return TessituraSDK.GetPrefixes({
      RetrievalKey: 1
    }).then(function (response) {
      $scope.acc.prefixes = arrayWrap(response.data.result.GetLocalDataResults.LocalData);
    });
  }

  function accountInfo() {

    return $q.all([
      TessituraSDK.GetAccountInfo(),
      TessituraSDK.LoginInfoEx()
    ])
    .then(function (responses) {
      var
        uid = responses[1].data.result.LoginInfoResults.LoginInformation.UID,
        userType = responses[1].data.result.LoginInfoResults.LoginInformation.user_type,
        result = responses[0].data.result.GetAccountInfoResults,
        accountInformation = result.AccountInformation;

      // For the users email, show them their login UID.
      // The reason is because if their account email and weblogin email gets out of sync, they can fix it with the uid.
      // If they are facebook, show the default account details
      $scope.isFacebook = userType == 'facebook';
      var email = $scope.isFacebook ? accountInformation.email:uid;

      var details = {
        prefix: $filter('toString')(accountInformation.prefix),
        firstName: accountInformation.fname,
        lastName: accountInformation.lname,
        email: email,
        originalEmail: email,
        phone: accountInformation.phone,
        originalPhone: accountInformation.phone,
        dateOfBirthDay: null,
        dateOfBirthMonth: null,
        dateOfBirthYear: null,
        originalDateOfBirth: null
      };

      return TessituraSDK.GetConstituentInfoEx({
        TableListTokens: 'AT'
      })
      .then(function (response) {
        var
          result = response.data.result.GetConstituentInfoExResults,
          attributes = null;

        if (result.hasOwnProperty('ConstituentAttribute')) {
          attributes = arrayWrap(result.ConstituentAttribute);
        }

        if (!attributes || !attributes.length) {
          $scope.acc.details = details;
          return;
        }

        var dateOfBirth = filter(attributes, function (attribute) {
          return attribute.keyword_no == dateOfBirthKeywordNo;
        });

        if (dateOfBirth.length > 0) {
          var dateOfBirthValue = dateOfBirth[0].attribute_value;

          details.originalDateOfBirthDay = details.dateOfBirthDay = Number(moment(dateOfBirthValue).format('DD'));
          details.originalDateOfBirthMonth = details.dateOfBirthMonth = Number(moment(dateOfBirthValue).format('MM'));
          details.originalDateOfBirthYear = details.dateOfBirthYear = Number(moment(dateOfBirthValue).format('YYYY'));
          details.originalDateOfBirth = dateOfBirth[0].attribute_value;
        }

        $scope.acc.details = details;
      });
    });
  }

  function needsConfirmPassword() {
    if ($scope.isFacebook) {
      return false;
    }

    return $scope.acc.details.email != $scope.acc.details.originalEmail
  }

  function disconnectFromFacebook() {
    $scope.acc.disconnectingFacebook = true;
    AuthenticationService.facebookDisconnect()
    .then(function (result) {
      $scope.acc.facebookAccount = null;
    })
    .catch(function (response) {
      if (response.data.error == 'no_alternative_login') {
        Notifications.create({
          style: 'danger',
          message: 'Please set a password before you disconnect your Facebook account from your Center Theatre Group account.'
        }, 'facebook.disconnect_error');
      }
    })
    .finally(function () {
      $scope.acc.disconnectingFacebook = false;
    });
  }

  var alert = {
    message: 'Your account has been updated successfully.',
    container: 'prefs'
  };

  function saveChanges() {
    $scope.acc.savingChanges = true;
    Notifications.remove('account-update');

    var params = {};
    if ($scope.acc.details.email != $scope.acc.details.originalEmail && !$scope.isFacebook) {
      params.email = $scope.acc.details.email;
      params.existing_password = $scope.acc.details.password;
    }
    if ($scope.acc.details.phone != $scope.acc.details.originalPhone) {
      params.phonenumber = $scope.acc.details.phone;
    }
    if (
      $scope.acc.details.originalDateOfBirthDay !== $scope.acc.details.dateOfBirthDay && $scope.acc.details.dateOfBirthDay !== null &&
      $scope.acc.details.originalDateOfBirthMonth !== $scope.acc.details.dateOfBirthMonth && $scope.acc.details.dateOfBirthMonth !== null &&
      $scope.acc.details.originalDateOfBirthYear !== $scope.acc.details.dateOfBirthYear && $scope.acc.details.dateOfBirthYear !== null
    ) {
      var date = new Date();
      date.setDate($scope.acc.details.dateOfBirthDay);
      date.setMonth($scope.acc.details.dateOfBirthMonth - 1);
      date.setYear($scope.acc.details.dateOfBirthYear);

      params.dob = moment(date).format('YYYY-MM-DD');
    }

    TessituraSDK.UpdateAccountDetails(params).catch(function (e) {
      switch(e.data.error) {
        case 'Error occured retrieving User ID':
        case 'Unable to get User ID':
        case 'Error updating login':
          alert = {
            style: 'danger',
            message: 'An error occured trying to update your login, no changes have been made.',
            container: 'prefs'
          };
          break;
        case 'Invalid Password':
          alert = {
            style: 'danger',
            message: 'The password you entered is incorrect.',
            container: 'prefs'
          };
          break;
        case 'Error getting account info':
        case 'Error updating account':
          alert = {
            style: 'danger',
            message: 'Error occured updating account details, please check your details and try again',
            container: 'prefs'
          };
          break;
        case 'Error getting existing details':
        case 'Error update DOB':
          alert = {
            style: 'danger',
            message: 'Error occured updating Date of Birth',
            container: 'prefs'
          };
          break;
        default:
          alert = {
            style: 'danger',
            message: 'Error in request parameters:<br>' + e.data.error,
            container: 'prefs'
          };
      }
      return $q.reject();
    })
    .finally(function () {
      CacheStorage.remove('Toolbar-userData');
      Notifications.create(alert, 'account-update');
      $scope.acc.savingChanges = false;
    });
  }
}]);
