/* global moment */
app.controller('UpcomingEventsController', ['$scope', '$filter', '$q', 'TessituraSDK', 'AuthenticationService', 'UpcomingEventsService', function ($scope, $filter, $q, TessituraSDK, AuthenticationService, UpcomingEventsService) {
  var
    orderBy = $filter('orderBy');

  $scope.acc = {
    loading: true,
    loggedIn: false,
    myUpcomingEvents: null
  };

  // Start off with getting the login info
  AuthenticationService.getLoginInfo()
  .then(function (loginInfo) {
    if (loginInfo.user_type === 'registered' || loginInfo.user_type === 'facebook') {
      $scope.acc.loggedIn = true;

      return UpcomingEventsService.getUserUpcomingEvents(loginInfo, 3);
    }

    return $q.resolve(0);
  })
  .then(function (myUpcomingEvents) {
    $scope.acc.myUpcomingEvents = myUpcomingEvents;
  })
  .finally(function () {
    $scope.acc.loading = false;

    var accordionTabsReady = window.setInterval(function () {
      if (window.App && window.App.initialized) {
        if (window.App.accordionTabs.run()){
          window.App.accordionTabs.reset();
        }
        clearInterval(accordionTabsReady);
      }
    }, 1000);
  });

}]);
