/* global moment */
/* global libphonenumber-js */
app.controller('ManageTicketsController', ['$scope', '$q', '$window', '$location', '$filter', 'appConfig', 'MosSwitcher', 'AuthenticationService', 'UpcomingEventsService', 'Exchange', 'TessituraSDK', 'TessituraRESTSDK', 'OrderService', function ($scope, $q, $window, $location, $filter, appConfig, MosSwitcher, AuthenticationService, UpcomingEventsService, Exchange, TessituraSDK, TessituraRESTSDK, OrderService) {
    var find = $filter('find');

    // Local variables
    var loginInfo;
    var widgetConfig = getWidgetConfig();
    var searchParams = $location.search();
    var action =  searchParams && searchParams.action ? String(searchParams.action).toLowerCase() : null;
    var orderNo = searchParams && searchParams.order_no ? parseInt(searchParams.order_no, 10) : null;
    var perfNo = searchParams && searchParams.perf_no ? parseInt(searchParams.perf_no, 10) : null;

    // Scope variables
    $scope = angular.extend($scope, {
        loading: true,
        isGuest: false,
        canExchange: false,
        canDonate: false,
        error: false,
        event: null,
        passes: {},
        action: null,
        sublineItems: {},
        // Trickses to bypass scope issue
        emailOrText: {
            recipient: '',
            submit: emailOrText,
            sending: false
        },
        exchangeOrDonate: exchangeOrDonate
    });

    if (!orderNo || !perfNo || !action) {
        $scope.error = 'Invalid order, performance number, or action.';
        $scope.loading = false;
        return false;
    }

    // Expose the action
    $scope.action = action;

    // Ask user to login if required
    AuthenticationService.requireLogin()
    .then(AuthenticationService.isUserRegistered)
    // Check the user is logged in first
    .then(function (isUserRegistered) {
        if (!isUserRegistered) {
            // This user is not registered
            $scope.isGuest = true;

            // No longer need to display loading
            $scope.loading = false;

            // No need to continue
            return $q.resolve(true);
        }

        // Try to gracefully switch to the default mode of sale for the user
        return MosSwitcher.defaultMos(true);
    })
    .then(function () {
        // Get the default sale mode for the user
        return MosSwitcher.getSaleMode();
    })
    .then(function (saleMode) {
        // Is the same mode subscriber?
        $scope.canExchange = saleMode.canExchange;
        $scope.canDonate = saleMode.canDonate;

        // Grab login info
        return AuthenticationService.getLoginInfo();
    })
    .then(function (loginInfoEx) {
        loginInfo = loginInfoEx;

        return upcomingEvents();
    })
    .then(function () {
        return preparePassbooks();
    })
    .finally(function () {
        $scope.loading = false;
    });

    function upcomingEvents() {
        return UpcomingEventsService.getUserUpcomingEvents(loginInfo)
        .then(function (upcomingEvents) {
            $scope.event = find(upcomingEvents, function (item) {
                return parseInt(item.orderNo, 10) === orderNo && item.perf_no === perfNo;
            });
        })
        .catch(function () {
            $scope.event = null;
        });
    };

    function preparePassbooks() {
        if (action !== 'download') {
            return $q.resolve();
        }

        return OrderService.PrintTicketsWithPassbook({
            iOrderNo: $scope.event.orderNo,
            lineItems: $scope.event.li_seq_no,
            performanceNo: $scope.event.perf_no,
            subLineItems: ''
        }).then(function (passes) {
            passes.forEach(function (pass) {
                $scope.passes[pass.sli_no] = pass.url;
            });
        });
    }

    function getSelected() {
        return Object.keys($scope.sublineItems).reduce(function (carry, key) {
            if ($scope.sublineItems[key] === false) {
                return carry;
            }

            carry.push(find($scope.event.sublineItems, function (item) {
                return item.sli_no === key;
            }));

            return carry;
        }, []);
    }

    function emailOrText() {
        var selected = getSelected();

        if (selected.length === 0) {
            return alert('Please select one or more tickets');
        }

        var recipient = $scope.emailOrText.recipient.trim();
        var sublines = selected.map(function (selected) {
            return selected.sli_no;
        }).join(',');

        if (action === 'email') {
            if (!validateEmail(recipient)) {
                return alert('Please enter a valid email address');
            }

            $scope.emailOrText.sending = true;

            TessituraRESTSDK.post('/Emails/ConstituentInfo/' + $scope.event.customer_no + '/Send', {
                TemplateId: appConfig.digital_tickets_template_id,
                EmailAddress: recipient,
                EmailProfileId: appConfig.digital_tickets_email_profile_id,
                NameValues: [
                    {
                        Name: 'OrderId',
                        Value: $scope.event.orderNo
                    },
                    {
                        Name: 'SubLineItems',
                        Value: sublines
                    }
                ]
            }).then(function () {
                alert('The selected tickets have been successfully sent.');
                return writeCustomerServiceIssue('Email', recipient, selected);
            }).catch(function () {
                alert('There was an issue sending the selected tickets. Please try again or contact Audience Services at 213.628.2772 if the problem persists.');
            }).finally(function () {
                $scope.emailOrText.sending = false;
            });
        } else if (action === 'text') {
            if (!validatePhone(recipient)) {
                return alert('Please enter a valid phone number');
            }

            $scope.emailOrText.sending = true;

            return TessituraSDK.SendMMS({
                sli_no: sublines,
                phone_no: recipient
            }).then(function (response) {
                var result = response.data.result;

                if (result !== true) {
                    return $q.reject(result);
                }

                return $q.resolve();
            }).then(function () {
                alert('The selected tickets have been successfully sent.');
                return writeCustomerServiceIssue('Text', recipient, selected);
            }).catch(function (error) {
                if (error === 'INVALID_NUMBER') {
                    alert('The provided number appears to be invalid - please double check and try again.');
                } else if (error === 'NUMBER_NOT_REACHABLE') {
                    alert('The provided number cannot be reached at the moment - please try again later.');
                } else if (error === 'INTERNATIONAL_NUMBER_NOT_SUPPORTED') {
                    alert('Only national numbers are supported at the moment - please provide a USA number and try again.');
                } else if (error === 'BLOCKED_NUMBER') {
                    alert('The provided number is blocked, please try a different number.');
                } else if (error === 'NOT_A_MOBILE_NUMBER') {
                    alert('The provided number cannot recieve SMS/MMS messages, please try a different number.');
                } else {
                    alert('There was an issue sending the selected tickets. Please try again or contact Audience Services at 213.628.2772 if the problem persists.');
                }
            }).finally(function () {
                $scope.emailOrText.sending = false;
            });
        }
    }

    function exchangeOrDonate() {
        var selected = getSelected();

        if (selected.length === 0) {
            return alert('Please select one or more tickets');
        }

        if (action === 'exchange') {
            Exchange.exchangeSubLineItems(orderNo, null, selected);
        } else if (action === 'donate') {
            OrderService.DonateTickets({
                field: 'sSubLineItems',
                tickets: selected.map(function (item) {
                    return item.sli_no;
                }).join(',')
            })
            .then(function () {
                $window.location.reload();
            });
        }
    }

    function validateEmail(email) {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (regex.test(email)) {
            return true;
        }

        return false;
    }

    function validatePhone(phone) {
        var regex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/;

        if (regex.test(phone)) {
            return true;
        }

        return false;
    }

    function writeCustomerServiceIssue(type, recipient, sublines) {
        var notes = [
            'Type: ' + type + "\n",
            'Recipient: ' + recipient + "\n\n",
            sublines.map(function (item) {
                return (
                    'Ticket No: ' + item.ticket_no + "\n" +
                    'Section: ' + item.zone_desc + "\n" +
                    'Row: ' + item.seat_row + "\n" +
                    'Seat: ' + item.seat_num + "\n"
                );
            }).join("\n")
        ];

        var csi = angular.extend({}, widgetConfig.csi, {
            PerformanceNumber: perfNo,
            Notes: notes.join('')
        });

        return TessituraSDK.AddCustomerServiceIssue(csi);
    }

    function getWidgetConfig() {
        var widgetConfig = document.getElementById('widget-config');

        if (widgetConfig) {
            widgetConfig = JSON.parse(widgetConfig.innerHTML);
        } else {
            widgetConfig = {};
        }

        return widgetConfig;
    }
}]);
