/* global moment */
(function () {
    function controller($q, $filter, $location, TessituraSDK, AuthenticationService, MosSwitcher, TicketManagementService) {
        var vm = this;

        var find = $filter('find');

        var searchParams = $location.search();
        var perfNo = searchParams && searchParams.perf_no ? parseInt(searchParams.perf_no, 10) : null;

        vm.loading = true;
        vm.error = false;
        vm.can_exchange = false;
        vm.can_donate = false;
        vm.event = null;

        if (!perfNo) {
            vm.error = 'Invalid performance number.';
            vm.loading = false;
            return false;
        }

        AuthenticationService.requireLogin().then(function () {
            return MosSwitcher.defaultMos(true);
        }).then(function () {
            return MosSwitcher.getSaleMode();
        }).then(function (saleMode) {
        vm.can_exchange = saleMode.canExchange;
        vm.can_donate = saleMode.canDonate;

        return TicketManagementService.getGroupedEvents();
        }).then(function (response) {
            vm.event = find(response, function (item) {
                return item.perf_no === perfNo;
            });

            if (vm.event) {
                return printTickets();
            }

            return $q.resolve();
        }).finally(function () {
            vm.loading = false;
        });

        function printTickets() {
            var unprinted = vm.event.unprinted_seats;

            if (!unprinted.length) {
                return $q.resolve();
            }

            var sublines = unprinted.map(function (item) {
                return item.sli_no;
            });

            return TessituraSDK.CTGPrintTickets({
                field: 'sSubLineItems',
                tickets: sublines.join(',')
            }).finally(function () {
                return TicketManagementService.getGroupedEvents().then(function (response) {
                    vm.event = find(response, function (item) {
                        return item.perf_no === perfNo;
                    });
                });
            })
        }
    }

    app.controller('TicketDetailsGroupedController', [
        '$q', '$filter', '$location', 'TessituraSDK', 'AuthenticationService', 'MosSwitcher', 'TicketManagementService', controller
    ]);
}())
