app.filter('performanceLongTitle', ['appConfig', function (appConfig) {
  return function (performance) {
    if (!performance) {
      return '';
    }

    if (angular.isArray(performance.webContent) == true) {
      for (var i in performance.webContent) {
        var webContent = performance.webContent[i];
        if (webContent.content_type == appConfig.webContentTypes.longTitle) {
          return webContent.content_value;
        }
      }
    }

    return performance.perf_desc || performance.description;
  };
}]);
