(function () {
    'use strict';

    app.directive('hasScrolledToBottom', function () {
        return {
            restrict: 'A',
            scope: {
                hasScrolledToBottom: '='
            },
            link: function (scope, element, attrs) {
                var raw = element[0];

                element.bind('scroll', function () {
                    if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                        scope.hasScrolledToBottom = true;
                    } else {
                        scope.hasScrolledToBottom = false;
                    }

                    scope.$apply();
                });
            }
        };
    });

})();
