app.filter('performanceImage', ['appConfig', function (appConfig) {
  return function (imageUrl, performanceId, imageSize) {
    if (imageUrl) {
      return imageUrl;
    } else {
      return appConfig.performanceImageFallback
        .replace(/\{perf_no\}/g, performanceId)
        .replace(/\{size\}/g, imageSize);
    }
  };
}]);
