app.controller('ShareOrderController', ['$scope', '$attrs', '$q', '$filter', '$http', 'TessituraSDK', 'GetParameters', 'AuthenticationService', 'Router', function ($scope, $attrs, $q, $filter, $http, TessituraSDK, GetParameters, AuthenticationService, Router) {
  'use strict';

  $scope.loading = true;

  // Order we need to load to display
  var orderNo = parseInt($attrs.orderNo || GetParameters['order-no']);

  // Help filters
  var arrayWrap = $filter('arrayWrap');
  var orderDetails = [];

  // Performance objects to allow sharing for.
  $scope.performances = [];

  // Get the order details so we know the line items
  TessituraSDK.GetCustomOrderDetails({
    order_no: orderNo
  })

  /**
   * We now need to to get the performance ids from the order
   * and get the productions related to it. The production has 
   * the synopses and webcontent.
   */
  .then(function (response) {
    orderDetails = response.data.result.ExecuteLocalProcedureResults;

    orderDetails = {
      Order: orderDetails.LocalProcedure,
      LineItem: orderDetails.LocalProcedure1,
      SubLineItem: orderDetails.LocalProcedure2,
      SubLineItemFee: orderDetails.LocalProcedure3,
      Fee: orderDetails.LocalProcedure4,
      Contribution: orderDetails.LocalProcedure5,
      Payment: orderDetails.LocalProcedure6,
      PaymentPlan: orderDetails.LocalProcedure7
    };

    $scope.orderDetails = orderDetails;

    orderDetails.LineItem = arrayWrap(orderDetails.LineItem);

    // Get the performance ids we need to fetch
    var performanceIdsToGet = [];
    orderDetails.LineItem.forEach(function (lineItem) {
      performanceIdsToGet.push(lineItem.perf_no);
    });

    // Make sure theres no doubles
    performanceIdsToGet = $filter('unique')(performanceIdsToGet);

    if (performanceIdsToGet.length === 0) {
      return $q.reject();
    }

    var productions = performanceIdsToGet.map(function (performanceId) {
      return getProduction(performanceId)
      .then(function (response) {

        var production = response.data.result.GetProductionDetailEx3Result.Production;
        production.webContent = response.data.result.GetProductionDetailEx3Result.WebContent;

        return production;
      });
    });

    return $q.all(productions);

  })


  .then(function (productions) {
    var prod_season_numbers = $filter('unique')(productions.map(function (production) {
      return 'event[]=' + production.prod_season_no;
    }));

    // getting the event cmsfields for each production season
    var eventsServicePromise = $http.get(Router.getUrl('cms.event', { prod_no: '' }) + '?' + prod_season_numbers.join('&'));

    var prodSeasonPages = [];

    eventsServicePromise.then(function (response) {
      productions.forEach(function (production) {
        production.cmsData = response.data[production.prod_season_no];

        production.longTitle = $filter('productionLongTitle')(production, {
          fallback: production.cmsData.Title
        });
      });

      var prods = $filter('groupBy')(productions, 'prod_no'); // returns an object so no array methods
      var list = [];
      for(var i in prods){
        list.push( $filter('first')(prods[i]));
      }
      
      // Add productions to scope for rendering
      $scope.productions = list;
    });

  })

  // Show the content now everything has loaded
  .finally(function () {
    $scope.loading = false;
  });

  function getProduction(performanceId) {
    return AuthenticationService.getMos()
    .then(function (mos) {
      return TessituraSDK.GetProductionDetailEx3({
        iPerf_no: performanceId,
        iModeOfSale: mos,
      });
    });
  }

  /**
   * Reloads the add this plugin.
   * Try again/suppress error reasons:
   *   User may block social plugins
   *   Addthis may be down
   *   Loading addthis library failed because its huge
   *   Library hasnt finished initializing yet
   */
  var reloadCount = 0
  $scope.reloadAddthis = function () {
    if (reloadCount++ > 5) {
      return;
    }

    try {
        window.addthis.layers.refresh();
    } catch(e) {
        setTimeout(function () {
          $scope.reloadAddthis();
        }, 500);
      }
  };


  /**
   * Adds an array to each Performance object with all
   * of its web content fields
   * 
   * @param  {[Production]} productions
   * @param  {[WebContent]} webContents
   */
  function attachWebContentToProduction(productions, webContents) {
    angular.forEach(arrayWrap(productions), function (production) {
      production.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == production.prod_season_no;
      });
    });
  }
}]);
