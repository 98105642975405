app.controller('UserMessagesController', ['$scope', '$rootScope', '$window', '$filter', '$q', 'TessituraSDK', 'AuthenticationService', 'Notifications', 'Router', 'UserMessagingService', function ($scope, $rootScope, $window, $filter, $q, TessituraSDK, AuthenticationService, Notifications, Router, UserMessagingService) {
  $scope = angular.extend($scope, {
    loading: true,
    userIsGuest: false,
    messages: null,
    manageOption: 'null',
    updating: false,
    markAsRead: markAsRead,
    manageMessages: manageMessages
  });

  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      $scope.userIsGuest = true;
      $q.reject();
    }
  })
  .then(function () {
    return setupMessages();
  })
  .finally(function () {
    $scope.loading = false;
  });

  function setupMessages() {
    return UserMessagingService.initNotificationMessages().then(function (response) {
      var messages = $filter('orderBy')(response, '+read_ind');

      $scope.messages = messages;

      $rootScope.$broadcast('notification.updated');
    });
  }

  function markAsRead(messageId) {
    return UserMessagingService.readUserMessage(messageId).then(function () {
      return setupMessages();
    });
  }

  function manageMessages() {
    var toDelete = $scope.messages;

    $scope.updating = true;

    if ($scope.manageOption === 'deleteRead') {
      toDelete = $scope.messages.filter(function (message) {
        return message.read_ind == 'Y';
      });
    }

    toDelete = toDelete.map(function (message) {
      return message.message_id;
    });

    return UserMessagingService.deleteUserMessages(toDelete).then(function (response) {
      return setupMessages();
    }).finally(function () {
      $scope.updating = false;
    });
  }
}]);
