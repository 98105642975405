app.service('toQuerystring', function () {
  return function toQuerystring(obj, prefix) {
    var str = [];
    for (var p in obj) {
      var k = prefix ? prefix + "[" + p + "]" : p, 
          v = obj[p];
      str.push(angular.isObject(v) ? toQuerystring(v, k) : (k) + "=" + encodeURIComponent(v));
    }
    return str.join("&");
  };
});
