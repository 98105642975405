/* global moment */
(function () {
    function controller(AuthenticationService, MosSwitcher, TicketManagementService) {
        var vm = this;

        vm.loading = true;
        vm.events = null;

        AuthenticationService.requireLogin().then(function () {
            return MosSwitcher.defaultMos(true);
        }).then(function () {
        return TicketManagementService.getGroupedEvents();
        }).then(function (response) {
            vm.events = response;
        }).finally(function () {
            vm.loading = false;
        });
    }

    app.controller('MyTicketsGroupedController', [
        'AuthenticationService', 'MosSwitcher', 'TicketManagementService', controller
    ]);
}())
