(function () {
  'use strict';

  app.filter('slice', function() {
    return function(arr, start, end) {
      return arr.slice(start, end);
    };
  });


  app.directive('calendarFacetFilters', ['$compile', function ($compile) {


    return {
      scope: {
        filters : '=',
        update : '&',
        catid : '='
      },
      link : function(scope, element){

        var cancel = scope.$watch('filters', function(newVal){

          // console.log('newVal',newVal);

          element = element.html('');

          if(scope.filters ===  undefined || scope.filters.length === 0){
            return;
          }

          var htmlStr = [];
          htmlStr.push('<div class="checkbox"><label><input type="checkbox" name="' + scope.filters.id + '">' + scope.filters.facet + '</label></div>');

          element = element.html(htmlStr.join(''));

          function handleChange() {
            scope.updateCheckboxes();
          }

          var checkboxes = element.find('input'),
              checkboxesLength = checkboxes.length;

          var _scope = scope;

          for (var i = checkboxesLength - 1; i >= 0; i--) {
            checkboxes[i].addEventListener("change", handleChange, false);
          }

          scope.updateCheckboxes = function(){
            var vals = [];
            for (var i = checkboxesLength - 1; i >= 0; i--) {
              if(angular.element(checkboxes[i]).prop('checked')){
                vals.push(angular.element(checkboxes[i]).attr('name'));
              }
            }

            scope.update()({vals:vals, catid:scope.catid.id, index:scope.catid.index});
          }

          // cancel();

        });

      },
      restrict: 'A'
    };
  }]);
}());
