/* global moment */
app.service('EventService', ['$q', '$http', '$filter', 'TessituraSDK', 'AuthenticationService', 'InventoryService', 'appConfig', 'Router', function ($q, $http, $filter, TessituraSDK, AuthenticationService, InventoryService, appConfig, Router) {
  var
    // Hold all loaded events. Makes things a bit quicker. Prolly consider shoving in localStorage
    productions = [],
    // Nice, Tessitura format
    dateFormat = 'YYYY-MM-DDTHH:mm:ssZ',
    arrayWrap = $filter('arrayWrap'),
    orderBy = $filter('orderBy'),
    labels = {
      'on-sale': 'Buy Tickets',
      'off-sale': 'Not On Sale',
      'coming-soon': 'Coming Soon',
      'just-announced': 'Just Announced'
    };

  return {
    get: get,
    getFirstPerformance: getFirstPerformance
  };


  function markBreadcrumb(msg) {
    if (window.Raven) {
      Raven.captureBreadcrumb({
        message: msg,
        category: 'action'
      });
    }
  }

  function get(prodSeasonNo) {
    // If we already loaded this, fetch it from cache
    if (productions.hasOwnProperty(prodSeasonNo)) {
      // And return it
      return productions[prodSeasonNo];
    }

    // Fetch from Tessitura
    return load(prodSeasonNo);
  };

  function load(prodSeasonNo) {
    var production = {
      no: prodSeasonNo,
      title: null,
      status: null,
      label: null,
      startDate: null,
      endDate: null,
      onSaleDate: null,
      offSaleDate: null,
      facility: null,
      facilityNo: null,
      webContent: {},
      keywords: [],
      bookingURL: null,
      cmsFields: null,
      venue: {},
      reviews: [],
      description: null,
      credits: [],
      worksPerformed: [],
    };

    if (!prodSeasonNo || (prodSeasonNo && parseInt(prodSeasonNo, 10) === 0)) {
      production.status = 'just-announced';
      production.label = labels[production.status];

      return $q.resolve(production);
    }

    // Get the login info to grab the MOS
    markBreadcrumb('EventService#load attempting to load event information');
    productions[prodSeasonNo] = AuthenticationService.getLoginInfo().then(function (loginInfo) {
      return $q.all([
        InventoryService.GetProductionsEx3ByProduction({
          iModeOfSale: loginInfo.MOS,
          sStartDate: moment().startOf('hour').format(dateFormat),
          iProdSeasonID: prodSeasonNo
        }),
        InventoryService.GetWebContent({
          iInventoryNumber: prodSeasonNo,
          iPackageNumber: 0
        })
      ])
      .then(function (responses) {

        markBreadcrumb('EventService#load called GetProductionsEx3ByProduction and GetWebContent');

        var
          productionResult = responses[0],
          hasProduction = 'Production' in productionResult,
          webContents = responses[1];

        var fieldsAndStatus = contentFieldsAndBookingStatus(webContents);

        production.webContent = fieldsAndStatus.fields;

        if (!hasProduction) {
          production.status = 'off-sale';

          if (fieldsAndStatus.status) {
            production.label = fieldsAndStatus.status;
          } else {
            production.label = labels[production.status];
          }

          return $q.resolve(production);
        }

        var
          hasFirstDate = hasProduction && 'first_dt' in productionResult.Production,
          hasLastDate = hasProduction && 'last_dt' in productionResult.Production,
          promises = [
            getFirstPerformance(loginInfo, productionResult.Production),
            getLastPerformance(loginInfo, productionResult.Production),
            getCMSFields(productionResult.Production)
          ];

        markBreadcrumb('EventService#load call event promises');
        return $q.all(promises).then(function (responses) {
          markBreadcrumb('EventService#load called event promises');

          var
            firstPerformance = responses[0],
            lastPerformance = responses[1],
            eventDetails = responses[2],
            offSale = false;

          if (!hasProduction || (!hasFirstDate && !hasLastDate)) {
            production.status = 'off-sale';
            offSale = true;
          } else {
            // Populate event info
            production.title = productionResult.Production.prod_desc;
            production.status = 'on-sale';
            production.venue = eventDetails.Venue;
            production.reviews = eventDetails.Reviews;
            production.description = eventDetails.Description;
            production.credits = eventDetails.Credits;
            production.worksPerformed = eventDetails.WorksPerformed;

            if (productionResult.Production.on_sale_ind.toLowerCase() !== 'y') {
              production.status = 'off-sale';
              offSale = true;
            }

            if (firstPerformance) {
              production.title = firstPerformance.title;
              production.startDate = firstPerformance.startDate;
              production.onSaleDate = firstPerformance.onSaleDate;
              production.facility = firstPerformance.facility;
              production.facilityNo = firstPerformance.facilityNo;
            }

            if (lastPerformance) {
              production.endDate = lastPerformance.endDate;
              production.offSaleDate = lastPerformance.offSaleDate;

              if (!firstPerformance) {
                production.title = lastPerformance.title;
                production.startDate = lastPerformance.startDate;
                production.onSaleDate = lastPerformance.onSaleDate;
                production.facility = lastPerformance.facility;
                production.facilityNo = lastPerformance.facilityNo;
              }
            }

            if (!offSale && moment().isBefore(production.onSaleDate)) {
              production.status = 'coming-soon';
            }

            if (!offSale && moment().isAfter(production.offSaleDate)) {
              production.status = 'off-sale';
            }
          }

          // Populate event fields
          production.cmsFields = eventDetails;
          production.bookingURL = eventDetails.Link;

          // Populate keywords
          production.keywords = arrayWrap(productionResult.Keyword);

          // Use a the custom booking text if set
          if (fieldsAndStatus.status) {
            production.label = fieldsAndStatus.status;
          } else {
            production.label = labels[production.status];
          }

          markBreadcrumb('EventService#load populated production data');

          return $q.resolve(production);
        });
      }).catch(function (errResponse) {
        if (window.Raven && errResponse && errResponse.data) {
          Raven.captureException(errResponse.data.error);
        }

        production.status = 'just-announced';
        production.label = labels[production.status];

        return $q.resolve(production);
      });
    });

    return productions[prodSeasonNo];
  };

  function getFirstPerformance(loginInfo, productionInfo) {
    return InventoryService.GetPerformancesForProduction({
      iModeOfSale: loginInfo.MOS,
      sSeasonIds: productionInfo.season_no,
      iProdSeasonID: productionInfo.prod_season_no
    }).then(function (response) {
      var performances = response.Performance;

      if ( !performances.length ) {
        return $q.resolve(false);
      }

      // Order by date ascending
      performances = orderBy(performances, function (performance) {
        return moment(performance.perf_date).valueOf();
      });

      // Return first performance specific dates
      return $q.resolve({
        title: performances[0].description,
        startDate: moment(performances[0].perf_date),
        onSaleDate: moment(performances[0].start_dt),
        facility: performances[0].facility_desc,
        facilityNo: performances[0].facility_no
      });
    });
  };

  function getLastPerformance(loginInfo, productionInfo) {
    return InventoryService.GetPerformancesForProduction({
      iModeOfSale: loginInfo.MOS,
      sSeasonIds: productionInfo.season_no,
      iProdSeasonID: productionInfo.prod_season_no
    }).then(function (response) {
      var performances = response.Performance;

      if ( !performances.length ) {
        return $q.resolve(false);
      }

      // Order by date descending
      performances = orderBy(performances, function (performance) {
        return moment(performance.perf_date).valueOf();
      }, true);

      // Return first performance specific dates
      return $q.resolve({
        title: performances[0].description,
        startDate: moment(performances[0].perf_date),
        onSaleDate: moment(performances[0].start_dt),
        facility: performances[0].facility_desc,
        facilityNo: performances[0].facility_no
      });
    });
  };

  function contentFieldsAndBookingStatus(webContents) {
    // Populate web content fields
    var data = {
      fields: {},
      status: false
    };

    angular.forEach(webContents, function (webContent) {
      // Use the special booking text if one exist
      if (parseInt(webContent.content_type, 10) === appConfig.webContentTypes.customBookingText) {
        data.status = webContent.content_value;

        // Skip shoving this in the web contents
        return;
      }

      data.fields[ webContent.content_type_desc ] = webContent.content_value;
    });

    return data;
  };

  function getCMSFields(productionInfo) {
    var endpoint = Router.getUrl('cms.event', {
      prod_no: productionInfo.prod_season_no
    });

    return $http.get(endpoint).then(function (response) {
      return response.data;
    });
  };
}]);
