/**
 * Filter that expects an OnAccountItem from tessitura to be passed it.
 * It will return the real production name.
 */
app.filter('productionNameFromAccountItem', ['Exchange', function (Exchange) {
  
  var exchangeModesByPaymentMethod = null;

  // Load exchange modes and remap is based on payment_method number
  Exchange.getExchangeModes()
  .then(function (exchangeModes) {

    exchangeModesByPaymentMethod = {};

    for(var i in exchangeModes) {
      var mode = exchangeModes[i];
      exchangeModesByPaymentMethod[mode.payment_method] = mode;
    }

  });

  function productionNameFromAccountItem(accountItem) {

    // Return - if we are still loading exchange modes
    if (exchangeModesByPaymentMethod === null) {
      return '-';
    }

    // Check if we have an exchageMode for this payment method
    if (exchangeModesByPaymentMethod[accountItem.pmt_method]) {
      return exchangeModesByPaymentMethod[accountItem.pmt_method].prod_desc;
    }

    // If we dont have one, return the tessitura description
    return accountItem.description;
  };

  productionNameFromAccountItem.$stateful = true;

  return productionNameFromAccountItem;
}]);
