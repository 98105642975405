app.controller('BuyGiftCertificateController', ['$scope', '$attrs', '$q', '$sce', '$window', '$modal', 'TessituraSDK', 'Donation', 'appConfig', 'CacheStorage', 'GetParameters', 'Cart', 'AuthenticationService', function ($scope, $attrs, $q, $sce, $window, $modal, TessituraSDK, Donation, appConfig, CacheStorage, GetParameters, Cart, AuthenticationService) {
  'use strict';

  // Promises for the page load
  var loadingPromises = [];
  var promise;

  $scope.loading = true;
  $scope.addingToBasket = false;

  if (GetParameters['membership']) {
    $scope.giftType = 'gift_membership';
  } else {
    $scope.giftType = 'gift_certificate';
  }

  // Parse the gift certificate data
  $scope.certificateOptions = angular.fromJson(document.getElementById('certificate-options').innerHTML);

  // All form fields
  $scope.form = {
    sendMethod: "",
    purchasingFor: "",
    toName: "",
    fromName: "",
    emailRecipient: "",
    printRecognition: GetParameters['print_recognition'] ? GetParameters['print_recognition'] : "",
    occasion: $scope.certificateOptions.options[0],
    amount: GetParameters['amount'] ? Number(GetParameters['amount']) : "",
    message: ""
  };

  $scope.$watch('form.purchasingFor', function (newVal) {
    // If the user has edited the input, dont touch it
    if (document.querySelector('[ng-model="form.emailRecipient"]').classList.contains('ng-dirty')) {
      return;
    }

    if (newVal == 'me') {
      AuthenticationService.getEmailAddress()
      .then(function (email) {
        if ($scope.form.purchasingFor == 'me') {
          $scope.form.emailRecipient = email;
        }
      });
    } else {
      $scope.form.emailRecipient = '';
    }
  });


  promise = AuthenticationService.requireLogin();
  loadingPromises.push(promise);

  // Get the account info so we can prefill the form field
  promise = TessituraSDK.GetAccountInfo()
    .then(function (response) {
      if (response.data.result.GetAccountInfoResults) {
        var lsal_desc = response.data.result.GetAccountInfoResults.AccountInformation.lsal_desc;

        $scope.form.fromName = (lsal_desc !== 'Anonymous Anonymous') ? response.data.result.GetAccountInfoResults.AccountInformation.lsal_desc : undefined;
      }
    })
    .catch(function () {
      // Do nothing
    });
  loadingPromises.push(promise);

  /**
   * Adds the gift certificate to basket
   */
  $scope.addToBasket = function () {
    if (parseFloat($scope.form.amount) < 25) {
        return alert('Please enter an amount equal or greater than 25.');
    }
    $scope.addingToBasket = true;
    var address;
    var addDonation = false;
    var PurchaseGiftCertificateParams = {
      Amount: parseFloat($scope.form.amount),
      PaymentMethod: appConfig.giftCertificatePaymentMethodId
    };

    var promise;

    // Check if we'll need to add a donation
    promise = TessituraSDK.GetCart()
      .then(function (response) {
        if (!response.data.result || !response.data.result.GetCartResults || !response.data.result.GetCartResults.Order) {
          addDonation = true;
        }
      });

    // Get the address from the address directive
    // if the sendMethod is mail
    if ($scope.form.sendMethod == 'mail') {
      promise = promise
        .then(function () {
          // Only save the address if the user is purchasing for self
          return $scope.getAddressFunction($scope.form.purchasingFor == 'me');
        })
        .then(function (addressObj) {
          // Save the address for later
          address = addressObj;
        });
    }

    if ($scope.giftType == 'gift_certificate') {
      // Add the gift certificate to the cart
      promise = promise
      .then(function () {
        return TessituraSDK.PurchaseGiftCertificate(PurchaseGiftCertificateParams);
      })
      .then(function (response) {
        // Get the giftcertficate date for emailing
        var GiftCertificate = response.data.result.PurchaseGiftCertificateResults.GiftCertificate;
        var storage = getGiftData(GiftCertificate.gc_no, address);

        // Store the data from checkout.js
        CacheStorage.set('BuyGiftCertificateMeta-' + GiftCertificate.gc_no, storage);
      });
    } else {
      promise = promise
      .then(function () {
        // Add the contribution to the cart
        return TessituraSDK.AddContribution({
          'Amount': $scope.form.amount,
          'Fund': GetParameters['fund_no'],
          'AccountMethod': 0,
          'Renew': 'false',
          'Upgrade': 'false'
        });
      })

      // Change the expiration time
      .then(function () {
        return TessituraSDK.ResetTicketExpiration();
      })

      // Get the new cart data
      .then(function (response) {
        return Cart.getCart(true);
      })

      // Find the contribution in the cart
      // Add the meta data for later
      .then(function (cartData) {

        var checkoutActions = [];

        // If we dont have a contribution, this went very wrong
        if (cartData.Contribution.length === 0) {
          return $q.reject();
        }

        // Get the certificate data
        var storage = getGiftData('', address);
        var emailGiftCertificate = storage.emailGiftCertificate;
        var csi = storage.csi;

        // Add the gift certificate for this
        if (emailGiftCertificate != null) {
          // Tell checkout.js how to handle this object
          emailGiftCertificate.type = "emailGiftCertificate";

          checkoutActions.push(emailGiftCertificate);
        }


        // Add the csi for this
        if (csi != null) {
          // Tell checkout.js how to handle this object
          csi.type = "csi";

          checkoutActions.push(csi);
        }

        // Get the contribution that was just added
        var contribution = cartData.Contribution[cartData.Contribution.length - 1];

        // Store the data for checkout
        // Yes we are storing an array
        CacheStorage.set('ContributionCheckoutMeta-' + contribution.ref_no, checkoutActions);

      });;

    }

      // Next add the donation if needed
    promise
      .then(function () {
        if (addDonation) {
          return Donation.addDefaultDonationAmount();
        }
      })
      // Finally forward the user on
      .then(function () {
        $window.location = $attrs.afterAddedToCartUrl;
      })
      // Display an error if something when wrong
      .catch(function () {
        $scope.addingToBasket = false;
      });
  };

  function getGiftData(gc_no, address){

    // Clone the form and replace the occasion object for text
    var formClone = angular.copy($scope.form);
    formClone.occasion = formClone.occasion.activityType.text;

    // Make the giftcard data for the csi
    var giftCardData = {
      gc_no: gc_no,
      formData: formClone,
      address: address,
      occasion_template: $scope.form.occasion.template,
      giftType: $scope.giftType
    };

    // Get the key for to access the origins object of occasion
    var originsKey = $scope.form.sendMethod + "-" + $scope.form.purchasingFor;

    // Create the CSI request to be sent at checkout
    var csi = {
      ContactMethod: $scope.certificateOptions.contactMethod,
      Category: $scope.certificateOptions.category,
      ActivityType: $scope.form.occasion.activityType,
      Origin: $scope.form.occasion.origins[originsKey],
      Notes: angular.toJson(giftCardData, 3),
      PerformanceNumber: 0,
      PackageNumber: 0
    };

    var emailGiftCertficateParams = null;
    if (giftCardData.formData.sendMethod == 'email') {
      emailGiftCertficateParams = {
        // Gift cert info
        to: giftCardData.formData.toName,
        from: giftCardData.formData.fromName,
        message: giftCardData.formData.message,
        amount: '$' + giftCardData.formData.amount,
        occasion_template: giftCardData.occasion_template,
        occasion: giftCardData.formData.occasion,
        address: address,
        code: giftCardData.gc_no,

        // File format
        format: 'pdf',
        base64: false,
        giftType: $scope.giftType,

        // Who and where to email it
        sendToName: giftCardData.formData.purchasingFor === 'me' ? giftCardData.formData.toName : giftCardData.formData.fromName,
        sendToEmail: giftCardData.formData.emailRecipient
      };
    }

    // Finally store all the data so it can go into a session
    var storage = {
      gc_no: gc_no,
      formData: formClone,
      address: address,
      csi: csi,
      emailGiftCertificate: emailGiftCertficateParams
    };

    return storage;
  }



  function previewGiftCertificate(){

    // Make a scope for the modal so we can edit it
    var modalScope = $scope.$new();
    modalScope.close = function () {
      modalInstance.close();
    };

    // Open the modal
    // This will show a loading wheel by default
    var modalInstance = $modal.open({
      backdrop: 'static',
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'gift-certificate-preview.html'),
      size: 'mg',
      scope: modalScope
    });

    // Preview settings
    var GetGiftCertificatePrintoutParams = {
      to: $scope.form.toName,
      from: $scope.form.fromName,
      message: $scope.form.message,
      amount: '$' + $scope.form.amount,
      code: 'ABC-123',
      format: 'png',
      base64: true,
      giftType: $scope.giftType,
      occasion: $scope.form.occasion.text,
      occasion_template: $scope.form.occasion.template
    };

    // Now fetch the preview from the server
    TessituraSDK.GetGiftCertificatePrintout(GetGiftCertificatePrintoutParams)
      .then(function (response) {
        modalScope.preview = response.data;
      });
  }
  $scope.previewGiftCertificate = previewGiftCertificate;



  // Wait for all page dependencies to load
  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
    });
}]);
