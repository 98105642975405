app.controller('BestAvailableController', ['$scope', '$attrs', '$sce', '$filter', '$q', '$window', '$modal', 'TessituraSDK', 'AuthenticationService', 'CacheStorage', '$location', 'Donation', 'appConfig', 'Router', 'Cart', 'PerformanceSearchService', 'MosSwitcher', function ($scope, $attrs, $sce, $filter, $q, $window, $modal, TessituraSDK, AuthenticationService, CacheStorage, $location, Donation, appConfig, Router, Cart, PerformanceSearchService, MosSwitcher) {
  var
    defaultQuantity = 0,
    dateFormat = 'yyyy-MM-dd HH:mm:ss',
    currentDate = $filter('date')(Date.now(), dateFormat),
    find = $filter('find'),
    filter = $filter('filter'),
    groupBy = $filter('groupBy'),
    orderBy = $filter('orderBy'),
    arrayWrap = $filter('arrayWrap'),
    productionLongTitle = $filter('productionLongTitle'),
    performanceLongTitle = $filter('performanceLongTitle'),
    modeOfSale = null,
    allocations = [];

  $scope.ba = {
    addingToCart: false,
    bestAvailableSelected : true,
    bestAvailablePriceTypes: [],
    datesLoaded: false,
    oneAtATime: true,
    notEnoughSeats: false,
    addToCartError: false,
    quantityLimitError: false,
    performanceDoesNotExist: false,
    noPerformances: false,
    noAvailability: false,
    priceTypeError: false,
    selected: {
      zone: {}
    },
    cart: {
      total: 0
    },
    quantity: defaultQuantity,
    resetSelectedZone: resetSelectedZone,
    calculateTotal: calculateTotal,
    selectPreviousPerformance: selectPreviousPerformance,
    selectNextPerformance: selectNextPerformance,
    setZone: setZone,
    manageBestAvailable : manageBestAvailable,
    resetBestAvailable: resetBestAvailable,
    addToCart: addToCart,
    setDefaultQuantity : setDefaultQuantity,
    displayADA: displayADA,
    sections: null,
    sectionsLength: 0
  };

  function setDefaultQuantity(priceType) {
    var avail_count = parseInt(priceType.avail_count, 10);
    var allocation = find($scope.ba.selectedPerformance.allocation, function (item) {
      return parseInt(priceType.price_type, 10) === parseInt(item.price_type, 10);
    });

    priceType.capped = false;

    if (avail_count <= defaultQuantity) {
      priceType.quantity = avail_count;
    } else {
      priceType.quantity = defaultQuantity;
    }

    if (avail_count > 9) {
      priceType.maxVal = 9;
    } else {
      priceType.maxVal = avail_count;
    }

    if (allocation) {
      var allowed = parseInt(allocation.allowed, 10);

      if (priceType.maxVal > allowed) {
        priceType.maxVal = allowed;
      }

      if (allowed === 0) {
        priceType.capped = true;
        priceType.quantity = 0;
      }
    }

    if (priceType.quantity > priceType.maxVal) {
      priceType.quantity = priceType.maxVal;
    }

    priceType.options = Array.apply(null, Array(priceType.maxVal + 1)).map(function (_, i) {
      return i;
    });
  }

  var
    filterPriceTypes = {
      general: [1, 106, 129, 275, 276, 277, 168, 169, 95],
      subscriber: [106, 111, 138],
      passport: [6, 106, 111, 128, 138, 152, 162],
      other: false,
      exchange: false
    },
    priceTypeMode = null,
    sessionKey = '';

  var searchParams = $location.search(),
    prodSeasonNo = $attrs.hasOwnProperty('prodSeasonNo') ? $attrs.prodSeasonNo : 0,
    performanceNo = $attrs.hasOwnProperty('perfNo') ? $attrs.perfNo : 0;

  if(searchParams && searchParams.prod_no){
    prodSeasonNo = Number(searchParams.prod_no);
  }

  if(searchParams && searchParams.perf_no){
    performanceNo = Number(searchParams.perf_no);
  }

  // Try switching the user to mode of sale for this page
  MosSwitcher.attemptGeneralMos()
  .then(function (response) {
    // Set the mode of sale so we can use later
    modeOfSale = response.mos;

    // Keep track of the sale mode we're in
    priceTypeMode = response.mode;

    // Do we need to use a special session key
    if (priceTypeMode === 'other') {
      sessionKey = response.sessionKey
    }

    // Do we need special price type filtering?
    if (priceTypeMode === 'exchange') {
      filterPriceTypes['exchange'] = response.priceTypes;
    }

    return loadAllocation();
  })
  .then(function (response) {
    return loadProduction(prodSeasonNo, performanceNo);
  })
  .then(function () {
    // If we have performances then success, return
    if ($scope.ba.Performance.length > 0) {
      return;
    }

    // This performance just cant be brought, display generic error
    $scope.ba.noPerformances = true;
  });

  $scope.returnUrl = function() {
    return $location.url();
  }

  function loadAllocation() {
    return Cart.getLimits().then(function (response) {
      allocations = response;
    });
  }

  function loadProduction(prodSeasonNo, perfNo) {
    $scope.ba.noPerformances = false;
    $scope.ba.noAvailability = false;

    return TessituraSDK.GetProductionDetailEx3({
      // Session Key (maybe)
      SessionKey: sessionKey,
      // Either this
      iPerf_no: perfNo,
      // Or this
      iProd_Season_no: prodSeasonNo,
      // This is though
      iModeOfSale: modeOfSale
    })
    .catch(function () {
      $scope.ba.performanceDoesNotExist = true;
      return $q.reject();
    })
    .then(function (response) {
      // Make production information available to scope
      angular.extend($scope.ba, response.data.result.GetProductionDetailEx3Result);

      var webContent = arrayWrap(response.data.result.GetProductionDetailEx3Result.WebContent);

      attachWebContentToProduction(
        response.data.result.GetProductionDetailEx3Result.Production,
        response.data.result.GetProductionDetailEx3Result.WebContent
      );

      // Grab long title, will be overridden later
      $scope.ba.Production.long_title = productionLongTitle($scope.ba.Production, {
        fallback: $scope.ba.Title.description
      });

      // Make sure performances are in a nice array
      var performances = arrayWrap($scope.ba.Performance);

      return PerformanceSearchService.getPerformanceAvailabilitySPROC({
        mos: modeOfSale
      }).then(function (result) {
          var data = arrayWrap(result.data.result.ExecuteLocalProcedureResults.LocalProcedure);

          performances = performances.map(function (performance) {
            var availability = data.filter(function (item) {
              return parseInt(item.perf_no, 10) === parseInt(performance.perf_no, 10);
            });

            performance.gross_availbility = availability.reduce(function (carry, item) {
              return carry + parseInt(item.avail_count, 10);
            }, 0);

            return performance;
          });

          filterPerformances(performances, perfNo, webContent);
      }, function (error) {
          filterPerformances(performances, perfNo, webContent);
      });
    });
  }

  function filterPerformances(performances, perfNo, webContent){
    // Filter out any past performances and off-sale performances
    $scope.ba.Performance = $filter('pick')(performances, function (performance) {
      return performance.on_sale_ind !== 'N' && $filter('date')(performance.perf_date, dateFormat) > currentDate;
    });

    $scope.ba.Performance = arrayWrap($scope.ba.Performance);

    // $scope.ba.Performance
    $scope.ba.Performance.forEach(function (performance) {
      var syosEnabled = $filter('filter')(webContent, function (content) {
        return content.content_type == appConfig.webContentTypes.webSYOS;
      });

      if (syosEnabled.length) {
        performance.syosEnabled = syosEnabled[0].content_value == 'Y';
      } else {
        performance.syosEnabled = false;
      }

      performance.allocation = allocations.filter(function (allocation) {
        return parseInt(allocation.perf_no, 10) === parseInt(performance.perf_no, 10);
      });
    });

    var findPerformance = $scope.ba.Performance.filter(function (performance) {
      return parseInt(performance.perf_no, 10) === parseInt(perfNo, 10);
    });

    var selectedPerformance = findPerformance.length ? findPerformance[0] : false;

    if (!selectedPerformance) {
      selectedPerformance = $scope.ba.Performance[0];
    }

    // Set the default performance
    $scope.ba.selectedPerformance = selectedPerformance;

    // Houston, we have some data!
    $scope.ba.datesLoaded = true;

    // Update availability
    resetAvailability();

    // Bind a change event to the performance dropdown
    $scope.$watch('ba.selectedPerformance', loadPerformance);
  }

  function attachWebContentToProduction(productions, webContents) {
    angular.forEach(arrayWrap(productions), function (production) {
      production.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == production.prod_season_no;
      });
    });
  }

  function resetSelectedZone() {
    $scope.ba.selected.zone = {};
  }

  function calculateTotal() {
    // Could be undefined. Don't want that. Skip it.

    if (angular.isUndefined($scope.ba.selected.zone.priceTypes)) {
      $scope.ba.cart.total = 0;
      return false;
    }

    var sub_total = 0;
    // Loop through each price type in a zone and tally up the prices
    angular.forEach($scope.ba.selected.zone.priceTypes, function (priceType) {
      sub_total += Number(priceType.price) * Number(priceType.quantity);
    });

    $scope.ba.cart.total = sub_total;
  }

  // @todo Maybe we don't want to show a previous button on the first performance?
  function selectPreviousPerformance() {
    var index = $scope.ba.Performance.indexOf($scope.ba.selectedPerformance);

    if (index === 0) {
        $scope.ba.selectedPerformance = $scope.ba.Performance[$scope.ba.Performance.length - 1];
    } else {
        $scope.ba.selectedPerformance = $scope.ba.Performance[index - 1];
    }
  }

  // @todo Same with the last performance?
  function selectNextPerformance() {
    var index = $scope.ba.Performance.indexOf($scope.ba.selectedPerformance);

    if(index === $scope.ba.Performance.length - 1) {
        $scope.ba.selectedPerformance = $scope.ba.Performance[0];
    }else{
        $scope.ba.selectedPerformance = $scope.ba.Performance[index + 1];
    }
  }

  $scope.$watch(function () {return $location.search();}, function () {
    if ($scope.ba.Performance && $scope.ba.Performance.length) {
      var searchParams = $location.search();
      var selectedPerformance = $scope.ba.Performance.filter(function (perf) {
        return parseInt(perf.perf_no, 10) === parseInt(searchParams.perf_no, 10);
      });
      selectedPerformance = selectedPerformance.length ? selectedPerformance[0] : $scope.ba.Performance[0];
      $scope.ba.selectedPerformance = selectedPerformance;
    }
  }, true);

  function setUrlPerfNo(perfNo) {
    var searchParams = $location.search();
    if (parseInt(perfNo, 10) !== parseInt(searchParams.perf_no, 10)) {
      $location.search('perf_no', perfNo);
    }
  }

  function loadPerformance() {
    $scope.ba.priceTypeError = false;
    $scope.ba.notEnoughSeats = false;
    $scope.ba.addToCartError = false;
    $scope.ba.quantityLimitError = false;

    if($scope.ba.selectedPerformance === undefined ) {
      return false;
    }

    $scope.ba.sections = null;

    $scope.ba.bestAvailablePriceTypes = [];

    setUrlPerfNo($scope.ba.selectedPerformance.perf_no);

    TessituraSDK.GetPerSeatFees({
        iPerfNo: $scope.ba.selectedPerformance.perf_no,
        iMOS: modeOfSale
    }).then(function (feesResponse) {
        TessituraSDK.GetPerformanceDetailWithDiscountingEx({
          SessionKey: sessionKey,
          iPerf_no: $scope.ba.selectedPerformance.perf_no,
          iModeOfSale: modeOfSale,
          sContentType: appConfig.webContentTypes.longTitle
        })
        .then(function (detailsResponse) {

          var
            // Performance data from Tessitura
            performance = detailsResponse.data.result.GetPerformanceDetailWithDiscountingExResult,
            // Price types - we need to make sure all prices are filtered by these
            priceTypes = arrayWrap(performance.PriceType),
            // Grab web contents
            webContents = arrayWrap(performance.WebContent);

          performance.Performance.webContent = webContents.filter(function (webContent) {
            var perf_no = parseInt(performance.Performance.inv_no, 10);

            return perf_no === parseInt(webContent.inv_no, 10) || perf_no === parseInt(webContent.orig_inv_no, 10);
          });

          $scope.ba.Production.long_title = performanceLongTitle(performance.Performance);

          $scope.ba.sections = [];
          $scope.ba.seated = true;

          // We'll assume these are the price types we want to use
          var priceTypesToUse = priceTypes;

          if (priceTypeMode != 'other') { // We're in a mode of sale we know about
            // This is the definitive list of price types to use
            var filteredPriceTypes = filterPriceTypes[priceTypeMode];

            // Grab IDs of price types
            priceTypesToUse = priceTypes.filter(function (priceType) {
              return filteredPriceTypes.indexOf(parseInt(priceType.price_type, 10)) !== -1;
            });

            // If in exchnage mode but exchange price types are no longer available
            if (priceTypeMode === 'exchange' && !priceTypesToUse.length) {
              // Find and use the default price type
              priceTypesToUse = priceTypes.filter(function (priceType) {
                return 'def_price_type' in priceType && priceType.def_price_type === 'Y';
              });
            }

            if (!priceTypesToUse.length) {
              $scope.ba.priceTypeError = true;
            }
          }

          // Map the price types into a nice array of IDs
          var availablePriceTypes = priceTypesToUse.map(function (priceType) {
            return parseInt(priceType.price_type, 10);
          });

          // Nicer way to keep track of why price types we have
          var priceTypesArray = priceTypesToUse.reduce(function (carry, item) {
            carry[item.price_type] = item;

            return carry;
          }, {});

          // We only want price types that are available, so get rid of the rest
          var allPrices = filter(arrayWrap(performance.AllPrice), function (price) {
            return availablePriceTypes.indexOf( parseInt(price.price_type, 10) ) !== -1;
          });

          var inArray = {};

          angular.forEach(allPrices, function (price) {
            if (price.available.toLowerCase() !== 'y') {
              return;
            }

            if (typeof inArray[price.price_type] === 'undefined') {
              inArray[price.price_type] = priceTypesArray[price.price_type];
              inArray[price.price_type].avail_count = 0;
            }

            inArray[price.price_type].avail_count += parseInt(price.avail_count, 10);
          });

          angular.forEach(inArray, function (priceType) {
            setDefaultQuantity(priceType);

            $scope.ba.bestAvailablePriceTypes.push(priceType);
          });

          // Turn nice array, and group by first word - important to do this here as
          // groupByFirstWord is very heavy and will be slow if not used properly
          var sections = $filter('groupByFirstWord')(allPrices, 'description');

          // Hold info about the sections
          var finalSections = {};

          // Run through each "section", and further group by zones
          angular.forEach(sections, function (section, title) {
            // Now we have a zone with multiple price types attached to them
            var sectionData = groupBy(section, 'description');

            // Hold info about the section data
            var finalSectionData = {};

            // Make a neato structure that can be used easily in front-end
            angular.forEach(sectionData, function (zones, zoneTitle) {
              // This could be a single item :/
              zones = arrayWrap(zones);

              var zoneData = {
                // No seats available
                availabilityCount: 0,
                // Ditto
                available: false,
                // Zone ID
                zoneNo: zones[0].zone_no,
                // ADA?
                ada: false,
                // No price types
                priceTypes: [],
                // Price range
                priceRange: 'Sold Out',
                // Fees
                feeRange: null
              };

              angular.forEach(zones, function (priceType) {
                // Set quantity to 0 incase no seats available
                priceType.quantity = 0;

                if (priceType.available.toLowerCase() === 'y') {
                  // Increment availabilityCount with count value from price type
                  zoneData.availabilityCount += parseInt(priceType.avail_count, 10);

                  // Set zone availability to true if a single price type is available
                  if (!zoneData.available) {
                    zoneData.available = true;
                  }

                  // Stick in a defaultQuantity value so it starts off with a non-zero value
                  priceType.quantity = defaultQuantity;
                }

                if ( !('avail_count' in priceType) ) {
                  priceType.avail_count = '0';
                }

                // Boom
                zoneData.priceTypes.push(priceType);
              });

              var
                // Group by same description
                sameNameGroup = groupBy(zoneData.priceTypes, 'price_type_desc'),
                sameNameSingle = [];

              // Loop through the grouped price types
              angular.forEach(sameNameGroup, function (priceTypes, priceType) {
                // Find the one with highest avalability and use that
                priceTypes = orderBy(priceTypes, ['-available']);

                // Sort by availability descending
                priceTypes = orderBy(priceTypes, function (price) {
                  return parseInt(price.avail_count, 10);
                }, true);

                sameNameSingle.push(priceTypes[0]);
              });

              // Order priceTypes by price ascending
              zoneData.priceTypes = orderBy(sameNameSingle, function (price) {
                return parseInt(price.price, 10);
              });

              var availablePrices = [];

              for (var i in zoneData.priceTypes) {
                var priceType = zoneData.priceTypes[i];

                if (priceType.available.toLowerCase() !== 'y') {
                  continue;
                }

                availablePrices.push( $filter('currency')(priceType.price, '$', 2) );
              }

              if (availablePrices.length) {
                zoneData.priceRange = availablePrices[0];

                if (availablePrices.length > 1) {
                  zoneData.priceRange += ' \u2014 ' + availablePrices[ availablePrices.length - 1 ];
                }
              }

              // Order priceTypes by price descending
              zoneData.priceTypes = orderBy(zoneData.priceTypes, function (price) {
                return parseInt(price.price, 10);
              }, true);

              var zoneFees = [];

              for (var j = 0; j < zoneData.priceTypes.length; j++) {
                var fees = feesResponse.data.result.filter(function (feeItem) {
                  return (
                    parseInt(feeItem.ZoneId, 10) === parseInt(zoneData.zoneNo, 10) &&
                    parseInt(feeItem.PriceTypeId, 10) === parseInt(zoneData.priceTypes[j].price_type, 10)
                  );
                });

                for (var k = 0; k < fees.length; k++) {
                  zoneFees.push(fees[k]);
                }
              }

              if (zoneData.available && zoneFees.length) {
                var minFee = $filter('min')(zoneFees, 'FeeAmount').FeeAmount;
                var maxFee = $filter('max')(zoneFees, 'FeeAmount').FeeAmount;

                if (minFee && maxFee) {
                  if (minFee == maxFee) {
                    zoneData.feeRange = '+' + $filter('currency')(minFee, '$', 2) + ' per ticket fee';
                  } else {
                    zoneData.feeRange = '+' + $filter('currency')(minFee, '$', 2) + ' \u2014 ' + $filter('currency')(maxFee, '$', 2) + ' per ticket fee';
                  }
                } else if (minFee) {
                  zoneData.feeRange = '+' + $filter('currency')(minFee, '$', 2) + ' per ticket fee';
                } else {
                  zoneData.feeRange = '+' + $filter('currency')(maxFee, '$', 2) + ' per ticket fee';
                }
              }

              // Boom
              finalSectionData[zoneTitle] = zoneData;
            });

            // Boom
            finalSections[title] = finalSectionData;
          });

          // Boom
          $scope.ba.sections = finalSections;
          $scope.ba.sectionsLength = Object.keys(finalSections).length;
          $scope.ba.seated = performance.Performance.seat_ind != 'N';
          resetAvailability();
          resetSelectedZone();
        });
    });
  };

  function resetAvailability(){
    var selectedPerformance = $scope.ba.selectedPerformance;

    if (selectedPerformance && 'gross_availbility' in selectedPerformance) {
      $scope.ba.noAvailability = selectedPerformance.gross_availbility < 1;
    }
  };

  function resetBestAvailable(){
      $scope.ba.bestAvailableSelected = false;
  };

  function manageBestAvailable(){

    resetSelectedZone();
    calculateTotal();
  };

  function setZone(zone, $event){
    $event.stopPropagation();
    $scope.ba.selected.zone = zone;
  };

  function displayADA() {
    var searchParams = $location.search(),
        perfNo = $attrs.hasOwnProperty('perfNo') ? $attrs.perfNo : 0

    if(searchParams && searchParams.perf_no){
      perfNo = Number(searchParams.perf_no);
    }

    if ($scope.ba.selectedPerformance) {
      perfNo = $scope.ba.selectedPerformance.perf_no;
    }

    return !CacheStorage.has('PerformanceADAMeta-' + perfNo);
  };

  // Bit long...
  function addToCart() {
    var
      // Defer for ADA widget
      adaDefer = $q.defer(),
      // Special requests holder
      specialRequests = [],
      // Special requests quantity
      specialRequestQuantity = 0,
      // Requested price types
      priceTypes = [],
      // Requested quantity
      quantity = 0,
      // Selected zone - 0 by default for pick my seats
      selectedZone = 0;

    $scope.ba.addingToCart = true;
    $scope.ba.notEnoughSeats = false;
    $scope.ba.addToCartError = false;
    $scope.ba.quantityLimitError = false;

    // If we're dealing with pick a seat
    if ($scope.ba.bestAvailableSelected) {
      // Loop through our available price types
      angular.forEach($scope.ba.bestAvailablePriceTypes, function (priceType) {
        // Loop through the requested price type
        for (var i = 0; i < Number(priceType.quantity); i++) {
          // Increment the quantity
          quantity++;
          // Plus add the price type to the array
          priceTypes.push(priceType.price_type);
        }
      });
    } else {
      // Ditto for selected zone
      angular.forEach($scope.ba.selected.zone.priceTypes, function (priceType) {
        // Only difference is, if quantity is not selected or price type
        // not available, skip the price type
        if (!priceType.quantity || priceType.available != 'Y') {
          return;
        }

        for (var i = 0; i < Number(priceType.quantity); i++) {
          quantity++;
          priceTypes.push(priceType.price_type);
        }
      });

      // Make a note of the zone, we'll need it later
      selectedZone = $scope.ba.selected.zone.zoneNo;
    }

    // If ADA is requested, show the pop up
    if ($scope.ba.ada || $scope.ba.selected.zone.ada) {
      var modalInstance = $modal.open({
        animation: true,
        keyboard: false,
        backdrop: 'static',
        templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'ada-seating.html'),
        controller: 'ADAModalInstanceController',
        size: 'lg',
        resolve: {
          performance: function () {
            return $q.when($scope.ba.selectedPerformance.perf_no);
          },
          quantity: quantity
        }
      });

      // Once it's closed, save whatever has been selected by user
      modalInstance.result.then(function (response) {
        specialRequests = response[0];
        specialRequestQuantity = response[1];

        // Flag adaDefer as being resolved
        adaDefer.resolve(true);
      }).finally(function (error) {
        $scope.ba.addingToCart = false;
      });
    } else {
      // If ADA is not requested, just flag adaDefer as being resolved
      adaDefer.resolve(true);
    }

    // Once adaDefer has been resolved
    return adaDefer.promise.then(function () {
      var requirements = specialRequests.map(function (request) {
        request = request.replace('QTY', specialRequestQuantity);

        return request;
      });

      return TessituraSDK.GetCart()
      .then(function (response) {
        var promise;

        if ($scope.ba.seated) {
          promise = TessituraSDK.ReserveTicketsEx({
            sPriceType: priceTypes.join(','),
            iPerformanceNumber: $scope.ba.selectedPerformance.perf_no,
            iNumberOfSeats: quantity,
            iZone: selectedZone,
            sSpecialRequests: requirements.join('&')
          });
        } else {
          promise = TessituraSDK.ReserveTicketsUnseated({
            sPriceType: priceTypes.join(','),
            iPerformanceNumber: $scope.ba.selectedPerformance.perf_no,
            iNumberOfSeats: quantity,
            iZone: selectedZone,
            sSpecialRequests: requirements.join('&')
          });
        }

        // Check the seats were added successfully
        promise = promise.then(function (response) {
          if (response.data.result[0] === "0") {
            $scope.ba.notEnoughSeats = true;
            return $q.reject(true);
          }
        });

        return promise;
      })
      .then(function () {
        TessituraSDK.ResetTicketExpiration().then(function () {
          $window.location = Router.getUrl('booking.basket');
        });
        return;
      })
      .catch(function (manualError) {
        if (manualError !== true) {
          var hasTicketLimit = manualError.data.error.match(/This request will exceed the ticket limit of (\d)/mi);

          if (angular.isArray(hasTicketLimit) && hasTicketLimit.length === 2) {
            $scope.ba.quantityLimitError = 'You may select up to ' + hasTicketLimit[1] + ' tickets for this performance.';
          } else {
            $scope.ba.addToCartError = true;
          }
        }

        $scope.ba.addingToCart = false;
      });
    });
  };
}]);

app.controller('ADAModalInstanceController', ['$scope', '$modalInstance', '$filter', 'TessituraSDK', 'CacheStorage', 'performance', 'quantity', function ($scope, $modalInstance, $filter, TessituraSDK, CacheStorage, performance, quantity) {
  var options = angular.fromJson(document.getElementById('best-available-options').innerHTML);

  $scope.modal = {
    ada: options.ada,
    minQuantity: 1,
    maxQuantity: quantity,
    data: {
      quantity: quantity,
      options: {},
      other: ''
    }
  };

  $scope.modal.continue = function () {
    var specialRequests = [];

    for (var i = 0; i < options.ada.length; i++) {
      var option = options.ada[i];

      if (option.option in $scope.modal.data.options && 'specialRequests' in option && angular.isArray(option.specialRequests)) {
        specialRequests = [].concat(specialRequests, option.specialRequests);
      }
    }

    options.csi.PerformanceNumber = performance;
    options.csi.Notes = angular.toJson($scope.modal.data);

    CacheStorage.set('PerformanceADAMeta-' + performance, options.csi);

    $modalInstance.close([$filter('unique')(specialRequests), $scope.modal.data.quantity]);
  };

  $scope.modal.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
}]);
