function DonationController($scope, $window, $filter, $attrs, GetParameters, TessituraSDK, Donation, Router, AuthenticationService, $q, appConfig, CacheStorage, Cart) {
  'use strict';

  var widgetConfig = document.getElementById('widget-config');

  if (widgetConfig) {
    widgetConfig = JSON.parse(widgetConfig.innerHTML);
  } else {
    widgetConfig = {};
  }

  var
    fundNo = parseFloat(widgetConfig.FundNumber || GetParameters['fund_no'] || $attrs.fundNo),
    context = widgetConfig.WidgetContext || 'Donation',
    disableInput = true;

  $scope.membership = null;
  $scope.errors = [];
  $scope.recognition = GetParameters['print_recognition'];
  $scope.giftThisDonation = !!$scope.recognition;
  $scope.loading = 0;
  $scope.donating = false;
  $scope.chosenLevel = undefined;
  $scope.nextMembershipForRenewal = null;
  $scope.threshhold = parseFloat(widgetConfig.DeclineBenefitsThreshold || $attrs.declineBenefitsThreshhold);
  $scope.declineBenefits = false;
  $scope.displayDeclineCheckbox = false;
  $scope.getMaxDonateableAmount = getMaxDonateableAmount;
  $scope.submitText = 'Donate';
  $scope.disableInput = !!(widgetConfig.DisableInput || 0);
  $scope.disablePrintRecognition = !!(widgetConfig.DisablePrintRecognition || 0);
  $scope.disableGiftDonation = !!(widgetConfig.DisableGiftDonation || 0);
  $scope.currentLevel = GetParameters['memb'];
  $scope.nextLevel = null;

  if (context === 'Membership') {
    $scope.submitText = 'Purchase';
  }

  var arrayWrap = $filter('arrayWrap')

  // Amount the user wants to donate, default with url value
  $scope.selectedDonationValue = parseFloat(GetParameters['amount']) || '';

  $scope.displayLevel = function(level) {
    if ($scope.selectedDonationValue == undefined) {
      return true;
    }

    return level.max <= $scope.selectedDonationValue || 0;
  };

  $scope.$watch('selectedDonationValue', function(newVal){

    if (!$scope.membership) {
      return;
    }

    if (newVal == false) {
      $scope.chosenLevel = undefined;
      return;
    }

    var maxDonateableAmount = getMaxDonateableAmount();

    for (var j = $scope.membership.levels.length - 1; j >= 0; j--) {
      var level = $scope.membership.levels[j];

      if (newVal > $scope.threshhold) {
        $scope.displayDeclineCheckbox = true;
      } else {
        $scope.displayDeclineCheckbox = false;
      }

      level.hidden = false;
      level.position = parseInt(level.min, 10);
      level.chosen = false;

      if (newVal > maxDonateableAmount) {
        return;
      }

      if (!$scope.disableInput && parseFloat(newVal) >= parseFloat(level.max)) {
        level.hidden = true;
      }

      if (Donation.donationValueIsBetween(newVal, level.min, level.max)) {
        level.chosen = true;
        $scope.chosenLevel = level;
        resetLevelPositions();
        level.position = 0;
      }
    }
  });


  function resetLevelPositions(){
    $scope.membership.levels.map(function(level){
      level.position = parseInt(level.min, 10);
    });
  }

  function getMaxDonateableAmount() {
    if (!$scope.membership) {
      return Infinity;
    }
    return parseFloat($scope.membership.levels[$scope.membership.levels.length - 1].max);
  }

  $scope.setSelectedDonationValue = function (value) {
    $scope.selectedDonationValue = parseInt(value).toFixed(2);
  };

    /**
   * Sends the CSI for the donation
   * @return Promise
   */
  function sendCSI() {

    // Did the user want print recognition
    if ($scope.recognition || $scope.declineBenefits) {
      // Get the cart for order number
      return Cart.getCart(true)
      .then(function (cartData) {

        var csis = [];
        var csi;

        if (cartData.Contribution.length === 0) {
          return $q.resolve();
        }

        // Print recognition csi


        if ($scope.recognition || $scope.declineBenefits) {
          // Copy the default CSI settings
          csi = angular.copy(appConfig.CSISettings.donationPrintRecognition);

          // Add in the Notes
          csi.PerformanceNumber = 0;
          csi.Notes = angular.toJson({
            Name: $scope.recognition,
            Fund: fundNo,
            Amount: $scope.selectedDonationValue,
            OrderNumber: cartData.Order.order_no
          }, 3);

          csi.type = 'csi';

          // checkout.js expects it to be an array
          csis.push(csi);
        }

        // Decline benefits csi

        if ($scope.declineBenefits) {
          // Copy the default CSI settings
          csi = angular.copy(appConfig.CSISettings.donationDeclineBenefits);

          // Add in the Notes
          csi.PerformanceNumber = 0;
          csi.Notes = angular.toJson({
            Name: $scope.recognition,
            Fund: fundNo,
            Amount: $scope.selectedDonationValue,
            OrderNumber: cartData.Order.order_no
          }, 3);


          csi.type = 'csi';

          // checkout.js expects it to be an array
          csis.push(csi);
        }


        // Get the contribution that was just added
        var contribution = cartData.Contribution[cartData.Contribution.length - 1];

        // Store the data for checkout
        CacheStorage.set('ContributionCheckoutMeta-' + contribution.ref_no, csis);
      });
    }

    // Theres no print recognition, return a resolved promise
    return $q.resolve();
  }

  var isUpgrade = function () {
    if (GetParameters['mode'] === undefined) {
      return false;
    }

    return GetParameters['mode'].toLowerCase() === 'upgrade';
  };

  $scope.isUpgrade = isUpgrade;

  var isRenewal = function () {
    if (GetParameters['mode'] === undefined) {
      return false;
    }

    return GetParameters['mode'].toLowerCase() === 'renew';
  };

  $scope.isRenewal = isRenewal;

  function getLevelFromMembership(membership) {

    for (var i in $scope.membership.levels) {
      var level = $scope.membership.levels[i];
      if (level.code == membership.memb_level) {
        return level;
      }
    }

    return null;
  }

  $scope.makeDonation = function () {

    if (!$scope.selectedDonationValue) {
      return;
    }

    if (parseFloat($scope.selectedDonationValue) < 25) {
        return alert('Please enter an amount equal or greater than 25.');
    }

    $scope.donating = true;

    if (!$scope.giftThisDonation) {
      // add donation to cart.
      TessituraSDK.AddContribution({
        'Amount': $scope.selectedDonationValue,
        'Fund': fundNo,
        'AccountMethod': 0,
        'Renew': String(isRenewal()),
        'Upgrade': String(isUpgrade())
      })
      .then(function () {
        // Change the expiration time
        return TessituraSDK.ResetTicketExpiration();
      })
      .then(sendCSI)
      .then(function () {
        $window.location = Router.getUrl('booking.basket');
      })
      .catch(function (response) {
        if (response.data.error.indexOf('Invalid Fund') !== -1) {
          $scope.errors.push('Invalid fund');
        }
        $scope.donating = false;
      });
    } else {
      $window.location = Router.getUrl('donation.gift_membership', {
        'amount': $scope.selectedDonationValue,
        'fund_no': fundNo,
        'print_recognition': $scope.recognition ? $scope.recognition:'',
        'redirectTo': $window.location.toString(),
        'membership': true
      });
    }
  };

  $scope.loading++;
  Donation.getDonationBands()
  .then(function (response) {
    $scope.membership = $filter('filter')(response, function (level) {
      return level.fund == fundNo;
    })[0];

    if ((isRenewal() || isUpgrade()) && GetParameters['cust_memb_no']) {
        $scope.loading++;
        getMembership(GetParameters['cust_memb_no']).then(function (membership) {
          $scope.membershipForRenewal = membership;
        }).finally(function () {
          $scope.loading--;
        });
    }

    if (isRenewal() || isUpgrade()) {
      var findCurrentLevelIndex = null;

      angular.forEach($scope.membership.levels, function (level, index) {
        if (findCurrentLevelIndex == null && level.code == $scope.currentLevel) {
          findCurrentLevelIndex = index;
        }
      });

      var currentLevelIndex = findCurrentLevelIndex;

      if ( $scope.membership.levels[currentLevelIndex + 1] !== undefined ) {
        $scope.nextLevel = $scope.membership.levels[currentLevelIndex + 1];
      }
    }

    if ($scope.membership && !$scope.selectedDonationValue) {
      var foundChosenLevel = $scope.membership.levels.filter(function (level) {
        return level.is_default == 'Y';
      });

      var chosenLevel = foundChosenLevel.length ? foundChosenLevel[0] : false;

      if (chosenLevel) {
        $scope.selectedDonationValue = parseInt(chosenLevel.min).toFixed(2);
      } else {
        $scope.selectedDonationValue = parseInt($scope.membership.levels[0].min).toFixed(2);
      }
    }

    $scope.loading--;
  });

  /**
   * Returns the customers membership with the
   * the cust_memb_no
   */
  function getMembership(cust_memb_no) {

    // Get the customer number
    return AuthenticationService.getLoginInfo()
    .then(function (loginInfo) {
      // Get the users memeberships
      return TessituraSDK.GetMemberships({
        RetrievalKey: loginInfo.customer_no
      });
    })
    .then(function (response) {
      var getMembershipsResult = response.data.result;
      var memberships = arrayWrap(getMembershipsResult.GetLocalDataResults.LocalData);

      for(var i in memberships) {
        var membership = memberships[i];
        if (membership.cust_memb_no === cust_memb_no) {
          return $q.resolve(membership);
        }
      }

      return $q.reject();
    });
  }

}

DonationController.$inject = ['$scope', '$window', '$filter', '$attrs', 'GetParameters', 'TessituraSDK', 'Donation', 'Router', 'AuthenticationService', '$q', 'appConfig', 'CacheStorage', 'Cart'];

app.controller('DonationController', DonationController);
