app.filter('count', function () {
  return function (object) {
    if (angular.isObject(object)) {
      if (object.length !== undefined) {
        return object.length;
      } else {
        return Object.keys(object).length;
      }
    }
    return object === undefined ? 0 : 1;
  };
});
