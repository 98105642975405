

app.controller('RedeemGiftCertificate', ['$scope', '$modalInstance', 'TessituraSDK', 'orderAmount', 'Notifications', function ($scope, $modalInstance, TessituraSDK, orderAmount, Notifications) {
  // Input field
  $scope.certificateCode = "";

  // Loading status
  $scope.loadingCode = false;
  $scope.applyingCode = false;
  $scope.invalidCode = false;
  $scope.applyingCode = false;

  // Code result
  $scope.certificateDetails = null;
  $scope.amountToRedeem = "";
  $scope.maxRedeemAmount = 0;

  /**
   * Checks tessi to see if the code the user
   * inserted it correct. If correct it fills scope
   * which triggers the next page
   */
  $scope.checkCode = function () {
    $scope.loadingCode = true;
    $scope.invalidCode = false;
    $scope.errorApplying = false;

    // Get cert info params
    var GetGiftCertificateInfoParams = {
      RedemptionCode: $scope.certificateCode,
    };

    TessituraSDK.GetGiftCertificateInfo(GetGiftCertificateInfoParams)
      .then(function (response) {
        var GiftCertificate = response.data.result.GiftCertificateInfoResults.GiftCertificate;
        // Store the certificate information
        $scope.certificateDetails = {
          amountRemaining: parseFloat(GiftCertificate.gc_balance),
          code: GiftCertificate.gc_no,
        };
        // Whats the max amount of that coupon that can be added to the cart
        $scope.maxRedeemAmount = Math.min(parseFloat(GiftCertificate.gc_balance), parseFloat(orderAmount));
        // Prefill the amount the user wants to use
        $scope.amountToRedeem = $scope.maxRedeemAmount;
      })
      .catch(function () {
        // Show code isnt valid error
        $scope.invalidCode = true;
      })
      .finally(function () {
        $scope.loadingCode = false;
      });
  };

  /**
   * Add the gift card to the order
   */
  $scope.applyAmount = function () {
    // Clear the currently showing error
    $scope.errorApplying = false;
    $scope.applyingCode = true;

    // Add Gift Cert params
    var ApplyGiftCertificateParams = {
      RedemptionCode: $scope.certificateDetails.code,
      Amount: parseFloat($scope.amountToRedeem),
    };

    // Add the certificate 
    TessituraSDK.ApplyGiftCertificate(ApplyGiftCertificateParams)
      .then(function (response) {
        // Tell the user it was added
        Notifications.create({
          message: "Gift Certificate applied"
        });

        // Close model
        return $modalInstance.close({});
      })
      .catch(function () {
        // Show to the user that something went wrong
        $scope.errorApplying = true;
      }).finally(function () {
        $scope.applyingCode = false;
      });
  };

  /**
   * Closes the model
   */
  $scope.close = function () {
    $modalInstance.close({});
  };

}]);
