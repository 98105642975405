app.controller('ClickWrapController', ['$q', '$window', '$scope', '$modalInstance', 'TessituraSDK', 'clickWrapConfig', function ($q, $window, $scope, $modalInstance, TessituraSDK, clickWrapConfig) {

  // Confirm checkbox status
  $scope.confirmed = false;

  // Loading 
  $scope.sendingCSI = false;

  // Variables used in view
  $scope.title = clickWrapConfig.title;
  $scope.description = clickWrapConfig.description;
  $scope.checkboxText = clickWrapConfig.checkboxText;
  $scope.confirmText = clickWrapConfig.confirmText;

  $scope.descriptionStyle = {
    overflow: 'scroll',
    height: $window.innerHeight * 0.5 + 'px'
  };

  angular.element($window).bind('resize', function () {
    $scope.descriptionStyle.height =  $window.innerHeight * 0.5 + 'px';
    $scope.$apply();
  });

  // Action handler for the Confirm button
  // Closes the model
  function confirm() {
    if ($scope.confirmed) {
      (clickWrapConfig.csi ? sendCSI():$q.resolve())
      .then(function () {
        $modalInstance.close(true);
      });
    } else {
      $modalInstance.close(false);
    }
  }
  $scope.confirm = confirm;

  // Send the CSI
  function sendCSI() {
    $scope.sendingCSI = true;

    var csiClone = angular.copy(clickWrapConfig.csi);
    
    csiClone.Notes = "";

    return TessituraSDK.AddCustomerServiceIssue(csiClone);
  }

}]);
