/* global trackEvent */

app.controller(
  'DeliveryController',
  ['$scope', '$attrs', '$filter', '$q', '$window', '$cookies', 'TessituraSDK', 'GoogleTagManager', 'Cart', 'AuthenticationService', 'Router', 'Exchange', 'CacheStorage', 'appConfig', 'AddressService', 'InventoryService', function (
      $scope,
      $attrs,
      $filter,
      $q,
      $window,
      $cookies,
      TessituraSDK,
      GoogleTagManager,
      Cart,
      AuthenticationService,
      Router,
      Exchange,
      CacheStorage,
      appConfig,
      AddressService,
      InventoryService
    ) {
  'use strict';
  var
    defaultAddressStreet1 = 'web added',
    defaultCountry = 1,
    arrayWrap = $filter('arrayWrap'),
    unique = $filter('unique'),
    find = $filter('find'),
    intersect = $filter('intersect');

  // Fields used for address verification
  var verificationFields = [
    'sStreet1', 'sStreet2', 'sCity', 'sStateProv', 'sPostalCode'
  ];

  GoogleTagManager.sendCheckoutStep(2);

  $scope.delivery = {

    loading: true,
    // List of shipping methods to display
    availableMethods: [],
    // List of addresses user has
    availableAddresses: [],
    // Selected shipping method
    selectedShippingMethod: null,
    // Selected billing and shipping addresses
    selectedBillingAddress: null,
    selectedShippingAddress: null,
    // Default shipping address for use with PAH
    defaultShippingAddress: null,
    // A array of delivery ids that are allowed to be shown
    deliveryMethodIdsToShow: [],
    // Deliveryes blacklisted because of
    deliveryBlacklistedItems: false,
    // User requires names?
    nameRequired: false,
    // Are we a guest user?
    userIsGuest: false,
    // Object for guest checkout
    guestCheckoutNames: {
      first: '',
      last: ''
    },
    // Object for new billing address
    newBillingAddress: {
      sPhone: null,
      iCountry: null,
      sStreet1: '',
      sStreet2: '',
      sPostalCode: '',
      sCity: '',
      sStateProv: '',
      iAddressType: 3,
      bPrimary: 'true',
      bSaveCopyOnChange: 'true'
    },
    // Object for new shipping address
    newShippingAddress: {
      iCountry: null,
      sStreet1: '',
      sStreet2: '',
      sPostalCode: '',
      sCity: '',
      sStateProv: '',
      iAddressType: 3
    },
    // Numbers only regex for phone number
    onlyNumbers: '^[0-9- :]+$',
    // Does the user wish to add a new billing address?
    addNewBillingAddress: false,
    // Does the user wish to add a new shipping address?
    addNewShippingAddress: false,
    // Use billing address for shipping
    useBillingAddress: 1,
    // Available countries to use
    availableCountries: [],
    // Available states for billing addresses
    availableBillingStates: [],
    // Ditto for shipping addresses
    availableShippingStates: [],
    error: null,
    processing: false,
    // Debug data
    tessituramethods: false,
    sessionKey: '',
    // Address verification
    verification: false,
    verifyingAddress: false,
    continueWithoutVerification: false,
    // Functions
    loadCountries: loadCountries,
    loadStatesForCountry: loadStatesForCountry,
    proceedToPayment: proceedToPayment,
    autofillFromZip: autofillFromZip,
    addBillingAddress: addBillingAddress,
    addShippingAddress: addShippingAddress,
    verifyAddress: verifyAddress,
    useVerifiedAddress: useVerifiedAddress
  };

  // Get the ball rolling - load shipping methods and countries
  $q.all([
    cartNotEmpty(),
    AuthenticationService.requireLogin(),
    setDeliveryOptions()
  ])
  .then(function () {
    return $q.all([
      AuthenticationService.isUserRegistered(),
      loadCountries(),
      TessituraSDK.GetAccountInfo()
    ]);
  })
  .then(function (responses) {
    var
      accountResponse = responses[2],
      accountResult = accountResponse.data.result.GetAccountInfoResults.AccountInformation;

    $scope.delivery.nameRequired = accountResult.fname === '-' || accountResult.lname === '-';

    $scope.delivery.userIsGuest = !responses[0];

    return availableMethods()
  })
  .then(function () {
    $scope.delivery.loading = false;
  });

  function cartNotEmpty() {
    return Cart.isCartEmpty()
    .then(function (isCartEmpty) {
      if (isCartEmpty) {
        return Router.goTo('booking.basket');
      }
    });
  }

  function setDeliveryOptions() {

    // Linear logic
    // if exchangeMode:
    //   if anyTicketIsUnder10DaysAway:
    //     return ticketExchangeUnder10Days
    //   else:
    //     return ticketExchange
    //
    // if sellingPackage:
    //   if renewal:
    //     return subsRenewal
    //   else:
    //     return subsPurchase
    //
    // if sellingTickets:
    //   if anyTicketIsUnder10DaysAway:
    //     return singleTicketPurchaseUnder10Days
    //   else:
    //     return singleTicketPurchase
    //
    // return other

    var isExchangeMode = null;
    var cartData = null;
    var blacklisted = null;

    return $q.all([
      Exchange.isInExchangeMode(),
      Cart.getCart()
    ])
    .then(function (responses) {
      isExchangeMode = responses[0];
      cartData = responses[1];

      var performances = arrayWrap(cartData.LineItem)
        .filter(function (perf) {
          return parseInt(perf.perf_no, 10) !== 0;
        })
        .map(function (perf) {
          return InventoryService.GetWebContent({
            iInventoryNumber: perf.perf_no
          }).then(function (contents) {
            var blacklistContent = find(contents, function (item) {
              return parseInt(item.content_type, 10) === appConfig.webContentTypes.deliveryBacklist;
            });

            if (!blacklistContent) {
              return null;
            }

            var title = perf.perf_desc;

            var titleContent = find(contents, function (item) {
              return parseInt(item.content_type, 10) === appConfig.webContentTypes.longTitle;
            });

            if (titleContent) {
              title = titleContent.content_value;
            }

            return {
              no: perf.perf_no,
              title: title,
              blacklist: blacklistContent.content_value.split(',')
            };
          });
        });

      var packages = arrayWrap(cartData.PackageLineItem)
        .filter(function (pkg) {
          return parseInt(pkg.pkg_no, 10) !== 0;
        })
        .map(function (pkg) {
          return InventoryService.GetWebContent({
            iPackageNumber: pkg.pkg_no
          })
          .then(function (contents) {
            var blacklistContent = find(contents, function (item) {
              return parseInt(item.content_type, 10) === appConfig.webContentTypes.deliveryBacklist;
            });

            if (!blacklistContent) {
              return null;
            }

            var title = pkg.perf_desc;

            var titleContent = find(contents, function (item) {
              return parseInt(item.content_type, 10) === appConfig.webContentTypes.longTitle;
            });

            if (titleContent) {
              title = titleContent.content_value;
            }

            return {
              no: pkg.perf_no,
              title: title,
              blacklist: blacklistContent.content_value.split(',')
            };
          });
        });

      return $q.all(
        [].concat(performances, packages)
      );
    })
    .then(function (responses) {
      responses = responses.filter(function (item) {
        return !!item;
      });

      blacklisted = unique(responses, 'no');

      var isSub = arrayWrap(cartData.PackageLineItem).length > 0;
      var isSubRenewal = cartData.Order.solicitor === 'RollOver';
      var isSellingTickets = arrayWrap(cartData.LineItem).length > 0;
      var isAnyTicketPerformanceUnder10Days = arrayWrap(cartData.LineItem).some(ticketEventIsSoon);

      if (isExchangeMode) {
        if (isAnyTicketPerformanceUnder10Days) {
          return 'ticketExchangeUnder10Days';
        } else {
          return 'ticketExchange';
        }
      }

      if (isSub) {
        if (isSubRenewal) {
          return 'subsRenewal';
        } else {
          return 'subsPurchase';
        }
      }

      if (isSellingTickets) {
        if (isAnyTicketPerformanceUnder10Days) {
          return 'singleTicketPurchaseUnder10Days';
        } else {
          return 'singleTicketPurchase';
        }
      }

      return 'other';

    })
    .then(function (mode) {
      var deliveryConfig = getDeliveryConfig();
      var toShow = deliveryConfig[mode];

      if (blacklisted && blacklisted.length) {
        var blacklistedList = blacklisted.map(function (item) {
          return item.blacklist.map(function (item) {
            return parseInt(item, 10);
          });
        });

        blacklistedList = [].concat.apply([], blacklistedList);

        var deliveryBlacklistedItems = false;

        toShow = toShow.filter(function (method) {
          var isBlacklisted = blacklistedList.indexOf( parseInt(method, 10) ) !== -1;

          if (!isBlacklisted && !deliveryBlacklistedItems) {
            deliveryBlacklistedItems = true;
          }

          return !isBlacklisted;
        });

        if (deliveryBlacklistedItems) {
          deliveryBlacklistedItems = blacklisted.map(function (item) {
            return item.title;
          });

          deliveryBlacklistedItems = unique(deliveryBlacklistedItems);

          $scope.delivery.deliveryBlacklistedItems = $filter('oxford')(deliveryBlacklistedItems);
        }
      }

      $scope.delivery.deliveryMethodIdsToShow = toShow;
    });
  }

  function ticketEventIsSoon(ticket) {
    return moment.utc(ticket.perf_dt).diff(moment.utc()) < (60 * 60 * 24 * 10); // 10 days
  }

  function canShowDeliveryMethod(deliveryMethod) {
    var deliveryMethodId = parseFloat(deliveryMethod.id);
    return $scope.delivery.deliveryMethodIdsToShow.indexOf(deliveryMethodId) > -1;
  }

  function availableMethods() {
    return TessituraSDK.GetAddressAndShippingMethodEx()
    .then(function (response) {
      var
        result = response.data.result,
        methods = arrayWrap(result.GetAddressAndShippingMethodResults.ShippingMethod),
        addresses = arrayWrap(result.GetAddressAndShippingMethodResults.Address);

      $scope.delivery.tessituramethods = methods;
      $scope.delivery.sessionKey = $cookies.get('sessionkey');

      // Remove inactive and default addresses
      addresses = addresses.filter(function (address) {
        // Filter out inactive addresses
        if (address.inactive == 'Y' ||
            address.street1 == 'web added' ||
            address.street1 == 'Guest street' ||
            $scope.delivery.nameRequired
          ) {
          return false;
        }

        return true;
      });

      var availableMethods = [];

      angular.forEach(methods, function (method) {
        // Something we don't want?
        if (method.description.indexOf('*') !== -1) {
          return;
        }

        availableMethods.push(method);
      });

      // Filter by methods we can show
      $scope.delivery.availableMethods = $filter('filter')(availableMethods, canShowDeliveryMethod);

      return Cart.getCart().then(function (cartData) {
        var onlyDigitalContent = cartData.LineItem.length && cartData.LineItem.filter(function (li) {
          return appConfig.digital_prod_type_ids.indexOf(parseInt(li.prod_type, 10)) > -1;
        }).length === cartData.LineItem.length;

        var deliveryMethodIds = $scope.delivery.availableMethods.map(function (method) { return parseInt(method.id, 10); });
        if (onlyDigitalContent && intersect(deliveryMethodIds, appConfig.digital_delivery_methods)) {
          $scope.delivery.availableMethods = $scope.delivery.availableMethods.filter(function (method) {
            return appConfig.digital_delivery_methods.indexOf(parseInt(method.id, 10)) > -1;
          });
        } else {
          $scope.delivery.availableMethods = $scope.delivery.availableMethods.filter(function (method) {
            return appConfig.digital_delivery_methods.indexOf(parseInt(method.id, 10)) === -1;
          });
        }

        // If no method is available after filtering
        // fallback to collect at box office
        if (!$scope.delivery.availableMethods.length) {
          $scope.delivery.availableMethods = $filter('filter')(availableMethods, {
            id: -1
          });
        }

        var defaultMethod = $filter('filter')($scope.delivery.availableMethods, {
          default: 'Y'
        });

        if (defaultMethod.length) {
          $scope.delivery.selectedShippingMethod = defaultMethod[0];
        } else {
          $scope.delivery.selectedShippingMethod = $scope.delivery.availableMethods[0];
        }

        $scope.delivery.availableMethods.forEach(function (method) {
          if (!$scope.delivery.selectedShippingMethod && (method.default == 'Y' || $scope.delivery.availableMethods.length == 1)) {
            $scope.delivery.selectedShippingMethod = method;
          }
        });

        angular.forEach(addresses, function (address) {
          // Is this a default Tessitura address?
          if (address.street1 == defaultAddressStreet1) {
            $scope.delivery.defaultShippingAddress = address;
            // Skip it
            return;
          }

          // Convert address to pretty, comma separated string
          address.toString = addressString(address);

          $scope.delivery.availableAddresses.push(address);

          // Set the billing address
          if (!$scope.delivery.selectedBillingAddress && address.primary == 'Y') {
            $scope.delivery.selectedBillingAddress = address;
            $scope.delivery.selectedShippingAddress = address;
          }
        });

        if (!$scope.delivery.selectedBillingAddress && addresses.length) {
          $scope.delivery.selectedBillingAddress = addresses[0];
        }

        if (!$scope.delivery.selectedShippingAddress && addresses.length) {
          $scope.delivery.selectedShippingAddress = addresses[0];
        }

        if (!$scope.delivery.availableAddresses.length) {
          $scope.delivery.addNewBillingAddress = true;
        }
      });
    });
  };

  function loadCountries() {
    return TessituraSDK.GetCountries().then(function (response) {
      var
        result = response.data.result,
        countries = arrayWrap(result.GetCountriesResults.Country);

      $scope.delivery.availableCountries = countries;

      angular.forEach(countries, function (country) {
        // Set the default country to the empty billing and shipping addresses
        if (country.id == defaultCountry) {
          if (!$scope.delivery.newBillingAddress.iCountry) {
            $scope.delivery.newBillingAddress.iCountry = country.id;
          }

          if (!$scope.delivery.newShippingAddress.iCountry) {
            $scope.delivery.newShippingAddress.iCountry = country.id;
          }
        }
      });
    });
  };

  function loadStatesForCountry(mode) {
    var countryId;

    if (mode == 'billing') {
      countryId = $scope.delivery.newBillingAddress.iCountry;
    } else {
      countryId = $scope.delivery.newShippingAddress.iCountry;
    }

    return TessituraSDK.GetStateProvinceEx({
      CountryIds: countryId || defaultCountry
    }).then(function (response) {
      var
        result = response.data.result,
        states = null;

      if ('GetStateProvinceResults' in result) {
        states = result.GetStateProvinceResults.StateProvince;
      } else {
        states = result.GetStateProvinceResultsEx.StateProvince;
      }

      if (mode == 'billing') {
        $scope.delivery.availableBillingStates = states;
      } else {
        $scope.delivery.availableShippingStates = states;
      }
    });
  };

  function proceedToPayment() {
    $scope.delivery.error = null;
    $scope.delivery.processing = true;

    var shippingMethod;
    if ($scope.delivery.selectedShippingMethod) {
      shippingMethod = $scope.delivery.selectedShippingMethod.description;
    }

    Cart.getCart().then(function (cartData) {
      var totalPrice = 0;
      var cartItems = cartData.LineItem.map(function (item) {
          var sublineItems = cartData.SubLineItem.filter(function (subItem) {
              return subItem.li_seq_no === item.li_seq_no;
          });

          var lineTotal = sublineItems.reduce(function (carry, item) {
              return carry + parseFloat(item.due_amt);
          }, 0);

          totalPrice += lineTotal;

          return {
              item_id: item.perf_no,
              item_name: item.perf_desc,
              coupon: cartData.Order.source_no,
              location_id: item.facil_no,
              price: lineTotal,
              quantity: sublineItems.length,
          };
      });

      var contributions = cartData.Contribution.map(function (item) {
          return {
              item_id: item.fund_no,
              item_name: item.fund_desc,
              price: parseFloat(item.contribution_amt),
          }
      });

      contributions.forEach(function (contribution) {
          cartItems.push(contribution)
          totalPrice += contribution.price
      });

      var data = {
          currency: "USD",
          value: totalPrice,
          shipping_tier: shippingMethod,
          items: cartItems,
      };
      trackEvent('add_shipping_info', data);
    });

    // No need to listen for the return event
    // This will be a hundred times quicker then the tessi calls
    GoogleTagManager.sendCheckoutStep(2, shippingMethod);

    setShippingInformation()
    .then(function () {
        $window.location = $attrs.paymentPageUrl;
    }).catch(function (error) {
      if (error.data.error.indexOf('Foreign shipping is not allowed for this order') !== -1) {
        $scope.delivery.error = 'Foreign shipping is not allowed for this order';
      } else {
        $scope.delivery.error = error.data.error;
      }
      $scope.delivery.processing = false;
    }).finally(function () {
      CacheStorage.remove('Toolbar-userData');
    });
  };

  function setShippingInformation() {
    // Start off by using the billing address
    var shippingAddressNo = $scope.delivery.selectedBillingAddress.address_no;

    // Check if we're not using billing address
    if (!$scope.delivery.userIsGuest && !$scope.delivery.useBillingAddress) {
      // Use the selected shipping address
      shippingAddressNo = $scope.delivery.selectedShippingAddress.address_no
    }

    return TessituraSDK.SetShippingInformation({
      iAddress_no: shippingAddressNo,
      iShippingMethod: $scope.delivery.selectedShippingMethod.id
    });
  }

  function addressString(address) {
    var parts = [];

    parts.push(address.street1);

    if (angular.isString(address.street2) && address.street2) {
      parts.push(address.street2);
    }

    parts.push(address.city);

    if (angular.isString(address.state) && address.state) {
      parts.push(address.state);
    }

    parts.push(address.postal_code);

    if(address.hasOwnProperty('country_long')){
      parts.push(address.country_long);
    }else{
      parts.push(address.country);
    }

    return parts.join(', ');
  };


  function autofillFromZip(address) {
    address.addressFindError = false;

    TessituraSDK.FindByPostCode({
      zip: address.sPostalCode,
      country: "USA"
    })
    .then(function (response) {
      var location = response.data.result;
      address.sCity = location.city;
      address.sStateProv = location.state;
    })
    .catch(function (){
      address.addressFindError = true;
    });
  }

  function addBillingAddress (isValid){
    if(isValid){

      var promises = [];

      if ($scope.delivery.userIsGuest) {
        $scope.delivery.newBillingAddress.iAddressType = appConfig.guestCheckoutAddressType;
        $scope.delivery.newBillingAddress.bPrimary = 'false';
      }

      promises.push(TessituraSDK.UpdateAddressEx($scope.delivery.newBillingAddress));

      // Update the salutation
      if (!$scope.delivery.availableAddresses.length) {
        var params = {
          bUpdateSalutation: 'true'
        };

        if ($scope.delivery.userIsGuest || $scope.delivery.nameRequired) {
          params.sFirstName = $scope.delivery.guestCheckoutNames.first;
          params.sLastName = $scope.delivery.guestCheckoutNames.last;
        }

        promises.push(TessituraSDK.UpdateAccountInfoEx2(params));
      }
      // Add the new address using what we've just given

      $q.all(promises)
      .then(function (responses) {
        var address = responses[0].data.result.UpdateAddressExResult.Address;
        address.toString = addressString(address);
        $scope.delivery.availableAddresses.push(address);
        $scope.delivery.selectedBillingAddress = address;
        $scope.delivery.selectedShippingAddress = address;
        $scope.delivery.addNewBillingAddress = false;
        resetNewBillingAddress();
      })
      .catch(function (error) {
        return $q.reject(error.data.error);
      })
      .finally(function () {
        $scope.delivery.verifyingAddress = false;
        $scope.delivery.verification = false;
        $scope.delivery.continueWithoutVerification = false;
      });
    }
  }


  function addShippingAddress (isValid){
    if(isValid){

      if ($scope.delivery.userIsGuest) {
        $scope.delivery.newShippingAddress.iAddressType = appConfig.guestCheckoutAddressType;
      }

      // Add the new address using what we've just given
      var promise = TessituraSDK.UpdateAddressEx($scope.delivery.newShippingAddress)
      .then(function (response) {
        var address = response.data.result.UpdateAddressExResult.Address;
        address.toString = addressString(address);
        $scope.delivery.availableAddresses.push(address);
        $scope.delivery.selectedShippingAddress = address;
        $scope.delivery.addNewShippingAddress = false;
        resetNewShippingAddress();
      })
      .catch(function (error) {
        return $q.reject(error.data.error);
      })
      .finally(function () {
        $scope.delivery.verifyingAddress = false;
        $scope.delivery.verification = false;
        $scope.delivery.continueWithoutVerification = false;
      });

    }
  }

  function resetNewBillingAddress(){
    // Object for new billing address
    $scope.delivery.newBillingAddress = {
      sPhone: null,
      iCountry: null,
      sStreet1: '',
      sStreet2: '',
      sPostalCode: '',
      sCity: '',
      sStateProv: '',
      iAddressType: 3,
      bPrimary: 'true',
      bSaveCopyOnChange: 'true'
    };
  }

  function resetNewShippingAddress(){
    // Object for new shipping address
    $scope.delivery.newShippingAddress = {
      iCountry: null,
      sStreet1: '',
      sStreet2: '',
      sPostalCode: '',
      sCity: '',
      sStateProv: '',
      iAddressType: 3
    };
  }


  /**
   * Load the config from the JSON object
   * @return {Object} {
   *         singleTicketPurchase: [1,2,3],
   *         <string modeName>: [ <int id of shipping method>, ...]
   * }
   */
  function getDeliveryConfig() {
    // Load and parse the json
    var json = document.getElementById('delivery-config').innerHTML;
    var config = angular.fromJson(json);

    var deliveryConfig = {};

    // Map the methods from string names to delivery ids
    angular.forEach(config.modes, function (deliveryTypes, methodName) {
      var deliveryTypeIds = deliveryTypes.map(function (deliveryType) {
        return config.methods[deliveryType];
      });

      deliveryConfig[methodName] = deliveryTypeIds;
    });

    return deliveryConfig;
  }

  function verifyAddress(mode) {
    var address = mode == 'billing' ? $scope.delivery.newBillingAddress : $scope.delivery.newShippingAddress;

    $scope.delivery.verifyingAddress = true;

    var fields = [];

    for (var k in address) {
      if (verificationFields.indexOf(k) === -1) {
        continue;
      }

      if (angular.isString(address[k]) && address[k].length) {
        fields.push(address[k]);
      }
    }

    return AddressService.verify({
      search: fields,
      country: 'USA'
    })
    .then(function (verification) {
      if (verification === true) {
        if (mode == 'billing') {
          return $scope.delivery.addBillingAddress(true);
        }

        return $scope.delivery.addShippingAddress(true);
      }

      if (!verification.items.length) {
        $scope.delivery.continueWithoutVerification = true;
        return true;
      }

      $scope.delivery.verification = verification;
    })
    .finally(function () {
      $scope.delivery.verifyingAddress = false;
    });
  }

  function useVerifiedAddress(item, mode) {
    var address = mode == 'billing' ? $scope.delivery.newBillingAddress : $scope.delivery.newShippingAddress;

    return AddressService.get({
      moniker: item.Moniker
    })
    .then(function (response) {
      for (var k in response) {
        address[k] = response[k];
      }

      if (mode == 'billing') {
        $scope.delivery.newBillingAddress = address;
      } else {
        $scope.delivery.newShippingAddress = address;
      }
    })
    .finally(function () {
      if (mode == 'billing') {
        return $scope.delivery.addBillingAddress(true);
      }

      return $scope.delivery.addShippingAddress(true);
    });
  }
}]);
