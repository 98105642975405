app.controller('SubLineItemSelectorModalController', ['$modalInstance', '$scope', 'Notifications', 'title', 'subLineItems', 'action', function ($modalInstance, $scope, Notifications, title, subLineItems, action) {
  // Scope variables
  $scope = angular.extend($scope, {
    title: title,
    subLineItems: subLineItems,
    loading: false,
    selectedSubLineItems: [],
    confirm: confirm,
    close: $modalInstance.close
  });

  function confirm() {
    var selectedSubLineItems = [];

    // Map the index to the actual object
    for (var i in $scope.selectedSubLineItems) {
      if ($scope.selectedSubLineItems[i] === true) {
        selectedSubLineItems.push(subLineItems[i]);
      }
    }

    if (angular.isFunction(action)) {
      $scope.loading = true;

      action(selectedSubLineItems)
      .then($modalInstance.close)
      .catch(function () {
        alert('Sorry, we can not ' + title.toLowerCase() + ' your tickets right now');

        $modalInstance.dismiss();
      });
    } else {
      $modalInstance.close(selectedSubLineItems);
    }
  };
}]);
