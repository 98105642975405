var toggle_btn_selector = '[data-toggle]';

var elements = document.querySelectorAll(toggle_btn_selector);

elements.forEach(function(element) {
    var targetElement = document.querySelector(element.dataset.toggle);

    if (!targetElement) {
        return;
    }
    if (element.toggleInitialized) {
        return;
    }

    var bodyClass = element.dataset.toggleBodyClass;
    var toggleClass = element.dataset.toggleClass || 'is-toggled';

    var focusElement = document.querySelector(element.dataset.toggleFocus);

    element.addEventListener('click', function() {
        if (targetElement.dataset.toggleOnce && targetElement.classList.contains('is-toggled')) {
            return;
        }

        var isToggled = element.classList.contains(toggleClass);

        element.innerHTML = isToggled ? 'Read More' : 'Read Less' ;

        var closeElement = document.querySelector(element.dataset.toggleClose);

        if (closeElement && closeElement.classList.contains(toggleClass)) {
            closeElement.click();
        }

        targetElement.classList.toggle(toggleClass);
        element.classList.toggle(toggleClass);

        element.setAttribute('aria-expanded', isToggled ? 'false' : 'true');
        targetElement.setAttribute('aria-hidden', isToggled ? 'true' : 'false');

        if (bodyClass) {
            document.body.classList.toggle(bodyClass);
        }

        if (!isToggled && focusElement) {
            focusElement.focus();
        }
    }, false);

    // Prevents the element from be initialized again
    element.toggleInitialized = true;
});
