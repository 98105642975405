app.service('APIService', ['TessituraSDK', function (TessituraSDK) {
  // Cache for API calls
  var calls = {};

  return {
    call: call,
    post: post
  };

  function call(method, params) {
    // Do we have params?
    params = params || {};

    // Make a cache key using method name and hash of params
    var key = method + btoa(angular.toJson(params));

    // Make a call if not already being made
    if (!(key in calls)) {
      // Store the promise in an array
      calls[key] = TessituraSDK[method](params);
    }

    // Return the promise
    return calls[key];
  };

  function post(method, params) {
    return TessituraSDK[method](params);
  };
}]);
