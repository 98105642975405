app.controller('PromoCodeController', ['$scope', '$attrs', '$sce', '$window', '$filter', 'TessituraSDK', 'GetParameters', 'AuthenticationService', 'PromoCodeService', 'MosSwitcher', 'Cart', function ($scope, $attrs, $sce, $window, $filter, TessituraSDK, GetParameters, AuthenticationService, PromoCodeService, MosSwitcher, Cart) {
  'use strict';
  var
    queryCode = GetParameters['code'],
    force = GetParameters['force'],
    redirect = GetParameters['redirect'],
    removeClicked = false;

  var widgetConfig = document.getElementById('widget-config');

  if (widgetConfig) {
    widgetConfig = JSON.parse(widgetConfig.innerHTML);
  } else {
    widgetConfig = [];
  }

  $scope.promocode = {
    processing: true,
    code: '',
    apply: apply,
    remove: remove,
    error: false,
    loaded: false,
    applied: false,
    details: null
  };

  $scope.$watch(function() {
    return $scope.promocode.code;
  }, watchPromoCode);

  MosSwitcher.notifyOfMos()
  .then(function () {
    return AuthenticationService.getLoginInfo();
  })
  .then(function (loginInfo) {
    if (force === 'add' && queryCode) {
      return forceApply();
    }

    if (force === 'remove') {
      return forceRemove();
    }

    if (queryCode || 'applied_promo_code' in loginInfo) {
      if ('applied_promo_code' in loginInfo) {
        $scope.promocode.applied = true;
        $scope.promocode.code = loginInfo.applied_promo_code.toUpperCase();
      } else {
        $scope.promocode.code = queryCode.toUpperCase();
      }

      return loadPromoCode();
    }

    $scope.promocode.processing = false;
  });

  function apply() {
    if (removeClicked) {
      return false;
    }

    $scope.promocode.error = false;

    if ($scope.promocode.loaded) {
      $scope.promocode.processing = true;

      return PromoCodeService.addPromoCode($scope.promocode.details).then(function (response) {
        $scope.promocode.processing = false;
        $scope.promocode.applied = true;
      });
    }

    return loadPromoCode();
  }

  function remove() {
    removeClicked = true;
    $scope.promocode.error = false;

    return MosSwitcher.removePromoCode().catch(function () {
      removeClicked = false;
    });
  }

  function loadPromoCode() {
    $scope.promocode.processing = true;
    $scope.promocode.error = false;

    return PromoCodeService.loadPromoCode($scope.promocode.code.toUpperCase()).then(function (response) {
      $scope.promocode.details = response;

      var
        content = $scope.promocode.details.text2 || '',
        contentOverride = getPromoCodeConfig();

      $scope.promocode.details.text2 = $sce.trustAsHtml(content);

      $scope.promocode.details.extraText = contentOverride ? $sce.trustAsHtml(contentOverride.Description) : false;

      $scope.promocode.processing = false;
      $scope.promocode.loaded = true;

      $scope.promocode.code = response.promo_code.toUpperCase();
    })
    .catch(displayError);
  }

  function forceApply() {
    return Cart.clear()
    .then(function () {
      return TessituraSDK.ApplyPromoCode({
        promoCode: queryCode
      });
    })
    .then(function () {
      if (redirect) {
        $window.location = redirect;
      } else {
        $window.location.reload();
      }
    });
  }

  function forceRemove() {
    return Cart.clear()
    .then(function () {
      return TessituraSDK.RemovePromoCode();
    })
    .then(function () {
      return MosSwitcher.defaultMos();
    })
    .then(function () {
      if (redirect) {
        $window.location = redirect;
      } else {
        $window.location.reload();
      }
    });
  }

  function addCacheBuster(redirect) {
    if (new RegExp(/\?+/g).test(redirect)) {
      return redirect + '&cb=' + Date.now();
    }

    return redirect + '?cb=' + Date.now();
  }

  function getPromoCodeConfig() {
    if (!widgetConfig.length) {
      return false;
    }

    return $filter('find')(widgetConfig, function (config) {
      return (
        // There's a matching promo code
        angular.isString(config.PromoCodes) &&
        config.PromoCodes.toUpperCase().indexOf( $scope.promocode.code.toUpperCase() ) !== -1 &&
        // There's content for it
        angular.isString(config.Description) &&
        config.Description.length !== 0
      );
    });
  }

  function watchPromoCode(newValue, oldValue) {
    if (!oldValue.length && newValue.length) {
      return false;
    }

    if (oldValue.toUpperCase() === newValue.toUpperCase()) {
      return false;
    }

    $scope.promocode.loaded = false;
    $scope.promocode.details = null;
  }

  function displayError(message) {
    $scope.promocode.error = message;
    $scope.promocode.processing = false;
  }
}]);
