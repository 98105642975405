app.service('UserMessagingService', ['$q', '$location', '$rootScope', '$cookies', 'TessituraRESTSDK', 'TessituraSDK', 'CacheStorage', 'Notifications', '$modal', 'appConfig', '$sce', 'Router', '$filter', 'AuthenticationService', function ($q, $location, $rootScope, $cookies, TessituraRESTSDK, TessituraSDK, CacheStorage, Notifications, $modal, appConfig, $sce, Router, $filter, AuthenticationService) {
  'use strict';

  var URLMatchRegExp = /(\/?[\w-]+)(\/[\w-]+)*\/?|(((http|ftp|https):\/\/)?[\w-\*]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?)/i;

  function getMessageSummary() {
    return TessituraRESTSDK.get('/ReferenceData/PricingRuleMessageTypes/Summary', {});
  }

  function parseCartMessagesResponse(response) {
    response = response.data.result;

    if (!response) {
      return [];
    }

    return response;
  }

  function getMessageCacheKey(message) {
    return 'dont-show-user-message-' + message.Id;
  }

  function loadMessage(message) {
    var notification;
    try {
      notification = JSON.parse(message.Message);
    } catch(e) {
      notification = {
        message: message
      };
    }

    var cacheKey = getMessageCacheKey(message);

    if (CacheStorage.has(cacheKey) == false) {
      notification.onClose = function () {
        CacheStorage.set(cacheKey, true, 3600 * 1000);
      }
      Notifications.create(notification, 'userMessages-' + message.Id);
    }
  }

  function loadAlert(message) {
    var cacheKey = getMessageCacheKey(message);
    if (CacheStorage.has(cacheKey) == false) {
      loadMessage(message);
      // We only should a message once, so if it doesnt have a cache key
      // show it and then set the cache to true.
      CacheStorage.set(cacheKey, true, 3600 * 1000);
    }
  }

  function loadUserMessages() {

    if (!Router.isCurrentRoute('booking.basket')) {
      return;
    }

    getCartMessages()
    .then(function (messages) {
      for (var i in messages) {
        var message = messages[i];

        switch(message.MessageType.Description) {
          case "Alert":
          //   loadAlert(message);
          //   break;
          // case "Message":
            loadMessage(message);
            break;
        }
      }
    });
  }


  function getMessageTypes() {
    return getMessageSummary()
    .then(function (response) {
     // We now need to fetch the actual messages
      var messageTypes = [];
      var options = {};
      response = parseMessageTypesResponse(response);

      for (var n = 0; n < response.length; n++) {
        messageTypes.push(response[n].Id);
      }

      return messageTypes;
    });
  }

  function parseMessageTypesResponse(response) {
    response = response.data.result;

    if (!response) {
      return [];
    }

    response = response.filter(function (item) {
      return item.Inactive === false;
    });

    return response;
  }


  function getCartMessages() {
    return getMessageTypes()
    .then(function (messageTypes) {
      if (!$cookies.get('sessionkey')) {
        return;
      }

      return TessituraRESTSDK.get('/Web/Cart/' + $cookies.get('sessionkey') + '/Messages?messageTypes=' + messageTypes)
      .then(function (messages) {
        return parseCartMessagesResponse(messages);
      });
    });
  }

  function initNotificationMessages() {
    return AuthenticationService.isUserRegistered()
    .then(function (isUserRegistered) {
      if (!isUserRegistered) {
        return $q.resolve([]);
      }

      return TessituraSDK.CreateUserMessages()
      .then(function () {
        return getAccountInfo();
      })
      .then(function (accountInfo) {
        return TessituraSDK.GetUserMessages().then(function (response) {
          var
            result = response.data.result.ExecuteLocalProcedureResults,
            messages = $filter('arrayWrap')(result.LocalProcedure);

          messages = $filter('orderBy')(messages, 'read_ind');

          messages = messages.filter(function (message) {
            return message.delete_ind == 'N';
          });

          messages.forEach(function (message, i) {
            for (var k in accountInfo) {
              if (message.message_text.match('{%' + k + '%}')) {
                messages[i].message_text = message.message_text.replace('{%' + k + '%}', accountInfo[k]);
              }
            }
          });

          messages.forEach(function (message) {
            if (message.delete_ind == 'Y') {
              return;
            }

            var name = 'message-' + message.message_id;

            if (Notifications.exists(name) || message.read_ind == 'Y') {
              return;
            }

            var params = {
              message: message.message_text,
              closeable: true,
              container: 'notifications',
              style: 'info',
              sticky: true,
              location: message.url_pattern,
              meta: {
                message_id: message.message_id
              },
              onClose: function (alert, UserMessagingService) {
                UserMessagingService.readUserMessage(alert.meta.message_id);
              }
            };

            Notifications.create(params, name);
          });

          return $q.resolve(messages);
        });
      });
    });
  }

  function getAccountInfo() {
    return TessituraSDK.GetAccountInfo().then(function (response) {
      return $q.resolve(response.data.result.GetAccountInfoResults.AccountInformation);
    });
  }

  function readUserMessage(messageId) {
    return TessituraSDK.ReadUserMessage({
      message_id: messageId,
      delete: 0
    }).then(function () {
      Notifications.remove( 'message-' + messageId );

      $rootScope.$broadcast('notification.updated');
    });
  };

  function deleteUserMessages(messages) {
    if (!messages || !messages.length) {
      return $q.resolve();
    }

    var promises = messages.map(function (messageId) {
      return TessituraSDK.ReadUserMessage({
        message_id: messageId,
        delete: 1
      }).then(function () {
        Notifications.remove( 'message-' + messageId );
      });
    });

    return $q.all(promises);
  };

  return {
    getCartMessages: getCartMessages,
    loadUserMessages: loadUserMessages,
    initNotificationMessages: initNotificationMessages,
    readUserMessage: readUserMessage,
    deleteUserMessages: deleteUserMessages
  };
}]);
