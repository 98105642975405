
app.directive('addressSelector', ['$sce', 'appConfig', function ($sce, appConfig) {
  return {
    controller: 'AddressSelectorController',
    templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'address-selector.html'),
    scope: {
      getAddressFunction: '=',
      loading: '=',
      ngRequired: '=',
      addressName: '@'
    }
  };
}]);


app.controller('AddressSelectorController', ['$scope', '$q', '$filter', 'TessituraSDK', function ($scope, $q, $filter, TessituraSDK) {

  // Promises to wait for to consider the page "loaded"
  var loadingPromises = [];
  // Used for syntactic sugar bellow
  var promise;
  // Address template for new addresses
  var newAddressTemplate  =  {
  };

  // Update template for new addresses
  var updateAddressTemplate  =  {
    sStreet1: '',
    sStreet2: '',
    sCity: '',
    sStateProv: '',
    sPostalCode: '',
    iCountry: 0,
    iAddressNumber: 0,
    iAddressType: 3,
    bPrimary: 'false',
    sMonths: '',
    sInActive: '',
    bSaveCopyOnChange: 'false',
    sMailPurposes: ''
  };

  $scope.loading = true;
  $scope.newAddress = null;
  $scope.selectedAddress = null;
  $scope.errorAddingAddress = false;

  /**
   * Function thats passed to the parent of the directive
   * to return the address the user wants
   * @return {[type]} [description]
   */
  $scope.getAddressFunction = function (saveNewAddress) {
    if (saveNewAddress === undefined) {
      saveNewAddress = true;
    }

    if ($scope.newAddress && saveNewAddress) {
      $scope.errorAddingAddress = false;

      var updateableAddress = convertConstituentInfoAddressToUpdateableAddress($scope.newAddress);
      return TessituraSDK.UpdateAddressEx(updateableAddress)
        .then(function (response) {
          return $q.resolve(response.data.result.UpdateAddressExResult.Address);
        })
        .catch(function () {
          $scope.errorAddingAddress = true;
          return $q.reject();
        });
    } else if ($scope.newAddress && saveNewAddress == false) {
      return $q.resolve(addCountryLongField($scope.newAddress));
    } else if (angular.isObject($scope.selectedAddress)) {
      return $q.resolve($scope.selectedAddress);
    } else {
      return $q.reject('No address chosen');
    }
  };

  /**
   * Reload all the addresses
   */
  function reloadAddresses(){
    var GetConstituentInfoExParams = {
      // return only address
      TableListTokens: 'AD'
    };

    return TessituraSDK.GetConstituentInfoEx(GetConstituentInfoExParams)
      .then(function (response) {
        if (response.data.result.GetConstituentInfoExResults) {
          $scope.addresses = response.data.result.GetConstituentInfoExResults.Addresses;
          $scope.addresses = $filter('arrayWrap')($scope.addresses);
          $scope.addresses = $filter('orderBy')($scope.addresses, '-primary_ind');
          $scope.addresses = $scope.addresses
            .filter(function (address) {
              // Filter out inactive addresses
              if (address.inactive == 'Y' ||
                  address.street1 == 'web added' ||
                  address.street1 == 'Guest street'
                ) {
                return false;
              };

              return true;
            });
          $scope.selectedAddress = $scope.addresses[0];
        } else {
          $scope.addresses = [];
          $scope.selectedAddress = null;
          createNewAddress();
        }
      })
      .catch(function () {
        $scope.addresses = [];
        $scope.selectedAddress = null;
        createNewAddress();
      });
  }
  loadingPromises.push(reloadAddresses());

  /**
   * Gets all the countries from the tessitura.
   * Also find the USA and make it the default for new adddresses
   */
  promise = TessituraSDK.GetCountries()
    .then(function (response) {
      var countryOptions = response.data.result.GetCountriesResults.Country;

      $scope.countryOptions = countryOptions.filter(function(country) {
        // Convert all ids to int (they was strings)
        country.id = parseInt(country.id);

        // If the USA then set it to default
        if(country.short_desc == 'USA') {
          newAddressTemplate.country = country.id;
        }

        // Filter out entires without a description
        return !!country.description;
      });
    });
  loadingPromises.push(promise);

  /**
   * Get all the states from the tessitura
   */
  promise = TessituraSDK.GetStateProvinceEx()
    .then(function (response) {
      var stateProvinceOptions;

      if ('GetStateProvinceResults' in response.data.result) {
        stateProvinceOptions = response.data.result.GetStateProvinceResults.StateProvince;
      } else {
        stateProvinceOptions = response.data.result.GetStateProvinceResultsEx.StateProvince;
      }

      // Filter out entires without a description
      $scope.stateProvinceOptions = stateProvinceOptions.filter(function(province) {
        return !!province.description;
      });
    });
  loadingPromises.push(promise);

  /**
   * Creates a new address
   */
  function createNewAddress() {
    $scope.newAddress = angular.copy(newAddressTemplate);
  }
  $scope.createNewAddress = createNewAddress;

  /**
   * Stops creating a new address
   */
  function cancelNewAddress(){
    $scope.newAddress = null;
  }
  $scope.cancelNewAddress = cancelNewAddress;

  /**
   * Returns the the string for the select
   */
  function addressString(address) {
    var parts = [];

    parts.push(address.street1);

    if (angular.isString(address.street2) && address.street2) {
      parts.push(address.street2);
    }

    parts.push(address.city);

    if (angular.isString(address.state) && address.state) {
      parts.push(address.state);
    }

    parts.push(address.postal_code);

    var country = getCountryFromId(address.country);
    if (country) {
      parts.push(country.short_desc);
    }

    return parts.join(', ');
  }

  $scope.addressString = addressString;


  /**
   * Converts an address object returned by GetConstituentInfoEx
   * into a object that could be saved using the UpdateAddressEx
   * function.
   *
   * @param  Addresses  address  Addresses from GetConstituentInfoEx
   * @return Object              Address that can be used in UpdateAddressEx
   */
  function convertConstituentInfoAddressToUpdateableAddress(address){
    // Take a new address template
    var newAddressObj = angular.copy(updateAddressTemplate);

    // Copy all attributes over into the new address
    if (address.address_no) {
      newAddressObj.iAddressNumber = parseInt(address.address_no);
    }

    if (address.address_type) {
      newAddressObj.iAddressType = parseInt(address.address_type);
    }

    if (address.inactive) {
      newAddressObj.sInActive = address.inactive == 'Y' ? 'true':'false';
    }

    if (address.mail_purposes) {
      newAddressObj.sMailPurposes = address.mail_purposes;
    }

    if (address.months) {
      newAddressObj.sMonths = address.months;
    }

    if (address.primary_ind) {
      newAddressObj.bPrimary = address.primary_ind == 'Y' ? 'true':'false';
    }

    if (!$scope.addresses.length) {
      newAddressObj.bPrimary = 'true';
    }

    newAddressObj.iCountry = parseInt(address.country);
    newAddressObj.sPostalCode = address.postal_code;
    newAddressObj.sStreet1 = address.street1;
    newAddressObj.sStreet2 = typeof address.street2 == 'string' ? address.street2:'';
    newAddressObj.sCity = address.city;
    newAddressObj.sStateProv = address.state;

    // Return the mapped address
    return newAddressObj;
  }

  /**
   * Adds the long country field to an addres
   * object from the country id
   */
  function addCountryLongField(address) {
    for (var i in $scope.countryOptions) {
      var country = $scope.countryOptions[i];
      if (address.country == country.id) {
        address.country_long = country.description;
        return address;
      }
    }

    return address;
  }

  function getCountryFromId(id) {
    for (var i in $scope.countryOptions) {
      var country = $scope.countryOptions[i];
      if (country.id == id) {
        return country;
      }
    }
  }


  function autofillFromZip() {
    $scope.addressFindError = false;

    TessituraSDK.FindByPostCode({
      zip: $scope.newAddress.post_code,
      country: "USA"
    })
    .then(function (response) {
      var location = response.data.result;
      $scope.newAddress.city = location.city;
      $scope.newAddress.state = location.state;
    })
    .catch(function (){
      $scope.addressFindError = true;
    });
  }
  $scope.autofillFromZip = autofillFromZip;

  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
    });

}]);
