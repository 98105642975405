(function () {
  'use strict';

  app.controller('NumberSelectorController', ['$scope', function ($scope) {
    validate();

    function reset() {
      if ($scope.min !== undefined) {
        $scope.ngModel = $scope.min;
      } else {
        $scope.ngModel = 0;
      }
    }

    function validate() {
      if ($scope.ngModel === undefined) {
        reset();
        return false;
      }

      return true;
    }

    $scope.decrement = function () {

      if (validate() && $scope.min === undefined || $scope.ngModel > $scope.min) {
        $scope.ngModel--;
      }

    };

    $scope.increment = function () {

      if (validate() && $scope.max === undefined || $scope.ngModel < $scope.max) {
        $scope.ngModel++;
      }
      
    };

    $scope.$watch('ngModel', function () {
      if ($scope.ngModel === null) {
        $scope.ngModel = $scope.min;
      }
    });
  }]);

  app.directive('numberSelector', function () {
    return {
      controller: 'NumberSelectorController',
      template:
          '<div class="input-group">' +
          '<div  ng-disabled="min >= ngModel" class="input-group-addon btn" ng-click="decrement()"><i class="fa fa-chevron-left" aria-hidden="true"></i></div>' +
          '<input class="form-control" ng-model="ngModel" type="number" min="{{min}}" max="{{max}}"  />' +
          '<div ng-disabled="max <= ngModel" class="input-group-addon btn" ng-click="increment()"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>' +
          '</div>',
      scope: {
        ngModel: '=',
        min: '=',
        max: '='
      }
    };
  });
}());
