/**
 * Loops over an array over object and returns
 * the sum of the attribute provided as the
 * first parameter.
 */
app.filter('route', ['Router', function (Router) {
  return function (routeName, options) {
    options = options || {};
    return Router.getUrl(routeName, options);
  };
}]);
