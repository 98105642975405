/* global trackEvent */

app.controller('AuthenticationController', ['$q', '$scope', '$attrs', 'TessituraSDK', '$window', '$filter', 'GetParameters', 'AuthenticationService', 'CacheStorage', 'Router', 'PromoCodeService', 'UserMessagingService', 'MosSwitcher', 'Cart', 'appConfig', function ($q, $scope, $attrs, TessituraSDK, $window, $filter, GetParameters, AuthenticationService, CacheStorage, Router, PromoCodeService, UserMessagingService, MosSwitcher, Cart, appConfig) {
  'use strict';


  var defaultCountry = 1;
  var recaptchaReady = false;
  var arrayWrap = $filter('arrayWrap')

  // Init recapctcha
  window.recaptchaOnLoad = function () {
    recaptchaReady = true;
  }

  /**
   * Stops safari saving the state for this page
   */
  window.onpageshow = function(event) {
      if (event.persisted) {
        $scope.auth.guestLoggingIn = false;
        $scope.auth.loggingIn = false;
        $scope.auth.registering = false;
      }

  };

  /**
   * [user description]
   * @type {Object}
   */
  $scope.auth = {
    loading: true,
    loggingIn: false,
    registering: false,
    guestLoggingIn: false,
    fbLoggingIn: false,
    login: false,
    passwordReset: false,
    user: {
      emailAddress: '',
      emailAddressConfirmation: '',
      firstName: '',
      lastName: '',
      password: '',
      newPassword: '',
      exists: false,
      isGuest: false,
      billing: {
        sPhone: null,
        sStreet1: '',
        sStreet2: '',
        sCity: '',
        sStateProv: '',
        sPostalCode: '',
        iCountry: null,
        iAddressType: 3,
        iAddressNumber: 0,
        bPrimary: 'true',
        bSaveCopyOnChange: 'false'
      }
    },
    next: next,
    checkingUser: false,
    displayRegisterForm: false,
    loginUser: loginUser,
    registerUser: registerUser,
    guestCheckout: guestCheckout,
    facebookLogin: facebookLogin,
    logoutUser: logoutUser,
    error: displayError,
    passwordStrength: 0,
    fbDisabled: true,
    resetPassword: resetPassword,
    passwordResetSent: false,
    invalidConfirmEmail: false,
    showRecaptcha: false,
    availableCountries: [],
    availableStates: [],
    loadCountries: loadCountries,
    loadStatesForCountry: loadStatesForCountry
  };

  var loginInfo = null;

  var currentPromoCode;

  var emailFields = document.querySelectorAll('.disable-copy-paste');

  $scope.onlyNumbers = '^[0-9]+$';

  /**
   * When the email changes hide the password field
   */
  $scope.$watch('auth.user.emailAddress', function (newVal, oldVal) {
    $scope.auth.user.exists = false;
    $scope.auth.displayRegisterForm = false;
    $scope.auth.login = false;
    $scope.auth.passwordReset = false;
  });

  /**
   * Watches the password field and updates the strength bar
   */
  $scope.$watch('auth.user.newPassword', function (newVal, oldVal) {
    $scope.auth.passwordStrength = getStrength(newVal);

    if($scope.auth.passwordStrength < 40){
      $scope.auth.passwordLabel = 'Weak';
    }else if($scope.auth.passwordStrength < 70){
      $scope.auth.passwordLabel = 'Medium';
    }else{
      $scope.auth.passwordLabel = 'Strong';
    }
  }, true);

  $scope.$watch('auth.user.emailAddressConfirmation', function (newVal, oldVal) {
    if (!newVal) {
      $scope.auth.invalidConfirmEmail = false;
    } else {
      $scope.auth.invalidConfirmEmail = (
        angular.isString(newVal) &&
        newVal.toLowerCase() !== $scope.auth.user.emailAddress.toLowerCase()
      );
    }
  }, true);

  // See if the user is already logged in
  AuthenticationService.isUserRegistered()
    .then(function (isUserRegistered) {
      if (isUserRegistered) {
        if (GetParameters['redirect-to'] != void(0)) {
          $window.location = GetParameters['redirect-to'];
        } else {
          $window.location = $attrs.loginSuccessUrl;
        }

        return false;
      }

      $scope.auth.fbDisabled = false;

      AuthenticationService.getLoginInfo()
      .then(function (loginInfoEx) {
        loginInfo = loginInfoEx;

        if (loginInfoEx && 'applied_promo_code' in loginInfoEx) {
          PromoCodeService.loadPromoCode(loginInfoEx.applied_promo_code)
          .then(function (promoCodeObj) {
            currentPromoCode = promoCodeObj;
          });
        }

        loadCountries();

        if (loginInfoEx.user_type == 'guest') {
          $scope.auth.user.emailAddress = loginInfoEx.UID;
          $scope.auth.user.isGuest = true;

          return next().finally(function () {
            $scope.auth.loading = false;
          });
        }

        // emailFields.forEach(function (input) {
        //   angular.element(input).on('cut copy paste', function (e) {
        //     e.preventDefault();
        //   });
        // });

        $scope.auth.loading = false;
      });
    });

  function shouldShowGuest() {
    return false;
    // if ($scope.auth.user.isGuest) {
    //   return false;
    // }

    // var directTo = GetParameters['redirect-to'];

    // if (!directTo || directTo.indexOf('/account/') > -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  $scope.shouldShowGuest = shouldShowGuest();


  /**
   * Opens the facebookLogin and binds the
   * onSucccessfulLogin function the callback
   */
  function facebookLogin(){
    $scope.auth.fbLoggingIn = true;
    AuthenticationService.facebookConnect()
      .then(function () {
        onSuccessfulLogin();
      })
      .catch(function (err) {
        displayError(err);
      });
  }

  /**
   * Redirects the user to the to the correct
   * location once they are logged in.
   */
  function onSuccessfulLogin(){
    var promise = $q.resolve();

    CacheStorage.remove('Toolbar-userData');

    if (currentPromoCode) {
      promise = PromoCodeService.addPromoCode(currentPromoCode, {redirect: false});
    }

    var trackingEvent = $scope.auth.registering ? 'sign_up' : 'login';
    var loginMethod;

    switch (true) {
        case $scope.auth.fbLoggingIn:
            loginMethod = 'Facebook';
            break;
        case $scope.auth.guestLoggingIn:
            loginMethod = 'Guest';
            break;
        case $scope.auth.loggingIn:
            loginMethod = 'Tessitura';
            break;
        case $scope.auth.registering:
            loginMethod = 'Tessitura';
            break;
        default:
            loginMethod = '<Unknown>';
            break;
    }

    trackEvent(trackingEvent, {
        method: loginMethod,
    });

    return promise
        .then(transferCart)
        .then(function () {
            // there was nothing to add to cart
            if (GetParameters['redirect-to'] != void(0)) {
                $window.location = GetParameters['redirect-to'];
            } else {
                $window.location = $attrs.loginSuccessUrl;
            }
        });
  }

  function transferCart() {
    var deferred = $q.defer();

    Cart.isCartEmpty().then(function (isCartEmpty) {
      if (isCartEmpty) {
        deferred.resolve();
      } else {
        TessituraSDK.TransferCart().then(function () {
          deferred.resolve();
        }).catch(function () {
          deferred.resolve();
        });
      }
    });

    return deferred.promise;
  };

  /**
   * Trigger for the next button when showing email
   */
  function next() {
    $scope.auth.user.exists = false;
    $scope.auth.displayRegisterForm = false;
    $scope.auth.checkingUser = true;
    $scope.auth.login = false;

    $scope.auth.showRecaptcha = true;

    var deferred = $q.defer();

    var interval = setInterval(function () {
      if (recaptchaReady === false) {
        return;
      }

      clearInterval(interval);

      var oldRecaptchaDiv = document.getElementById('authentication-recaptcha');

      if (oldRecaptchaDiv) {
        var recaptchaDiv = document.createElement('div');
        recaptchaDiv.id = 'authentication-recaptcha';

        oldRecaptchaDiv.parentElement.replaceChild(recaptchaDiv, oldRecaptchaDiv);
      }

      window.grecaptcha.render('authentication-recaptcha', {
        sitekey: appConfig.reCaptchaSiteKey,
        callback: function (recaptchaResponse) {
          TessituraSDK.CheckUserExists({
            email: $scope.auth.user.emailAddress,
            token: recaptchaResponse
          }).then(function (response) {

            if (response.data.result.action === 'login') {
              $scope.auth.user.exists = true;
              $scope.auth.login = true;
            } else if (response.data.result.action === 'password_reset') {
              $scope.auth.user.exists = true;
              $scope.auth.passwordReset = true;
            } else {
              $scope.auth.displayRegisterForm = true;
            }

            $scope.auth.checkingUser = false;

            window.grecaptcha.reset();
            $scope.auth.showRecaptcha = false;

            deferred.resolve(true);
          }).catch(function () {
            displayError('There was an error processing your request, please try again');

            window.grecaptcha.reset();
            $scope.auth.showRecaptcha = false;

            deferred.reject(false);
          });
        }
      });
    }, 100);

    return deferred.promise;
  };

  /**
   * Reads the form to log the user in.
   * Then redirects the user to the login-success-url attribute location.
   */
  function loginUser() {
    $scope.auth.loggingIn = true;
    $scope.auth.errorMessage = false;

    TessituraSDK.LoginEx2({
      sUID: $scope.auth.user.emailAddress,
      sPwd: $scope.auth.user.password,
      iPromotionCode: 0
    })
    .then(function (response) {
      var error = response.data.error;

      if (error) {
        $scope.auth.loggingIn = false;

        return displayError(error);
      }

      if (response.data.result != true) {
        $scope.auth.loggingIn = false;

        return displayError('Invalid email or password');
      }

      return onSuccessfulLogin();
    })
    .catch(function (){
      $scope.auth.loggingIn = false;
    });
  };

  /**
   * Reads the form to register the user
   * Then redirects the user to the login-success-url attribute location.
   */
  function registerUser() {
    $scope.auth.registering = true;
    //trigger Tessitura API call for user registration
    TessituraSDK.Register({
      sUID: $scope.auth.user.emailAddress,
      sPwd: $scope.auth.user.newPassword,
      iLoginType: 1,
      sEmail: $scope.auth.user.emailAddress,
      sFname: $scope.auth.user.firstName,
      sLname: $scope.auth.user.lastName
    }).then(function (response) {
      if (response.data.result == true) {
        return TessituraSDK.UpdateAddressEx($scope.auth.user.billing).then(function () {
          return onSuccessfulLogin();
        }).catch(function () {
          return onSuccessfulLogin();
        });
      }

      displayError('There was an error registering');
    })
    .catch(function () {
      $scope.auth.registering = false;
    });
  };

  /**
   * Handles guest checkout for users who do not wish to register.
   */
  function guestCheckout() {
    $scope.auth.guestLoggingIn = true;

    TessituraSDK.GuestLogin({
      sUID: $scope.auth.user.emailAddress,
      iPromotionCode: loginInfo.hasOwnProperty('OriginalPromoCode') ? loginInfo.promotion_code : 0
    }).then(function (response) {
      if (response.data.result == true) {
        onSuccessfulLogin();
        return;
      }

      displayError('There was an error logging in');
    })
    .catch(function () {
      $scope.auth.guestLoggingIn = false;
    });
  };

  function logoutUser() {
    TessituraSDK.Logout().then(function (response) {
      var error = response.data.error;

      if (error) {
        return displayError(error);
      }

      $window.location = $attrs.logoutSuccessUrl;
    });
  };

  function displayError(message) {
    $scope.auth.errorMessage = message;
  };

  /**
   * Calculates the "strength" of the password represented
   * as a number from 0-100
   * @param  String  pass The password to test
   * @return int          The result
   */
  function getStrength(pass) {
    var score = 0;

    if (!pass) {
      return score;
    }

    // award every unique letter until 5 repetitions
    var letters = {};
    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    }

    var variationCount = 0;

    for (var check in variations) {
      variationCount += (variations[check] == true) ? 1 : 0;
    }

    score += (variationCount - 1) * 10;

    if(score > 100) {
      score = 100;
    }

    return parseInt(score, 10);
  }


  function resetPassword() {
    TessituraSDK.RegisterFromGuest();

    $scope.auth.passwordResetSent = true;
  }

  function loadCountries() {
    return TessituraSDK.GetCountries().then(function (response) {
      var
        result = response.data.result,
        countries = arrayWrap(result.GetCountriesResults.Country);

      $scope.auth.availableCountries = countries;

      angular.forEach(countries, function (country) {
        if (country.id == defaultCountry && !$scope.auth.user.billing.iCountry) {
          $scope.auth.user.billing.iCountry = country.id;
        }
      });
    });
  };

  function loadStatesForCountry() {
    var countryId = $scope.auth.user.billing.iCountry;

    return TessituraSDK.GetStateProvinceEx({
      CountryIds: countryId || defaultCountry
    }).then(function (response) {
      var
        result = response.data.result,
        states = null;

      if ('GetStateProvinceResults' in result) {
        states = result.GetStateProvinceResults.StateProvince;
      } else {
        states = result.GetStateProvinceResultsEx.StateProvince;
      }

      $scope.auth.availableStates = states;
    });
  }
}]);
