/* global showdown */
app.controller('markdown', ['$scope', '$interval', function ($scope, $interval) {
  var hello = '### Hello, World! ';
  $scope.hello = hello;

  $scope.kitchen = "**Pellentesque habitant morbi tristique** senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. _Aenean ultricies mi vitae est_. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, `commodo vitae`, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum  rutrum orci, sagittis tempus lacus enim ac dui. [Donec non enim](#) in turpis pulvinar facilisis. Ut felis.";

  var counter = 0

  $interval(function () {
    $scope.hello = hello + counter++;
  }, 1000);
}]);

/**
 * Totally stole from ngBind
 * I'm not even hiding this fact
 */
app.directive('md', ['$sce', '$parse', '$compile', function ($sce, $parse, $compile) {
  var converter = new showdown.Converter();

  return {
    restrict: 'E',
    transclude: true,
    scope: true,
    template: '<span>' + 
                '<ng-transclude class="hide"></ng-transclude>' + 
                '<span ng-bind-html="html"><span>' + 
              '</span>',
    link: function link(scope, element, attrs, controller, transcludeFn) {

      var transElement = transcludeFn();

      scope.$watch(transElement[0].innerHTML, function (newValue, oldValue) {
        var markdown = newValue;
        var html = converter.makeHtml(markdown || '');
        scope.html = $sce.getTrustedHtml(html);
      });
    }


    // compile: function mdCompile(element) {
    //   var
    //     getter = $parse(element.text()),
    //     watch = $parse(element.text(), function getStringValue(value) {
    //       return (value || '').toString();
    //     });

    //   $compile.$$addBindingClass(element);

    //   return function mdLink(scope, element) {
    //     $compile.$$addBindingInfo(element, element.text());

    //     scope.$watch(watch, function watchAction() {
    //       var html = converter.makeHtml(getter(scope) || '');

    //       element.html($sce.getTrustedHtml(html));
    //     });
    //   };
    // }
  };
}]);
