app.service('MosSwitcher', ['$q', '$filter', '$window', '$cookies', 'appConfig', 'TessituraSDK', 'Notifications', 'AuthenticationService', 'Exchange', 'PromoCodeService', 'Cart', 'CacheStorage', 'Router', function ($q, $filter, $window, $cookies, appConfig, TessituraSDK, Notifications, AuthenticationService, Exchange, PromoCodeService, Cart, CacheStorage, Router) {

  var rejectReasons = {
    promocode: "Please complete your current transaction or remove the promo code to continue.",
    exchangeMode: "Please complete or cancel your exchange first.",
    ticketsInCart: "Please complete your purchase or empty your cart first.",
    other: "Sorry, we cannot fulfill your request. Please try again."
  };

  var constituencyModeOfSales = appConfig.constituencyModeOfSales;

  var arrayWrap = $filter('arrayWrap');

  return {
    attemptGeneralMos: attemptGeneralMos,
    generalMos: generalMos,
    defaultMos: defaultMos,
    subscriberMos: subscriberMos,
    customMos: customMos,
    notifyOfMos: notifyOfMos,
    removePromoCode: removePromoCode,
    getSaleMode: getSaleMode
  };

  function processPromocodeMouseover(promoDetails) {
    return '<div class="alert-notification__mouseover-message">' +
        ((promoDetails.text4 && promoDetails.text4.trim().length > 0) ? '<img src="' + promoDetails.text4 + '" />' : '') +
        '<h3>' + promoDetails.text1 + '</h3>' +
        ((promoDetails.text2 && promoDetails.text2.trim().length > 0) ? '<p>' + promoDetails.text2 + '</p>' : '') +
      '</div>';
  }

  function notifyOfMos() {
    return getSaleMode().then(function (mode) {
      var promoCode = false;
      var currentMessage;
      var removeMessage;

      var callToAction = {
        message: '(Switch to General)',
        onClick: function (MosSwitcher) {
          MosSwitcher.generalMos();
        }
      };

      var modeOfSale = 'General';

      if (mode.defaultMos == appConfig.fixedSubscriberMos || mode.defaultMos == appConfig.flexSubscriberMos) {
        modeOfSale = 'Subscriber';
      } else if(mode.defaultMos == appConfig.passportMos) {
        modeOfSale = 'Passport';
      }

      if (mode.title == 'subscriber') {
        // User is in package mos
        currentMessage = 'You are seeing availability and pricing for subscribers';

      } else if(mode.title == 'passport') {
        // User is in passport mos
        currentMessage = 'You are seeing availability and pricing for passports';

      } else if (mode.title == 'general') {
        // User is in standard mos
        currentMessage = 'You are seeing general availability and pricing';

        if (mode.mos == mode.defaultMos) {
          // Don't display sticky if general mode of sale
          // _is_ the users default mode of sale
          callToAction = false;
        } else {
          // Call to action to switch to default mode of sale
          callToAction = {
            message: '(Switch to ' + modeOfSale + ')',
            onClick: function (MosSwitcher) {
              MosSwitcher.defaultMos();
            }
          };
        }
      } else if (mode.title == 'exchange') {
        // We're in exchange mode of sale
        currentMessage = 'You are currently exchanging tickets for ' + mode.production;

        // Call to action to cancel exchange and go to default mode of sale
        callToAction = {
          message: '(Cancel and switch to ' + modeOfSale + ')',
          onClick: function (Cart, MosSwitcher) {
            Cart.clear().then(function () {
              MosSwitcher.defaultMos();
            });
          }
        };
      } else if ('promoCode' in mode) {
        return PromoCodeService.loadPromoCode(mode.promoCode).then(function (promoDetails) {
          // Some promo code
          currentMessage = 'You have the "'+ mode.promoCode +'" promo code applied';

          // Call to action to switch from promo code to default mode of sale
          callToAction = [
            {
              message: '(View details)' + processPromocodeMouseover(promoDetails),
              onClick: Router.getUrl('promo', {
                promoCode: mode.promoCode
              })
            }, {
              message: '(Remove and switch to ' + modeOfSale + ')',
              onClick: function (MosSwitcher) {
                MosSwitcher.removePromoCode();
              }
            }
          ];

          setNotification(currentMessage, callToAction);
        });
      } else {
        // User is in an unknown mode of sale
        // @todo figure out what message we want to display
        currentMessage = 'You are seeing availability and pricing for subscribers';
      }

      setNotification(currentMessage, callToAction);
    });
  }

  function setNotification(currentMessage, callToAction) {
    var options = {
      message: currentMessage,
      closeable: false,
      sticky: true
    };

    if (callToAction !== false) {
      options.cta = arrayWrap(callToAction);
    }

    if (callToAction === false) {
      Notifications.remove('sticky.mode_of_sale');
    } else {
      // Show sticky?
      Notifications.create(options, 'sticky.mode_of_sale');
    }
  }

  function attemptGeneralMos() {
    return changeMosIfNeeded(appConfig.generalMos);
  }

  function subscriberMos() {
    return changeMosIfNeeded(appConfig.fixedSubscriberMos);
  }

  function passportMos() {
    return changeMosIfNeeded(appConfig.passportMos);
  }

  function customMos(modeOfSale) {
    return changeMosIfNeeded(modeOfSale);
  }

  function generalMos() {
    return TessituraSDK.ChangeModeOfSaleEx({
      NewModeOfSale: appConfig.generalMos
    }).then(function () {
      return notifyOfMos();
    }).then(function () {
      $window.location.reload();
    })
    .catch(function () {
      reasonForMosSwitchFailure().then(function (reason) {
        alert(reason);
      });
    });
  }

  function defaultMos(graceful) {
    return AuthenticationService.getLoginInfo()
    .then(function (loginInfo) {
      if ('applied_promo_code' in loginInfo) {
        return $q.resolve(false);
      }

      return getModeOfSaleForUser()
    })
    .then(function (subscriberModeOfSale) {
      if (subscriberModeOfSale !== false) {
        return TessituraSDK.ChangeModeOfSaleEx({
          NewModeOfSale: subscriberModeOfSale
        });
      }

      return $q.resolve(true);
    })
    .then(function () {
      return notifyOfMos();
    })
    .then(function () {
      if (graceful) {
        return $q.resolve(true);
      }

      $window.location.reload();
    })
    .catch(function () {
      var promise = reasonForMosSwitchFailure();

      if (graceful === true) {
        return promise;
      }

      promise.then(function (reason) {
        alert(reason);
      });
    });
  }

  function removePromoCode() {
    var confirmAndClear = 'Your cart has items that will no longer be valid without ' +
        'the promo code so removing this will clear your cart. Do you wish to continue?';

    return Cart.confirmAndClear(confirmAndClear)
    .then(function () {
      return TessituraSDK.RemovePromoCode();
    })
    .then(function () {
      return defaultMos();
    })
    .then(function () {
      return notifyOfMos();
    })
    .then(function () {
      $window.location.reload();
    });
  }

  function changeMosIfNeeded(newMos) {
    return getSaleMode().then(function (saleMode) {
      // Check if the current modeOfSale is in a special mode (i.e. promo code)
      if ('promoCode' in saleMode && saleMode.promoCode) {
        return $q.resolve({
          status: true,
          mos: saleMode.mos,
          mode: saleMode.title,
          promoCode: saleMode.promoCode,
          priceTypes: saleMode.priceTypes,
          sessionKey: saleMode.sessionKey
        });
      }

      // Users will request general manually so don't do this
      // if user's default mode of sale is not general
      if (newMos == appConfig.generalMos && newMos != saleMode.defaultMos) {
        return $q.resolve({
          status: true,
          mos: saleMode.mos,
          mode: saleMode.title,
          sessionKey: saleMode.sessionKey
        });
      }

      // Check we arent already in the correct mode of sale
      if (saleMode.mos == newMos) {
        return $q.resolve({
          status: true,
          mos: saleMode.mos,
          mode: saleMode.title,
          sessionKey: saleMode.sessionKey
        });
      }

      // Finally, attempt to switch
      return TessituraSDK.ChangeModeOfSaleEx({
        NewModeOfSale: newMos
      }).then(function () {
        return $q.resolve({
          status: true,
          mos: newMos,
          mode: saleMode.title,
          sessionKey: saleMode.sessionKey
        });
      }).catch(function () {
        // There was an error
        return reasonForMosSwitchFailure().then(function (reason) {
          return $q.resolve({
            status: false,
            mos: saleMode.mos,
            reason: reason,
            mode: saleMode.title,
            sessionKey: saleMode.sessionKey
          });
        });
      });
    }).finally(function () {
      // Sort out that mode of sale sticky
      return notifyOfMos();
    });
  }

  function getSaleMode() {
    return $q.all([
      AuthenticationService.getLoginInfo(),
      getModeOfSaleForUser(),
      Exchange.getExchangeMode(),
      Cart.getPriceTypes()
    ]).then(function (responses) {
      var modeOfSale = parseInt(responses[0].MOS, 10);

      var exchangeMode = responses[2];

      var mode = {
        title: 'other',
        mos: modeOfSale,
        defaultMos: responses[1],
        sessionKey: $cookies.get('sessionkey'),
        canExchange: false,
        canDonate: false
      };

      if (modeOfSale == appConfig.fixedSubscriberMos || modeOfSale == appConfig.flexSubscriberMos) {
        mode.title = 'subscriber';
      } else if (modeOfSale == appConfig.passportMos) {
        mode.title = 'passport';
      } else if (modeOfSale == appConfig.generalMos) {
        mode.title = 'general';
      } else if (exchangeMode !== null && modeOfSale == exchangeMode.mode_of_sale) {
        mode.title = 'exchange';
        mode.production = exchangeMode.prod_desc;
      }

      if ('applied_promo_code' in responses[0]) {
        mode.promoCode = responses[0].applied_promo_code;
      }

      mode.priceTypes = responses[3];

      mode.canExchange = (
        mode.defaultMos == appConfig.fixedSubscriberMos ||
        mode.defaultMos == appConfig.flexSubscriberMos
      );

      mode.canDonate = (
        mode.defaultMos == appConfig.fixedSubscriberMos ||
        mode.defaultMos == appConfig.flexSubscriberMos ||
        mode.defaultMos == appConfig.passportMos
      );

      return $q.resolve(mode);
    });
  }

  function getModeOfSaleForUser() {
    var modeOfSale = appConfig.generalMos;

    return AuthenticationService.isUserRegistered().then(function (response) {
      if (response) {
        return TessituraSDK.GetConstituentInfoEx();
      }

      return $q.reject(false);
    }).then(function (response) {
      var
        result = response.data.result.GetConstituentInfoExResults,
        constituencies = arrayWrap(result.Constituency);

      if (!constituencies.length) {
        return $q.resolve(modeOfSale);
      }

      constituencies.forEach(function (constituency) {
        var
          constituencyId = parseInt(constituency.id, 10),
          newModeOfSale = getModeOfSaleForConstituency(constituencyId);

        if (newModeOfSale !== false) {
          modeOfSale = newModeOfSale;
        }
      });

      return $q.resolve(parseInt(modeOfSale, 10));
    })
    .catch(function (error) {
      return $q.resolve(modeOfSale);
    });
  }

  function getModeOfSaleForConstituency(userConstituency) {
    var newModeOfSale = false;

    angular.forEach(constituencyModeOfSales, function (constituencies, modeOfSale) {
      if (!newModeOfSale && constituencies.indexOf(userConstituency) !== -1) {
        newModeOfSale = modeOfSale;
      }
    });

    return newModeOfSale;
  }

  function reasonForMosSwitchFailure() {
    return $q.all([
      // Check if we're in exchange mode
      Exchange.isInExchangeMode(),
      // Check if we have a promo code applied
      PromoCodeService.hasPromocodeApplied(),
      // Check if we have items in cart
      Cart.hasTicketsOrPackageInCart()
    ]).then(function (results) {
      if (results[0]) {
        return rejectReasons['exchangeMode'];
      }

      if (results[1]) {
        return rejectReasons['promocode'];
      }

      if (results[2]) {
        return rejectReasons['ticketsInCart'];
      }

      return rejectReasons['other'];
    });
  }
}]);
