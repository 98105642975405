/* global moment */
app.filter('concatDateTime', ['$filter', function ($filter) {
  return function(input, format, options) {
    input = input || '';
    options = options || {};

    if(options.tbaText){
      return options.tbaText;
    }

    if(!moment(input).isValid()){
      return '';
    }

    var out = "",
        time = "",
        dateString = mmDateFunctions.mm_getMomentUTCDate(input);

        out = $filter('date')(dateString, format, 'UTC');

    if( options.showtime || options.showfulltime ){
      time = $filter('date')(dateString, "h:mma", 'UTC');
      time = time.toLowerCase();
      out = out + ' at ' + time;
    }

    return out;
  };
}]);

app.filter('concatTrueDateTime', ['$filter', function ($filter) {
  return function(input, format, options) {
    input = input || '';
    options = options || {};

    if(options.tbaText){
      return options.tbaText;
    }

    if(!moment(input).isValid()){
      return '';
    }

    var out = "",
        time = "",
        dateString = mmDateFunctions.mm_getMomentUTCDate(input);

        out = $filter('date')(input, format);

    if( options.showtime ){
      time = $filter('date')(input, "ha");
      time = time.toLowerCase();
      out = out + ' at ' + time;
    }

    if( options.showfulltime ){
      time = $filter('date')(input, "h:mma");
      time = time.toLowerCase();
      out = out + ' at ' + time;
    }

    return out;
  };
}]);

app.filter('dateUTC', ['$filter', function ($filter) {
  return function(input, format) {
    input = input || '';

    if(!moment(input).isValid()){
      return '';
    }

    var out = "",
        time = "",
        dateString = mmDateFunctions.mm_getMomentUTCDate(input);

    return $filter('date')(dateString, format, 'UTC');

  };
}]);


app.filter('dateDiff', function () {
  return  function (dateStr) {
    return moment(dateStr).diff();
  };
});

var mmDateFunctions = {
  // returns the date without the timezone offset based on the UTC offset
  // so that the date isn't influenced by the OS/browser timezone settings
  mm_getMomentUTCDate : function(input){

    var result = moment(
      moment(input, moment.ISO_8601).add(
        moment.parseZone(input).utcOffset(), 'minutes'
      )
    ).format();

    return result;

  }
};
