(function () {
  'use strict';


  app.directive('perfSearchDropdowns', ['$compile', function ($compile) {


    return {
      scope: {
        values : '=',
        update : '&',
        type: '=',
        watch: '='
      },
      link : function(scope, element){

        var cancel = scope.$watch('values', function(newVal){

          if(scope.values === undefined || scope.values.length === 0){
            return;
          }

          var htmlStr = [];
          for (var i = 0; i < scope.values.length; i++) {
            if(scope.values[i] !== undefined){
              var value = (scope.values[i].value) ? scope.values[i].value : i;
              htmlStr.push('<li><a href data-index="'+value+'">' + scope.values[i].title + '</a></li>');
            }
          }

          element = element.html(htmlStr.join(''));

          var links = element.find('a'),
              linksLength = links.length;

          var _scope = scope, index;

          for (var j = 0; j < linksLength; j++) {
            links[j].addEventListener("click", function(e){
              e.preventDefault();
              scope.update()({type:scope.type, vals:e.target.getAttribute('data-index')});
            }, false);
          }

          if(!scope.watch){
            cancel();
          }

        });

      },
      restrict: 'A'
    };
  }]);
}());
