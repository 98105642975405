app.controller('YapEducatorsConfernce', ['$q', '$scope', 'TessituraSDK', 'Router', '$filter', 'AuthenticationService', 'appConfig', '$timeout', 'ClickWrap', 'Cart', '$window', 'GetParameters', function ($q, $scope, TessituraSDK, Router, $filter, AuthenticationService, appConfig, $timeout, ClickWrap, Cart, $window, GetParameters) {

  var arrayWrap = $filter('arrayWrap');
  
  var widgetConfig = getWidgetConfig();

  angular.extend($scope, {
    // Indicators
    loading: true,
    formSaving: false,
    formSubmited: false,

    // Form
    educators: [],

    // Methods
    createEducator: createEducator,
    removeEducator: removeEducator,
    disableSubmit: disableSubmit,
    saveForm: saveForm
  });

  var
    orderNo = GetParameters['order_no'],
    perfNo = GetParameters['perf_no'],
    liSeqNo = GetParameters['li_seq_no'];

  loadSubjects()
  .then(function () {
    return TessituraSDK.GetCustomOrderDetails({
      order_no: orderNo
    }).then(function (response) {
      var result = response.data.result.ExecuteLocalProcedureResults;
      return {
        Order: result.LocalProcedure,
        LineItem: arrayWrap(result.LocalProcedure1),
        SubLineItem: arrayWrap(result.LocalProcedure2),
        SubLineItemFee: arrayWrap(result.LocalProcedure3),
        Fee: arrayWrap(result.LocalProcedure4),
        Contribution: arrayWrap(result.LocalProcedure5),
        Payment: arrayWrap(result.LocalProcedure6),
        PaymentPlan: arrayWrap(result.LocalProcedure7)
      };
    });
  })
  .then(function (orderDetails) {
    // Load the conferences from
    loadConferences(orderDetails.LineItem[0].prod_season_no);
    return loadGrades(orderDetails);
  })
  .then(function () {
    createEducator();
    $scope.loading = false;
  });

  function loadSubjects() {
    return TessituraSDK.SMaPSubjects()
    .then(extractLocalData)
    .then(function (subjects) {
      $scope.subjects = subjects;
    });
  }

  function loadConferences(productionId) {

    TessituraSDK.SMaPTeacherConferences()
    .then(extractLocalData)
    .then(function (allConferences) {
      $scope.conferences = allConferences
        .filter(function(conf) { return parseInt(conf.Production, 10) === parseInt(productionId, 10); });
    });
  }

  function loadGrades(orderDetails) {
    return TessituraSDK.SMaPGrades()
    .then(extractLocalData)
    .then(function (grades) {
      var package = grades.filter(function (grade) {
        return parseInt(grade.MOS, 10) === parseInt(orderDetails.Order.MOS, 10);
      });

      if (package.length) {
        package = package[0];
      } else {
        $scope.invalidMos = true;

        return false;
      }

      var gradeLevels = package.grade_levels.split(',').map(function (grade) {
        return {
          description: grade,
          gradeNum: parseInt(grade, 10),
          id: parseInt(grade, 10),
          packages: [package.pkg_no]
        };
      });

      gradeLevels = gradeLevels.sort(function (gradeA, gradeB) {
        return gradeA.gradeNum > gradeA.gradeNum ? 1 : -1;
      });

      $scope.grades = gradeLevels;
    });
  }

  function createEducator() {
    $scope.educators.push(newEducator());
  }

  function removeEducator(educator) {
    var location = $scope.educators.indexOf(educator);
    $scope.educators.splice(location, 1);
  }

  function newEducator() {
    return {
      name: "",
      subjectIds: {},
      gradeIds: {},
      email: "",
      phone: "",
      conference: {},
    };
  }

  function educatorNiceText(educator) {

    var selectedGrades = $scope.grades.filter(function (grade) {
      return educator.gradeIds[grade.id];
    });

    var grades = selectedGrades.map(mapDescription);

    var selectedSubjects = $scope.subjects.filter(function (subject) {
      return educator.subjectIds[subject.id];
    });

    var subjects = selectedSubjects.map(mapDescription);

    if (educator.customSubject) {
      subjects.push(educator.customSubjectText);
    }

    return [
      "Name: " + educator.name,
      "Email: " + educator.email,
      "Conference Date:" + $filter('concatDateTime')(educator.conference.Conference_Date, 'EEEE, MMMM d, yyyy'),
      "Phone: " + educator.phone,
      "Subjects: " + subjects.join(', '),
      "Grades: " + grades.join(', '),
    ].join("\n");
  }

  function mapDescription(obj) {
    return obj.description;
  }

  function saveForm() {
    $scope.formSaving = true;

    var educatorText = $scope.educators.map(educatorNiceText)

    educatorText.unshift("Performance Number: " + perfNo);

    educatorText.unshift("Order Number: " + orderNo);

    var csi = angular.extend({}, widgetConfig.csi, {
      PerformanceNumber: perfNo,
      Notes: educatorText.join("\n\n")
    });

    TessituraSDK.UpdateLineItemNote({
      orderNo: orderNo,
      liSeqNo: liSeqNo,
      notes: appConfig.yapRSVPCompleted
    })
    .then(function () {
      return TessituraSDK.AddCustomerServiceIssue(csi);
    })
    .then(function () {
      $scope.formSubmited = true;
      $timeout(function () {
        Router.goTo('user.account');
      }, 5000);
    })
    .catch(function (errorResponse) {
      $scope.error = errorResponse.data.error;
    })
    .finally(function () {
      $scope.formSaving = false;
    });

  }

  function disableSubmit() {
    return $scope.educators.length == 0;
  }

  function extractLocalData(response) {
    return arrayWrap(response.data.result.GetLocalDataResults.LocalData);
  }


  function getWidgetConfig() {
    var widgetConfig = document.getElementById('widget-config');

    if (widgetConfig) {
      widgetConfig = JSON.parse(widgetConfig.innerHTML);
    } else {
      widgetConfig = {};
    }

    return widgetConfig;
  }

}]);
