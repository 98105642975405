app.filter('find', ['$filter', function ($filter) {

  var filter = $filter('filter');

  return function (array, expression, comparator) {
    if (!array) {
      return null;
    }

    var results = filter(array, expression, comparator);

    if (angular.isArray(results) && results.length > 0) {
      return results[0];
    }

    return null;
  };
}]);
