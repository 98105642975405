app.controller('EventController', ['$scope', '$q', '$attrs', 'Router', 'EventService', 'DigestService', 'AuthenticationService', 'InventoryService', function ($scope, $q, $attrs, Router, EventService, DigestService, AuthenticationService, InventoryService) {
  'use strict';

  var prodSeasonNo = $attrs.prodSeasonNo || $scope.$parent.production.prod_season_no;
  var dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  var perf_no = null;
  var returnUrl = window.location.href;

  // Tell scope we're loading
  $scope = angular.extend($scope, {
    loading: true,
    event: null
  });

  AuthenticationService.getLoginInfo().then(function (loginInfo) {
    return $q.all([
      InventoryService.GetPerformancesForProduction({
        iModeOfSale: loginInfo.MOS,
        sStartDate: moment().startOf('hour').format(dateFormat),
        iProdSeasonID: prodSeasonNo
      })
    ])
    .then(function (responses) {
      var productionResult = responses[0];

      if (productionResult.Performance.length) {
        perf_no = productionResult.Performance[0].perf_no;
      }

      EventService.get(prodSeasonNo).then(function (event) {
        if (event.status === 'on-sale') {
          if (event.webContent['Web SYOS'] == 'Y') {
            if (perf_no) {
              event.bookingUrl = Router.getUrl( 'booking.syos', {
                perf_no: perf_no,
                facility_no: event.facilityNo,
                return_to: returnUrl
              });
            }
          } else {
            if(event.cmsFields.ProdSeasonNo){
              event.bookingUrl = Router.getUrl( 'booking.best_available.season', {
                prod_no: event.cmsFields.ProdSeasonNo
              });
            }
          }

          event.cmsUrl = Router.getUrl( 'cms.link', {
            prod_no: event.no
          });

          event.cmsImage = Router.getUrl( 'cms.image', {
            prod_no: event.no
          });
        }

        $scope.event = event;
        $scope.loading = false;

        /**
         * Pushes dataLayer data to be used for
         * Facebook Pixel ViewContent tracking.
         * @see https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/dynamic-product-ads
         */
        if (!!$attrs.facebookView) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'view_content',
            'content_ids': [event.no]
          });
        }

        var itemViewData = {
            item_id: prodSeasonNo,
            item_name: event.webContent['Long Title'],
        };

        if ($attrs.trackGTMView) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'item_view',
                items: [itemViewData]
            });
        }

        $scope.trackBuy = function() {
            window.dataLayer = window.dataLayer || [];
            var itemViewData = {
                item_id: prodSeasonNo,
                item_name: event.webContent['Long Title'],
            };
            window.dataLayer.push({
                event: 'select_item',
                items: [itemViewData]
            });
        }
      }, function (errResponse) {
        if (window.Raven && errResponse && errResponse.data) {
          Raven.captureException(errResponse.data.error);
        }
      });
    });
  }).catch(function(err) {
    console.log(err);
  })
}]);
