/* global moment */
(function (){

    function controller($q, $filter, $window, $location, TessituraSDK, TessituraRESTSDK, AuthenticationService, MosSwitcher, TicketManagementService, OrderService, Exchange, appConfig) {
        var vm = this;

        var find = $filter('find');
        var groupBy = $filter('groupBy');

        var searchParams = $location.search();
        var action =  searchParams && searchParams.action ? String(searchParams.action).toLowerCase() : null;
        var perfNo = searchParams && searchParams.perf_no ? parseInt(searchParams.perf_no, 10) : null;

        vm.loading = true;
        vm.error = false;
        vm.can_exchange = false;
        vm.can_donate = false;
        vm.event = null;
        vm.action = null;
        vm.selected = {};
        vm.passes = {};
        vm.terms = false;
        vm.recipient = '';
        vm.processing = false;
        vm.emailOrText = emailOrText;
        vm.exchangeOrDonate = exchangeOrDonate;

        if (!perfNo || !action) {
            vm.error = 'Invalid performance number or action.';
            vm.loading = false;
            return false;
        }

        vm.action = action;

        AuthenticationService.requireLogin().then(function () {
            return MosSwitcher.defaultMos(true);
        }).then(function () {
            return MosSwitcher.getSaleMode();
        }).then(function (saleMode) {
          vm.can_exchange = saleMode.canExchange;
          vm.can_donate = saleMode.canDonate;

          return TicketManagementService.getGroupedEvents();
        }).then(function (response) {
            vm.event = find(response, function (item) {
                return item.perf_no === perfNo;
            });
        }).then(function () {
            return preparePassbooks();
        }).finally(function () {
            vm.loading = false;
        });

        function preparePassbooks() {
            if (action !== 'download' || !vm.event) {
                return $q.resolve();
            }

            var orders = groupBy(vm.event.printed_seats, 'order_no');

            orders = Object.keys(orders).map(function (order) {
                return OrderService.PrintTicketsWithPassbook({
                    iOrderNo: order,
                    performanceNo: vm.event.perf_no,
                    subLineItems: orders[order].map(function (seat) {
                        return seat.sli_no
                    }).join(',')
                });
            });

            return $q.all(orders).then(function (responses) {
                responses.forEach(function (response) {
                    response.forEach(function (seat) {
                        vm.passes[seat.sli_no] = seat.url;
                    });
                });
            });
        }

        function getSelectedSli() {
            return Object.keys(vm.selected).filter(function (selected) {
                return vm.selected[selected];
            });
        }

        function getSelectedSeats() {
            var selectedSli = getSelectedSli();

            return vm.event.printed_seats.filter(function (item) {
                return selectedSli.indexOf(item.sli_no) !== -1;
            });
        }

        function emailOrText() {
            var selectedSli = getSelectedSli();

            if (selectedSli.length === 0) {
                return alert('Please select one or more tickets');
            }

            var recipient = vm.recipient.trim();

            if (action === 'email') {
                sendEmail(recipient);
            } else if (action === 'text') {
                sendText(recipient);
            }
        }

        function exchangeOrDonate() {
            var selectedSli = getSelectedSli();

            if (selectedSli.length === 0) {
                return alert('Please select one or more tickets');
            }

            vm.processing = true;

            if (action === 'exchange') {
                Exchange.exchangeTickets(vm.event.prod_season_no, selectedSli).finally(function () {
                    vm.processing = false;
                });
            } else if (action === 'donate') {
                OrderService.DonateTickets({
                    field: 'sSubLineItems',
                    tickets: selectedSli.join(',')
                }).then(function () {
                    $window.location.reload();
                }).finally(function () {
                    vm.processing = false;
                });
            }
        }

        function sendEmail(recipient) {
            if (!validateEmail(recipient)) {
                return alert('Please enter a valid email address');
            }

            vm.processing = true;

            var orders = getSelectedSeats();

            orders = groupBy(orders, 'order_no');

            orders = Object.keys(orders).map(function (order) {
                return TessituraRESTSDK.post('/Emails/ConstituentInfo/' + vm.event.customer_no + '/Send', {
                    TemplateId: appConfig.digital_tickets_template_id,
                    EmailAddress: recipient,
                    EmailProfileId: appConfig.digital_tickets_email_profile_id,
                    NameValues: [
                        {
                            Name: 'OrderId',
                            Value: order
                        },
                        {
                            Name: 'SubLineItems',
                            Value: orders[order].map(function (seat) {
                                return seat.sli_no
                            }).join(',')
                        }
                    ]
                });
            });

            $q.all(orders).then(function () {
                alert('The selected tickets have been successfully sent.');
                return writeCustomerServiceIssue('Email', recipient);
            }).catch(function () {
                alert('There was an issue sending the selected tickets. Please try again or contact Audience Services at 213.628.2772 if the problem persists.');
            }).finally(function () {
                vm.processing = false;
            });
        }

        function sendText(recipient) {
            if (!validatePhone(recipient)) {
                return alert('Please enter a valid phone number');
            }

            vm.processing = true;

            return TessituraSDK.SendMMS({
                sli_no: getSelectedSli().join(','),
                phone_no: recipient
            }).then(function (response) {
                var result = response.data.result;

                if (result !== true) {
                    return $q.reject(result);
                }

                return $q.resolve();
            }).then(function () {
                alert('The selected tickets have been successfully sent.');
                return writeCustomerServiceIssue('Text', recipient);
            }).catch(function (error) {
                if (error === 'INVALID_NUMBER') {
                    alert('The provided number appears to be invalid - please double check and try again.');
                } else if (error === 'NUMBER_NOT_REACHABLE') {
                    alert('The provided number cannot be reached at the moment - please try again later.');
                } else if (error === 'INTERNATIONAL_NUMBER_NOT_SUPPORTED') {
                    alert('Only national numbers are supported at the moment - please provide a USA number and try again.');
                } else if (error === 'BLOCKED_NUMBER') {
                    alert('The provided number is blocked, please try a different number.');
                } else if (error === 'NOT_A_MOBILE_NUMBER') {
                    alert('The provided number cannot recieve SMS/MMS messages, please try a different number.');
                } else {
                    alert('There was an issue sending the selected tickets. Please try again or contact Audience Services at 213.628.2772 if the problem persists.');
                }
            }).finally(function () {
                vm.processing = false;
            });
        }

        function writeCustomerServiceIssue(type, recipient) {
            var notes = [
                'Type: ' + type + "\n",
                'Recipient: ' + recipient + "\n\n",
                getSelectedSeats().map(function (item) {
                    return (
                        'Order No: ' + item.order_no + "\n" +
                        'Ticket No: ' + item.ticket_no + "\n" +
                        'SLI No: ' + item.sli_no + "\n" +
                        'Section: ' + item.zone_desc + "\n" +
                        'Row: ' + item.seat_row + "\n" +
                        'Seat: ' + item.seat_num + "\n"
                    );
                }).join("\n")
            ];

            var csi = angular.extend({}, getWidgetConfig().csi, {
                PerformanceNumber: perfNo,
                Notes: notes.join('')
            });

            return TessituraSDK.AddCustomerServiceIssue(csi);
        }

        function getWidgetConfig() {
            var widgetConfig = document.getElementById('widget-config');

            if (widgetConfig) {
                widgetConfig = JSON.parse(widgetConfig.innerHTML);
            } else {
                widgetConfig = {};
            }

            return widgetConfig;
        }

        function validateEmail(email) {
            var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (regex.test(email)) {
                return true;
            }

            return false;
        }

        function validatePhone(phone) {
            var regex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/;

            if (regex.test(phone)) {
                return true;
            }

            return false;
        }
    }

    app.controller('ManageTicketsGroupedController', [
        '$q', '$filter', '$window', '$location', 'TessituraSDK', 'TessituraRESTSDK', 'AuthenticationService', 'MosSwitcher', 'TicketManagementService', 'OrderService', 'Exchange', 'appConfig', controller
    ]);

}());
