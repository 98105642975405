/* global moment */
/* global TessituraMerchantServices */
app.controller('TMSSavedCards', ['$scope', 'TessituraSDK', '$filter', '$q', 'AuthenticationService', 'CacheStorage', function ($scope, TessituraSDK, $filter, $q, AuthenticationService, CacheStorage) {

    var arrayWrap = $filter('arrayWrap');
    var find = $filter('find');

    var defaultAddressStreet1 = 'web added';

    var fontAwesomeIcons = {
        '-2': 'credit-card', // Generic Credit Card
         '1': 'cc-visa', // Visa
         '2': 'cc-mastercard', // Master Card
         '3': 'cc-amex', // American Express
         '4': 'cc-discover', // Discover
         '6': 'cc-diners-club', // Diner\'s Club
         '8': 'cc-jcb' // JCB
    }

    function loadCards() {
        return TessituraSDK.TMSSavedCards().then(function (response) {
            $scope.cards = response.data.result.map(function (card) {
                if (card.AccountType.Id in fontAwesomeIcons) {
                    card.Icon = fontAwesomeIcons[card.AccountType.Id];
                } else {
                    card.Icon = fontAwesomeIcons['-2'];
                }

                card.Deleting = false;

                return card;
            });

            $scope.loading = false;
        }).catch(function () {
            $scope.loading = false;
        });
    }

    var tmsCache = null;

    function getPaymentComponent(url, checksum) {
        if (tmsCache) {
            return tmsCache;
        }

        var deferred = $q.defer();

        var script = document.createElement('script');

        script.onload = function () {
            deferred.resolve(true);
        }

        script.src = url;
        script.integrity = checksum;
        script.crossOrigin = 'anonymous';

        document.head.appendChild(script);

        tmsCache = deferred.promise;

        return tmsCache;
    }

    function getBillingAddress() {
        return TessituraSDK.GetConstituentInfoEx({ TableListTokens: 'AD' }).then(function (response) {
            var addresses = arrayWrap(response.data.result.GetConstituentInfoExResults.Addresses);

            return find(addresses, function (address) {
                return address.street1 != defaultAddressStreet1 && address.primary_ind == 'Y' && address.inactive == 'N';
            });
        });
    }

    $scope.loading = true;
    $scope.cards = [];
    $scope.processing = false;
    $scope.loadingPaymentProcessor = false;

    $scope.deleteCard = function (card) {
        $scope.processing = true;

        card.Deleting = true;

        return TessituraSDK.TMSDeleteCard({ id: card.Id }).then(function (response) {
            $scope.processing = false;

            if (response.data.error) {
                return $q.reject('Error deleting card');
            }

            return loadCards();
        }).catch(function () {
            window.alert('Error deleting card');
            card.Deleting = false;
            $scope.processing = false;
        });
    }

    $scope.addNewCard = function () {
        $scope.loadingPaymentProcessor = true;

        var payload = null;
        var authorizeUrl = null;
        var finalizeUrl = null;

        return getBillingAddress().then(function (billingAddress) {
            if (!billingAddress) {
                return $q.reject('You require a valid address associated with your account before you can add a new card. Please visit Manage Addresses to add an address before you continue.');
            }

            return TessituraSDK.TMSPaymentComponent({ mode: 'save-card' }).then(function (response) {
                if (!response.data.result) {
                    return $q.reject('Sorry, we cannot add a new card right now, please try again later.');
                }

                payload = response.data.result.Payload;
                authorizeUrl = response.data.result.AuthorizeUrl;
                finalizeUrl = response.data.result.FinalizeUrl;

                return getPaymentComponent(response.data.result.Url, response.data.result.Checksum);
            }).then(function () {
                var merch = new TessituraMerchantServices.PaymentComponent({
                    payload: payload,
                    endpoints: {
                        authorize: authorizeUrl,
                        finalize: finalizeUrl,
                    },
                    onSubmit: function (data) {
                        if (data.storeCard) {
                            CacheStorage.removeRegex(/^TMSSavedCards\-.*/);
                        }
                    },
                    locale: 'en-US',
                    countryCode: 'US',
                    enableStoredCards: false,
                    showAddress: 'none',
                    billingAddress: {
                        street: billingAddress.street1,
                        city: billingAddress.city,
                        stateOrProvince: billingAddress.state,
                        postalCode: billingAddress.postal_code,
                        country: billingAddress.country,
                    }
                });

                merch.mount('#payment-container');

                $scope.loadingPaymentProcessor = false;
            }).catch(function () {
                return $q.reject('Sorry, we cannot add a new card right now, please try again later.');
            });
        }).catch(function (error) {
            alert(error);

            $scope.loadingPaymentProcessor = false;
        });
    }

    // Check the user is a logged in valid user
    AuthenticationService.requireLogin()
    .then(AuthenticationService.isUserRegistered)
    // Check the user is logged in first
    .then(function (isUserRegistered) {
        if (!isUserRegistered) {
            $scope.userIsGuest = true;
            $scope.loading = false;
        } else {
            return loadCards();
        }
    });
}]);
