/* global moment */
app.controller('CheckoutController', ['$q', '$scope', '$filter', '$attrs', '$window', '$sce', '$modal', '$cookies', 'appConfig', 'TessituraSDK', 'Router', 'CacheStorage', 'Exchange', 'GoogleTagManager', function ($q, $scope, $filter, $attrs, $window, $sce, $modal, $cookies, appConfig, TessituraSDK, Router, CacheStorage, Exchange, GoogleTagManager) {
  var PaymentMethods,
    loadingPromises = [],
    promise,
    i,
    cartData,
    arrayWrap = $filter('arrayWrap');

  $scope.loading = true;
  $scope.cartData = {};

  $scope.paymentDetailsNeeded = true;

  // Will be populated later by order summary directive
  $scope.reloadOrderSummary = function(){};

  // Example cards, to be replaced with GetPaymentMethods
	$scope.cards = [
		{ id: 122, fa: 'cc-mastercard', cardtype: 'MasterCard', name: 'My Mastercard', ending : '5555', expires: '2017-03-01'},
		{ id: 123, fa: 'cc-visa', cardtype: 'Visa Card', name: 'Visa Joint Account', ending : '5150', expires: '2017-03-01'},
		{ id: 124, fa: 'cc-amex', cardtype: 'Amex Card', name: 'My American Express Card', ending : '8511', expires: '2017-03-01'}
	];

  // The currently chosen card

	$scope.chosencard = {
    id : undefined
  };

  // Expiry years to show in the select
  $scope.expiryYears = [];
  for (i = 0; i < 10; i++) {
    var year = moment().add(i, 'year');
    $scope.expiryYears.push({
      value: year.format('YYYY'),
      text: year.format('YY')
    });
  }

  // Expiry months to show in the select
  $scope.expiryMonths = [];
  for (i = 0; i < 12; i++) {
    var month = moment().month(i);
    $scope.expiryMonths.push({
      value: month.format('MM'),
      text: month.format('MMM')
    });
  }

  // Location to store the new card details
  $scope.newCard = {
    name: "",
    cardNumber: "",
    expiryYear: $scope.expiryYears[0].value,
    expiryMonth: $scope.expiryMonths[0].value,
    cvc: "",
  };

  // Hides the Process order button
  // Shows the loading icon
  $scope.processingOrder = false;

  // Place to store error message
  // If empty, error div doesnt show
  $scope.errorMessage = "";

  function checkoutWithPaymentDetails(amount){

    var CTGCheckoutParams = {
      sCCOwner: $scope.newCard.name,
      sCCNumber: $scope.newCard.cardNumber,
      iCCType: getICCTypeFromCardNumber($scope.newCard.cardNumber),
      iCCYear: parseInt($scope.newCard.expiryYear),
      iCCMonth: parseInt($scope.newCard.expiryMonth),
      dAmount: amount,
      sCardAuthenticationCode: $scope.newCard.cvc,
    };

    return TessituraSDK.CTGCheckout(CTGCheckoutParams);
  }


  function checkoutNoPaymentDetails(amount){
    return TessituraSDK.CTGCheckout();
  }


  // Completes the checkout process and sends it to the server
  $scope.processOrder = function () {
    $scope.processingOrder = true;
    $scope.errorMessage = "";

    TessituraSDK.GetCart()
    .then(function (response) {
      if (!(response.data.result.GetCartResults.Order && (response.data.result.GetCartResults.SubLineItem  || response.data.result.GetCartResults.PackageSubLineItem  || response.data.result.GetCartResults.Contribution || response.data.result.GetCartResults.Payment))) {
        $window.location = Router.getUrl('booking.basket');
        return;
      }

      var dAmount = response.data.result.GetCartResults.Order.balance_to_charge;
      dAmount = parseFloat(dAmount);

      if (dAmount > 0) {
        return checkoutWithPaymentDetails(dAmount);
      } else {
        return checkoutNoPaymentDetails(dAmount);
      }
    })
    .then(function (response) {
      // No need to listen for the return event
      // This will be a hundred times quicker then the tessi calls
      GoogleTagManager.sendCheckoutStep(3);
    
      // Get the a new session key
      return TessituraSDK.GetNewSessionKeyEx();
    })
    .then(function (response) {
      // Transfer the old session to the new key
      var TransferSessionParams = {
        sNewKey: response.data.result,
        sSessionKey: $cookies.get('sessionkey')
      };
      return TessituraSDK.TransferSession(TransferSessionParams);
    })
    .then(function () {
      // On successful completion forward the user to the confirm page
      return postCheckoutProcessing();
    })
    .then(function () {
      // On successful completion forward the user to the confirm page
      $window.location = $attrs.orderConfirmationUrl + '?order-no=' + cartData.Order.order_no;
    })
    .catch(function (response) {
      // If there is an error, show it to the user
      // and disable the loading icon
      $scope.errorMessage = response.data.error;
      $scope.processingOrder = false;
    });
  };

  /**
   * Check theres actually a cart, if not forward them on.
   */
  function reloadCartData() {
    return TessituraSDK.GetCart()
      .then(function (response) {
        if (!(response.data.result.GetCartResults.Order && (response.data.result.GetCartResults.SubLineItem  || response.data.result.GetCartResults.PackageSubLineItem  || response.data.result.GetCartResults.Contribution || response.data.result.GetCartResults.Payment))) {
          $window.location = Router.getUrl('booking.basket');
          return;
        }

        cartData = response.data.result.GetCartResults;
        $scope.cartData = cartData;

        // Do the user need to pay anything
        $scope.paymentDetailsNeeded = parseInt(cartData.Order.balance_to_charge) > 0;

        // Get the ICCTypes for when the user checks out
        return TessituraSDK.GetPaymentMethod()
          .then(function(response){
            PaymentMethods = response.data.result.GetPaymentMethodResults.PaymentMethod;
          });
      });
  }
  loadingPromises.push(reloadCartData());


  /**
   * Does any remainder tasks after checking out
   * like saving the gift card CSI
   */
  function postCheckoutProcessing() {

    var loadingPromises = [];

    // Check all the payment methods and see if theres an gift card
    // Post a CSI with the JSON
    arrayWrap(cartData.Payment).forEach(function (payment) {
      var cacheKey = 'BuyGiftCertificateMeta-' + payment.gc_no;

      if (CacheStorage.has(cacheKey)) {
        var giftCardData = CacheStorage.get(cacheKey);
        loadingPromises.push(addGiftCardCSI(giftCardData));
        if (giftCardData.emailGiftCertificate) {
          loadingPromises.push(TessituraSDK.GetGiftCertificatePrintout(giftCardData.emailGiftCertificate));
        }
        CacheStorage.remove(cacheKey);
      }
    });
    // Remove any remaining CSI meta
    CacheStorage.remove(/^BuyGiftCertificateMeta\-.*/);


    // Check is we had any CSI data to be submitted for contributions 
    arrayWrap(cartData.Contribution).forEach(function (contribution) {
      // key the meta data may be under
      var cacheKey = 'ContributionCheckoutMeta-' + contribution.ref_no;

      // Check if we have the key
      if (CacheStorage.has(cacheKey)) {

        // Get the data from local sotrage
        var checkoutData = CacheStorage.get(cacheKey);

        // It should be an array of objects that are either
        // csi or emailGiftCertficate objects
        if (angular.isArray(checkoutData)) {

          // For each object, work out what it is
          // and pass it to the correct handler.
          checkoutData.forEach(function (data) {

            switch (data.type) {
              case 'csi':
                loadingPromises.push(TessituraSDK.AddCustomerServiceIssue(data));
                break;
              case 'emailGiftCertificate':
                loadingPromises.push(TessituraSDK.GetGiftCertificatePrintout(data));
                break;
            }

          });
        }

        // Delete any extra abandoned data
        CacheStorage.remove(cacheKey);
      }
    });
    // Remove any remaining CSI meta
    CacheStorage.remove(/^ContributionCheckoutMeta\-.*/);

    return $q.all(loadingPromises);
  }

  /**
   * Saves the giftCardData into a CSI
   */
  function addGiftCardCSI(giftCardData){
    return TessituraSDK.AddCustomerServiceIssue(giftCardData.csi);
  }

  var cardIcons = {
    "4" : 'fa-cc-visa', // Visa
    "5" : 'fa-cc-mastercard', // Master Card
    "37": 'fa-cc-amex', // Amex
  };
  function getCardFontAwesomeClass(cardNumber){
    for (var cardPrefix in cardIcons) {
      if (cardPrefix === cardNumber.substr(0, cardPrefix.length)) {
        return cardIcons[cardPrefix];
      }
    }
    return false;
  }
  $scope.getCardFontAwesomeClass = getCardFontAwesomeClass;

  function getICCTypeFromCardNumber(cardNumber) {
    for (var i in PaymentMethods) {
      var paymentMethod = PaymentMethods[i];
      if (paymentMethod.card_prefix && paymentMethod.card_prefix === cardNumber.substr(0, paymentMethod.card_prefix.length)) {
        return paymentMethod.id;
      }
    }
    throw new Error('ICCType not found.');
  }



  $scope.redeemAGiftCertificate = function () {
    var modalInstance = $modal.open({
      backdrop: 'static',
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'redeem-a-gift-certificate.html'),
      controller: 'RedeemGiftCertificate',
      resolve: {
        orderAmount: function () {
          return cartData.Order.balance_to_charge;
        }
      }
    });

    modalInstance.result
      .then(function () {
        reloadCartData();
        $scope.reloadOrderSummary();
      });
  };

  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
    });
}]);
