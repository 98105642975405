app.controller('YapApplicationController', ['$q', '$scope', 'TessituraSDK', 'Router', '$filter', 'AuthenticationService', 'appConfig', '$timeout', 'ClickWrap', 'Cart', '$window', function ($q, $scope, TessituraSDK, Router, $filter, AuthenticationService, appConfig, $timeout, ClickWrap, Cart, $window) {

  var filter  = $filter('filter');
  var find  = $filter('find');
  var arrayWrap  = $filter('arrayWrap');
  var groupBy  = $filter('groupBy');

  var teacherAssociationId = 10021;

  var widgetConfig = getWidgetConfig();

  var loginInfo = null;

  var maxPerformanceLimit = 3;

  var defaultAddressStreet1 = 'web added';
  var defaultCountry = 1;

  // Globals

  $scope.loading = true;
  $scope.location = "";
  $scope.invalidMos = false;
  $scope.basketToggled = false;
  $scope.invalidConfirmEmail = false;
  /**
   * Locations:
   *    school-affiliation
   *    select-performances
   *    review-application
   *    confirmation
   */

  var schoolAffiliation = {

    // Main form output data
    form: {
      districtId: null,
      schoolCustomerId: null,
      subjectIds: {},
      gradeIds: {},
      schoolEmail: "",
      confirmEmail: "",
      phone: "",
      nameRequired: false,
      firstName: "",
      lastName: "",
    },

    // Tessi data stores
    districts: [],
    subjects: [],
    grades: [],

    // Some validation
    hasValidAddress: false,

    // Boot loader
    init: function () {
      return $q.all([
        this.loadDistricts(),
        this.loadSubjects(),
        this.loadGrades(),
        this.loadUserInfo()
      ]);
    },

    saveData: function () {

      // Update the salutation
      if (this.form.nameRequired) {
        var params = {
          bUpdateSalutation: 'true',
          sFirstName: this.form.firstName,
          sLastName: this.form.lastName,
        };

        TessituraSDK.UpdateAccountInfoEx2(params);
      }

      return this.removeExistingRelation()
      .then(this.addRelation.bind(this));
    },

    addRelation: function () {
      return  TessituraSDK.UpdateAssociation({
        iCustomerNo: this.form.schoolCustomerId,
        iTypeID: teacherAssociationId, /// WHat is this value meant to be
        sNotes: this.getNotes()
      });
    },

    removeExistingRelation: function () {
      return TessituraSDK.GetConstituentInfoEx()
      .then(function (response) {
        var associations = arrayWrap(response.data.result.GetConstituentInfoExResults.Associations);
        var association = find(associations, {
          xref_type: teacherAssociationId,
          inactive: 'N'
        });

        if (association) {
          return  TessituraSDK.UpdateAssociation({
            iAssociationID: association.xref_no,
            iTypeID: 0,
            sNotes: association.notes,
            sInactive: 'Y'
          });
        }

      });
    },

    getNotes: function () {
      var notes = [];

      notes.push('District: ' + this.getSelectedDistrict().name);
      notes.push('School: ' + this.getSelectedSchool().name);
      notes.push('Email: ' + this.getSchoolEmail());
      notes.push('Phone: ' + this.getPhone());

      notes.push('Subjects: ' + this.getSelectedSubjects().map(function (subject) {
          return subject.description;
      }).join(', '));

      notes.push('Grades: ' + this.getSelectedGrades().map(function (subject) {
        return subject.description;
      }).join(', '));

      return notes.join("\r\n");
    },

    // Data loaders
    loadDistricts: function () {
      TessituraSDK.SMaPGetAssociableSchoolDistricts()
      .then(extractSprocData)
      .then(function (districts) {

        this.districts = districts.map(function (district) {
          return {
            name: district.district_name,
            id: district.id,
            getSchools: function () {
              if (this.schools) {
                return this.schools;
              }

              TessituraSDK.SMaPGetSchools({
                RetrievalKey: district.id
              })
              .then(extractLocalData)
              .then(function (schools) {
                schools = schools.filter(function (school) {
                  return !!school.tessitura_customer_id;
                });
                this.schools = schools;
              }.bind(this));

              return false;
            }
          };
        });
      }.bind(this));
    },

    loadSubjects: function () {
      return TessituraSDK.SMaPSubjects()
      .then(extractLocalData)
      .then(function (subjects) {
        this.subjects = subjects.concat({
          isEditable: true,
          description: '',
          placeholder: 'Other',
          id: -1
        });
      }.bind(this));
    },

    loadGrades: function () {
      return TessituraSDK.SMaPGrades()
      .then(extractLocalData)
      .then(function (grades) {
        var package = grades.filter(function (grade) {
          return parseInt(grade.MOS, 10) === parseInt(loginInfo.MOS, 10);
        });

        if (package.length) {
          package = package[0];
        } else {
          $scope.invalidMos = true;

          return false;
        }

        var gradeLevels = package.grade_levels.split(',').map(function (grade) {
          return {
            description: grade,
            gradeNum: parseInt(grade, 10),
            id: parseInt(grade, 10),
            packages: [package.pkg_no]
          };
        });

        gradeLevels = gradeLevels.sort(function (gradeA, gradeB) {
          return gradeA.gradeNum > gradeA.gradeNum ? 1 : -1;
        });

        this.grades = gradeLevels;
      }.bind(this));
    },

    loadUserInfo: function () {
      return TessituraSDK.GetConstituentInfoEx()
      .then(function (response) {
          var
            result = response.data.result,
            constituentInfo = result.GetConstituentInfoExResults,
            addresses = arrayWrap(constituentInfo.Addresses);

          addresses = $filter('filter')(addresses, function (address) {
            return address.street1 != defaultAddressStreet1 && address.primary_ind == 'Y' && address.inactive == 'N';
          });

          this.hasValidAddress = !!addresses.length;

          this.form.nameRequired = constituentInfo.ConstituentHeader.full_name1.trim() === '- -';
          
      }.bind(this));
    },

    availableCountries: null,

    availableStates: null,

    newAddress: {
      iCountry: defaultCountry,
      sStreet1: '',
      sStreet2: '',
      sPostalCode: '',
      sCity: '',
      sStateProv: '',
      iAddressType: 3,
      bPrimary: 'true'
    },

    loadCountries: function () {
      return TessituraSDK.GetCountries().then(function (response) {
        var
          result = response.data.result,
          countries = arrayWrap(result.GetCountriesResults.Country);

        this.availableCountries = countries;

        angular.forEach(countries, function (country) {
          // Set the default country to the empty billing and shipping addresses
          if (country.id == defaultCountry && !this.newAddress.iCountry) {
            this.newAddress.iCountry = country.id;
          }
        }.bind(this));
      }.bind(this));
    },

    loadStatesForCountry: function () {
      this.availableStates = null;

      return TessituraSDK.GetStateProvinceEx({
        CountryIds: this.newAddress.iCountry || defaultCountry
      }).then(function (response) {
        var
          result = response.data.result,
          states = null;

        if ('GetStateProvinceResults' in result) {
          states = result.GetStateProvinceResults.StateProvince;
        } else {
          states = result.GetStateProvinceResultsEx.StateProvince;
        }

        this.availableStates = states;
      }.bind(this));
    },

    // Preview screen getters
    getSelectedDistrict: function () {
      return find(this.districts, {
        id: this.form.districtId
      });
    },
    getSelectedSchool: function () {
      return find(this.getSelectedDistrict().schools, {
        tessitura_customer_id: this.form.schoolCustomerId
      });
    },
    getSelectedSubjects: function () {
      return filter(this.subjects, function (subject) {
        return !!this.form.subjectIds[subject.id];
      }.bind(this));
    },
    getSelectedGrades: function () {
      return filter(this.grades, function (grade) {
        return !!this.form.gradeIds[grade.id];
      }.bind(this));
    },
    getSchoolEmail: function () {
      return this.form.schoolEmail;
    },
    getPhone: function () {
      return this.form.phone;
    },

    // Routing stuffs
    route: 'school-affiliation',
    isCompleted: false,
    goTo: function () {
      $scope.location = this.route;

      $scope.$watch(function (scope) {
        return {
          schoolEmail: scope.schoolAffiliation.form.schoolEmail,
          confirmEmail: scope.schoolAffiliation.form.confirmEmail
        };
      }, function (newVal) {
        if (!newVal.schoolEmail && !newVal.confirmEmail) {
          $scope.invalidConfirmEmail = false;
        } else if (newVal.schoolEmail && !newVal.confirmEmail) {
          $scope.invalidConfirmEmail = false;
        } else if (!newVal.schoolEmail && newVal.confirmEmail) {
          $scope.invalidConfirmEmail = false;
        } else {
          $scope.invalidConfirmEmail = (
            angular.isString(newVal.schoolEmail) &&
            angular.isString(newVal.confirmEmail) &&
            newVal.schoolEmail.toLowerCase() !== newVal.confirmEmail.toLowerCase()
          );
        }
      }, true);

      setWindowHash(this.route);
    },

    isActive: function () {
      return $scope.location == this.route;
    },

    showPreview: function () {
      return (
        this.isCompleted &&
        !this.isActive() &&
        !confirmation.isActive()
      );
    },

    addingAddress: false,

    submit: function () {
      if ($scope.invalidConfirmEmail) {
        alert('Please ensure the confirmation email matches the contact email');

        return false;
      }

      var promise = $q.resolve();

      if (!this.hasValidAddress) {
        this.addingAddress = true;

        promise = TessituraSDK.UpdateAddressEx(this.newAddress)
        .then(function (response) {
          return this.loadUserInfo();
        }.bind(this))
        .catch(function (error) {
          alert('There was a problem adding your address. Please verify the details and try again.');
        })
        .finally(function () {
          this.addingAddress = false;
        }.bind(this));
      }

      promise.then(function () {
        this.isCompleted = true;

        if (selectPerformances.isCompleted) {
          reviewApplication.goTo();
        } else {
          selectPerformances.goTo();
        }
      }.bind(this));
    }
  };
  $scope.schoolAffiliation = schoolAffiliation;


  var selectPerformances = {
    limitReached: false,

    details: [],

    packages: null,

    init: function () {
      this.packages = null;

      // Watches the order of cart items, and resets the priority number
      this.updateCartPriorities();

      var gradePackages = schoolAffiliation.getSelectedGrades().reduce(function (carry, grade) {
        carry = carry.concat(grade.packages);

        return carry;
      }, []);

      // Download the unique package data
      var promises = $filter('unique')(gradePackages).map(this.getNFSPackage);

      return $q.all(promises)
      .then(function (packages) {
        // Filter out packages with no performances 
        packages = packages.filter(function (package) {
          if (Object.keys(package.productions).length == 0) {
            return false;
          }

          for (var i in package.productions) {
            if (package.productions[i].performances.length == 0) {
              return false;
            }
          }

          return true;
        });

        this.packages = packages;
      }.bind(this));
 
    },

    /**
     * When a performance is selected from a dropdown we need extra meta data.
     * This call loads that meta data and attaches it to the original object.
     * @param  {[type]} performance [description]
     * @return {[type]}             [description]
     */
    loadPerformanceMeta: function (performance) {
      if (performance.extended) {
        return;
      }

      getPerformance(performance.perf_no)
      .then(function (performancePlus) {
        // Add the extra data in to the orginal
        angular.extend(performance, performancePlus, {
          extended: true
        });
      });
    },

    /**
     * @return {[type]} [description]
     */
    saveData: function () {

      var
        cart = angular.copy(this.cart),
        packages = cart.map(function (item) {
          return parseInt(item.package.pkg_no, 10);
        }),
        performances = cart.map(function (item) {
          return parseInt(item.performance.perf_no, 10);
        }),
        performanceGroups = cart.map(function (item) {
          return parseInt(item.performance.perf_group_no, 10);
        });

      var count = 1;

      var saveNextItem = function () {
        var item = cart.shift();

        if (!item) {
          return;
        }

        return this.saveItem(item, count++)
        .then(saveNextItem);
      }.bind(this);

      var orderNo, packageLineItems;

      return saveNextItem()
      .then(function () {
        var details = [
          appConfig.yapApplicationNote,
          '-----'
        ];

        details = details.concat(this.details);

        TessituraSDK.AddOrderCommentsEx2({
          Comment: details.join("\r\n"),
          LineItemID: 0,
          LineItemType: 'O',
          CustomerNo: 0,
          CategoryNo: 0
        });
      }.bind(this))
      .then(function () {
        return TessituraSDK.UpdateCustomOrderData({
          FieldIndex: 6,
          FieldValue: 'Application'
        });
      }.bind(this))
      .then(function () {
        return Cart.getCart(true);
      })
      .then(function (orderDetails) {
        orderNo = orderDetails.Order.order_no;

        // find all package line items relating to this application
        packageLineItems = orderDetails.PackageLineItem.filter(function (packageLineItem) {
          return (
            parseInt(packageLineItem.li_no, 10) !== 0 &&
            packages.indexOf( parseInt(packageLineItem.pkg_no, 10) ) !== -1 &&
            performances.indexOf( parseInt(packageLineItem.perf_no, 10) ) !== -1 &&
            'perf_group_no' in packageLineItem &&
            performanceGroups.indexOf( parseInt(packageLineItem.perf_group_no, 10) ) !== -1
          );
        });

        // turn it into an application for the school
        return $q.all(packageLineItems.map(function (packageLineItem) {
          return TessituraSDK.MaintainOrderInitiatorRecipient({
            iInitiatorNumber: parseInt(schoolAffiliation.form.schoolCustomerId, 10),
            iRecipientNumber: parseInt(schoolAffiliation.form.schoolCustomerId, 10),
            iLineItemID: parseInt(packageLineItem.li_seq_no, 10),
            sSubLineItemIDs: ''
          });
        }));
      })
      .then(function () {
        // also mark these as not having completed RSVP
        return $q.all(packageLineItems.map(function (packageLineItem) {
          return TessituraSDK.AddOrderCommentsEx2({
            Comment: appConfig.yapRSVPNotCompleted,
            LineItemID: parseInt(packageLineItem.li_seq_no, 10),
            LineItemType: 'L',
            CustomerNo: 0,
            CategoryNo: 0
          });
        }));
      })
      .then(function () {
        return TessituraSDK.Checkout();
      })
      .then(function () {
        return $q.resolve(orderNo);
      })
      .catch(function () {
        return Cart.clear().then(function () {
          return $q.reject();
        });
      });

    },

    saveItem: function (item, count) {
      var quantityAndPriceTypes = Object.keys(item.quantities).reduce(function (carry, priceType) {
        var quantity = item.quantities[priceType];

        var priceTypes = Array.apply(null, Array(quantity)).map(function (_, i) {
          return priceType;
        });

        carry.priceTypes = carry.priceTypes.concat(priceTypes);
        carry.quantity += quantity;

        return carry;
      }, {
        priceTypes: [],
        quantity: 0
      });

      var quantityString = Object.keys(item.quantities).map(function (priceType) {
        return priceType + ': ' + item.quantities[priceType];
      });

      this.details.push(
        count + '. ' + item.performance.perf_no + '; ' +
        quantityString.join(', ') +
        (item.contactIfMoreAvailable ? '; Contact if more seats available' : '')
      );

      return TessituraSDK.AddNFSPackagePerformanceItem({
        NFSPackageLineItemId: 0,
        PriceType: quantityAndPriceTypes.priceTypes.join(','),
        PackageNumber: item.package.pkg_no,
        PerformanceGroupNumber: item.performance.perf_group_no,
        PerformanceNumber: item.performance.perf_no,
        NumberOfSeats: quantityAndPriceTypes.quantity,
        Zone: 0,
        RequestedSeats: '',
        LeaveSingleSeats: 'true',
        SpecialRequests: '',
        UnSeated: item.package.seat_ind == 'Y' ? 'true' : 'false'
      })
      .then(function (response) {
        if (response.data.result['0'] == '0') {
          return $q.reject();
        }

        return $q.resolve();
      });
    },

    getPackageLineItemNumber: function (pkg_no, perf_no, perf_group_no) {
      return Cart.getCart(true)
      .then(function (cartDetails) {
        var parentPackage = find(cartDetails.PackageLineItem, {
          pkg_no: pkg_no
        });

        if (parentPackage) {
          return $q.resolve(parentPackage.pkg_li_no);
        }

        return $q.resolve(0);
      });
    },

    /**
     * Gets a NFS package and preprocesses it.
     * Adding a performances and webContent array.
     * @param  {int} packageNo [description]
     * @return {Package}          Returns the package object
     */
    getNFSPackage: function (packageNo) {
      var packagePromise = TessituraSDK.GetNFSPackageDetailEx({
        PackageID: packageNo,
        ModeOfSale: loginInfo.MOS
      });

      var conferencesPromise = TessituraSDK.SMaPTeacherConferences();

      return $q.all([
        packagePromise,
        conferencesPromise
      ]).then(function (responses) {
        var data = responses[0].data.result.GetNFSPackageDetailExResult;
        var conferences = extractLocalData(responses[1]);

        var
          package = data.Package,
          performances = arrayWrap(data.Performance),
          webContent = arrayWrap(data.WebContent),
          performanceWebContent = arrayWrap(data.PerformanceWebContent),
          now = moment().format('YYYY-MM-DDTHH:00:00Z');

        package.webContent = webContent;

        // No sponsor by default
        package.sponsor = false;

        var
          // Is there a sponsor text content field
          sponsorText = find(package.webContent, function (webContent) {
            return parseInt(webContent.content_type, 10) === appConfig.webContentTypes.stuMatSponsorText;
          }),
          // Is there a sponsor logo content field
          sponsorImage = find(package.webContent, function (webContent) {
            return parseInt(webContent.content_type, 10) === appConfig.webContentTypes.stuMatSponserLogo;
          }),
          // Is there a sponsor URL content field
          sponsorURL = find(package.webContent, function (webContent) {
            return parseInt(webContent.content_type, 10) === appConfig.webContentTypes.stuMatSponsorURL;
          });

        if (sponsorText && sponsorImage) {
          package.sponsor = {
            text: sponsorText.content_value,
            image: sponsorImage.content_value,
            url: sponsorURL ? sponsorURL.content_value : false
          };
        }

        package.title = $filter('packageLongTitle')(package);

        performances = performances.filter(function (performance) {
          /// REMEBER TO REMOVE THIS ^^^^^
          return (
            // Skip any in the past
            moment.parseZone(performance.perf_dt).isSameOrAfter(now, 'day') &&
            // Skip any not on sale
            performance.on_sale_ind === 'Y' &&
            // Only contain Student Matinee performances
            parseInt(performance.perf_status, 10) === 6
          );
        });

        var simplePerformances = [];

        // Add meta data to each performance
        angular.forEach(performances, function (performance, index) {
          performance.webContent = filter(performanceWebContent, {
            orig_inv_no: performance.perf_no
          });

          simplePerformances.push(selectPerformances.simplifyPerformance(performance, package));
        });

        // Group by the long title
        var groups = groupBy(simplePerformances, 'title');

        package.productions = [];

        angular.forEach(groups, function (performances) {
          var performance = performances[0];
          var prodConferences = conferences.filter(function (prod) { return parseInt(prod.Production, 10) === parseInt(performance.prod_season_no, 10) });

          package.productions.push({
            title: performance.title,
            conferences: prodConferences,
            niceConferenceDates: prodConferences.map(function(conf) {return $filter('concatDateTime')(conf.Conference_Date, 'MMM d, yyyy')}).join("; "),
            performances: performances,
          });
        });

        return package;
      });
    },

    /**
     * Returns the min quantity the number selector should go to for a pricetype
     * @param  {int} priceTypeNo  PriceType number
     */
    getMinQuantity: function (priceTypeNo) {
      var range = widgetConfig.quantityLimits[priceTypeNo];
      if (range && range.min) {
        return range.min;
      } else {
        return 1;
      }
    },

    /**
     * Returns the max quantity the number selector should go to for a pricetype
     * @param  {int} priceTypeNo  PriceType number
     */
    getMaxQuantity: function (priceTypeNo) {
      var range = widgetConfig.quantityLimits[priceTypeNo];
      if (range && range.max) {
        return range.max;
      } else {
        return 50;
      }
    },

    /**
     * Returns the primary price type
     */
    getPrimaryPriceType: function () {
      return Object.keys(widgetConfig.quantityLimits).reduce(function (carry, priceType) {
        if ('student' in widgetConfig.quantityLimits[priceType]) {
          return priceType;
        }

        return carry;
      }, 0);
    },

    /**
     * Returns the maximum quantity value for primary price type
     */
    getMaxAllocation: function () {
      return this.getMaxQuantity( this.getPrimaryPriceType() );
    },

    /**
     * Maps a performance object into something more usable
     * @param  {[type]} performance [description]
     * @param  {[type]} package     [description]
     * @return {[type]}             [description]
     */
    simplifyPerformance: function (performance, package) {
      var syosEnabled = filter(performance.webContent, function (content) {
        return (
          // content present
          parseInt(content.content_type, 10) === appConfig.webContentTypes.webSYOS &&
          // content is Y
          content.content_value.toLowerCase() === 'y'
        );
      });

      return {
        // Performance number as default
        perf_no: performance.perf_no,
        // Facility as standard
        facility_desc: performance.facility_desc,
        // Need the performance group for reserving
        prod_season_no: performance.prod_season_no,
        // Need the performance group for reserving
        perf_group_no: performance.perf_group_no,
        // Stick a package number in because tis useful
        pkg_no: package.pkg_no,
        // Add month so can group nicely
        month: moment(performance.perf_dt).format('MMMM YYYY'),
        // Add in a moment date so it can be used in filters
        perf_dt: moment.parseZone(performance.perf_dt),
        formatedDate: $filter('concatDateTime')(moment.parseZone(performance.perf_dt), 'EEEE, MMMM d, yyyy', {showfulltime:true}),
        // Stick in the nice title
        title: $filter('performanceLongTitle')(performance),
        // Selected zone
        selectedZone: null,
        // Do we wish to show the label that says users can pick their seats?
        syosEnabled: !!syosEnabled.length
      };
    },

    assign: function () {
      angular.extend.apply(this, arguments);
    },

    // Basket stuff
    cart: [],

    /**
     * Watch for changes for the cart, and updates
     * the priority attribute so they are sequential.
     * 
     * This method is better then using array index because with
     * multiple packages we split the array up but need to maintain
     * the global order
     */
    updateCartPriorities: function () {
      $scope.$watch(function () {
        return this.cart;
      }.bind(this), function () {
        var i = 1;
        this.cart.forEach(function (item) {
          item.priority = i++;
        });
      }.bind(this), true);

    },

    /**
     * Returns a template for a cart item.
     * Each performance row will have one of these items
     * in its scope. It will be filled in then added to the cart array
     */
    itemTemplate: function () {
      return {
        performance: null,
        package: null,
        production: null,
        // Key:Value, PriceTypeId: Quantity
        quantities: {},
        contactIfMoreAvailable: false
      };
    },

    /**
     * Adds an itemTemplate to the cart a
     */
    addItemToCart: function (item) {
      var _item = angular.copy(item);
      this.cart.push(_item);

      this.resetItem(item);

      this.limitReached = this.cart.length >= maxPerformanceLimit;
    },

    removeItemFromCart: function (item) {
      this.cart.forEach(function (element, index) {
        if (item === element) {
          this.cart.splice(index, 1);
          return false;
        }
      }.bind(this));

      this.limitReached = this.cart.length >= maxPerformanceLimit;
    },

    /**
     * Delete the values of an item.
     * Called by the html after an item is added to the cart
     */
    resetItem: function (item) {
      angular.extend(item, this.itemTemplate());
    },

    getPackageSponsors: function () {
      return this.cart.filter(function (item) {
        return item.package.sponsor;
      }).map(function (item) {
        return item.package.sponsor;
      });
    },

    // Routing stuffs
    route: 'select-performances',
    isCompleted: false,
    goTo: function () {
      this.init();
      $scope.location = this.route;
      setWindowHash(this.route);
    },

    isActive: function () {
      return $scope.location == this.route;
    },

    showPreview: function () {
      return (
        this.isCompleted &&
        !this.isActive() &&
        !confirmation.isActive()
      );
    },

    submit: function () {
      this.isCompleted = true;
      reviewApplication.goTo();
    }

  };
  $scope.selectPerformances = selectPerformances;
  

  var reviewApplication = {

    saving: false,

    error: false,

    orderNo: null,

    init: function () {},
    
    saveApplication: function () {

      Cart.confirmAndClear('You have currently have items in your cart. These will be removed if you continue.')
      .then(function () {
        // Confirm the T&Cs
        return ClickWrap.openWithElementContents('clickwrap');
      })

      // Create an order first
      .then(function () {

        this.saving = true;

        return selectPerformances.saveData();
      }.bind(this))

      // Create affiliations
      .then(function (orderNo) {
        // Grab the order number
        this.orderNo = orderNo;

        return schoolAffiliation.saveData();
      }.bind(this))

      // Transfer to the default mode of sale
      .then(function () {
        return AuthenticationService.transferSession();
      })

      // Change to standard mode of sale
      .then(function () {
        return AuthenticationService.subscriberShift();
      })

      // Move to the success page ?
      .then(function () {
        return confirmation.goTo({
          orderNo: this.orderNo
        });
      }.bind(this))

      // Set saving to false
      .catch(function () {
        this.saving = false;
        this.error = true;
      }.bind(this));

    },

    // Routing stuffs
    route: 'review-application',
    isCompleted: false,
    goTo: function () {
      $scope.location = this.route;
      setWindowHash(this.route);
    },

    isActive: function () {
      return $scope.location == this.route;
    }

  };
  $scope.reviewApplication = reviewApplication;


  var confirmation = {
    init: function (orderNo) {

      this.orderNo = orderNo;
      this.loadOrderConfirmationEmail();
    },

    loadOrderConfirmationEmail: function () {

      TessituraSDK.GetOrderConfirmationEmail({
        iTemplateNo: appConfig.yapApplicationConfirmation,
        iOrderNo: this.orderNo
      })
      .then(function (response) {

        var emailBodyparts = arrayWrap(response.data.result.Email.BodyParts.EmailBodyPart);

        // Loop over and look for the HTML version
        for (var i = 0; i < emailBodyparts.length; i++) {
          if (emailBodyparts[i].ContentType == 'text/html') {
            $scope.confirmationEmail = angular.element(emailBodyparts[i].Body).find('table')[0].outerHTML;
          }
        }
          
      });
    },

    // Routing stuffs
    route: 'confirmation',
    isCompleted: false,
    goTo: function (options) {
      this.init(options.orderNo);
      $scope.location = this.route;
      setWindowHash(this.route);
    },

    isActive: function () {
      return $scope.location == this.route;
    }

  };
  $scope.confirmation = confirmation;

  /**
   * Startup routing for the application.
   * Loads up all the sub modules and sets the initial route.
   */
  function init() {
    return AuthenticationService.requireLogin()
    .then(AuthenticationService.isUserRegistered)
    // Check the user is logged in first
    .then(function (isUserRegistered) {
      if (!isUserRegistered) {
        $scope.userIsGuest = true;
        $scope.acc.loading = false;
        return;
      }

      return AuthenticationService.getLoginInfo().then(function (loginInfoEx) {
        loginInfo = loginInfoEx;

        return schoolAffiliation.init();
      });
    });
  }

  /**
   * Calls GetPerformanceDetailWithDiscountingEx with the current
   * mode of sale. Returns the Performance object with its other attributes
   * attached to it as properties.
   * 
   * @param  {int} perf_no 
   * @return {Promise<Performance>}
   */
  function getPerformance(perf_no) {
    return TessituraSDK.GetPerformanceDetailWithDiscountingEx({
      iPerf_no: perf_no,
      iModeOfSale: loginInfo.MOS
    }).then(function (response) {
      var performance = response.data.result.GetPerformanceDetailWithDiscountingExResult;
      
      performance.AllPrice = arrayWrap(performance.AllPrice);
      performance.Price = arrayWrap(performance.AllPrice).filter(function (p) { return p.available != 'N'; });
      performance.PriceType = arrayWrap(performance.PriceType);
      performance.webContent = arrayWrap(performance.WebContent);

      return performance;
    });
  }


  /**
   * Unwraps a table call.
   * Its used a few times so i made a shortcut
   */
  function extractLocalData(response) {
    return arrayWrap(response.data.result.GetLocalDataResults.LocalData);
  }

  /**
   * Unwraps a table call.
   * Its used a few times so i made a shortcut
   */
  function extractSprocData(response) {
    return arrayWrap(response.data.result.ExecuteLocalProcedureResults.LocalProcedure);
  }



  init()
  .then(function () {
    schoolAffiliation.goTo();
  })
  .finally(function () {
    $scope.loading = false;
  });


  function getWidgetConfig() {
    var widgetConfig = document.getElementById('widget-config');

    if (widgetConfig) {
      widgetConfig = JSON.parse(widgetConfig.innerHTML);
    } else {
      widgetConfig = {};
    }

    return widgetConfig;
  }

  function map(key) {
    return function (obj) {
      return obj[key];
    };
  }

  // Delay setting the window hash so the page has time to relayout
  function setWindowHash(hash) {
    setTimeout(function () {
      $window.location.hash = hash;
    }, 10);
  }

}]);
