app.controller('DonationPopupController', ['$scope', 'TessituraSDK', '$q', '$window', 'Donation', 'CacheStorage', 'messageConfig', '$modalInstance', '$http', function ($scope, TessituraSDK, $q, $window, Donation, CacheStorage, messageConfig, $modalInstance, $http) {
  'use strict';

  angular.merge($scope, messageConfig);


  $scope.errorAddingContribtion = false;
  $scope.showDonationAmountBox = false;
  $scope.otherDonationForm = {
    amount: 5
  };

  /**
   * Button handler for donate the amount asked for in the popup
   */
  $scope.donateAmount = function () {
    addAmountAndForward(messageConfig.amount);
  };

  /**
   * Button handler for donate other amount
   * - Hides the default buttons and displays the other amount form
   */
  $scope.donateDifferentAmount = function () {
    $scope.showDonationAmountBox = true;
  };

  /**
   * Form submit for `other amount` form
   * - Adds the `other amount` to the basket and forwards to shipping
   */
  $scope.addOtherDonation = function () {
    addAmountAndForward($scope.otherDonationForm.amount);
  };

  /**
   * Button handle for ask me another time
   * - Hides the popup and goes to shipping
   */
  $scope.askMeAnotherTime = function () {
    dontShowDonationPopup();
    $modalInstance.close('goToNextStep');
  };

  /**
   * Dont show the donation popup again for another hour
   */
  function dontShowDonationPopup() {
    CacheStorage.set('dont-show-donation-popup', true, 3600 * 1000);
  }

  /**
   * Adds an amount to the cart as a contribution
   * Then forwards the user to the shipping page
   * @param {float} amount  Donation amount
   */
  function addAmountAndForward(amount) {
    TessituraSDK.AddContribution({
      Amount: amount,
      Fund: messageConfig.fundId,
      AccountMethod: 0,
      Upgrade: 'false',
      Renew: 'false'
    })
    .then(function () {
      // Change the expiration time
      return TessituraSDK.ResetTicketExpiration();
    })
    .then(function () {
      dontShowDonationPopup();
      $modalInstance.close('goToNextStep');
    })
    .catch(function () {
      $scope.errorAddingContribtion = true;
    });
  }


  $scope.temporarySkip = function () {
    $modalInstance.close('goToNextStep');
  };

}]);
