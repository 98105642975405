app.controller('ManageAddressesController', ['$scope', '$q', '$filter', 'TessituraSDK', 'AuthenticationService', 'AddressService', 'appConfig', function ($scope, $q, $filter, TessituraSDK, AuthenticationService, AddressService, appConfig) {
  // Promises to wait for to consider the page "loaded" 
  var loadingPromises = [];
  // Used for syntactic sugar bellow
  var promise;
  // Address template for new addresses
  var addressTemplate  =  {
    sStreet1: '',
    sStreet2: '',
    sCity: '',
    sStateProv: '',
    sPostalCode: '',
    iCountry: 0,
    iAddressNumber: 0,
    iAddressType: 3,
    bPrimary: 'false',
    sMonths: '',
    sInActive: '',
    bSaveCopyOnChange: 'false',
    sMailPurposes: ''
  };

  // Fields used for address verification
  var verificationFields = [
    'sStreet1', 'sStreet2', 'sCity', 'sStateProv', 'sPostalCode'
  ];

  // Is the page loading
  $scope.loading = true;
  // Which addressIds are updating
  $scope.addressesUpdating = {};
  // Array of all users addresses
  $scope.addresses = [];
  // Array of all countries from tessi
  $scope.countryOptions = [];
  // Array of all states from tessi
  $scope.stateProvinceOptions = [];
  // Address to currently edit in the form
  $scope.editingAddress = null;
  // Saving an address?
  $scope.savingAddress = false;

  // Check the user is a logged in valid user
  promise = AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      $scope.userIsGuest = true;
      $scope.loading = false;
      return $q.defer().promise;
    } else {
      return reloadAddresses();
    }
  });
  loadingPromises.push(promise);


  /**
   * Reload all the addresses
   */
  function reloadAddresses(){
    var GetConstituentInfoExParams = {
      // return only address
      TableListTokens: 'AD'
    };

    return TessituraSDK.GetConstituentInfoEx(GetConstituentInfoExParams)
      .then(function (response) {
        if (response.data.result.GetConstituentInfoExResults) {
          $scope.addresses = response.data.result.GetConstituentInfoExResults.Addresses;
          $scope.addresses = $filter('arrayWrap')($scope.addresses);
          $scope.addresses = $filter('orderBy')($scope.addresses, '-primary_ind');
          $scope.addresses = $scope.addresses
            .map(function (address) {
              // Map this to our standard UpdateAddress form
              return convertConstituentInfoAddressToUpdateableAddress(address);
            })
            .filter(function (address) {
              // Filter out inactive addresses
              if (address.sInActive == 'Y' ||
                  address.sStreet1 == 'web added' ||
                  address.sStreet1 == 'Guest street' ||
                  address.iAddressType == appConfig.guestCheckoutAddressType
                ) {
                return false;
              };

              return true;
            });
        } else {
          $scope.addresses = [];
        }
      });
  }

  /**
   * Gets all the countries from the tessitura.
   * Also find the USA and make it the default for new adddresses
   */
  promise = TessituraSDK.GetCountries()
    .then(function (response) {
      var countryOptions = response.data.result.GetCountriesResults.Country;

      $scope.countryOptions = countryOptions.filter(function(country) {
        // Convert all ids to int (they was strings)
        country.id = parseInt(country.id);

        // If the USA then set it to default
        if(country.short_desc == 'USA') {
          addressTemplate.iCountry = country.id; 
        }

        // Filter out entires without a description 
        return !!country.description;
      });
    });
  loadingPromises.push(promise);

  /**
   * Get all the states from the tessitura
   */
  promise = TessituraSDK.GetStateProvinceEx()
    .then(function (response) {
      var stateProvinceOptions = null;

      if ('GetStateProvinceResults' in response.data.result) {
        stateProvinceOptions = response.data.result.GetStateProvinceResults.StateProvince;
      } else {
        stateProvinceOptions = response.data.result.GetStateProvinceResultsEx.StateProvince;
      }

      // Filter out entires without a description 
      $scope.stateProvinceOptions = stateProvinceOptions.filter(function(province) {
        return !!province.description;
      });
    });
  loadingPromises.push(promise);


  /**
   * Opens the address object passed
   * in for the user to edit
   * 
   * @param Object  address  Address object
   */
  function editAddress(address) {
    // Copy the address, in case they hit cancel
    $scope.editingAddress = angular.copy(address);
  }
  $scope.editAddress = editAddress;

  /**
   * Sets the provided address to the primary address
   * 
   * @param Object  address  Address object
   */
  function makePrimaryAddress(address) {
    // Copy the address, (dont edit the original)
    var addressCopy = angular.copy(address);
    // Mark it as the primary address
    addressCopy.bPrimary = 'true';
    // Save the changes
    saveAddress(addressCopy);
  }
  $scope.makePrimaryAddress = makePrimaryAddress;

  /**
   * Makes the address object as inactive
   * 
   * @param Object  address  Address object
   */
  function deleteAddress(address) {
    // Copy the address, (dont edit the original)
    var addressCopy = angular.copy(address);
    // Make it as inactive (basically deleting)
    addressCopy.sInActive = 'Y';
    // Save the changes
    saveAddress(addressCopy);
  }
  $scope.deleteAddress = deleteAddress;


  /**
   * Creates a new address object
   * and sends it to be editted
   * 
   * @param Object  address  Address object
   */
  function newAddress() {
    // Make a new address
    var newAddressObj = angular.copy(addressTemplate);
    // Open it in the editor form
    editAddress(newAddressObj);
  }
  $scope.newAddress = newAddress;

  /**
   * Saves an address in Tessitura and reloads
   * the addresses the user sees.
   * 
   * @param Object  address  Address object
   */
  function saveAddress(address){
    // Hide the address editing field 
    $scope.editingAddress = null;
    // Mark the addressId as loading
    $scope.addressesUpdating[address.iAddressNumber] = true;
    // Mark as address as updating
    $scope.savingAddress = true;

    if (!$scope.addresses.length) {
      address.bPrimary = 'true';
    }

    // Update it in tessi
    return TessituraSDK.UpdateAddressEx(address)
    // After, reload all the address data
    .then(reloadAddresses)
    // Finally make the address as no longer loading 
    .then(function () {
      $scope.addressesUpdating[address.iAddressNumber] = false;
    })
    .finally(function () {
      $scope.savingAddress = false;
      $scope.verifyingAddress = false;
      $scope.verification = false;
      $scope.continueWithoutVerification = false;
      $scope.editingAddress = null;
    });
  }
  $scope.saveAddress = saveAddress;

  function getPhone() {
    return TessituraSDK.GetAccountInfo()
    .then(function (response) {
      return response.data.result.GetAccountInfoResults.AccountInformation.phone;
    })
  }

  /**
   * Takes in an id from a country and
   * returns its string name
   * 
   * @param  int    id  iCountry ID
   * @return string     Countries human name
   */
  function countryNameFromId(id){
    // Loop over every country
    for (var i = 0; i < $scope.countryOptions.length; i++) {
      var country = $scope.countryOptions[i];
      // If its the country we are looking for
      if (country.id == id) {
        // Return the countries name
        return country.description;
      }
    }
  }
  $scope.countryNameFromId = countryNameFromId;

  function verifyAddress(address) {
    $scope.verifyingAddress = true;

    var fields = [];

    for (var k in address) {
      if (verificationFields.indexOf(k) === -1) {
        continue;
      }

      if (angular.isString(address[k]) && address[k].length) {
        fields.push(address[k]);
      }
    }

    return AddressService.verify({
      search: fields,
      country: 'USA'
    })
    .then(function (verification) {
      if (verification === true) {
        return saveAddress(address);
      }

      if (!verification.items.length) {
        $scope.continueWithoutVerification = true;
        return true;
      }

      $scope.verification = verification;
    })
    .finally(function () {
      $scope.verifyingAddress = false;
    });
  }
  $scope.verifyAddress = verifyAddress;
  $scope.verifyingAddress = false;
  $scope.verification = false;
  $scope.continueWithoutVerification = false;

  function useVerifiedAddress(item, address) {
    return AddressService.get({
      moniker: item.Moniker
    })
    .then(function (response) {
      for (var k in response) {
        address[k] = response[k];
      }
    })
    .finally(function () {
      return saveAddress(address);
    });
  }
  $scope.useVerifiedAddress = useVerifiedAddress;

  /**
   * Converts an address object returned by GetConstituentInfoEx
   * into a object that could be saved using the UpdateAddressEx
   * function.
   * 
   * @param  Addresses  address  Addresses from GetConstituentInfoEx
   * @return Object              Address that can be used in UpdateAddressEx
   */
  function convertConstituentInfoAddressToUpdateableAddress(address){
    // Take a new address template
    var newAddressObj = angular.copy(addressTemplate);

    // Copy all attributes over into the new address
    newAddressObj.iAddressNumber = parseInt(address.address_no);
    newAddressObj.iAddressType = parseInt(address.address_type);
    newAddressObj.sCity = address.city;
    newAddressObj.iCountry = parseInt(address.country);
    newAddressObj.sInActive = address.inactive == 'Y' ? 'Y':'N';
    newAddressObj.sStateProv = address.state;
    newAddressObj.sMailPurposes = address.mail_purposes;
    newAddressObj.sMonths = address.months;
    newAddressObj.sPostalCode = address.postal_code;
    newAddressObj.bPrimary = address.primary_ind == 'Y' ? 'true':'false';
    newAddressObj.sStreet1 = address.street1;
    newAddressObj.sStreet2 = typeof address.street2 == 'string' ? address.street2:'';

    // Return the mapped address
    return newAddressObj;
  }

  /**
   * When all resources have loaded.
   * Setting loading to false.
   */
  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
    });
}]);
