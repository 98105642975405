/* global trackEvent */

app.controller('OrderConfirmationController', ['$scope', 'TessituraSDK', 'GetParameters', '$http', '$sce', 'GoogleTagManager', 'AuthenticationService', 'Notifications', '$filter', 'appConfig', function ($scope, TessituraSDK, GetParameters, $http, $sce, GoogleTagManager, AuthenticationService, Notifications, $filter, appConfig) {

  GoogleTagManager.sendCheckoutStep(4);

  var arrayWrap = $filter('arrayWrap');

  // Order we need to load to display
  var orderNo = parseInt(GetParameters['order-no']);

  // Is this an YAP order?
  var isYAPOrder = false;

  // Place to store the email html
  $scope.confirmationEmail = '';

  // Is user registered?
  $scope.isUserRegistered = false;

  // Loading?
  $scope.loading = true;

  AuthenticationService.isUserRegistered()
  .then(function (isUserRegistered) {
    $scope.isUserRegistered = isUserRegistered;

    return TessituraSDK.GetCustomOrderDetails({
      order_no: orderNo
    });
  })
  .then(function (response) {
    var result = response.data.result.ExecuteLocalProcedureResults;

    return {
      Order: result.LocalProcedure,
      LineItem: arrayWrap(result.LocalProcedure1),
      SubLineItem: arrayWrap(result.LocalProcedure2),
      SubLineItemFee: arrayWrap(result.LocalProcedure3),
      Fee: arrayWrap(result.LocalProcedure4),
      Contribution: arrayWrap(result.LocalProcedure5),
      Payment: arrayWrap(result.LocalProcedure6),
      PaymentPlan: arrayWrap(result.LocalProcedure7)
    };
  })
  .then(function (orderDetails) {
    var totalPrice = 0;
    var cartItems = orderDetails.LineItem.map(function (item) {

        var sublineItems = orderDetails.SubLineItem.filter(function (subItem) {
            return subItem.li_seq_no === item.li_seq_no;
        });
        var lineTotal = sublineItems.reduce(function (carry, item) {
            return carry + parseFloat(item.due_amt);
        }, 0);

        totalPrice += lineTotal;

        return {
            item_id: item.perf_no,
            item_name: item.perf_desc,
            coupon: orderDetails.Order.source_no,
            location_id: item.facil_no,
            price: lineTotal,
            quantity: sublineItems.length,
        };
    });

    var contributions = orderDetails.Contribution.map(function (item) {
        return {
            item_id: item.fund_no,
            item_name: item.campaign_desc,
            price: parseFloat(item.recd_amt),
        }
    });

    contributions.forEach(function (contribution) {
        cartItems.push(contribution)
        totalPrice += contribution.price
    });

    var data = {
        currency: "USD",
        value: totalPrice,
        items: cartItems,
    };

    trackEvent('add_payment_info', data);

    isYAPOrder = ((
      'notes' in orderDetails.Order &&
      angular.isString(orderDetails.Order.notes) &&
      orderDetails.Order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
    ) || (
      'custom_6' in orderDetails.Order &&
      angular.isString(orderDetails.Order.custom_6) &&
      orderDetails.Order.custom_6.length
    ));

    if ($scope.isUserRegistered) {
      if (isYAPOrder) {
        // Get the html from the server with specific template
        return TessituraSDK.GetOrderConfirmationEmail({
          iOrderNo: orderNo,
          iTemplateNo: appConfig.yapOrderConfirmation
        });
      }

      // Get the html from the server with default template
      return TessituraSDK.GetOrderConfirmationEmail({
        iOrderNo: orderNo
      });
    } else {
      return TessituraSDK.GetLastOrderConfirmationEmail();
    }
  })
  .then(function (response) {

    var emailBodyparts = arrayWrap(response.data.result.Email.BodyParts.EmailBodyPart);

    // Loop over and look for the HTML version
    for (var i = 0; i < emailBodyparts.length; i++) {
      if (emailBodyparts[i].ContentType == 'text/html') {
        $scope.confirmationEmail = angular.element(emailBodyparts[i].Body).find('table')[0].outerHTML;
      }
    }
  })
  .finally(function () {
    $scope.loading = false;
  });

  GoogleTagManager.sendTransaction(orderNo);
  GoogleTagManager.sendCheckoutEvents(orderNo);

  $scope.sendEmail = function () {
    if ($scope.isUserRegistered) {
      TessituraSDK.SendOrderConfirmationEmail({
        iOrderNo: orderNo
      });
    } else {
      TessituraSDK.GetLastOrderConfirmationEmail();
    }

    Notifications.create({
      message: 'Your confirmation has been emailed to you.'
    });
  };

}]);
