angular.module('TessituraSDK').config(['$provide', function ($provide) {
    $provide.decorator('CacheStorage', ['$delegate', function ($delegate) {
        $delegate.append = function (key, value, ttl) {
            var existing = null;

            if ($delegate.has(key)) {
                existing = $delegate.get(key);
            }

            if (!angular.isArray(existing)) {
                existing = [];
            }

            existing.push(value);

            return $delegate.set(key, existing, ttl);
        }

        return $delegate;
    }]);
}]);
