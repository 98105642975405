/* global moment */
app.service('InventoryService', ['$q', '$filter', 'APIService', 'appConfig', function ($q, $filter, APIService, appConfig) {
  // Tools
  var
    arrayWrap = $filter('arrayWrap');

  return {
    GetPerformancesEx4: GetPerformancesEx4,
    GetPerformancesForProduction: GetPerformancesForProduction,
    GetProductionsEx3: GetProductionsEx3,
    GetProductionsEx3ByProduction: GetProductionsEx3ByProduction,
    GetWebContent: GetWebContent
  };

  function GetPerformancesEx4(params) {
    params = params || {};

    if (!('iModeOfSale' in params)) {
      params.iModeOfSale = appConfig.generalMos;
    }

    return APIService.call('GetPerformancesEx4', params).then(function (response) {
      var result = response.data.result.GetPerformancesEx4Result;

      return $q.resolve({
        Performance: arrayWrap(result.Performance),
        WebContent: arrayWrap(result.WebContent)
      });
    });
  };

  function GetPerformancesForProduction(params) {
    params = params || {};

    var prodSeasonNo = 'iProdSeasonID' in params ? params.iProdSeasonID : false;

    delete params.iProdSeasonID;

    if (!prodSeasonNo) {
      return GetPerformancesEx4(params);
    }

    return GetPerformancesEx4(params).then(function (response) {
      return $q.resolve({
        Performance: response.Performance.filter(function (performance) {
          return parseInt(performance.prod_season_no, 10) === parseInt(prodSeasonNo, 10);
        }),
        WebContent: response.WebContent.filter(function (webContent) {
          return parseInt(webContent.inv_no, 10) === parseInt(prodSeasonNo, 10);
        })
      });
    });
  };

  function GetProductionsEx3(params) {
    params = params || {};

    if (!('iModeOfSale' in params)) {
      params.iModeOfSale = appConfig.generalMos;
    }

    return APIService.call('GetProductionsEx3', params).then(function (response) {
      var result = response.data.result.GetProductionsEx3Result;

      return $q.resolve({
        Production: arrayWrap(result.Production),
        Credit: arrayWrap(result.Credit),
        Keyword: arrayWrap(result.Keyword),
        WebContent: arrayWrap(result.WebContent)
      });
    });
  };

  function GetProductionsEx3ByProduction(params) {
    params = params || {};

    var prodSeasonNo = 'iProdSeasonID' in params ? params.iProdSeasonID : false;

    delete params.iProdSeasonID;

    if (!prodSeasonNo) {
      return GetProductionsEx3(params);
    }

    return GetProductionsEx3(params).then(function (response) {
      var filteredProduction = response.Production.filter(function (production) {
        return parseInt(production.prod_season_no, 10) === parseInt(prodSeasonNo, 10);
      });

      if (!filteredProduction.length) {
        return $q.resolve({});
      }

      return $q.resolve({
        Production: filteredProduction[0],
        Credit: response.Credit.filter(function (credit) {
          return parseInt(credit.prod_season_no, 10) === parseInt(prodSeasonNo, 10);
        }),
        Keyword: response.Keyword.filter(function (keyword) {
          return parseInt(keyword.prod_season_no, 10) === parseInt(prodSeasonNo, 10);
        }),
        WebContent: response.WebContent.filter(function (webContent) {
          return parseInt(webContent.orig_inv_no, 10) === parseInt(prodSeasonNo, 10);
        })
      });
    });
  };

  function GetWebContent(params) {
    params = params || {};

    return APIService.call('GetWebContent', params).then(function (response) {
      var result = response.data.result.GetWebContentResults;

      return $q.resolve(arrayWrap(result.WebContent));
    });
  };
}]);
