app.controller('YapSurvey', ['$q', '$scope', 'TessituraSDK', 'Router', '$filter', 'AuthenticationService', 'appConfig', '$timeout', 'ClickWrap', 'Cart', 'GetParameters', function ($q, $scope, TessituraSDK, Router, $filter, AuthenticationService, appConfig, $timeout, ClickWrap, Cart, GetParameters) {

  var arrayWrap = $filter('arrayWrap');
  var find = $filter('find');
  var sum = $filter('sum');
  var filter = $filter('filter');

  var orderNo = GetParameters['order_no'];

  var widgetConfig = getWidgetConfig();

  angular.extend($scope, {
    loading: true,
    formSubmited: false,

    transports: [],
    performances: [],

    isEditPerformance: isEditPerformance,
    editPerformance: editPerformance,
    completedPerformance: completedPerformance,
    disableSubmit: disableSubmit,
    submit: submit
  });

  init()
  .then(function () {
    editPerformance($scope.performances[0]);
    $scope.loading = false;
  });

  function init() {
    return $q.all([
      loadOrder(orderNo),
      loadTransportMethods()
    ]);
  }

  function loadOrder(orderNo) {
    return getOrder(orderNo)
    .then(function (orderDetails) {
      
      var promises = orderDetails.LineItem.map(function (lineItem) {
 
        var row = {
          form: {
            numberOfTeachers: 0,
            transport: null,
            numberOfTransports: null
          }
        };

        return getPerformance(lineItem.perf_no)
        .then(function (performance) {

          row.title = $filter('performanceLongTitle')(performance);
          row.date = $filter('concatTrueDateTime')(lineItem.perf_dt, 'EEEE, MMMM d, yyyy', {showfulltime:true});
          row.venue = performance.facility_desc;

          var subLineItems = filter(orderDetails.SubLineItem, {li_seq_no: lineItem.li_seq_no});

          row.priceTypes = subLineItems.reduce(function (sum, subLineItem) {
            
            if (!sum[subLineItem.price_type_desc]) {
              sum[subLineItem.price_type_desc] = 0;
            }
            sum[subLineItem.price_type_desc]++;

            return sum;
          }, {});

          row.numberOfChaperones = filter(subLineItems, {price_type: widgetConfig.chaperonePriceTypeNo}).length;


          return getEducatorConference(performance.prod_season_no)
          .then(function (conference) {
            if (conference) {
              row.educatorConference = $filter('concatDateTime')(conference.Conference_Date, 'EEEE, MMMM d, yyyy');
            }
            return row;
          });

        })
      });

      return $q.all(promises);
    })
    .then(function (rows) {
      $scope.performances = rows;
    });
  }

  function getEducatorConference(prod_season_no) {
    return TessituraSDK.SMaPTeacherConferences()
    .then(function (response) {
      var conferences = arrayWrap(response.data.result.GetLocalDataResults.LocalData);

      return find(conferences, { Production: prod_season_no });
    });
  }

  function getPerformance(perfNo) {
    return TessituraSDK.GetPerformanceDetailEx({
      iPerf_no: perfNo,
      iModeOfSale: appConfig.generalMos
    })
    .then(function (response) {
      var performance = response.data.result.GetPerformanceDetailExResult.Performance;
      
      performance.webContent = arrayWrap(response.data.result.GetPerformanceDetailExResult.WebContent);

      return performance;
    });
  }


  function getOrder(orderNo) {
    return TessituraSDK.GetCustomOrderDetails({
      order_no: parseFloat(orderNo)
    })
    .then(function (response) {
      var promises = [];

      var result = response.data.result.ExecuteLocalProcedureResults;

      var orderDetails = {
        Order: result.LocalProcedure,
        LineItem: result.LocalProcedure1,
        SubLineItem: result.LocalProcedure2,
        SubLineItemFee: result.LocalProcedure3,
        Fee: result.LocalProcedure4,
        Contribution: result.LocalProcedure5,
        Payment: result.LocalProcedure6,
        PaymentPlan: result.LocalProcedure7
      };

      orderDetails.LineItem = arrayWrap(orderDetails.LineItem);
      orderDetails.SubLineItem = arrayWrap(orderDetails.SubLineItem);
      orderDetails.SubLineItemFee = arrayWrap(orderDetails.SubLineItemFee);
      orderDetails.Fee = arrayWrap(orderDetails.Fee);
      orderDetails.Payment = arrayWrap(orderDetails.Payment);
      orderDetails.Contribution = arrayWrap(orderDetails.Contribution);

      return orderDetails;
    });
  }

  function loadTransportMethods() {
    return TessituraSDK.SMaPTransportOptions()
    .then(function (response) {
      $scope.transports = arrayWrap(response.data.result.GetLocalDataResults.LocalData);
    });
  }

  var selectedPerformance;
  function editPerformance(performance) {
    performance.completed = false;
    selectedPerformance = performance;
  }

  function isEditPerformance(performance) {
    return selectedPerformance == performance; 
  }

  function getWidgetConfig() {
    var widgetConfig = document.getElementById('widget-config');

    if (widgetConfig) {
      widgetConfig = JSON.parse(widgetConfig.innerHTML);
    } else {
      widgetConfig = {};
    }

    return widgetConfig;
  }

  function nextIncomplete() {
    var location = $scope.performances.indexOf(selectedPerformance);
    do {
      location++;
    } while ($scope.performances[location] && $scope.performances[location].completed);
    selectedPerformance = $scope.performances[location];
  }

  function completedPerformance(performance) {
    performance.completed = true;
    nextIncomplete();
  }

  function disableSubmit() {
    return !$scope.performances.every(function (performance) {
      return performance.completed;
    });
  }

  function getNotes() {
    return angular.toJson($scope.performances, 3);
  }

  function submit() {

    var csi = angular.extend({}, widgetConfig.csi, {
      Notes: getNotes()
    });

    TessituraSDK.AddCustomerServiceIssue(csi)
    .then(function () {

      $scope.formSubmited = true;
      $timeout(function () {
        Router.goTo('user.account');
      }, 5000);

    })
    .catch(function (errorResponse) {
      $scope.error = errorResponse.data.error;
    })
    .finally(function () {
      $scope.formSaving = false;
    });
  }

}]);
