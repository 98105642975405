app.controller('RegisterController', ['$scope', '$attrs', 'TessituraSDK', function ($scope, $attrs, TessituraSDK) {

  //user object
  $scope.user = {
    firstname    : "",
    lastname     : "",
    emailAddress : "",
    password     : ""
  };

  /**
   * Handle request for user registration.
   */
  $scope.register = function() {
    var options = {
      sUID       : $scope.user.emailAddress,
      sPwd       : $scope.user.password,
      iLoginType : 1,
      sEmail     : $scope.user.emailAddress,
      sFname     : $scope.user.firstname,
      sLname     : $scope.user.lastname
    };

    //trigger Tessitura API call for user registration
    TessituraSDK.Register(options).then(function (response) {
      if (response.data.result == true) {
        window.location = $attrs.registerSuccessUrl;
      } else {
        $scope.error(response.data.result);
      }
    });
  };

  /**
   * Set error message when an API request is unsuccessful.
   */
  $scope.error = function() {
    $scope.error_message = "There was an error registering you.";
  }
}]);
