/* global moment */
app.controller('TimeoutBarController', ['$scope', '$rootScope', '$filter', '$attrs', '$window', '$q', 'TessituraSDK', 'CacheStorage', 'Router', 'Cart', 'AuthenticationService', 'MosSwitcher', function ($scope, $rootScope, $filter, $attrs, $window, $q, TessituraSDK, CacheStorage, Router, Cart, AuthenticationService, MosSwitcher) {

  // How long in seconds should the notification stay hidden
  var notificationHideTime = parseInt($attrs.notificationHideTime || 10) * 60;

  var arrayWrap = $filter('arrayWrap');

  $scope.notificationHidden = false;

  /**
   * Check if we need to display the expired cart message
   */
  if (CacheStorage.has('TimeoutBar-expired')) {
    // We've displayed it, remove the flag
    CacheStorage.remove('TimeoutBar-expired');
    Cart.isCartEmpty()
    .then(function (isCartEmpty) {
      $scope.cartExpired = isCartEmpty;
    });
  } else {
    $scope.cartExpired = false;
  }


  /**
   * Check if the user wants to hide the notification bar
   */
  if (CacheStorage.has('TimeoutBar-unhideNotifcationAt')) {
    var unhideNotifcationAt = CacheStorage.get('TimeoutBar-unhideNotifcationAt');
    unhideNotifcationAt = moment(unhideNotifcationAt);

    if (unhideNotifcationAt.diff() > 0) {
      $scope.notificationHidden = true;
    } else {
      CacheStorage.remove('TimeoutBar-unhideNotifcationAt');
    }
  }

  var timezone;
  function getServerTimezone() {
    if (timezone) {
      return $q.resolve(timezone);
    }

    return Cart.getCart()
    .then(function (cartData) {
      if (!cartData.Order || !cartData.Order.order_dt) {
        return $q.reject();
      }

      var segments = cartData.Order.order_dt.split('-');

      timezone = '-' + segments[segments.length - 1];
      return timezone;
    })
  }

  var expiryDatetime;
  function getTicketExpiration() {
    if (expiryDatetime) {
      return $q.resolve(expiryDatetime);
    }

    return  TessituraSDK.GetTicketExpiration()
    .then(function (response) {
      if (!response.data || !response.data.result) {
        return $q.reject();
      }

      expiryDatetime = response.data.result[0];

      return response.data.result[0];
    });
  }

  function cartExpired() {
    $scope.cartExpired = true;
    $scope.notificationHidden = true;
    CacheStorage.set('TimeoutBar-expired', true);
    CacheStorage.remove('TimeoutBar-unhideNotifcationAt');
    CacheStorage.removeRegex(/tsdk-id=ctgtest-method=GetCart/);

    AuthenticationService.transferSession()
    .then(function () {
      $window.location = $attrs.onCartExpiredUrl;
    });
  }

  var isCartEmptyCache = null;
  function isCartEmpty() {
    if (isCartEmptyCache !== null) {
      return $q.resolve(isCartEmptyCache);
    }

    return Cart.isCartEmpty()
    .then(function (isCartEmpty) {
      isCartEmptyCache = isCartEmpty;
      return isCartEmptyCache;
    })
  }

  /**
   * Update the time remaining.
   * Gets the cart, logs the start time if an item has
   * just been added. Updates the time remaining
   * and calls clear cart if times ran over
   */
  function updateTimeRemaining(){
    $q.all([
      getTicketExpiration(),
      isCartEmpty()
    ])
    .then(function (result) {

      if (result[1] == true) {
        return $q.reject();
      }

      var fullExpirationTime = result[0] + moment().tz('America/Los_Angeles').format('Z');
      $scope.timeRemaining = moment(fullExpirationTime);
      $scope.timeRemainingDiff = moment($scope.timeRemaining.diff());

      if ($scope.timeRemaining.diff() < 0 && !$scope.cartExpired) {
        cartExpired();
      }
    })
    .catch(function () {
      $scope.timeRemaining = null;
      $scope.timeRemainingDiff = null;
      $scope.notificationHidden = true;
    });
  }

  /**
   * Hide the started "x time remaining" notification
   * Triggered when user clicks X
   */
  function hideNotification() {
    var hideAt = moment().add(notificationHideTime, 'seconds');
    CacheStorage.set('TimeoutBar-unhideNotifcationAt', hideAt.toISOString());

    $scope.notificationHidden = true;
  }
  $scope.hideNotification = hideNotification;

  /**
   * Hide the "your cart expired because..."" notification
   */
  function hideCartExpiredNotification(){
    $scope.cartExpired = false;
  }
  $scope.hideCartExpiredNotification = hideCartExpiredNotification;

  var interval;

  function start() {
    if (interval) {
      clearInterval(interval);
    }

    isCartEmpty().then(function(result) {
      if (!result) {
        interval = setInterval(updateTimeRemaining, 1000);
      }
    });
  }

  $rootScope.$on('cart.updated', function () {
    isCartEmptyCache = null;
  });

  start();
}]);
