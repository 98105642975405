app.controller('DonationCampaignController', ['$scope', '$attrs', 'TessituraSDK', '$filter', '$q', '$window', 'Donation', 'Cart', 'appConfig', 'CacheStorage', 'GetParameters', 'Router', function ($scope, $attrs, TessituraSDK, $filter, $q, $window, Donation, Cart, appConfig, CacheStorage, GetParameters, Router) {
  'use strict';

  var widgetConfig = document.getElementById('widget-config');

  if (widgetConfig) {
    widgetConfig = JSON.parse(widgetConfig.innerHTML);
  } else {
    widgetConfig = {};
  }

  var fundNo = parseFloat(widgetConfig.FundNumber || GetParameters['fund_no'] || $attrs.fundNo);

  // Is the page loading
  $scope.ready = false;

  // Is the page loading
  $scope.addingDonation = false;

  // The donation bands
  $scope.band = null;

  // Bind functions to scope
  $scope.chooseLevel = chooseLevel;
  $scope.setVal = setSelectedDonationValue;
  $scope.addDonationToCart = addDonationToCart;

  // Current select level
  $scope.chosenLevel = null;

  // Goal numbers
  $scope.disableProgressBar = !!(widgetConfig.DisableProgressBar || 0);
  $scope.campaignProgress = parseFloat(widgetConfig.ProgressPercentage || 0);
  $scope.campaignGoal = parseFloat(widgetConfig.GoalAmount || 0);
  $scope.disablePrintRecognition = !!(widgetConfig.DisablePrintRecognition || 0);
  $scope.disableGiftDonation = !!(widgetConfig.DisableGiftDonation || 0);

  // Print recognition input
  $scope.printRecognition = GetParameters['print_recognition'] || '';
  $scope.giftThisDonation = !!$scope.printRecognition;

  $q.all([
    loadDonationBands()
  ])
  .then(function () {
    $scope.ready = true;
  });

  /**
   * When the amount input is checked, update
   * which band level this corresponds to
   */
  $scope.$watch('selectedDonationValue', function(newVal){
    newVal = parseFloat(newVal);
    var newLevel = getLevelForAmount(newVal);
    chooseLevel(newLevel);
  });

  /**
   * Loads the donation bands from tessi
   */
  function loadDonationBands() {
    return Donation.getDonationBands()
    .then(function (bands) {
      $scope.band = $filter('filter')(bands, function (level) {
        return level.fund == fundNo;
      })[0];

      if ($scope.band) {
        $scope.band.levels.forEach(function (level) {
          level.collapse = true;
          level.position = parseInt(level.min, 10);
        });
      }
    });
  }

  function resetLevelPositions(){
    $scope.band.levels.map(function(level){
      level.position = parseInt(level.min, 10);
    });
  }

  /**
   * Takes in an amount, returns the the level
   * that the amount is for.
   */
  function getLevelForAmount(amount) {
    var matchingLevel = null;

    if (!$scope.band) {
      return null;
    }

    for (var i in $scope.band.levels) {
      var level = $scope.band.levels[i];
      if (amount >= level.min) {
        matchingLevel = level;
      }
    }

    return matchingLevel;
  }

  /**
   * Sets the current donation value
   * @param int value New value
   */
  function setSelectedDonationValue(value){
    $scope.selectedDonationValue = parseFloat(value);
  }

  /**
   * Sets the current level
   * @param  object level Object from band.levels
   */
  function chooseLevel(level){
    if (!$scope.band) {
      return;
    }

    angular.forEach($scope.band.levels, function(level){
      level.collapse = true;
    });

    $scope.chosenLevel = level;

    resetLevelPositions();

    if (level) {
      level.position = 0;
      level.collapse = false;
    }
  }

  /**
   * Sends the CSI for the donation
   * @return Promise
   */
  function sendCSI() {

    // Did the user want print recognition
    if ($scope.printRecognition) {
      // Get the cart for order number
      return Cart.getCart(true)
      .then(function (cartData) {

        if (cartData.Contribution.length === 0) {
          return $q.resolve();
        }

        // Copy the default CSI settings
        var csi = angular.copy(appConfig.CSISettings.donationPrintRecognition);

        // Add in the Notes
        csi.PerformanceNumber = 0;
        csi.Notes = angular.toJson({
          Name: $scope.printRecognition,
          Fund: fundNo,
          Amount: $scope.selectedDonationValue,
          OrderNumber: cartData.Order.order_no
        }, 3);

        // Get the contribution that was just added
        var contribution = cartData.Contribution[cartData.Contribution.length - 1];

        csi.type = 'csi';

        // Store the data for checkout
        CacheStorage.set('ContributionCheckoutMeta-' + contribution.ref_no, [csi]);
      });
    }

    // Theres no print recognition, return a resolved promise
    return $q.resolve();
  }

  /**
   * Add the donation to the cart.
   * Save the CSI data in local storage
   */
  function addDonationToCart() {
    if ($scope.giftThisDonation) {
      $window.location = Router.getUrl('donation.gift_membership', {
        'amount': $scope.selectedDonationValue,
        'fund_no': fundNo,
        'print_recognition': $scope.printRecognition ? $scope.printRecognition : '',
        'redirectTo': $window.location.toString(),
        'membership': true
      });

      return true;
    }

    $scope.addingDonation = true;

    return TessituraSDK.AddContribution({
      'Amount': $scope.selectedDonationValue,
      'Fund': fundNo,
      'AccountMethod': 0,
      'Renew': 'false',
      'Upgrade': 'false'
    })
    .then(function () {
      // Change the expiration time
      return TessituraSDK.ResetTicketExpiration();
    })
    .then(sendCSI)
    .then(function () {
      $window.location = $attrs.afterAddDonationUrl;
    })
    .catch(function () {
      $scope.addingDonation = false;
    });

  }


}]);
