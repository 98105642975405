app.filter('propEmpty', function () {
  return function (array, property) {

    return  array.filter(function (object) {
      var item = object[property];

      if (angular.isString(item)) {
        return item.length === 0;
      }

      if (angular.isObject(item)) {
        return Object.keys(item).length === 0;
      }

      return !item;
    });
  };
});
