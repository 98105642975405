/* global moment */
app.service(
  'PerformanceSearchService',
  ['$q', '$filter', '$http', 'appConfig', 'TessituraSDK', 'AuthenticationService', 'Router', 'InventoryService', function (
      $q,
      $filter,
      $http,
      appConfig,
      TessituraSDK,
      AuthenticationService,
      Router,
      InventoryService
    ) {
  'use strict';

  var events = [],
      find = $filter('find'),
      times = [],
      prices = [];

  return {
    events: events,
    times: times,
    getEvents: getEvents,
    getSortOrders : getSortOrders,
    getTimes : getTimes,
    getTimeByIndex: getTimeByIndex,
    getGenres : getGenres,
    getAgeAppropriate: getAgeAppropriate,
    getPrices : getPrices,
    setPrices: setPrices,
    resetPrices: resetPrices,
    getPriceByIndex: getPriceByIndex,
    getThemes : getThemes,
    getPerformanceAvailabilitySPROC : getPerformanceAvailabilitySPROC,
    getPerformanceKeywords : getPerformanceKeywords,
    getFacebookFriends : getFacebookFriends,
    getEventByProdSeasonNumber:getEventByProdSeasonNumber,
    updateSearchURL: updateSearchURL,
    getDOW: getDOW,
    getAnyShowObject: getAnyShowObject,
    decodeParams: decodeParams,
    getCMSEvents: getCMSEvents
  };

  function getFacebookFriends(fbFriendsParams){
    return AuthenticationService.getLoginInfo().then(function (loginInfo) {
      if (loginInfo.user_type === 'facebook') {
        fbFriendsParams = fbFriendsParams || {};
        return TessituraSDK.GetFacebookFriends(fbFriendsParams);
      }

      return $q.resolve({});
    });
  }

  function getPerformanceAvailabilitySPROC(params){
    return TessituraSDK.GetPerformanceAvailabilitySPROC(params)
  }

  function getPerformanceKeywords(params){
    return TessituraSDK.GetPerformanceKeywords(params)
  }

  function getEventByProdSeasonNumber(prodSeasonNo){
    var result = events.filter(function(event){
      return event.prod_season_no === prodSeasonNo;
    })[0];

    if(result){
      return result;
    }else{
      return getAnyShowObject();
    }
  }

  function getAnyShowObject(){
    return {
      description :'any show',
      prod_season_no : 0
    }
  }


  function getEvents(params) {
    return InventoryService.GetPerformancesEx4(params).then(function (response) {
      response.Performance = response.Performance.filter(function (event) {
        return event.on_sale_ind !== 'N';
      });

      events = response.Performance;

      return response;
    });
  }

  function getSortOrders(){
    return $q(function(resolve, reject) {
      resolve(
        [
          {'id' : 1, 'title': 'Price (Low to high)', 'exp' : {'predicate': ['price', 'timestamp'], 'reverse' : false} },
          {'id' : 2, 'title': 'Price (High to low)','exp' : {'predicate': ['price', 'timestamp'], 'reverse' : true}},
          {'id' : 3, 'title': 'Date (ascending)', 'exp' : {'predicate': ['timestamp', 'price'], 'reverse' : false}},
          {'id' : 4, 'title': 'Date (descending)', 'exp' : {'predicate': ['timestamp', 'price'], 'reverse' : true}}
        ]
      );
    });
  }

  function getTimes(){
    return $q(function(resolve){
      if(times.length > 0){
        resolve(times);
      }else{
        var now = moment();
        times.push({'val':{'display':true}, 'diff':{min:1, max:10000}, 'title' : 'any time'});
        times.push({'val': {'display':true, 'start' : moment(), 'end': moment().add(7, 'day') }, 'diff' : {min:1, max:7}, 'title': 'next 7 days'});
        times.push({'val': {'display':true, 'start' : moment(), 'end' : moment().add(30, 'day') }, 'diff' : {min:1, max:30}, 'title': 'next 30 days'});
        for (var i = 0; i < 4; i++) {
          var momentObj = moment( [moment().format('YYYY'), moment().format('MM'), 1]).add(i, 'month');
          var diff = momentObj.diff(now, 'days');
          var end = moment(momentObj).endOf('month').date();
          times.push(
            {
            'val': {'display':true, 'start' : moment(momentObj), 'end' : moment(momentObj).add(1, 'month')}, 'diff': {min:diff, max:diff + end}, 'title' :' in ' + moment(momentObj).format('MMMM')
            }
          );
        };
        resolve(times);
      }
    });
  }

  function getTimeByIndex(index){
    return times[index];
  }

  function getPriceByIndex(index){
    return prices[index];
  }

  function getGenres(){
    return [
      'Classics',
      'Comedy',
      'Dance',
      'Drama',
      'Musical',
      'New',
      'Work',
      'Opera'
    ];
  }

  function getAgeAppropriate(){
    return [
      'Adult Oriented 17+',
      'ages 16+',
      'ages 11+',
      'Family Friendly'
    ];
  }

  function getPrices(){

    if(prices.length > 0){
      return prices;
    }else{
      prices = [
        {'id':3,'title': 'any price', val:[0]},
        {'id':1,'title': 'less than $50', val:[0, 49]},
        {'id':2,'title': 'less than $100', val:[0, 99]},
        {'id':4,'title': 'free events', val:[-1, 1]}
      ];
      return prices;
    }
  }

  function setPrices(filteredPrices)
  {
    prices = filteredPrices;
  }

  function resetPrices()
  {
    prices = [
      {'id':3,'title': 'any price', val:[0]},
      {'id':1,'title': 'less than $50', val:[0, 49]},
      {'id':2,'title': 'less than $100', val:[0, 99]},
      {'id':4,'title': 'free events', val:[-1, 1]}
    ];

    return prices;
  }

  function getThemes(){
    return [
      'African-American',
      'Asian',
      'Gay Issues',
      'LA Oriented',
      'Latino',
      'Multicultural',
      'Political',
      'Women\'s issues'
    ];
  }

  function getDOW(){
    return [
      1,2,3,4,5,6,7
    ];
  }

  function encodeParams(params) {
    return 'search='+JSON.stringify(params);
  }

  function decodeParams(params) {

    if(!angular.equals({}, params )){
      if(params.eventT){
        return params;
      }else{

        try {
          return JSON.parse(params.search);
        } catch(e) {

        }

      }
    }

    return{};
}


  function updateSearchURL(searchFilters, chosenFilters, facetFilters, extraFacetsSearchObj){

    var surl = '';
    var searchObj = {};
    surl += 'eventT='+searchFilters.event.prod_season_no;
    surl += '&timeT='+searchFilters.time.title;

    searchObj.eventT = searchFilters.event.prod_season_no;
    searchObj.timeT = searchFilters.time.title;

    if(searchFilters.time.val !== undefined){
      if(searchFilters.time.val.start !== undefined && searchFilters.time.val.end !== undefined){
        surl += '&timeS='+moment(searchFilters.time.val.start).toJSON();
        surl += '&timeE='+moment(searchFilters.time.val.end).toJSON();
        searchObj.timeS = moment(searchFilters.time.val.start).toJSON();
        searchObj.timeE = moment(searchFilters.time.val.end).toJSON();
      }
    }

    if(chosenFilters.order){
      surl += '&order='+chosenFilters.order;
      searchObj.order = chosenFilters.order;
    }

    if(chosenFilters.tickets.quantity){
      surl += '&quantity='+chosenFilters.tickets.quantity;
      searchObj.quantity = chosenFilters.tickets.quantity;
    }

    if(searchFilters.price !== undefined){
      surl += '&priceId='+searchFilters.price.id;
      searchObj.priceId = searchFilters.price.id;
    }

    if(searchFilters.price.val.length >0){
      surl += '&priceMin='+searchFilters.price.val[0];
      surl += '&priceMax='+searchFilters.price.val[1];
      searchObj.priceMin = searchFilters.price.val[0];
      searchObj.priceMax = searchFilters.price.val[1];
    }

    if(facetFilters){
      surl += '&facets='+JSON.stringify(facetFilters);
      searchObj.facets = facetFilters;
    }

    if(extraFacetsSearchObj){
      surl += '&xfacets='+JSON.stringify(extraFacetsSearchObj);
      searchObj.xfacets = extraFacetsSearchObj;
    }

    return encodeParams(searchObj);

  }

  function getCMSEvents(params) {
    var now = moment();
    return InventoryService.GetPerformancesEx4(params).then(function (response) {
      var performances = response.Performance;

      // Get rid of non on sale events first
      performances = performances.filter(function(event){
        return now > moment(event.start_dt) && now < moment(event.end_dt);
      });

      // Filter out any Digital Performances as that data comes from somewhere else
      var productionPerformances = performances.filter(function(event){
        return appConfig.digital_prod_type_ids.indexOf(parseInt(event.prod_type, 10)) === -1;
      });
      var videoPerformances = performances.filter(function(event){
        return appConfig.digital_prod_type_ids.indexOf(parseInt(event.prod_type, 10)) > -1;
      });

      var prodSeasonNumbers = productionPerformances.map(function (perf) {
        perf.is_digital = false;
        return perf.prod_season_no;
      });

      prodSeasonNumbers = $filter('unique')(prodSeasonNumbers);

      prodSeasonNumbers = prodSeasonNumbers.reduce(function (acc, prodSeasonNo) {
        acc.push('event[]=' + prodSeasonNo);
        return acc;
      }, []).join('&');

      var perfNos = videoPerformances.map(function (perf) {
        perf.is_digital = true;
        return 'perf_no[]=' + perf.perf_no;
      });

      var eventsServicePromise = $q.all([
        $http.get( Router.getUrl('cms.event', { prod_no: '' }) + '?' + prodSeasonNumbers ),
        $http.get( Router.getUrl('cms.video_event_data') + '?' + perfNos.join('&') ),
      ]);

      return eventsServicePromise.then(function (eventsResponses) {
        var eventsResponse = eventsResponses[0];
        var videosResponse = eventsResponses[1];
        var validEvents = [];

        angular.forEach(eventsResponse.data, function (event, prodSeasonNo) {
          if (event !== false) {
            validEvents.push( parseInt(prodSeasonNo, 10) );
          }
        });

        var validPerfs = Object.keys(videosResponse.data).reduce(function (carry, perfNo) {
          if (videosResponse.data[perfNo]) {
            carry.push(parseInt(perfNo, 10));
          }
          return carry;
        }, []);

        var performanceNumbers = performances.map(function (performance) {
          return parseInt(performance.perf_no, 10);
        });

        performances = performances.filter(function (perf) {
          return validEvents.indexOf(parseInt(perf.prod_season_no, 10)) !== -1 || validPerfs.indexOf(parseInt(perf.perf_no)) !== -1;
        });

        performances.forEach(function (performance) {
          performance.cmsFields = eventsResponse.data[performance.prod_season_no] ?
            eventsResponse.data[performance.prod_season_no]
            : videosResponse.data[performance.perf_no];
        });

        // Creates duplicate performances for video events if spanning more than 1 day
        validPerfs.forEach(function (perfNo) {
          var cmsPerfData = videosResponse.data[perfNo];
          var originalPerf = find(performances, function (perf) {return parseInt(perf.perf_no, 10) === perfNo});
          originalPerf.full_day = false;
          originalPerf.time_prefix = '';

          if (cmsPerfData && originalPerf) {  // If should be unnessary but hey, better to be safe than sorry :)
            var startMoment = moment(cmsPerfData.VideoStartDate);
            var endMoment = moment(cmsPerfData.VideoEndDate);
            var testEndOfDayDate = startMoment.clone().endOf('day');

            while (endMoment > testEndOfDayDate) {
              testEndOfDayDate.add(4, 'h').endOf('day');
              var newPerf = Object.assign({}, originalPerf);

              if (endMoment > testEndOfDayDate) {
                // Add a full day performance
                newPerf.perf_date = testEndOfDayDate.clone().startOf('day').format();
                newPerf.full_day = true;
              } else {
                // Add the performance up to the end time
                newPerf.perf_date = endMoment.format();
                newPerf.time_prefix = 'Until ';
              }

              performances.push(newPerf);
            }
          }
        });

        response.Performance = performances;
        response.WebContent = response.WebContent.filter(function (webContent) {
          return performanceNumbers.indexOf( parseInt(webContent.orig_inv_no, 10) ) !== -1;
        });

        events = performances;

        return $q.when(response);
      });

    });
  }
}]);
