/* global moment */
app.controller('ElementController', ['$q', '$scope', '$filter', '$window', '$sce', '$modal', 'appConfig', 'TessituraSDK', 'TessituraRESTSDK', 'Router', 'CacheStorage', 'Exchange', 'GoogleTagManager', 'Cart', 'AuthenticationService', 'proxyClient', function ($q, $scope, $filter, $window, $sce, $modal, appConfig, TessituraSDK, TessituraRESTSDK, Router, CacheStorage, Exchange, GoogleTagManager, Cart, AuthenticationService, proxyClient) {
    var
        defaultAddressStreet1 = 'web added',
        paymentMethods,
        cartData,
        emailAddress,
        arrayWrap = $filter('arrayWrap'),
        unique = $filter('unique'),
        recaptchaReady = false;

    GoogleTagManager.sendCheckoutStep(3);

    $scope = angular.extend($scope, {
        loading: 0,
        cartData: {},
        paymentDetailsNeeded: true,
        reloadOrderSummary: function () {},
        cards: [],
        chosenCard: null,
        isGuest: false,
        disableSavedCard: false,
        guestEmail: null,
        processingOrder: false,
        redeemAGiftCertificate: redeemAGiftCertificate,
        processOrder: processOrder,
        termsAccepted: true,
        savedCard: false,
        newCard: true,
        iframe: null,
        saveCard: false,
        newCardName: null,
        noCardNeeded: false,
        hasValidAddress: false,
        processingElementOrder: false,
        processingError: null,
        usingSpecialCard: false,
        showCovidTermsAgreementMessage: false,
        agreedToTerms: agreedToTerms,
        recaptchaValidated: false
    });

    // Init recapctcha
    window.recaptchaOnLoad = function () {
        recaptchaReady = true;
    }

    // Start the loading icon
    $scope.loading++;

    // 1) Check we are following the exchange or allocation
    redirectIfRulesFailure()

    // 2) Make sure the user has logged in
    .then(AuthenticationService.requireLogin)

    // 3) Take user back to the cart page if session expired
    .then(redirectIfSessionExpired)

    // 4) Take the user back to the cart page if there is empty
    .then(redirectIfCartEmpty)

    // 5) Load the cart data and credit card icc type
    .then(reloadCartData)

    // 6) Check if this is an anonymous user
    .then(AuthenticationService.isUserRegistered)

    // 7) Check if address has been set
    .then(checkForValidAddress)

    // 8) Finally, extend the time by two minutes
    .then(extendSessionTime)

    // 9) Get email address (optionally)
    .then(getEmailAddress)

    // Everything is loaded!
    .then(function () {
        $scope.loading--;
    });

    function agreedToTerms(value) {
        $scope.termsAccepted = value;
    }


    /**
     * Checks if the user is following the exchange or allocation rules
     * Sends them to the cart if they arent where they will see an error message
     * @return {Promise}
     */
    function redirectIfRulesFailure() {
      return $q.all([
        Exchange.checkExchangeModeRules(), Cart.checkAllocation()
      ]).then(function (results) {
        if (results[0] == false || results[1].length) {
          $window.location = Router.getUrl('booking.basket');
          return $q.reject();
        }

        return $q.resolve();
      });
    }

    function redirectIfCartEmpty() {
        return Cart.isCartEmpty()
        .then(function (result) {
            if (result == true) {
                $window.location = Router.getUrl('booking.basket');
                return $q.reject();
            }
        });
    }

    function redirectIfSessionExpired() {
      return TessituraSDK.GetCart(true)
      .catch(function () {
          $window.location = Router.getUrl('booking.basket');
          return $q.reject();
      });
    }

    function getEmailAddress() {
      if (parseInt(cartData.Order.shipping_method, 10) !== appConfig.digital_tickets_delivery_method) {
        return $q.resolve();
      }

      return AuthenticationService.getEmailAddress().then(function (email) {
        emailAddress = email;
      });
    }

    function checkForValidAddress(isUserRegistered) {
        if (!isUserRegistered) {
          return TessituraSDK.GetCart(true)
          .then(function (response) {
            var
                result = response.data.result,
                cartResults = result.GetCartResults,
                order = cartResults.Order;

            $scope.hasValidAddress = 'address_no' in order;
          });
        } else {
          return TessituraSDK.GetConstituentInfoEx()
          .then(function (response) {
              var
                result = response.data.result,
                constituentInfo = result.GetConstituentInfoExResults,
                addresses = arrayWrap(constituentInfo.Addresses);

              addresses = $filter('filter')(addresses, function (address) {
                return address.street1 != defaultAddressStreet1 && address.primary_ind == 'Y' && address.inactive == 'N';
              });

              $scope.hasValidAddress = !!addresses.length;
          });
        }
    }

    function extendSessionTime() {
      return TessituraSDK.ResetTicketExpiration({
        checkout: true
      });
    }

    /**
     * Sets up the UI for not needed a credit card
     */
    function noCardNeeded() {
        $scope.noCardNeeded = true;
    }

    /**
     * Loads the element payment window for when a card is needed
     * @return {[type]} [description]
     */
    function loadElement() {
        return AuthenticationService.isUserRegistered()
        .then(function (isUserRegistered) {
            if (isUserRegistered) {
              return $q.all([
                getElementTokens(),
                loadSavedCards()
              ])
            } else {
              $scope.newCard = true;
              $scope.isGuest = true;
              return getElementTokens();
            }
        })
        .catch(function (errors) {
          $scope.processingError = errors;
        });
    }

    /**
     * Loads the element payment window for when a card is needed
     * Gets the unique token needed to make the transaction
     * @return {[type]} [description]
     */
    function getElementTokens() {
        var deferred = $q.defer();

        var interval = setInterval(function () {
            if (recaptchaReady === false) {
                return;
            }

            clearInterval(interval);

            window.grecaptcha.render('checkout-recaptcha', {
                sitekey: appConfig.reCaptchaSiteKey,
                callback: function (recaptchaResponse) {
                    TessituraSDK.GetElementTokens({
                        orderTotal: cartData.Order.order_total,
                        method: 3,
                        kioskClient: false,
                        token: recaptchaResponse
                    }).then(function (response) {
                        if (!response.data.result) {
                            // There has been an error - end it here
                            return deferred.reject('Sorry, we could not process your payment transaction.');
                        }

                        $scope.iframe = appConfig.elementEndpoint + '?TransactionSetupID=' + response.data.result;
                        $scope.recaptchaValidated = true;

                        return deferred.resolve(true);
                    }).catch(function (error) {
                        //'We could not process your payment transaction.');
                        return deferred.reject('Sorry, we could not process your payment transaction.');
                    })
                }
            });
        }, 100);

        return deferred.promise;
    };

    function reloadCartData() {
        return Cart.getCart(true).then(function (_cartData) {

            // Map variables
            cartData = _cartData;
            $scope.cartData = cartData;

            // Redirect to basket if cart is empty
            if (!(cartData.Order && (cartData.SubLineItem  || cartData.PackageSubLineItem  || cartData.Contribution || cartData.Payment))) {
                $window.location = Router.getUrl('booking.basket');
                return $q.defer().promise;
            }

            var ticketsInCovidTermsFacilities = cartData.SubLineItem.filter(function (item) {
                return appConfig.covid_terms_facilities.indexOf(parseInt(item.facility_no)) > -1;
            });

            if (ticketsInCovidTermsFacilities.length <= 0) {
                ticketsInCovidTermsFacilities = cartData.PackageSubLineItem.filter(function (item) {
                    return appConfig.covid_terms_facilities.indexOf(parseInt(item.facility_no)) > -1;
                });
            }

            if (document.querySelector('#covid-terms-message') && ticketsInCovidTermsFacilities.length > 0) {
                $scope.showCovidTermsAgreementMessage = true;
                $scope.termsAccepted = false;
                return showCovidPopup();
            }

            // Disable saved cards if this is an YAP application
            $scope.disableSavedCard = ((
              'notes' in cartData.Order &&
              angular.isString(cartData.Order.notes) &&
              cartData.Order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
            ) || (
              'custom_6' in cartData.Order &&
              angular.isString(cartData.Order.custom_6) &&
              cartData.Order.custom_6.length
            ));
        })
        // Decide if we need to load element
        .then(function () {
            if (paymentDetailsNeeded()) {
                loadElement();
            } else {
                noCardNeeded();
            }
        });
    };

    function showCovidPopup() {
        var modalInstance = $modal.open({
            backdrop: 'static',
            windowClass: 'covid-terms-popup',
            controller: 'CovidTermsController',
            templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'covid-terms.html'),
            resolve: {
                terms: function () {
                    var termsMsg = document.querySelector('#covid-terms-message').innerText;
                    return $sce.trustAsHtml(termsMsg);
                },
                agreedToTerms: function() {
                    return function (state) {
                        agreedToTerms(state);
                        modalInstance.close();
                    }
                },
            }
        });
    }

    function loadSavedCards() {
      var fontAwesomeIcons = {
        "Visa": 'cc-visa',
        "Master Card": 'cc-mastercard',
        "American Express": 'cc-amex',
        "Discover": 'cc-discover',
        "Diner's Club": 'cc-diners-club',
      };

      return TessituraSDK.GetUserCards().then(function (response) {
        var
          result = response.data.result,
          cards = arrayWrap(result.ExecuteLocalProcedureResults.LocalProcedure);

        cards.forEach(function (card, i) {
          cards[i].fa = fontAwesomeIcons[card.description];
        });

        $scope.cards = cards;

        $scope.newCard = false;
        $scope.savedCard = true;
      });
    }

    /**
     * Checks if the user needs to pay anything based on the
     * balance_to_charge value of the order
     * @return {Boolean}
     */
    function paymentDetailsNeeded() {
        return parseInt(cartData.Order.balance_to_charge) > 0;
    }

    /**
     * Listen to a message posted by the iframe.
     * If its true, this is probably from the Element iframe
     * @param event
     * @param data
     */
    angular.element($window).bind('message', function(event) {

      event = event.originalEvent || event;
      if (event && event.data) {
        try {
          var response = angular.fromJson(event.data);
          if (response.client == proxyClient) {
            $scope.processingElementOrder = true;
            $scope.processingError = null;
            handleMessage(response);
          }
        } catch (_error) {
          $scope.processingElementOrder = false;
          $scope.processingError = null;
        }
      }
    });

    /**
     * Handles a message passed from postMessage.
     * If the property true is set, it assumes the cart was checked out.
     * @param  {Object} data postMessage data parsed from json
     */
    function handleMessage(data) {
        if (data.result === 'completed') {
          // This user done something messed up to access the iframe directly
          // after a payment has gone through - lets not let them through, shall we?
          alert([
            'This order has already been completed.',
            'If you think this is an error, please contact Audience Services at 213.628.2772.',
            'You will now be taken to the cart page.'
          ].join(' '));

          return resetSession()
          .then(function () {
              // Take user to cart page
              $window.location = Router.getUrl('booking.basket');
          });

        } else if (data.result === 'cancelled') {
          // The user cancelled the transaction, return to cart page
          $window.location = Router.getUrl('booking.basket');

        } else if (data.result === 'refunded expired session' || data.result === 'refunded anonymous session') {
          // The user's session timed out while we were processing checkout
          alert([
            'Sorry, there was a problem with your transaction.',
            'Please try again or contact Audience Services at 213.628.2772 if the problem persists.',
            'You will now be taken to the cart page.'
          ].join(' '));

          return resetSession()
          .then(function () {
              // Take user to cart page
              $window.location = Router.getUrl('booking.basket');
          });

        } else if (data.result === 'not refunded expired session' || data.result === 'not refunded anonymous session') {
          // The user's session timed out while we were processing checkout
          alert([
            'Sorry, there was a problem with your transaction, and your payment could not be voided.',
            'Please try again or contact Audience Services at 213.628.2772 if the problem persists.',
            'You will now be taken to the cart page.'
          ].join(' '));

          return resetSession()
          .then(function () {
              // Take user to cart page
              $window.location = Router.getUrl('booking.basket');
          });

        } else if (data.result === 'refunded failed checkout' || data.result === 'refunded') {
          // There was a problem with the transaction and user has been refunded
          $scope.processingError = [
            'Sorry, there was a problem with your transaction.',
            'Please try again or contact Audience Services at 213.628.2772 if the problem persists.'
          ].join(' ');

          $scope.processingElementOrder = false;
        } else if (data.result === 'presale') {
            // There was presale active and user used wrong card
            $scope.processingError =  data.cardError;
            $scope.processingElementOrder = false;
            $scope.usingSpecialCard = true;
        } else if (data.result === 'not refunded failed checkout' || data.result === 'not refunded') {
          // There was a problem with the transaction and user has not been refunded
          $scope.processingError = [
            'Sorry, there was a problem with your transaction, and your payment could not be voided.',
            'Please contact Audience Services at 213.628.2772.'
          ].join(' ');

          $scope.processingElementOrder = false;
        } else if (data.result) {

            // deferred
            var deferred = $q.defer();

            // If save card option is ticked
            if (!$scope.isGuest && $scope.saveCard) {
              // Pass the nickname
              TessituraSDK.AddUserCard({
                nickname: $scope.newCardName
              }).then(function () {
                deferred.resolve(true);
              }).catch(function () {
                deferred.resolve(true);
              });
            } else {
              deferred.resolve(true);
            }

            deferred.promise.then(function () {
              // Do any post processing like sending CSI's
              return postCheckoutProcessing();
            }).then(function () {
              // Send the order confirmation email
              return TessituraSDK.SendLastOrderConfirmationEmail();
            }).then(function () {
              return sendDigitalTickets();
            }).then(function () {
                return resetSession();
            }).then(function () {
                // On successful completion forward the user to the confirm page
                $window.location = Router.getUrl('booking.confirmation', {
                  orderNo: cartData.Order.order_no
                });
            }).catch(function (error) {
                // If there is an error, show it to the user
                // and disable the loading icon
                $scope.processingError = error.data.error;

                $scope.processingElementOrder = false;
            });
        }

        if ($scope.processingError !== null) {
            reloadCartData();
        }
    };

    /**
     * Completes the checkout process where the user
     * has no balance in their cart
     * @return {[type]} [description]
     */
    function processOrder() {
        $scope.processingOrder = true;
        $scope.processingError = null;

        /**
         * ** READ ME
         * When we implement saved cards, add the logic here, like
         *
         * if usingSaveCard:
         *     promise = checkoutWithSavedCard();
         * else:
         *     promise = checkout();
         *
         * promise.then(
         *   //.. Rest of code here
         */

        var promise;

        if ($scope.noCardNeeded) {
          promise = checkoutNoPaymentDetails();
        } else {
          if (typeof $scope.chosenCard.code === 'undefined') {
            // If there is an error, show it to the user and disable the loading icon
            $scope.processingError = 'Please enter your credit card\'s 3 or 4 digit CVV security code.';

            $scope.processingOrder = false;

            return false;
          }

          promise = checkoutWithSavedCard();
        }

        promise.then(function () {
          // Do any post processing like sending CSI's
          return postCheckoutProcessing();
        }).then(function () {
          // Send the order confirmation email
          return TessituraSDK.SendOrderConfirmationEmail({
            iOrderNo: cartData.Order.order_no
          });
        }).then(function () {
          return sendDigitalTickets();
        }).then(function () {
          return resetSession();
        }).then(function () {
            // On successful completion forward the user to the confirm page
            $window.location = Router.getUrl('booking.confirmation', {
              orderNo: cartData.Order.order_no
            });
        }).catch(function (error) {
            // If there is an error, show it to the user
            // and disable the loading icon
            $scope.processingError = error && error.data ? error.data.error : error;

            $scope.processingOrder = false;
        });
    };

    /**
     * Does any remainder tasks after checking out
     * like saving the gift card CSI
     */
    function postCheckoutProcessing() {
        var promises = [];

        if ($scope.showCovidTermsAgreementMessage) {
            var covidCSI = angular.copy(appConfig.CSISettings.covidTerms);

            covidCSI.PerformanceNumber = 0;
            covidCSI.Notes = angular.toJson({
                OrderNumber: cartData.Order.order_no
            });

            promises.push(sendCSI(covidCSI));
        }

        if (CacheStorage.has('syosDonation')) {
            var csi = angular.copy(appConfig.CSISettings.donationPrintRecognition);

            csi.PerformanceNumber = 0;
            csi.Notes = angular.toJson({
                PatronID: cartData.Order.customer_no,
                OrderNumber: cartData.Order.order_no
            });

            promises.push(sendCSI(csi));

            CacheStorage.remove('syosDonation');
        }

        // Check all the payment methods and see if theres an gift card
        // Post a CSI with the JSON
        arrayWrap(cartData.Payment).forEach(function (payment) {
            var cacheKey = 'BuyGiftCertificateMeta-' + payment.gc_no;

            if (CacheStorage.has(cacheKey)) {
                var giftCardData = CacheStorage.get(cacheKey);

                promises.push(sendCSI(giftCardData.csi));

                if (giftCardData.emailGiftCertificate) {
                    promises.push(giftCertificatePrintout(giftCardData.emailGiftCertificate));
                }

                CacheStorage.remove(cacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^BuyGiftCertificateMeta\-.*/);

        // Check is we had any CSI data to be submitted for contributions
        arrayWrap(cartData.Contribution).forEach(function (contribution) {
            // key the meta data may be under
            var cacheKey = 'ContributionCheckoutMeta-' + contribution.ref_no;

            // Check if we have the key
            if (CacheStorage.has(cacheKey)) {
                // Get the data from local sotrage
                var checkoutData = CacheStorage.get(cacheKey);

                // It should be an array of objects that are either
                // csi or emailGiftCertficate objects
                if (angular.isArray(checkoutData)) {
                    // For each object, work out what it is
                    // and pass it to the correct handler.
                    checkoutData.forEach(function (data) {

                        switch (data.type) {
                            case 'csi':
                                promises.push(sendCSI(data));
                                break;
                            case 'emailGiftCertificate':
                                promises.push(giftCertificatePrintout(data));
                                break;
                        }

                    });
                }

                // Delete any extra abandoned data
                CacheStorage.remove(cacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^ContributionCheckoutMeta\-.*/);

        // Check all performances to see if there's an ADA request
        // Post a CSI with the JSON
        var subLineItems =  arrayWrap(cartData.SubLineItem);

        // Grab performance IDs of unique subline items
        subLineItems = unique(subLineItems, 'perf_no').map(function (subLineItem) {
            return subLineItem.perf_no;
        });

        // Check if ADA exists for these performances
        subLineItems.forEach(function (perfNo) {
            var cacheKey = 'PerformanceADAMeta-' + perfNo;

            if (CacheStorage.has(cacheKey)) {
                promises.push(sendCSI( CacheStorage.get(cacheKey) ));

                // Remove it from local storage
                CacheStorage.remove(cacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^PerformanceADAMeta\-.*/);

        // Check all packages to see if there's an ADA request
        var packageSubLineItems =  arrayWrap(cartData.PackageSubLineItem);

        // Grab performance IDs of unique subline items
        packageSubLineItems = unique(packageSubLineItems, 'pkg_no').map(function (packageSubLineItem) {
            return packageSubLineItem.pkg_no;
        });

        // Check if ADA exists for these packages
        packageSubLineItems.forEach(function (pkgNo) {
            var
                adaCacheKey = 'PackageADAMeta-' + pkgNo,
                renewCacheKey = 'PackageRenewMeta-' + pkgNo;

            if (CacheStorage.has(adaCacheKey)) {
                promises.push(sendCSI( CacheStorage.get(adaCacheKey) ));

                // Remove it from local storage
                CacheStorage.remove(adaCacheKey);
            }

            if (CacheStorage.has(renewCacheKey)) {
                promises.push(sendCSI( CacheStorage.get(renewCacheKey) ));

                // Remove it from local storage
                CacheStorage.remove(renewCacheKey);
            }
        });

        // Remove any remaining CSI meta
        CacheStorage.removeRegex(/^PackageADAMeta\-.*/);
        CacheStorage.removeRegex(/^PackageRenewMeta\-.*/);

        // Check if we have any generic CSI
        if (CacheStorage.has('AdditionalMeta')) {
          var additionalMeta = CacheStorage.get('AdditionalMeta');

          if (angular.isArray(additionalMeta)) {
            additionalMeta.forEach(function (csi) {
              promises.push(sendCSI(csi));
            });
          }

          CacheStorage.remove('AdditionalMeta');
        }

        return $q.all(promises);
    };

    /**
     * Wrapper for TessituraSDK.AddCustomerServiceIssue
     * @param  {Object} data
     * @return {Promise}
     */
    function sendCSI(data) {
        return TessituraSDK.AddCustomerServiceIssue(data);
    };

    /**
     * Wrapper for TessituraSDK.GetGiftCertificatePrintout
     * @param  {Object} data
     * @return {Promise}
     */
    function giftCertificatePrintout(data) {
        return TessituraSDK.GetGiftCertificatePrintout(data);
    };

    /**
     * Performs a standard checkout for a 0 value card
     * @return {Promise}
     */
    function checkoutNoPaymentDetails() {
        return TessituraSDK.Checkout();
    };

    /**
     * Checks out the current session using a saved card
     * @return {Promise}
     */
    function checkoutWithSavedCard() {
        return TessituraSDK.PaymentWithSavedCard({
            accountNo: $scope.chosenCard.act_id,
            card: $scope.chosenCard.description,
            cvv: $scope.chosenCard.code,
            expiryDate: $scope.chosenCard.card_expiry_dt,
            lastFour: $scope.chosenCard.act_no_four
        });
    };

    /**
     * Opens the redeem a gift certificate popup
     * @return {[type]} [description]
     */
    function redeemAGiftCertificate() {
        var modalInstance = $modal.open({
            backdrop: 'static',
            windowClass: 'donation-popup-modal',
            templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'redeem-a-gift-certificate.html'),
            controller: 'RedeemGiftCertificate',
            resolve: {
                orderAmount: function () {
                    return cartData.Order.balance_to_charge;
                }
            }
        });

        modalInstance.result.then(function () {
            reloadCartData();
            $scope.reloadOrderSummary();
        });
    };

    function sendDigitalTickets() {
      if (parseInt(cartData.Order.shipping_method, 10) !== appConfig.digital_tickets_delivery_method) {
        return $q.resolve();
      }

      return TessituraRESTSDK.post('/Emails/ConstituentInfo/' + cartData.Order.customer_no + '/Send', {
        TemplateId: appConfig.digital_tickets_template_id,
        EmailAddress: emailAddress,
        EmailProfileId: appConfig.digital_tickets_email_profile_id,
        NameValues: [
          {
            Name: 'OrderId',
            Value: cartData.Order.order_no
          }
        ]
      });
    };

    function resetSession() {
      // Transfer the old session to the new key
      return AuthenticationService.transferSession()
      .then(function () {
        // Shift back into standard mode of sale for customer
        return AuthenticationService.subscriberShift();
      });
    };
}]);
