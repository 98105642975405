/* global moment */
app.controller('FlexPackageController', ['$scope', '$window', '$attrs', 'TessituraSDK', '$http', '$filter', 'AuthenticationService', '$q', 'Router', 'GetParameters', 'appConfig', 'MosSwitcher', function ($scope, $window, $attrs, TessituraSDK, $http, $filter, AuthenticationService, $q, Router, GetParameters, appConfig, MosSwitcher) {

  $scope.basket = [];
  $scope.subscriptionLimits = [];
  $scope.remainder = 3;
  $scope.subscriptionAmount = 2;

  $scope.numberToSelect = 4;
  $scope.totalItems = $scope.basket.length;
  $scope.numberOfSelectedDates = 0;
  $scope.packages = [];

  $scope.wrongMos = false;
  $scope.wrongMosReason = "";

  var subscriptionLimit = 6;
  var modeOfSale = appConfig.packageMos;
  var packageNumber = parseInt($attrs.packageNumber || GetParameters.packageNumber);

  for (var i = 1; i <= subscriptionLimit; i++) {
    $scope.subscriptionLimits.push(i);
  };

  // Try to switch the mode of sale
  // if it fails, get the error and display it to the user
  MosSwitcher.packageMos()
  .catch(function () {
    $scope.wrongMos = true;

    MosSwitcher.reasonForMosSwitchFailure('package')
    .then(function (reason) {
      $scope.wrongMosReason = reason;
    });

  });

  AuthenticationService.requireLogin()
    .then(function () {
      var GetNFSPackageDetailExPromises = [];

      // Multiple packages can be added here
      GetNFSPackageDetailExPromises.push(TessituraSDK.GetNFSPackageDetailEx({
        'PackageID':packageNumber,
        'ModeOfSale': modeOfSale
      }));

      // Wait till we have the data for all the packages
      $q.all(GetNFSPackageDetailExPromises).then(
          function(response){
            // For each package process and group the performances
            angular.forEach(response, function(result){
              var data = result.data.result.GetNFSPackageDetailExResult;
              var PerformanceGroup = data.PerformanceGroup;
              var package = data.Package;

              data.Performance = $filter('arrayWrap')(data.Performance);
              data.PerformanceWebContent = $filter('arrayWrap')(data.PerformanceWebContent);

              // Add meta data to each performance
              angular.forEach(data.Performance, function(perf, index){
                // Add a moment date for performance
                perf.month = moment(perf.perf_dt).format('MMMM YYYY');

                perf.webContent = $filter('filter')(data.PerformanceWebContent, {
                  orig_inv_no: perf.perf_no
                });

                perf.DYO_Season_Name = dyoSeasonName(perf.webContent);
                perf.longTitle = $filter('performanceLongTitle')(perf);
              });


              // Place to store the groups of performances
              var groups = {};

              // Go over each performance and add them to a group base
              // on the DYO_Season_Name field
              angular.forEach(data.Performance, function(performance){

                // Exit out if DYO_Season_Name isnt set
                if (!performance.DYO_Season_Name) {
                  return;
                }

                var DYO_Season_Name = performance.DYO_Season_Name;

                // Check when made a group object for this group
                if (!groups[DYO_Season_Name]) {
                  groups[DYO_Season_Name] = {
                    description: DYO_Season_Name,
                    performances: []
                  };
                }

                // Add the performance to the group
                groups[DYO_Season_Name].performances.push(performance);

              });

              // Group the performances by their description
              angular.forEach(groups, function(group){
                group.performances = $filter('groupBy')(group.performances, 'description');
              });

              // Add the performance groups to the package
              package.perf_groups = groups;

              // Add the package to be displayed on the page
              $scope.packages.push(package);

            });
          }
        );
      });


  $scope.setNumberOfSelectedDates = function(group){

    if(group.selectedPerformance === undefined || group.selectedPerformance === null) {
      group.active = false;
      $scope.numberOfSelectedDates--;
    }else{

      if(!group.active){
        group.active = true;
        $scope.numberOfSelectedDates++;
      }
    }

  };

  function dyoSeasonName(webContent) {
    for (var i = 0; i < webContent.length; i++) {
      if (webContent[i].content_type == appConfig.webContentTypes.dyoSeasonName) {
        return webContent[i].content_value;
      }
    }
    
    return false;
  }

  $scope.defaultZones = {};

  $scope.setDefaultZone = function(zone,perf_group){

    if(perf_group.defaultZone === undefined){
      perf_group.defaultZone = zone;
    }

  }

  $scope.setPriceZones = function(group, prevId, perf_group_no, perf_group, package){

    if(prevId && group.selectedPerformancePriceZone !== undefined){
      $scope.removePerformanceFromBasket(prevId, perf_group_no);
    }else{
      $scope.setNumberOfSelectedDates(group);
    }


    if(group.selectedPerformance === null){
      group.selectedPerformancePriceZone = undefined;
      return false;
    }


    group.loadingZones = true;

    var perfPromise = TessituraSDK.GetPerformanceDetailWithDiscountingEx({
      iPerf_no: group.selectedPerformance.perf_no,
      iModeOfSale: modeOfSale
    });

    perfPromise.then(
      function(response){
        group.loadingZones = false;
        var result = response.data.result.GetPerformanceDetailWithDiscountingExResult.AllPrice;

        if(angular.isArray(result)){
          group.selectedPerformanceZones = result;
        }else{
          group.selectedPerformanceZones = [result];
        }

        if(perf_group.defaultZone){
          var zoneNo = perf_group.defaultZone.zone_no,
              priceType = perf_group.defaultZone.price_type;

          if(zoneNo){
          // find the zone in the group.selectedPerformanceZones
            angular.forEach(group.selectedPerformanceZones, function(zone, index){
              if(zone){
                if(zone.zone_no === zoneNo && zone.price_type === priceType){
                  group.selectedPerformancePriceZone = group.selectedPerformanceZones[index];
                  $scope.addToBasket(group, package);
                }
              }
            });
          }
        }
      },
      function(error){
        console.log(error);
      }
    );
  };


$scope.addToBasket = function(group, package){

    if(!group.selectedPerformancePriceZone || group.selectedPerformance === null){
      return false;
    }

    var lineItem = {
      perf : group.selectedPerformance,
      zone: group.selectedPerformancePriceZone,
      package: package,
      group: group
    };

    angular.forEach($scope.basket, function(lineItem, key){
      // check perf_no & perf_group_no as a performance can be in multiple groups
      var index = (lineItem.perf.perf_no === group.selectedPerformance.perf_no && lineItem.perf.perf_group_no === group.selectedPerformance.perf_group_no);

      if(index){
        $scope.basket.splice(key, 1);
      }
    });

    // console.log('lineItem.zone.avail_count', lineItem.zone.avail_count);
    // check availability
    if(lineItem.zone.avail_count < 400){
      lineItem.limited = true;
    }else{
      lineItem.limited = false;
    }

    $scope.basket.push(lineItem);
  }

  $scope.removePerformanceFromBasket = function(id, perf_group_no){

    if(id){
      var index = getLineItemIndex(id, perf_group_no);
      if(index > -1){
        removeLineItemFromBasket(index, false);
      }
    }
  }

  $scope.removeFromBasket = function(id, perf_group_no){
    if(id){
      var index =  getLineItemIndex(id, perf_group_no);
      if(index > -1){
        removeLineItemFromBasket(index, true);
      }
    }
  }

  function getLineItemIndex(id, perf_group_no){
    var index = -1;
    angular.forEach($scope.basket, function(lineItem, key){
      if(id === lineItem.perf.perf_no && perf_group_no === lineItem.perf.perf_group_no){
        index = key;
      }
    });

    return index;
  }

  function removeLineItemFromBasket(index, onlyRemovePerformance){
    var item = $scope.basket[index];
    onlyRemovePerformance = onlyRemovePerformance || false;

    if(onlyRemovePerformance){
      item.group.selectedPerformance = undefined;
    }

    item.group.selectedPerformanceZones = undefined;
    item.group.selectedPerformancePriceZone = undefined;
    $scope.setNumberOfSelectedDates(item.group);
    $scope.basket.splice(index, 1);
  }

  $scope.setSubscriptionAmount = function(choice){
    $scope.subscriptionAmount = choice;
  }

  $scope.$watch('basket.length', function(newValue, oldValue) {
    $scope.totalItems = newValue;
    $scope.remainder = $scope.numberToSelect - newValue;
  });

  function addNFSPackagePerformanceItemFromBasketItem(basketItem, NFSPackageLineItemId) {
      return TessituraSDK.AddNFSPackagePerformanceItem({
        NFSPackageLineItemId: NFSPackageLineItemId,
        PriceType: parseInt(basketItem.zone.price_type),
        PackageNumber: parseInt(basketItem.package.pkg_no),
        PerformanceGroupNumber: parseInt(basketItem.perf.perf_group_no),
        PerformanceNumber: parseInt(basketItem.perf.perf_no),
        NumberOfSeats: parseInt($scope.subscriptionAmount),
        Zone: parseInt(basketItem.zone.zone_no),
        LeaveSingleSeats: 'false',
        UnSeated: 'false',
        RequestedSeats: '',
        SpecialRequests: ''
      });
  }

  function findLineItemIdForPackageId(packageId) {
    return TessituraSDK.GetCart()
      .then(function (response) {
        // The the package line item(s)
        var PackageLineItem = $filter('arrayWrap')(response.data.result.GetCartResults.PackageLineItem);

        // Find the correct line item
        for (var i = PackageLineItem.length - 1; i > 0; i--) {
          var lineItem = PackageLineItem[i];
          if (lineItem.li_no !== "0" && lineItem.pkg_no == packageId) {
            return $q.resolve(lineItem.li_no);
          }
        }

        return $q.reject();
      });
  }

  $scope.addtoCart = function () {
    $scope.addingToCart = true;

    var packageLineItemId;

    // Add the first line item in the basket to force
    // the creation of the packageId
    var promise = addNFSPackagePerformanceItemFromBasketItem($scope.basket[0], 0);

    // Reprime the GetCart call
    promise = promise
      .then(function () {
        return TessituraSDK.GetCart();
      });

    // Add the rest of the basket items with the package line item id
    promise = promise
      .then(function () {
        var promsies = $scope.basket.slice(1).map(function (basketItem) {
          // Get the lineItemId
          return findLineItemIdForPackageId(basketItem.package.pkg_no)
            .then(function (lineItemId) {
              // Add the performance to the package
              return addNFSPackagePerformanceItemFromBasketItem(basketItem, lineItemId);
            });
          });

        return $q.all(promsies);
      });

    // Validate the package
    promise = promise
      .then(function () {
        return TessituraSDK.ValidateNFSPackages()
          .then(function (response) {
            var errorsCount = Object.keys(response.data.result.ValidateNFSPackagesResults).length;
            if (errorsCount > 0) {
              return $q.reject();
            }
          });
      });

    // Finally
    promise
      .then(function () {
        // Success, take them to the cart
        $window.location = Router.getUrl('booking.basket');
        $scope.addToCartError = false;
      })
      .catch(function (err) {
        // If theres an error clean up and remove everything
        $scope.addToCartError = true;
        TessituraSDK.GetCart()
          .then(function (response) {
            // The the package line item(s)
            var PackageLineItem = $filter('arrayWrap')(response.data.result.GetCartResults.PackageLineItem);

            // Remove all of the line items!
            PackageLineItem.forEach(function (packageLineItem) {
              TessituraSDK.RemoveNFSPackage({
                NFSPackageLineItemId: packageLineItem.li_no,
                PerformanceLineItemId: 0,
                PerformanceNumber: 0
              });
            });

          });
      })
      .finally(function () {
        $scope.addingToCart = false;
      });
  };

}]);
