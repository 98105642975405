/* global moment */
app.controller('StudentEventsController', ['TessituraSDK', '$scope', '$filter', function (TessituraSDK, $scope, $filter) {

  var arrayWrap = $filter('arrayWrap');

  $scope.form = {
    referrer: 'Friend'
  };

  $scope.loading = true;
  $scope.completed = false;

  $scope.schoolDropdown = {
    message: "Select a district",
    disabled: true
  };

  $scope.maxDate = moment().format();
  $scope.onlyNumbers = '^[0-9- :]+$';

  TessituraSDK.SMaPGetAssociableSchoolDistricts()
  .then(function (response) {
    var districts = arrayWrap(response.data.result.ExecuteLocalProcedureResults.LocalProcedure);
    $scope.districts = $filter('orderBy')(districts, 'district_name');
  })
  .finally(function () {
    $scope.loading = false;
  });

  $scope.$watch('form.district', function (newVal, oldVal) {
    if (!newVal) {
      return;
    }

    $scope.schoolDropdown.disabled = false;
    $scope.schoolDropdown.message = "Loading schools";
    $scope.schools = [];

    TessituraSDK.SMaPGetSchools({
      RetrievalKey: $scope.form.district
    })
    .then(function (response) {
      var schools = arrayWrap(response.data.result.GetLocalDataResults.LocalData);
      $scope.schools = $filter('orderBy')(schools, 'name');

      $scope.schools = $scope.schools.filter(function (school) {
        return !!school.tessitura_customer_id;
      });

      if ($scope.schools.length == 0) {
        $scope.schoolDropdown.message = "No schools in this district";
      } else {
        $scope.schoolDropdown.message = "Select a school";
      }


    });
  });

  $scope.displayRequireEmailPhoneMessage = function () {
    return (
      $scope.form.hasOwnProperty('guardian_email') &&
      $scope.form.hasOwnProperty('guardian_phone') &&
      $scope.form.guardian_email == undefined &&
      $scope.form.guardian_phone == undefined
    );
  }

  $scope.initForm = function (key) {
    if (!$scope.form.hasOwnProperty(key)) {
      $scope.form[key] = undefined;
    }
  };



  $scope.submit = function () {

    var birthdayMoment = moment.utc([
      parseFloat($scope.form.dateOfBirthYear),
      parseFloat($scope.form.dateOfBirthMonth) - 1,
      parseFloat($scope.form.dateOfBirthDay)
    ]);

    TessituraSDK.StudentBodySignupForm({
      firstName: $scope.form.first_name,
      lastName: $scope.form.last_name,
      email: $scope.form.email,
      birthdate: birthdayMoment.toISOString(),
      school_id: $scope.form.school_id,
      parent_first_name: $scope.form.guardian_first_name,
      parent_last_name: $scope.form.guardian_last_name,
      parent_email: $scope.form.guardian_email,
      parent_phone: $scope.form.guardian_phone,
      referrer: $scope.form.referrer,
      student_zip: $scope.form.student_zip
    });

    $scope.completed = true;
  };

}]);
