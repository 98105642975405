app.filter('isEmpty', function () {
  return function (item) {
    if (angular.isString(item)) {
      return item.length == 0
    }
    if (angular.isObject(item)) {
      return Object.keys(item).length == 0
    }

    return item;
  };
});
