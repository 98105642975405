/* global moment */
app.controller('PackagesForRenewalController', ['$q', '$scope', '$filter', '$attrs', 'TessituraSDK', 'Router', 'AuthenticationService', 'appConfig', function ($q, $scope, $filter, $attrs, TessituraSDK, Router, AuthenticationService, appConfig) {

  var arrayWrap = $filter('arrayWrap');

  $scope.loading = true;
  $scope.showPastOrders = false;
  $scope.orders = [];

  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      return;
    }

    loadOrders();
  });

  /**
   * Loads the orders from tessitura
   * and puts them into scope
   */
  function loadOrders(){

    return AuthenticationService.getLoginInfo()
      .then(function (result) {

        var GetOrdersExParams = {
          customer_no: parseInt(result.customer_no),
          renewals_only: 'Y',
          perf_start_dt: moment().format('YYYY-MM-DDTHH:00:00'),
          print_only: 'N'
        };

        return TessituraSDK.GetCustomOrdersEx(GetOrdersExParams)
          .then(function (response) {
            return arrayWrap(response.data.result.ExecuteLocalProcedureResults.LocalProcedure);
          });
      })
      // Once we have the orders, go and get the details for each
      .then(function (orders) {
        // Skip YAP applications
        orders = orders.filter(function (order) {
          return !((
            // order has a note
            'notes' in order &&
            // note is a string
            angular.isString(order.notes) &&
            // the note is yap flag
            order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
          ) || (
            // order has a custom_6 value
            'custom_6' in order &&
            // custom_6 is a string
            angular.isString(order.custom_6) &&
            // value doesn't matter, so long as it exists
            order.custom_6.length
          ));
        });

        // Skip the rest
        orders = $filter('unique')(orders.filter(function (order) {
          return (
            // Package is unpaid for
            parseInt(order.tot_paid_amt, 10) == 0 &&
            (
              'ok_to_renew' in order &&
              angular.isString(order.ok_to_renew) &&
              order.ok_to_renew.toLowerCase() == 'y'
            )
          );
        }), 'order_no');

        if (!orders) {
          orders = [];
        }

        var orderPromises = orders.map(getOrderDetailsFromOrder);

        return $q.all(orderPromises);
      })

      // Finally loop over all the orders details
      // and group them in to past and open orders
      .then(function (GetOrderDetailsInfoResults) {
        $scope.orders = GetOrderDetailsInfoResults.map(getOrderBreakdownFromOrderDetails);
      })
      .finally(function () {
        $scope.loading = false;
      });
  }


  /**
   * Given an order object it returns a promise which
   * resolves with a GetOrderDetailsInfo object
   */
  function getOrderDetailsFromOrder(order) {

    var GetOrderDetailsParams = {
      order_no: parseInt(order.order_no)
    };

    // Get the order in more details
    return TessituraSDK.GetCustomOrderDetails(GetOrderDetailsParams)
      .then(function (response) {
        var result = response.data.result.ExecuteLocalProcedureResults;

        result = {
          Order: result.LocalProcedure,
          LineItem: result.LocalProcedure1,
          SubLineItem: result.LocalProcedure2,
          SubLineItemFee: result.LocalProcedure3,
          Fee: result.LocalProcedure4,
          Contribution: result.LocalProcedure5,
          Payment: result.LocalProcedure6,
          PaymentPlan: result.LocalProcedure7
        };

        result.LineItem = arrayWrap(result.LineItem);

        var lineItems = result.LineItem.filter(function (lineItem) {
          return angular.isString(lineItem.pkg_desc) && lineItem.pkg_desc.length > 0;
        });

        var packageDetailPromises = lineItems.map(function (lineItem) {
          return TessituraSDK.GetPackageDetailWithDiscountingEx({
            PackageID: lineItem.pkg_no,
            ModeOfSale: result.Order.MOS
          });
        });

        return $q.all(packageDetailPromises).then(function (packageDetailResponses) {
          packageDetailResponses.forEach(function (packageDetailResponse) {
            var
              packageDetailResult = packageDetailResponse.data.result.GetPackageDetailWithDiscountingExResult,
              packageDetails = packageDetailResult.Package,
              packageNo = packageDetails.pkg_no,
              seasonNo = packageDetails.season_no,
              webContent = arrayWrap(packageDetailResult.WebContent);

            result.LineItem.forEach(function (lineItem) {
              if (lineItem.pkg_no == packageNo && !lineItem.season_no) {
                lineItem.season_no = seasonNo;
                lineItem.webContent = webContent;
              }
            });
          });

          return result;
        });
      });
  }

  /**
   * Takes in an OrderDetailsInfo object and works out if
   * that order is considered open or past.
   */
  function isOrderDetailsOpen(GetOrderDetailsInfoResult) {
    var LineItems = arrayWrap(GetOrderDetailsInfoResult.LineItem);

    return LineItems.some(function (LineItem) {
      return moment(LineItem.perf_dt).diff() > 0;
    });
  }

  /**
   * Converts a complex OrderDetailsInfo object into
   * a simple version with just the needed data for the view
   */
  function getOrderBreakdownFromOrderDetails(GetOrderDetailsInfoResult) {
    var titles = [];
    var packages = [];

    // Add any performances & packages that was purchased
    arrayWrap(GetOrderDetailsInfoResult.LineItem)
      .forEach(function (LineItem) {
        if (angular.isString(LineItem.pkg_desc) && LineItem.pkg_desc.length > 0) {
          titles.push($filter('packageLongTitle')(LineItem));
        } else {
          titles.push($filter('performanceLongTitle')(LineItem));
        }

        packages.push(parseInt(LineItem.pkg_no, 10));
      });

    // Add any gift certificates that was purchased
    arrayWrap(GetOrderDetailsInfoResult.Payment)
      .forEach(function (payment) {
        if (angular.isString(payment.gc_no) && payment.gc_no.length > 0 && parseFloat(payment.payment_amt) < 0) {
          titles.push(payment.description);
        }
      });

    // Removing any duplicate titles
    titles = $filter('unique')(titles);

    var seasonNo = arrayWrap(GetOrderDetailsInfoResult.LineItem).map(function (lineItem) {
      return lineItem.season_no;
    });

    // Unique packages
    packages = packages.filter(function (e, i, packages) {
      return packages.lastIndexOf(e) === i;
    });

    // Return the nice friendly object
    return {
      title: titles.join(', '),
      purchasedOn: moment(GetOrderDetailsInfoResult.Order.order_dt),
      tot_due_amt: parseFloat(GetOrderDetailsInfoResult.Order.tot_due_amt),
      orderNo: parseInt(GetOrderDetailsInfoResult.Order.order_no),
      year: moment(GetOrderDetailsInfoResult.Order.order_dt).year(),
      seasonNo: seasonNo[0],
      packages: packages
    };
  }

  function attachWebContentToPerformance(performances, webContents) {
    angular.forEach(arrayWrap(performances), function (performance) {
      performance.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == performance.perf_no;
      });
    });
  }
}]);
