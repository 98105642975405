app.service('ClickWrap', ['$document', '$modal', '$sce', 'appConfig', function ($document, $modal, $sce, appConfig) {

  return {
    openWithJsonFromElement: openWithJsonFromElement,
    openWithElementContents: openWithElementContents
  };

  /**
   * Reads the config for the widget from the html with the provided id
   * Then calls openFromConfig
   * @param  {string} elementId HTML element id to read config from
   * @return {Promise}
   */
  function openWithJsonFromElement(elementId) {
    var elementHtml = document.getElementById(elementId).innerHTML;
    var config = angular.fromJson(elementHtml);

    return openFromConfig(config);
  }

  /**
   * Reads the config for the widget from the html with the provided id
   * Then calls openFromConfig
   * @param  {string} elementId HTML element id to read config from
   * @return {Promise}
   */
  function openWithElementContents(elementId) {
    var element = document.getElementById(elementId);

    var config = {
      title: element.getAttribute('title'),
      confirmText: element.getAttribute('confirm-text'),
      description: element.innerHTML,
      checkboxText: element.getAttribute('checkbox-text')
    };

    return openFromConfig(config);
  }

  /**
   * Takes in a config for the application and opens a modal.
   * Passed the config to the controller and returns the modal promise
   * @param  {Object} config See example page
   * @return {Promise}
   */
  function openFromConfig(config) {

    var modalInstance = $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'click-wrap-popup.html'),
      controller: 'ClickWrapController',
      resolve: {
        clickWrapConfig: function () {
          return config;
        }
      }
    });

    return modalInstance.result;

  }
}]);
