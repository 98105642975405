/* global moment */
app.controller('FlexPackage2Controller', ['$scope', '$window', '$attrs', 'TessituraSDK', '$http', '$filter', 'AuthenticationService', '$q', 'Router', 'GetParameters', 'appConfig', 'MosSwitcher', function ($scope, $window, $attrs, TessituraSDK, $http, $filter, AuthenticationService, $q, Router, GetParameters, appConfig, MosSwitcher) {
  // Tools
  var
    arrayWrap = $filter('arrayWrap'),
    filter = $filter('filter'),
    groupBy = $filter('groupBy');

  var widgetConfig = document.getElementById('widget-config');

  if (widgetConfig) {
    widgetConfig = JSON.parse(widgetConfig.innerHTML);
  }

  // Vars
  var
    modeOfSale = appConfig.packageMos,
    packageNumber = parseInt(widgetConfig.PackageNo || $attrs.packageNumber || GetParameters.packageNumber),
    subscriptionLimit = 6;

  // Scope vars
  $scope = angular.extend($scope, {
    wrongMos: false,
    wrongMosReason: '',
    package: null,
    basket: [],
    numberToSelect: 4,
    numberOfSelectedDates: 0,
    totalItems: 0,
    subscriptionLimits: [],
    remainder: 3,
    subscriptionAmount: 2,
    defaultZones: [],
    addingToCart: false,
    // Functions
    setPriceZones: setPriceZones,
    setDefaultZone: setDefaultZone,
    setNumberOfSelectedDates: setNumberOfSelectedDates,
    addToBasket: addToBasket,
    setSubscriptionAmount: setSubscriptionAmount,
    addtoCart: addtoCart
  });

  for (var i = 1; i <= subscriptionLimit; i++) {
    $scope.subscriptionLimits.push(i);
  };

  // Try to switch the mode of sale
  // if it fails, get the error and display it to the user
  MosSwitcher.packageMos().catch(function () {
    $scope.wrongMos = true;

    MosSwitcher.reasonForMosSwitchFailure('package').then(function (reason) {
      $scope.wrongMosReason = reason;
    });
  });

  AuthenticationService.requireLogin().then(function () {
    loadPackage().then(function (data) {
        var
          package = data.Package,
          performances = arrayWrap(data.Performance),
          performanceGroups = arrayWrap(data.PerformanceGroup),
          webContent = arrayWrap(data.WebContent),
          performanceWebContent = arrayWrap(data.PerformanceWebContent);

        // Add meta data to each performance
        angular.forEach(performances, function (performance, index) {
          // Add a moment date for performance
          performance.month = moment(performance.perf_dt).format('MMMM YYYY');
          performance.date = moment(performance.perf_dt).format();

          performance.webContent = filter(performanceWebContent, {
            orig_inv_no: performance.perf_no
          });

          // performance.DYO_Season_Name = dyoSeasonName(perf.webContent);
          performance.longTitle = $filter('performanceLongTitle')(performance);

          // $scope.performances.push(performance);
        });

        // Split performances up by groups
        var groupedPerformances = $filter('groupBy')(performances, 'perf_group_no');

        // Assign these to actual groups from Tessitura
        performanceGroups.forEach(function (group) {
          group.performances = groupBy(groupedPerformances[group.perf_group_no], 'longTitle');
          group.selectedPerformance = null;
          group.selectedPerformancePriceZone = null;
          group.selectedPerformanceZones = null;
          group.loadingZones = false;
          group.defaultZone = null;
        });

        // Add the groups
        package.perf_groups = performanceGroups;

        // Add the package to be displayed on the page
        $scope.package = package;
    });


  });

  function loadPackage() {
    return TessituraSDK.GetNFSPackageDetailEx({
      'PackageID': packageNumber,
      'ModeOfSale': modeOfSale
    }).then(function (response) {
      return response.data.result.GetNFSPackageDetailExResult;
    });
  };

  function loadPerformane(perfNo) {
    return TessituraSDK.GetPerformanceDetailWithDiscountingEx({
      iPerf_no: perfNo,
      iModeOfSale: modeOfSale
    }).then(function (response) {
      return response.data.result.GetPerformanceDetailWithDiscountingExResult;
    });
  };

  function setPriceZones(group, prevId, perf_group_no, perf_group, package) {
    if (prevId && group.selectedPerformancePriceZone !== null) {
      removePerformanceFromBasket(prevId, perf_group_no);
    } else {
      setNumberOfSelectedDates(group);
    }

    if (group.selectedPerformance === null) {
      group.selectedPerformancePriceZone = null;
      return false;
    }

    group.loadingZones = true;

    loadPerformane(group.selectedPerformance.perf_no).then(function (performance) {
      var allPrices = arrayWrap(performance.AllPrice);

      group.selectedPerformanceZones = allPrices;

      if (perf_group.defaultZone) {
        var
          zoneNo = perf_group.defaultZone.zone_no,
          priceType = perf_group.defaultZone.price_type;

        if (zoneNo) {
        // find the zone in the group.selectedPerformanceZones
          angular.forEach(group.selectedPerformanceZones, function (zone, index) {
            if (zone && zone.zone_no === zoneNo && zone.price_type === priceType){
              group.selectedPerformancePriceZone = group.selectedPerformanceZones[index];
              $scope.addToBasket(group, package);
            }
          });
        }
      }

      group.loadingZones = false;
    });
  };

  function setDefaultZone(zone, perf_group) {
    if (perf_group.defaultZone === null) {
      perf_group.defaultZone = zone;
    }
  };

  function addToBasket(group, package) {
    if (!group.selectedPerformancePriceZone || group.selectedPerformance === null) {
      return false;
    }

    var lineItem = {
      perf : group.selectedPerformance,
      zone: group.selectedPerformancePriceZone,
      package: package,
      group: group
    };

    angular.forEach($scope.basket, function (lineItem, key) {
      // check perf_no & perf_group_no as a performance can be in multiple groups
      var index = (lineItem.perf.perf_no === group.selectedPerformance.perf_no && lineItem.perf.perf_group_no === group.selectedPerformance.perf_group_no);

      if (index) {
        $scope.basket.splice(key, 1);
      }
    });

    // check availability
    if (lineItem.zone.avail_count < 400) {
      lineItem.limited = true;
    } else {
      lineItem.limited = false;
    }

    $scope.basket.push(lineItem);
  };

  function removeFromBasket(id, perf_group_no) {
    if (id) {
      var index =  getLineItemIndex(id, perf_group_no);
      if (index > -1) {
        removeLineItemFromBasket(index, true);
      }
    }
  };

  function setNumberOfSelectedDates(group) {
    if (group.selectedPerformance === null) {
      group.active = false;
      $scope.numberOfSelectedDates--;
    } else {
      if (!group.active) {
        group.active = true;
        $scope.numberOfSelectedDates++;
      }
    }
  };

  function setSubscriptionAmount(choice) {
    $scope.subscriptionAmount = choice;
  };

  function removePerformanceFromBasket(id, perf_group_no) {
    if (id) {
      var index = getLineItemIndex(id, perf_group_no);
      if (index > -1) {
        removeLineItemFromBasket(index, false);
      }
    }
  };

  function getLineItemIndex(id, perf_group_no) {
    var index = -1;
    angular.forEach($scope.basket, function (lineItem, key) {
      if (id === lineItem.perf.perf_no && perf_group_no === lineItem.perf.perf_group_no) {
        index = key;
      }
    });

    return index;
  };

  function removeLineItemFromBasket(index, onlyRemovePerformance) {
    var item = $scope.basket[index];
    onlyRemovePerformance = onlyRemovePerformance || false;

    if (onlyRemovePerformance) {
      item.group.selectedPerformance = null;
    }

    item.group.selectedPerformanceZones = null;
    item.group.selectedPerformancePriceZone = null;
    setNumberOfSelectedDates(item.group);
    $scope.basket.splice(index, 1);
  };

  function addNFSPackagePerformanceItemFromBasketItem(basketItem, NFSPackageLineItemId) {
      return TessituraSDK.AddNFSPackagePerformanceItem({
        NFSPackageLineItemId: NFSPackageLineItemId,
        PriceType: parseInt(basketItem.zone.price_type),
        PackageNumber: parseInt(basketItem.package.pkg_no),
        PerformanceGroupNumber: parseInt(basketItem.perf.perf_group_no),
        PerformanceNumber: parseInt(basketItem.perf.perf_no),
        NumberOfSeats: parseInt($scope.subscriptionAmount),
        Zone: parseInt(basketItem.zone.zone_no),
        LeaveSingleSeats: 'false',
        UnSeated: 'false',
        RequestedSeats: '',
        SpecialRequests: ''
      });
  };

  function findLineItemIdForPackageId(packageId) {
    return TessituraSDK.GetCart().then(function (response) {
      // The the package line item(s)
      var PackageLineItem = $filter('arrayWrap')(response.data.result.GetCartResults.PackageLineItem);

      // Find the correct line item
      for (var i = PackageLineItem.length - 1; i > 0; i--) {
        var lineItem = PackageLineItem[i];
        if (lineItem.li_no !== "0" && lineItem.pkg_no == packageId) {
          return $q.resolve(lineItem.li_no);
        }
      }

      return $q.reject();
    });
  };

  function addtoCart() {
    $scope.addingToCart = true;

    var packageLineItemId;

    // Add the first line item in the basket to force
    // the creation of the packageId
    var promise = addNFSPackagePerformanceItemFromBasketItem($scope.basket[0], 0);

    // Reprime the GetCart call
    promise = promise.then(function () {
      return TessituraSDK.GetCart();
    });

    // Add the rest of the basket items with the package line item id
    promise = promise.then(function () {
      var promsies = $scope.basket.slice(1).map(function (basketItem) {
        // Get the lineItemId
        return findLineItemIdForPackageId(basketItem.package.pkg_no).then(function (lineItemId) {
          // Add the performance to the package
          return addNFSPackagePerformanceItemFromBasketItem(basketItem, lineItemId);
        });
      });

      return $q.all(promsies);
    });

    // Validate the package
    promise = promise.then(function () {
      return TessituraSDK.ValidateNFSPackages().then(function (response) {
        var errorsCount = Object.keys(response.data.result.ValidateNFSPackagesResults).length;
        if (errorsCount > 0) {
          return $q.reject();
        }
      });
    });

    // Finally
    promise.then(function () {
      // Success, take them to the cart
      $window.location = Router.getUrl('booking.basket');
      $scope.addToCartError = false;
    }).catch(function (err) {
      // If theres an error clean up and remove everything
      $scope.addToCartError = true;

      TessituraSDK.GetCart().then(function (response) {
        // The the package line item(s)
        var PackageLineItem = $filter('arrayWrap')(response.data.result.GetCartResults.PackageLineItem);

        // Remove all of the line items!
        PackageLineItem.forEach(function (packageLineItem) {
          TessituraSDK.RemoveNFSPackage({
            NFSPackageLineItemId: packageLineItem.li_no,
            PerformanceLineItemId: 0,
            PerformanceNumber: 0
          });
        });

      });
    }).finally(function () {
      $scope.addingToCart = false;
    });
  };

  $scope.$watch('basket.length', function (newValue, oldValue) {
    $scope.totalItems = newValue;
    $scope.remainder = $scope.numberToSelect - newValue;
  });
}]);
