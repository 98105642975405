/**
 *  This file contains:
 *    Service - Notifications
 *    Directive - notificationsContainer
 *    Controller - NotificationsContainerController
 */
app.service('Notifications', ['$rootScope', '$window', '$injector', '$timeout', 'CacheStorage', function ($rootScope, $window, $injector, $timeout, CacheStorage) {

  /**
   * Helper function for JSON.stringify
   * It will convert functions into strings rather then null
   */
  function functionSafeStringifyReplacer (key, value) {
    if (typeof value === 'function') {
      return value.toString();
    }
    return value;
  }

  /**
   * Helper function for JSON.parse
   * It will convert the stringified functions from the
   * preview function back to proper functions
   */
  function functionSafeStringifyReviver (key, value) {
    if (typeof value === 'string' && value.substring(0,8) === 'function') {
      // Eval is the only way to restore the object fully
      // unless we say functions cant be in stickies.
      // I thought about this, and its secure, if they can control
      // what goes into localStorage, they dont need to hack this
      /* jshint evil: true */
      try{
        return eval('(' + value + ')');
      } catch(e) {
        return value;
      }
    }
    return value;
  }

  // Place where all alerts are stored
  var alerts = [];

  function restoreStickiesFromLocalStorage(){
    // Get any sticky notifications from local storage
    var stickyNotificationsString = CacheStorage.get('stickyNotifications');
    var stickyNotifications;
    try {
      stickyNotifications = JSON.parse(stickyNotificationsString, functionSafeStringifyReviver);
    } catch(e) {
      stickyNotifications = [];
    }

    if (angular.isArray(stickyNotifications)) {

      // Remove the current stickies, they'll all
      // be re-applied in a minute
      alerts.map(function (alert, alertIndex) {
        if (alert.sticky) {
          return alertIndex;
        } else {
          return null;
        }
      })
      .reverse()
      .forEach(function (alertIndex) {
        if (alertIndex !== null){
          alerts.splice(alertIndex, 1);
        }
      });

      // When the objects are stored, they still have their hashkey
      // Remove it or angular will error.
      stickyNotifications.forEach(function (alert) {
        delete alert.$$hashKey;
        alerts.push(alert);
      });

    }
  }

  restoreStickiesFromLocalStorage();

  // Run this in a timeout so that it can wait for the
  // $injector to find the AuthenticationService
  $timeout(function () {
    $injector.invoke(['AuthenticationService', function(AuthenticationService) {

      // Remove any stickies that are MoS dependent if the MoS is different.
      AuthenticationService.getLoginInfo()
        .then(function (loginInformation) {
          var mustResave = false;
          alerts.forEach(function (alert, alertIndex) {
            if (alert.modeOfSale !== undefined && alert.modeOfSale != loginInformation.MOS) {
              alerts.splice(alertIndex, 1);
              mustResave = true;
            }
          });
          if (mustResave) {
            saveStickiesToLocatStorage();
          }
        });
    }]);
  });

  function saveStickiesToLocatStorage(){
    // Get only the sticky notifcations
    var stickyNotifications = alerts.filter(function (alert) {
      return alert.sticky;
    });
    // Stringify them
    var stickyNotificationsString = JSON.stringify(stickyNotifications, functionSafeStringifyReplacer);
    // Save them in localStorage
    CacheStorage.set('stickyNotifications', stickyNotificationsString);
  }

  // When localstorage changes, check to see if
  // stickyNotifcations has changes and up the alerts Array
  $window.addEventListener('storage', function (event) {
    // If the stickyNotifications changes, then restore the new version
    if (event.key === 'stickyNotifications') {
      // Run a digest cycle after
      $rootScope.$apply(restoreStickiesFromLocalStorage);
    }
  });

  // Watch alerts array for changes so we can update the local storage
  $rootScope.$watch(function () {
    return alerts;
  }, saveStickiesToLocatStorage, true);

  // Default options for an alert, always applied
  var defaultOptions = {
    closeable: true,
    sticky: false,
    container: 'default',
    style: 'info'
  };

  return {
    /**
     * Takes in a alertObj, adds the default options, adds
     * it to alerts array and displays it to the user.
     * @param  Object alertObj Alert object as defined in notifications.html
     * @param  String alertKey OPTIONAL A string name to relate to the alert
     * @return {[type]}          [description]
     */
    create: function (alertObj, alertKey) {
      // We store the string name in the alertObj under $name property
      if (alertKey !== undefined) {
        alertObj.$name = alertKey;
      }

      // Add defaults to the alert object
      this.addDefaults(alertObj);

      // Check to see if the alert already exists.
      // If it does overwrite the existing one
      // If it does not, insert it at the end
      if (this.exists(alertObj)) {
        var alertIndex = this.getAlertIndex(alertObj);
        alerts[alertIndex] = alertObj;
      } else {
        alerts.push(alertObj);
      }
    },

    /**
     * Is the alert requested being showed.
     * @param  Object|String alertIdentifier String or Object of a alert
     * @return Boolean
     */
    exists: function (alertIdentifier) {
      return this.getAlertIndex(alertIdentifier) > -1;
    },

    /**
     * Removes an alert from view.
     * @param  Object|String  alertIdentifier       String or Object of a alert
     * @param  Boolean        triggerOnCloseAction  Should on the onClose action be run, default true
     */
    remove: function (alertIdentifier, triggerOnCloseAction) {

      // Remove the alert from the alerts array/view
      var alertIndexs = this.getAlertIndexs(alertIdentifier);
      alertIndexs.reverse();
      for (var i in alertIndexs) {
        var alertIndex = alertIndexs[i];
        var alert = alerts[alertIndex];

        // Trigger the on close action if specified
        triggerOnCloseAction = angular.isUndefined(triggerOnCloseAction) ? true:triggerOnCloseAction;
        if (triggerOnCloseAction && alert.onClose !== undefined) {
          // The onClose can be a URL or a function
          // Check for what it is an take the correct action
          if (typeof alert.onClose === 'function') {
            try {
              $injector.invoke(alert.onClose, window, { alert: alert});
            } catch(e) {
              Raven.captureException(e);
            }
          } else if (typeof alert.onClose === 'string') {
            window.location = alert.onClose;
          }
        }

        alerts.splice(alertIndex, 1);
      }


    },

    /**
     * Get an alert from the alerts array
     * @param  Object|String  alertIdentifier       String or Object of a alert
     * @return Object|null
     */
    getAlert: function (alertIdentifier) {
      var alertIndex = this.getAlertIndex(alertIdentifier);
      var alert = alerts[alertIndex] || null;
      return alert;
    },

    /**
     * Get an alerts index in the alerts array
     * @param  Object|String  alertIdentifier       String or Object of a alert
     * @return Object|-1
     */
    getAlertIndex: function (alertIdentifier) {
      var alertIndex;
      for (alertIndex in alerts) {
        if (typeof alertIdentifier === 'string') {
          if (alerts[alertIndex].$name !== undefined && alerts[alertIndex].$name === alertIdentifier) {
            return alertIndex;
          }
        } else {
          if (alerts[alertIndex].$name !== undefined && alerts[alertIndex].$name === alertIdentifier.$name) {
            return alertIndex;
          }
          if (alerts[alertIndex] === alertIdentifier) {
            return alertIndex;
          }
        }
      }
      return -1;
    },

    /**
     * Get an alerts index in the alerts array
     * @param  Object|String  alertIdentifier       String or Object of a alert
     * @return Object|-1
     */
    getAlertIndexs: function (alertIdentifier) {
      var alertIndexs = [];
      for (var alertIndex in alerts) {
        if (alertIdentifier instanceof RegExp) {
          if (alerts[alertIndex].$name !== undefined && alertIdentifier.test(alerts[alertIndex].$name)) {
            alertIndexs.push(alertIndex);
          }
        } else if (typeof alertIdentifier === 'string') {
          if (alerts[alertIndex].$name !== undefined && alerts[alertIndex].$name === alertIdentifier) {
            alertIndexs.push(alertIndex);
          }
        } else {
          if (alerts[alertIndex].$name !== undefined && alerts[alertIndex].$name === alertIdentifier.$name) {
            alertIndexs.push(alertIndex);
          } else
          if (alerts[alertIndex] === alertIdentifier) {
            alertIndexs.push(alertIndex);
          }
        }
      }
      return alertIndexs;
    },

    /**
     * Returns the alerts array
     * @return Array
     */
    getAllAlerts: function () {
      return alerts;
    },

    /**
     * Adds the default options to the default array.
     * This applies the defaults to the alertObj so as to keep
     * the reference to alertObj.
     *
     * @param Object alertObj
     */
    addDefaults: function (alertObj) {
      var defaultOptionsIndex;
      for (defaultOptionsIndex in defaultOptions) {
        if (alertObj[defaultOptionsIndex] === undefined) {
          alertObj[defaultOptionsIndex] = defaultOptions[defaultOptionsIndex];
        }
      }
    }
  };
}]);

/**
 * This directive is used to to show notifications.
 * It supports a filter attribute only show notifications
 * with a certainly location.
 */
app.directive('notificationsContainer', ['$sce', 'appConfig', function ($sce, appConfig) {
  return {
    restrict: 'E',
    templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'notification.html'),
    scope: {
      containerName: '@filter'
    },
    controller: 'NotificationsContainerController'
  };
}]);

/**
 * Controller for the notificationsContainer directive.
 */
app.controller('NotificationsContainerController', ['$scope', '$rootScope', 'Notifications', '$injector', '$filter', function ($scope, $rootScope, Notifications, $injector, $filter) {
  
  $scope.alerts = Notifications.getAllAlerts();


  /**
   * Fired when a use clicks the X on an alert
   * Removes the alert.
   */
  $scope.closeAlert = function (alert) {
    Notifications.remove(alert);
  };

  /**
   * Fired when a use clicks on a CTA
   * Removes the alert if removeOnClick is set
   * Triggers the onClose action
   */
  $scope.ctaClick = function (alert, cta) {
    if (cta.removeOnClick === true) {
      Notifications.remove(alert);
    }
    if (typeof cta.onClick === 'function') {
      $injector.invoke(cta.onClick, window, { alert: alert, cta: cta });
    } else if (typeof cta.onClick === 'string') {
      window.location = cta.onClick;
    }
  };
}]);

