app.controller('InlinePromoCodeController', ['AuthenticationService', 'PromoCodeService', function (AuthenticationService, PromoCodeService) {
  'use strict';

  var vm = this;

  vm.loading = true;
  vm.formVisible = false;
  vm.code = '';
  vm.applied = false;
  vm.processing = false;

  vm.apply = function () {
    if (!vm.code.length) {
      return alert('Please enter a valid promo code');
    }

    vm.processing = true;

    PromoCodeService.loadPromoCode(vm.code)
    .then(function (response) {
      return PromoCodeService.addPromoCode(response, { redirect: false });
    })
    .then(function (response) {
      document.location.reload();
    })
    .catch(function (error) {
      alert(error);
    })
    .finally(function () {
      vm.processing = false;
    });
  }

  AuthenticationService.getLoginInfo()
  .then(function (response) {
    if (!('applied_promo_code' in response)) {
      return false;
    }

    if (!angular.isString(response.applied_promo_code)) {
      return false;
    }

    if (!response.applied_promo_code.length) {
      return false;
    }

    vm.applied = true;
    vm.code = response.applied_promo_code;
  })
  .finally(function () {
    vm.loading = false;
  });

  return vm;
}]);
