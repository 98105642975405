app.controller(
  'SchemaController',
  ['$attrs', '$element', '$filter', '$q', '$scope', '$location', 'AuthenticationService', 'EventService', 'InventoryService', 'PerformanceSearchService', function(
      $attrs,
      $element,
      $filter,
      $q,
      $scope,
      $location,
      AuthenticationService,
      EventService,
      InventoryService,
      PerformanceSearchService
    ) {
      'use strict';

      var prodNo = parseInt($attrs.prodNo, 10);
      var scriptTag = $element[0];

      var arrayWrap = $filter('arrayWrap');
      var dateFormat = 'yyyy-MM-dd HH:mm:ss';
      var dateFilter = $filter('date');

      AuthenticationService.getLoginInfo().then(function (loginInfo) {
        var promises = {
          'ProductionData': EventService.get(prodNo),
          'PerformancePriceData': PerformanceSearchService.getPerformanceAvailabilitySPROC({
            mos: loginInfo.MOS
          }),
          'Performances': InventoryService.GetPerformancesForProduction({'iProdSeasonID': prodNo}),
        };

        $q.all(promises).then(function(result) {
          var productionTitle = result.ProductionData.title;
          var syosEnabled = result.ProductionData.webContent['Web SYOS'] === 'Y';
          var perfs = arrayWrap(result.Performances.Performance);
          var perfNos = perfs.map(function (perf) {
            return parseInt(perf.perf_no, 10);
          });
          var startDate = perfs.reduce(function (startDate, perf) {
            var testDate = moment(perf.perf_date);
            if (!startDate) {startDate = testDate;}
            if (testDate < startDate) {startDate = testDate;}
            return startDate
          }, false);
          var endDate = perfs.reduce(function (endDate, perf) {
            var testDate = moment(perf.perf_date);
            if (!startDate) {endDate = testDate;}
            if (testDate > endDate) {endDate = testDate;}
            return endDate
          }, false);

          var perfPrices = result.PerformancePriceData.data.result.ExecuteLocalProcedureResults.LocalProcedure.filter(function(priceInfo) {
            return perfNos.indexOf(parseInt(priceInfo.perf_no, 10)) !== -1;
          });

          var ldData = {
            '@context': 'http://schema.org',
            '@type': 'TheaterEvent',
            'name': productionTitle,
            'startDate': startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
            'endDate': endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
            'image': result.ProductionData.cmsImage,
            'url': result.ProductionData.cmsUrl,
            'description': result.ProductionData.description,
          };

          ldData.location = getLocationSchemaData(result.ProductionData.venue);
          ldData.aggregateRating = getAggregateRatingSchemaData(result.ProductionData.reviews);
          ldData.offers = getAggregateOffers(perfs, perfPrices, syosEnabled);
          ldData.review = getReviewSchemaData(result.ProductionData.reviews);
          addCreditsSchemaData(result.ProductionData.credits, ldData);
          ldData.workPerformed = getWorkPerformedSchemaData(result.ProductionData.worksPerformed);

          switch(result.ProductionData.status) {
            case 'cancelled':
              ldData.eventStatus = 'https://schema.org/EventCancelled';
              break;
            case 'postponed':
              ldData.eventStatus = 'https://schema.org/EventPostponed';
              break;
            case 'rescheduled':
              ldData.eventStatus = 'https://schema.org/EventRescheduled';
              break;
            default:
              ldData.eventStatus = 'https://schema.org/EventScheduled';
              break;
          }
          scriptTag.setAttribute('type', 'application/ld+json');
          scriptTag.innerHTML = JSON.stringify([ldData]);
        });
      });

      /**
       *
       */
      function getAggregateRatingSchemaData(reviewData) {
        reviewData = reviewData || [];
        var ratedReviews = reviewData.filter(function (review) {
          return review.Rating;
        });
        var data = {
          '@type': 'AggregateRating',
          'ratingCount': ratedReviews.length,
          'reviewCount': reviewData.length,
        }
        if (ratedReviews.length) {
          var ratings = ratedReviews.map(function (review) {
            return review.Rating;
          });
          data.bestRating = ratings.reduce(function (carry, rating) {
            if (ratings > carry) {
              carry = ratings;
            }
            return carry;
          }, 0);
          data.worstRating = ratings.reduce(function (carry, rating) {
            if (ratings < carry) {
              carry = ratings;
            }
            return carry;
          }, 5);
          data.ratingValue = ratings.reduce(function (carry, rating) {
            return carry+= rating;
          }, 0) / ratings.length;
        }
        return data;
      }

      /**
       * @return Array
       */
      function getReviewSchemaData(reviewData) {
        return reviewData.reduce(function(carry, review) {
          var data = {
            '@type': 'Review',
            'reviewBody': review.Quote,
            'author': review.Author,
          };
          if (review.Rating) {
            data.reviewRating = {
              '@type': 'Rating',
              'bestRating': 5,
              'ratingValue': review.Rating,
            }
          }
          if (review.URL) {
            data.url = review.URL;
          }
          carry.push(data);
          return carry;
        }, []);
      }

      /**
       *
       */
      function addCreditsSchemaData(credits, schema) {
        credits.forEach(function (credit) {
          var role = credit.RoleType || 'performer';
          if (!schema[role]) {
            schema[role] = [];
          }
          schema[role].push({
            '@type': credit.Type || 'Person',
            'name': credit.Name,
            'additionalName': credit.Role,
            'sameAs': credit.URLs,
          });
        });
      }

      /**
       * @return Array
       */
      function getAggregateOffers(perfs, perfPrices, syosEnabled) {
        return perfs.reduce(function(carry, perf) {
          var perfNo = parseInt(perf.perf_no, 10);
          var priceData = perfPrices.reduce(function (carry, priceData) {
            if (parseInt(priceData.perf_no, 10) === perfNo) {
              carry.processed = true;
              var price = parseFloat(priceData.price);
              if (price && price <= carry.min) {
                carry.min = price;
              }
              if (price && price >= carry.max) {
                carry.max = price;
              }
              if (price && priceData.available === 'Y') {
                carry.available = true;
              }
            }
            return carry;
          }, {min: 999999, max: 0, available: false, processed: false});

          var url = $location.protocol() + '://' + $location.host();
          if (syosEnabled) {
            url+= '/booking/syos?perf_no=' + perfNo + '&facility_no=' + perf.facility_no + '&return_to=' + encodeURIComponent(window.location.pathname);
          } else {
            url+= '/booking/best-available?prod_no=' + prodNo + '&perf_no=' + perfNo;
          }

          if (priceData.processed) {
            carry.push({
              '@context': 'https://schema.org',
              '@type': 'AggregateOffer',
              'priceCurrency': 'USD',
              'url': url,
              'highPrice': priceData.max,
              'lowPrice': priceData.min,
              'availability': priceData.available ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
              'validFrom': moment(perf.start_dt).format('YYYY-MM-DDTHH:mm:ssZ'),
            });
          }
          return carry;
        }, []);
      }

      /**
       *
       */
      function getLocationSchemaData(productionVenueData) {
        return {
          '@type': 'Place',
          'name': productionVenueData.Name,
          'address': {
            '@type': 'PostalAddress',
            'streetAddress': productionVenueData.StreetAddress,
            'postalCode': productionVenueData.PostalCode,
            'addressCountry': productionVenueData.Country,
          }
        };
      }

      /**
       *
       */
      function getWorkPerformedSchemaData(worksPerformed) {
        return worksPerformed.map(function (work) {
          var creativeWork = {
            '@type': 'CreativeWork',
          }
          addCreditsSchemaData(work.Credits, creativeWork);
          return creativeWork;
        });
      }
    }
  ]
);
