app.filter('arrayWrap', function () {
  return function (possibleArray) {
    if (possibleArray === undefined) {
      return [];
    }

    if (Object.prototype.toString.call(possibleArray) !== '[object Array]') {
      possibleArray = [possibleArray];
    }

    return possibleArray;
  };
});
