app.service('PromoCodeService', ['$q', '$window', '$filter', 'TessituraSDK', 'AuthenticationService', function ($q, $window, $filter, TessituraSDK, AuthenticationService) {
  'use strict';

  return {
    loadPromoCode: loadPromoCode,
    addPromoCode: addPromoCode,
    hasPromocodeApplied: hasPromocodeApplied
  };

  function hasPromocodeApplied() {
    return AuthenticationService.getLoginInfo()
    .then(function (loginInfoEx) {
      return 'applied_promo_code' in loginInfoEx;
    });
  }

  function loadPromoCode(promoCode) {
    return AuthenticationService.getLoginInfo().then(function (loginInfoEx) {
      return TessituraSDK.GetPromoCodeEx({
        PromotionCode: 0,
        PromotionCodeString: promoCode
      }).then(function (response) {
        var
          result = response.data.result,
          promoCodeResult = result.GetPromoCodeExResults,
          promoCodes = $filter('arrayWrap')(promoCodeResult.PromoCodeDetails),
          thePromoCode = null;

        promoCodes = promoCodes.filter(function (promoCode) {
          return promoCode.appeal_inactive == 'N';
        });

        if (promoCodes.length) {
          thePromoCode = promoCodes[0];
        }

        if (!thePromoCode) {
          return $q.reject('The promo code you have provided is invalid, please try again');
        }

        return $q.when(thePromoCode);
      });
    });
  };

  function addPromoCode(promoCode, options) {
    options = options || {};
    options.redirect = options.redirect === undefined ? true : options.redirect;

    return TessituraSDK.ApplyPromoCode({
      promoCode: promoCode.promo_code
    })
    .then(function () {
      if (options.redirect) {
        if (promoCode.text3.toLowerCase().indexOf('.aspx') === -1) {
          $window.location = promoCode.text3;
        } else {
          $window.location = '/tickets/find/';
        }
      }

      return $q.when(true);
    });
  }
}]);
