/* global moment */
app.service('Calendar', ['$q', '$filter', 'appConfig', 'PerformanceSearchService', 'AuthenticationService', '$http', 'Router', 'MosSwitcher', 'TessituraSDK', function ($q, $filter, appConfig, PerformanceSearchService, AuthenticationService, $http, Router, MosSwitcher, TessituraSDK) {
    var
        arrayWrap = $filter('arrayWrap'),
        filter = $filter('filter'),
        groupBy = $filter('groupBy'),
        unique = $filter('unique'),
        orderBy = $filter('orderBy'),
        dateFormat = 'YYYY-MM-DDTHH:00:00Z',
        packageTitle = $filter('packageLongTitle'),
        performanceTitle = $filter('performanceLongTitle');

    var
        allowedCategories = (appConfig.calendarCategories || []),
        allowedProductionTypes = (appConfig.productionTypes || []),
        firstEventDate = moment().subtract(2, 'months').startOf('hour'),
        lastEventDate = moment();

    var service = {
        now: moment().startOf('day'),
        events: {},
        venues: {},
        timeSlots: {},
        seasons: {},
        facetFilters: {},
        weeks: null,
        weekDays: [
            ['Sun', 's'],
            ['Mon', 'm'],
            ['Tue', 't'],
            ['Wed', 'w'],
            ['Thu', 't'],
            ['Fri', 'f'],
            ['Sat', 's']
        ]
    };

    function loadEvents() {
        return MosSwitcher.attemptGeneralMos()
        .then(function (response) {
            return doLoadEvents(response.mos);
        })
        .then(function () {
            return doLoadStudentMatineeEvents();
        })
        .then(function () {
            return doLoadConferences();
        });
    };

    function doLoadEvents (modeOfSale) {
        return $q.all([
            PerformanceSearchService.getCMSEvents({
                sStartDate: firstEventDate.format(dateFormat),
                sEndDate: moment().add(2, 'y').startOf('hour').format(dateFormat),
                iModeOfSale: modeOfSale
            }),
            PerformanceSearchService.getPerformanceKeywords({
                mos: modeOfSale,
                categories: allowedCategories.join(',')
            }),
            // If the user isnt logged into facebook it'll error
            // avoid the error
            PerformanceSearchService.getFacebookFriends().catch(function(){})
        ]).then(function (responses) {
            var
                events = responses[0].Performance,
                webContents = responses[0].WebContent,
                keywords = arrayWrap(responses[1].data.result.ExecuteLocalProcedureResults.LocalProcedure),
                facebookFriends = responses[3] || {};

            service.facetFilters = {};

            generateFacetFilters(keywords);

            service.events = {};

            events.forEach(function (event) {
                processEvent(event, keywords, webContents);
            });

            setVenueCollection(events);
            setSeasonCollection(events);
            setTimeslotCollection(events);

            return $q.resolve(true);
        });
    };

    function doLoadStudentMatineeEvents() {
        return TessituraSDK.SMaPGrades()
        .then(function (response) {
            var
                result = response.data.result,
                localData = result.GetLocalDataResults.LocalData;

            // Grab modes of sales
            var packages = arrayWrap(localData).map(function (grade) {
                return TessituraSDK.GetNFSPackageDetailEx({
                    PackageID: parseInt(grade.pkg_no, 10),
                    ModeOfSale: parseInt(grade.MOS, 10)
                });
            });

            return $q.all(packages).then(function (responses) {
                arrayWrap(responses).forEach(function (response) {
                    var
                        result = response.data.result,
                        package = result.GetNFSPackageDetailExResult.Package,
                        performances = result.GetNFSPackageDetailExResult.Performance,
                        webContents = result.GetNFSPackageDetailExResult.PerformanceWebContent;

                    package.webContent = arrayWrap(result.GetNFSPackageDetailExResult.WebContent).filter(function (webContent) {
                        return parseInt(webContent.orig_inv_no, 10) === parseInt(package.pkg_no, 10);
                    });

                    arrayWrap(performances).forEach(function (performance) {
                        processPackageEvent(performance, webContents, package);
                    });
                });

                return $q.resolve(true);
            });
        });
    };

    function doLoadConferences() {
        return TessituraSDK.SMaPTeacherConferences().then(function (response) {
            var conferences = arrayWrap(response.data.result.GetLocalDataResults.LocalData);

            conferences.forEach(function (conference) {
                processConference(conference);
            });

            return $q.resolve(true);
        });
    };

    function generateFacetFilters(keywords) {
        var
            categoryKeywords = groupBy(keywords, 'category'),
            performanceKeywords = groupBy(keywords, 'perf_no'),
            facetFilters = [];

        allowedCategories.forEach(function (category, index) {
            var keywordsGroup = categoryKeywords[category];

            if (keywordsGroup) {
                var terms = keywordsGroup.map(function (keyword) {
                    return {
                        id: keyword.id,
                        description: keyword.description
                    };
                });

                terms = unique(terms, function (term) {
                    return term.id;
                });

                terms.forEach(function (term) {
                    facetFilters.push({
                        id: term.id,
                        facet: term.description,
                        cat: category,
                        sortOrder: allowedCategories.indexOf(category)
                    });
                });
            }
        });

        service.facetFilters = facetFilters;
    };

    function processEvent(event, keywords, webContents, facebookFriends) {
        var
            date = moment(event.perf_date.substr(0, event.perf_date.lastIndexOf('-'))),
            timestamp = moment([
                date.year(),
                date.month(),
                date.date()
            ]).valueOf();

        var groupedKeywords = filterKeywordsForEvent(keywords, event);

        // event.facets = groupedKeywords.facets;
        event.keywords = groupedKeywords.keywords;
        event.highlight = false;
        event.mute = false;
        event.hideBuyButton = event.cmsFields.HideBuyButton;
        event.timestamp = date.valueOf();
        event.webContent = filterWebContentForEvent(webContents, event);
        event.title = performanceTitle(event);
        event.facility_prefix = appConfig.facility_prefixes[parseInt(event.facility_no, 10)] ? appConfig.facility_prefixes[parseInt(event.facility_no, 10)] : 'at the';

        if (moment(event.perf_date).isAfter(lastEventDate)) {
            lastEventDate = moment(event.perf_date);
        }

        // if(facebookFriends[events[i].perf_no]){
        // events[i].facebookFriends = facebookFriends[events[i].perf_no];
        // }

        if (event.webContent) {
            var customText = $filter('first')(event.webContent.filter( function(content){
                return content.content_type == appConfig.webContentTypes.customBookingText;
            }));
            event.BuyButtonText = (customText) ? customText.content_value : 'Buy Tickets';
        }

        service.events[timestamp] = service.events[timestamp] || [];
        service.events[timestamp].push(event);
    };

    function processPackageEvent(event, webContents, package) {
        var
            date = moment(event.perf_dt.substr(0, event.perf_dt.lastIndexOf('-'))),
            timestamp = moment([
                date.year(),
                date.month(),
                date.date()
            ]).valueOf();

        event.prod_type = 998;
        event.facets = [];
        event.keywords = [];
        event.highlight = false;
        event.mute = false;
        event.hideBuyButton = true;
        event.timestamp = date.valueOf();
        event.perf_date = moment(event.perf_dt);
        event.title = package ? packageTitle(package) : performanceTitle(event);

        if (moment(event.perf_dt).isAfter(lastEventDate)) {
            lastEventDate = moment(event.perf_dt);
        }

        event.webContent = filterWebContentForEvent(webContents, event);

        if (event.webContent) {
            var customText = $filter('first')(event.webContent.filter( function(content){
                return content.content_type == appConfig.webContentTypes.customBookingText;
            }));
            event.BuyButtonText = (customText) ? customText.content_value : 'Buy Tickets';
        }

        service.events[timestamp] = service.events[timestamp] || [];
        service.events[timestamp].push(event);
    };

    function processConference(conference) {
        var date = moment(conference.Conference_Date.substr(0, conference.Conference_Date.lastIndexOf('-')));

        if (firstEventDate.isAfter(date)) {
            // Skip past conferences
            return false;
        }

        var timestamp = moment([
            date.year(),
            date.month(),
            date.date()
        ]).valueOf();

        conference.prod_type = 999;
        conference.facets = [];
        conference.keywords = [];
        conference.highlight = false;
        conference.mute = false;
        conference.hideBuyButton = true;
        conference.timestamp = date.valueOf();
        conference.perf_date = moment(conference.Conference_Date);
        conference.facility_desc = conference.Location;

        if (moment(conference.Conference_Date).isAfter(lastEventDate)) {
            lastEventDate = moment(conference.Conference_Date);
        }

        service.events[timestamp] = service.events[timestamp] || [];
        service.events[timestamp].push(conference);
    };

    function filterKeywordsForEvent(keywords, event) {
        var eventKeywords = {
            facets: {},
            keywords: []
        };

        allowedCategories.forEach(function (category) {
            eventKeywords.facets[category] = filter(keywords, function (keyword) {
                return keyword.category == category && keyword.perf_no == event.perf_no;
            });

            eventKeywords.keywords = eventKeywords.keywords.concat(eventKeywords.facets[category].map(function (keyword) {
                return keyword.id;
            }));
        });

        return eventKeywords;
    };

    function filterWebContentForEvent(webContents, event) {
        return filter(arrayWrap(webContents), function (webContent) {
            return webContent.orig_inv_no == event.perf_no;
        });
    };

    function setVenueCollection(events) {
        var venues = {};

        events.forEach(function (event) {
            if (venues[event.facility_no]) {
                return;
            }

            venues[event.facility_no] = event.facility_desc;
        });

        service.venues = venues;
    };

    function setSeasonCollection(events) {
        var seasons = {};

        events.forEach(function (event) {
            if (seasons[event.prod_season_no]) {
                return;
            }

            seasons[event.prod_season_no] = event.prod_season_no;
        });

        service.seasons = seasons;
    };

    function setTimeslotCollection(events) {
        var timeSlots = {};

        events.forEach(function (event) {
            if (timeSlots[event.time_slot]) {
                return;
            }

            timeSlots[event.time_slot] = event.time_slot_desc;
        });

        service.timeSlots = timeSlots;
    };

    function getWeeks(continuous, startFrom) {
        if (!startFrom) {
            startFrom = moment(startFrom).startOf('month');
        }

        startFrom = startFrom.startOf('week');
        continuous = continuous || false;

        var
            start = moment().startOf('month').startOf('week'),
            months = [],
            totalMonths = lastEventDate.endOf('month').diff(firstEventDate, 'months'),
            count = 0,
            done = false;

        while (!done) {
            var
                month = buildMonth(start),
                weeks = [];


            month.forEach(function (week) {
                if (continuous) {
                    var day = week[0].day.clone();

                    if (!day.startOf('week').isBefore(startFrom)) {
                        weeks = weeks.concat(week);
                    }
                } else {
                    weeks = weeks.concat(week);
                }
            });

            if (continuous) {
                months = months.concat(weeks);
            } else {
                months.push(weeks);
            }

            start = start.clone();
            start.add(1, 'm');

            if (!continuous) {
                start.startOf('month').startOf('week');
            }

            done = count++ == totalMonths;
        }

        return months;
    };

    function buildMonth(date) {
        var
            weeks = [],
            done = false,
            monthIndex = date.month(),
            count = 0;

        while (!done) {
            var week = buildWeek(date.clone());
            weeks.push(week);

            date.add(1, 'w');
            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }

        return weeks;

    };

    function buildWeek(date) {
        var days = [];

        for (var i = 0; i < 7; i++) {
            var
                timestamp = moment([
                    date.year(), date.month(), date.date()
                ]).valueOf(),
                monthTimestamp = moment([
                    date.year(), date.month()
                ]).valueOf();

            days.push({
                day: date,
                startWeek: date.clone().startOf('week').valueOf(),
                startOfMonth: date.date() === 1,
                timestamp: timestamp.valueOf(),
                monthTimestamp: monthTimestamp,
                today: date.isSame(moment(), 'day'),
                currentMonth: date.isSame(moment(), 'month'),
                events: getGroupedEvents(timestamp),
                focusable: false
            });

            date = date.clone();
            date.add(1, 'd');
        }

        return days;
    };

    function getGroupedEvents(timestamp) {
        var
            mainEvents = [],
            otherEvents = [],
            yapEvents = [],
            events = orderBy(service.events[timestamp], ['+timestamp']),
            eventsGrouped = groupBy(events, 'prod_type');

        angular.forEach(eventsGrouped, function (eventsByType, type) {
            eventsByType.forEach(function (event) {
                if ('Conference_Date' in event || 'perf_dt' in event) {
                    event.type = 'yap';
                    yapEvents.push(event);
                } else if (allowedProductionTypes.indexOf(Number(type)) !== -1) {
                    event.type = 'main';
                    mainEvents.push(event);
                } else {
                    event.type = 'other';
                    otherEvents.push(event);
                }
            });
        });

        return [].concat(mainEvents, otherEvents, yapEvents);
    };

    return {
        loadEvents: loadEvents,
        getWeeks: getWeeks,
        getGroupedEvents: getGroupedEvents,
        getFacetFilters: function () {
            return service.facetFilters;
        },
        getVenues: function () {
            return service.venues;
        },
        getTimeSlots: function () {
            return service.timeSlots;
        },
        getSeasons: function () {
            return service.seasons;
        },
        getWeekDays: function () {
            return service.weekDays;
        }
    };
}]);
