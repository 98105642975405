
app.service('Exchange', ['$window', '$q', '$filter', 'TessituraSDK', 'AuthenticationService', 'Cart', 'Router', 'Notifications', function ($window, $q, $filter, TessituraSDK, AuthenticationService, Cart, Router, Notifications) {

  var arrayWrap = $filter('arrayWrap');
  var subLineItemReturned = $filter('subLineItemReturned');

  return {
    getExchangeModes: getExchangeModes,
    isInExchangeMode: isInExchangeMode,
    getExchangeMode: getExchangeMode,
    exchangeSubLineItems: exchangeSubLineItems,
    checkExchangeModeRules: checkExchangeModeRules,
    exchangeTickets: exchangeTickets
  };

  /**
   * Returns a list of all exchange modes
   */
  function getExchangeModes() {
    return TessituraSDK.GetExchangeModes()
      .then(function (response) {
        var modes = arrayWrap(response.data.result.GetLocalDataResults.LocalData);
        return modes;
      });
  }

  /**
   * Returns the current exchange mode that the user
   * is in, or null if they arent in one.
   */
  function getExchangeMode() {
    // The cart, and exchange modes
    return Cart.getCart(true)
      .then(function (cart) {

        // Get subline items that are being returned
        var subLineItems = arrayWrap(cart.SubLineItem);
        subLineItems = subLineItemReturned(subLineItems);

        // If we arent returning any lines, we arent in exchange mode
        if (subLineItems.length === 0) {
          return $q.resolve(null);
        }

        // Take the first sublineitem
        var subLineItem = subLineItems[0];

        return getExchangeModeFromProdSeasonNo(subLineItem.prod_season_no);
      });
  }

  function getExchangeModeFromProdSeasonNo(prod_season_no){

    // The cart, and exchange modes
    return getExchangeModes()
      .then(function (exchangeModes) {
        // Loop over all the exchange modes
        for (var i = 0; i < exchangeModes.length; i++) {
          var exchangeMode = exchangeModes[i];
          if (exchangeMode.prod_season_no == prod_season_no) {
            return $q.resolve(exchangeMode);
          }
        }

        // Theres no exchange mode for this
        return $q.resolve(null);
      });
  }

  function isInExchangeMode() {
    return getExchangeMode()
      .then(function (mode) {
        return mode !== null;
      });
  }

    function exchangeTickets(prodSeasonNo, sublineItems) {
        return Cart.confirmAndClear('To exchange these tickets we need to clear your cart first. Empty cart now?').then(function () {
            return getExchangeModeFromProdSeasonNo(prodSeasonNo);
        }).then(function (mode) {
            if (mode === null) {
                return Cart.clear().then(function () {
                    return $q.reject();
                });
            }

            return TessituraSDK.ChangeModeOfSaleEx({
                NewModeOfSale: parseInt(mode.mode_of_sale, 10)
            });
        }).then(function () {
            return TessituraSDK.ReturnTickets({
                field: 'sSubLineItems',
                tickets: sublineItems.join(',')
            }).then(function (response) {
                // Was there an error?
                var results = response.data.result;

                if (!angular.isArray(results) || results.length === 0) {
                    return $q.reject();
                }

                // Was there an error?
                for (var i in response.data.result) {
                    var result = response.data.result[i];

                    if (result.error) {
                        return $q.reject();
                    }
                }
            }).catch(function () {
                return $q.reject();
            });
        }).then(function () {
            return TessituraSDK.ResetTicketExpiration();
        }).then(function () {
            $window.location = Router.getUrl('booking.best_available.season', {
                prod_no: prodSeasonNo
            });
        }).catch(function () {
            alert('We experienced an issue processing your request. Please try again or contact Audience Services at 213.628.2772 if the problem persists. This page will now refresh.');

            return Cart.clear().finally(function () {
                return TessituraSDK.ChangeModeOfSaleEx({
                    NewModeOfSale: 0
                }).finally(function () {
                    $window.location.reload();
                });
            });
        });
    }

  /**
   * Donates subline items and then reloads the order.
   */
  function exchangeSubLineItems(orderNumber, lineItem, subLineItems) {

    var modeOfSale;

    var Performance;

    // Exit if no lineItems selected
    if (subLineItems.length === 0) {
      return $q.resolve();
    }

    var tickets  = [];

    for (var i in subLineItems) {
      var subLineItem = subLineItems[i];
      tickets.push(subLineItem.sli_no);
    }

    // Start returning the tickets
    var ReturnTicketsParams = {
      field: 'sSubLineItems',
      tickets: tickets.join(',')
    };
    // Clear the cart
    return Cart.confirmAndClear('To exchange these tickets we need to clear your cart first. Empty cart now?')

    .then(function () {
      return AuthenticationService.getLoginInfo();
    })

    // Make the request for performance
    .then(function (loginInfo) {

      // Get the get perf request
      var GetPerformanceDetailExParams = {
        iModeOfSale: loginInfo.MOS,
        iPerf_no: parseInt(subLineItems[0].perf_no)
      };

      // Make request
      return TessituraSDK.GetPerformanceDetailEx(GetPerformanceDetailExParams);
    })

    // Get the mode of sale for this exchange mode
    .then(function (response) {

      Performance = response.data.result.GetPerformanceDetailExResult.Performance;

      return getExchangeModeFromProdSeasonNo(Performance.prod_season_no);
    })
    .then(function (mode) {
      if (mode === null) {
        // Theres no mode, empty the cart and cancel
        return Cart.clear()
        .then(function () {
          return $q.reject();
        });
      }
      modeOfSale = mode.mode_of_sale;

      // Set the users current mode
      return TessituraSDK.ChangeModeOfSaleEx({
        NewModeOfSale: parseInt(modeOfSale)
      });
    })

    // Return tickets (add them to basket)
    .then(function () {
      return TessituraSDK.ReturnTickets(ReturnTicketsParams)
      .then(function (response) {

        // Was there an error?
        var results = response.data.result;
        if (!angular.isArray(results) || results.length === 0) {
          return $q.reject();
        }

        // Was there an error?
        for (var i in response.data.result) {
          var result = response.data.result[i];
          if (result.error) {
            return $q.reject();
          }
        }
      })

      // If there was an error fails empty the cart
      // and return the user to the default MOS
      .catch(function () {
        Cart.clear()
        .finally(function () {
          TessituraSDK.ChangeModeOfSaleEx({
            NewModeOfSale: 0
          });
        });

        return $q.reject();
      });
    })

    // Get the order details for the delivery method
    .then(function () {
      return TessituraSDK.GetCustomOrderDetails({
        order_no: orderNumber
      })
      .then(function (response) {

        var result = response.data.result.ExecuteLocalProcedureResults;

        var orderDetails = {
          Order: result.LocalProcedure,
          LineItem: result.LocalProcedure1,
          SubLineItem: result.LocalProcedure2,
          SubLineItemFee: result.LocalProcedure3,
          Fee: result.LocalProcedure4,
          Contribution: result.LocalProcedure5,
          Payment: result.LocalProcedure6,
          PaymentPlan: result.LocalProcedure7
        };

        return orderDetails;
      });
    })

    // Set the old shipping information
    .then(function (orderDetails) {
      // Skip if delivery info not set
      if (!orderDetails.Order.delivery) {
        return;
      }

      // Build params for shipping
      var SetShippingInformationParams = {
        iAddress_no: 0,
        iShippingMethod: orderDetails.Order.delivery,
      };

      if (orderDetails.Order.address_no) {
        SetShippingInformationParams.iAddress_no = orderDetails.Order.address_no;
      }

      // Make request
      return TessituraSDK.SetShippingInformation(SetShippingInformationParams)
      // If it errors, thats fine.
      .catch(function () {
        return $q.resolve();
      });
    })
    .then(function () {
      // Reset expiration time so users aren't left with random length session
      return TessituraSDK.ResetTicketExpiration();
    })
    // Finally, forward the user on to the search page
    .then(function () {
      // Build the URL which shows replacement services
      $window.location = Router.getUrl('booking.best_available.season', {
        prod_no: Performance.prod_season_no
      });
    });

  }

  /**
   * Checks if the exchange mode of sale rule is follows or not.
   * Returns true if the user isnt in exchange mode or is allowed to checkout.
   * Returns false if the user isnt follow the result
   *
   * Rule:
   *   If the user is in exchange mode they need to be exchange the tickets
   *   for an equal number or great of the same price type.
   *
   * @return {Boolean}
   */
  function checkExchangeModeRules() {
    return $q.all([
      AuthenticationService.getMos(),
      Cart.getCart(true),
      getExchangeModes(),
      Cart.getPriceTypes()
    ])
    .then(function (response) {

      var mos = response[0];
      var cartData = response[1];
      var exchangeModes = response[2];
      var allowedPriceTypes = response[3];

      // Is our MoS in the exchange MoS
      var isInExchangeMode = exchangeModes.some(function (mode) {
        return mode.mode_of_sale == mos;
      });

      // No its not, so we cant be breaking the rules
      if (!isInExchangeMode) {
        return true;
      }

      // Count all the price types we are buying
      var priceTypesExchanging = {};
      var priceTypesBuying = {};

      // Get unique performance details
      var promises = $filter('unique')(cartData.LineItem, 'perf_no').map(function (lineItem) {
        return getPerformanceDetails(mos, lineItem.perf_no);
      });

      return $q.all(promises).then(function (performances) {
        var itemsBeingExchanged = cartData.SubLineItem.filter(function (sublineItem) {
          return 'ret_parent_sli_no' in sublineItem;
        });

        var itemsBeingBought = cartData.SubLineItem.filter(function (sublineItem) {
          return !('ret_parent_sli_no' in sublineItem);
        });

        // No items being bought? Return false
        if (!itemsBeingBought.length) {
          return false;
        }

        // Not enough items being bought? Return false
        if (itemsBeingBought.length < itemsBeingExchanged.length) {
          return false;
        }

        // We got our "allowed" price types which is not realiable
        // as they may not be available anymore, so also add default
        // price types to that list
        var defaultPriceTypes = performances.reduce(function (carry, performance) {
          var defaults = performance.PriceType.filter(function (priceType) {
            return 'def_price_type' in priceType && priceType.def_price_type === 'Y';
          }).map(function (priceType) {
            return parseInt(priceType.price_type, 10);
          });

          return carry.concat(defaults);
        }, []);

        // Join em together
        var validPriceTypes = [].concat(allowedPriceTypes, defaultPriceTypes);

        // Price types of items being bought, allow dupes!
        var newPriceTypes = itemsBeingBought.map(function (sublineItem) {
          return parseInt(sublineItem.price_type, 10);
        });

        // Keep track of valid price types found
        var totalValid = newPriceTypes.filter(function (priceType) {
          return validPriceTypes.indexOf(priceType) !== -1;
        });

        return totalValid.length == itemsBeingExchanged.length;
      });
    });
  }

  function getPerformanceDetails(modeOfSale, performanceNo) {
    return TessituraSDK.GetPerformanceDetailWithDiscountingEx({
      iModeOfSale: modeOfSale,
      iPerf_no: performanceNo
    })
    .then(function (response) {
      var
        result = response.data.result,
        performanceDetail = result.GetPerformanceDetailWithDiscountingExResult;

      performanceDetail.AllPrice = arrayWrap(performanceDetail.AllPrice);
      performanceDetail.Credit = arrayWrap(performanceDetail.Credit);
      performanceDetail.Price = arrayWrap(performanceDetail.Price);
      performanceDetail.PriceType = arrayWrap(performanceDetail.PriceType);
      performanceDetail.WebContent = arrayWrap(performanceDetail.WebContent);

      return performanceDetail;
    });
  }
}]);
