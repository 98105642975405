/* global moment */
app.controller('FlexPackage3Controller', ['$scope', '$window', '$attrs', 'TessituraSDK', '$http', '$filter', 'AuthenticationService', '$q', 'Router', 'GetParameters', 'appConfig', 'MosSwitcher', 'CacheStorage', 'Cart', function ($scope, $window, $attrs, TessituraSDK, $http, $filter, AuthenticationService, $q, Router, GetParameters, appConfig, MosSwitcher, CacheStorage, Cart) {
  // Tools
  var
    arrayWrap = $filter('arrayWrap'),
    filter = $filter('filter'),
    groupBy = $filter('groupBy'),
    orderBy = $filter('orderBy'),
    find = $filter('find');

  var widgetConfig = document.getElementById('widget-config');

  if (widgetConfig) {
    widgetConfig = JSON.parse(widgetConfig.innerHTML);
  } else {
    widgetConfig = {};
  }

  // Vars
  var
    modeOfSale = parseInt(widgetConfig.ModeOfSale || $attrs.modeOfSale) || appConfig.flexSubscriberMos,
    allowViaPromoCode = parseInt(widgetConfig.AllowViaPromoCode || $attrs.allowViaPromoCode) === 1 || false,
    packageNumbers = widgetConfig.PackageNo || $attrs.packageNumbers || GetParameters.packageNumbers,
    minimumPerformances = parseInt(widgetConfig.MinimumPerformances || $attrs.minimumPerformances) || 4,
    maximumPerformances = parseInt(widgetConfig.MaximumPerformances || $attrs.maximumPerformances) || null,
    maximumSubscriptions = parseInt(widgetConfig.MaximumSubscriptions || $attrs.maximumSubscriptions) || 10,
    pageTitle = widgetConfig.PageTitle || 'Design Your Own Season',
    defaultQuantity = 2,
    defaultPriceType = parseInt(widgetConfig.DefaultPriceType || $attrs.defaultPriceType) || 83,
    hideQuantityLessThan = maximumSubscriptions,
    now = moment().format('YYYY-MM-DDTHH:00:00Z'),
    notePrefix = 'Part of ' + pageTitle + ' #',
    packageFees = {};

  // Scope vars
  $scope = angular.extend($scope, {
    loading: true,
    wrongMos: false,
    wrongMosReason: '',
    packages: [],
    selectedPerformances: {},
    performanceZones: {},
    miniBasket: [],
    defaultZones: {},
    totalSelected: 0,
    totalPerformancesAvailable: 0,
    maximumSubscriptions: maximumSubscriptions,
    totalRequired: minimumPerformances,
    selectedQuantity: defaultQuantity,
    limitReached: false,
    addingToCart: false,
    basketToggled: false,
    pageTitle: pageTitle,
    totalSubscriptions: [],
    // Functions
    prepareZones: prepareZones,
    setDefaultZone: setDefaultZone,
    setQuantity: setQuantity,
    removeItem: removeItem,
    addToCart: addToCart
  });

  for (var i = 1; i <= maximumSubscriptions; i++) {
    $scope.totalSubscriptions.push(i);
  }

  AuthenticationService.requireLogin()
  .then(function () {
    // Try switching the user to mode of sale for this page
    return MosSwitcher.customMos(modeOfSale);
  })
  .then(function (response) {
    // Failed to switch MoS
    if (response.status === false || (allowViaPromoCode && 'promoCode' in response === false) || ('promoCode' in response && response.promoCode && !allowViaPromoCode)) {
      $scope.wrongMos = true;
      if ('promoCode' in response && response.promoCode && !allowViaPromoCode) {
        $scope.wrongMosReason = 'Please complete your current transaction purchase or remove the promo code to add this item to the cart.';
      } else if (allowViaPromoCode && 'promoCode' in response === false) {
        $scope.wrongMosReason = 'This item is only available via a promo code.';
      } else {
        $scope.wrongMosReason = response.reason;
      }
    }

    return loadPackages();
  })
  .then(function (responses) {
    responses.forEach(function (data) {
      var
        package = data.Package,
        performances = arrayWrap(data.Performance),
        performanceGroups = arrayWrap(data.PerformanceGroup),
        webContent = arrayWrap(data.WebContent),
        performanceWebContent = arrayWrap(data.PerformanceWebContent);

      // Grab the SYOS web content field
      var syosEnabled = filter(webContent, function (content) {
        return parseInt(content.content_type, 10) === appConfig.webContentTypes.webSYOS;
      });

      // Does one exist?
      if (syosEnabled.length) {
        // See if the value is Y
        package.syosEnabled = (
          angular.isString(syosEnabled[0].content_value) &&
          syosEnabled[0].content_value.toLowerCase() === 'y'
        );
      } else {
        // Otherwise nullify it
        package.syosEnabled = null;
      }

      package.performances = [];

      // Find package fee
      var feeWebContent = find(webContent, function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.webPackageItemCost;
      });

      // Keep track of found package fee
      packageFees[package.pkg_no] = (feeWebContent) ? feeWebContent.content_value : 10;

      performances = performances.filter(function (performance) {
        return (
          // Skip any in the past
          moment.parseZone(performance.perf_dt).isSameOrAfter(now, 'day') &&
          // Skip any not on sale
          performance.on_sale_ind === 'Y' &&
          // Skip sold out, off sale, or cancelled
          parseInt(performance.perf_status, 10) === 1
        );
      });

      // Make a map of performance group
      var groups = [];

      angular.forEach(performanceGroups, function (group) {
        groups[group.perf_group_no] = group.description;
      });

      // Add meta data to each performance
      angular.forEach(performances, function (performance, index) {
        performance.perf_group_desc = groups[performance.perf_group_no];

        performance.webContent = filter(performanceWebContent, {
          orig_inv_no: performance.perf_no
        });

        package.performances.push(simplifyPerformance(performance, package));
      });

      // Order by description
      package.performances = orderBy(package.performances, ['+perf_group_desc', '+perf_dt_orig']);
      package.performanceOrder = package.performances.map(function(perf) { return perf.title;});

      // Group by the long title
      package.performances = groupBy(package.performances, 'title');

      // Number of package performances
      var packagePerformances = Object.keys(package.performances).length;

      // Ignore packages with 0 available performances
      if (!packagePerformances) {
        return false;
      }

      // Keep a track of how many performances we have
      $scope.totalPerformancesAvailable += packagePerformances;

      // Some default options
      package.selectedPerformance = null;

      // Add the package to be displayed on the page
      $scope.packages.push(package);
    });

    return $q.resolve(true);
  })
  .finally(function () {
    $scope.loading = false;
  });

  function loadPackages() {
    // Split package numbers up by comma
    // Run through map to loop without loop
    var promises = packageNumbers.toString().split(',').map(function (packageNumber) {
      // Return loading promise
      return TessituraSDK.GetNFSPackageDetailEx({
        PackageID: packageNumber,
        ModeOfSale: modeOfSale
      }).then(function (response) {
        var webContent = arrayWrap(response.data.result.GetNFSPackageDetailExResult.WebContent);
        response.data.result.GetNFSPackageDetailExResult.Package.webContent = webContent;

        return response.data.result.GetNFSPackageDetailExResult;
      });
    });

    return $q.all(promises);
  };

  function loadPerformance(perfNo) {
    return TessituraSDK.GetPerformanceDetailWithDiscountingEx({
      iPerf_no: perfNo,
      iModeOfSale: modeOfSale
    }).then(function (response) {
      return response.data.result.GetPerformanceDetailWithDiscountingExResult;
    });
  };

  function prepareZones(key) {
    var selectedPerformance = $scope.selectedPerformances[key];

    if (selectedPerformance === null) {
      return false;
    }

    var package = selectedPerformance.pkg_no;

    return loadPerformance(selectedPerformance.perf_no).then(function (response) {
      // Save it in an array after doing a nice grouping
      var allPrices = groupPrices(response.AllPrice, selectedPerformance.pkg_no);

      // Can we assign seats for this person?
      $scope.selectedPerformances[key].seated = response.Performance.seat_ind != 'N';

      // Save it in an array
      $scope.performanceZones[key] = allPrices;

      if ($scope.defaultZones[package]) {
        var findSelectedZone = allPrices.filter(function (zone) {
          return zone.zone_no == $scope.defaultZones[package];
        });

        var selectedZone = findSelectedZone.length ? findSelectedZone[0] : false;

        if (selectedZone) {
          $scope.selectedPerformances[key].selectedZone = selectedZone;
        }
      }
    });
  };

  function groupPrices(allPrices, pkgNo) {
    // Firsty, array wrap it all
    allPrices = arrayWrap(allPrices);

    // Grab a fee
    var packageFee = packageFees[parseInt(pkgNo, 10)];

    // Find available zones for the sub price
    var availableZones = allPrices.filter(function (price) {
      return (
        // Only return default price type
        price.price_type == defaultPriceType &&
        // Only return zones with quantity higher than a config value
        price.avail_count > hideQuantityLessThan
      );
    });

    // Store zones to use on front end
    var priceZones = [];

    // Keep track of zones we've already added with the same name
    var existingZones = [];

    // Group by description - really heavy, only should be done in JS
    var sections = $filter('groupByFirstWord')(availableZones, 'description');

    // Because group by first word actually groups, we need to flatten this out
    angular.forEach(sections, function (zones, section) {
      // Which means looping throug things a few times *sigh*
      zones.forEach(function (zone) {
        var uniqueZoneDesc = section + ' ' + zone.description;

        var zoneDesc = zone.description + ' - ' + $filter('currency')( parseFloat(zone.price) + parseFloat(packageFee) );

        // Skip any unavailable, or if we already have one of the same name
        if (zone.available == 'N' || existingZones.indexOf(uniqueZoneDesc) !== -1) {
          return;
        }

        zone.section = section;
        zone.label = zoneDesc;

        priceZones.push(zone);

        existingZones.push(uniqueZoneDesc);
      });
    });

    return priceZones;
  };

  function simplifyPerformance(performance, package) {
    var syosEnabled = package.syosEnabled;

    // Only look for a SYOS web content if one doesn't exist at the package level
    if (syosEnabled === null) {
      // Grab the SYOS web content field
      syosEnabled = filter(performance.webContent, function (content) {
        return parseInt(content.content_type, 10) === appConfig.webContentTypes.webSYOS;
      });

      // Does one exist?
      if (syosEnabled.length) {
        // Check if the value is Y
        syosEnabled = (
          angular.isString(syosEnabled[0].content_value) &&
          syosEnabled[0].content_value.toLowerCase() === 'y'
        );
      }
    }

    return {
      // Performance number as default
      perf_no: performance.perf_no,
      // Facility as standard
      facility_desc: performance.facility_desc,
      // Need the performance group for reserving
      perf_group_no: performance.perf_group_no,
      // Need the performance group description for sorting
      perf_group_desc: performance.perf_group_desc,
      // Stick a package number in because tis useful
      pkg_no: package.pkg_no,
      // Add month so can group nicely
      month: moment(performance.perf_dt).format('MMMM YYYY'),
      // Add in a moment date so it can be used in filters
      perf_dt: moment.parseZone(performance.perf_dt),
      // Add in a moment date so it can be used in filters
      perf_dt_orig: performance.perf_dt,
      // Stick in the nice title
      title: $filter('performanceLongTitle')(performance),
      // Selected zone
      selectedZone: null,
      // Do we wish to show the label that says users can pick their seats?
      syosEnabled: syosEnabled
    };
  };

  function simplifyZone(zone) {
    return {
      zone_no: zone.zone_no,
      avail_count: parseInt(zone.avail_count || 0),
      description: zone.description,
      description_orig: zone.description_orig,
      section: zone.section,
      price: zone.price
    };
  };

  function setDefaultZone(key) {
    var
      selectedPerformance = $scope.selectedPerformances[key],
      package = selectedPerformance.pkg_no;

    if (typeof $scope.defaultZones[package] === 'undefined') {
      $scope.defaultZones[package] = selectedPerformance.selectedZone.zone_no;
    }
  };

  function setQuantity(quantity) {
    $scope.selectedQuantity = quantity + 1;
  };

  function removeItem(performance, index) {
    var toRemove = $scope.miniBasket[index];

    // Bye
    delete $scope.selectedPerformances[toRemove.title];
  };

  function addToCart() {
    $scope.addingToCart = true;
    $scope.addToCartError = false;

    var
      // First, group by packages since perfs needs adding by package
      groupedPerformances = groupBy($scope.miniBasket, 'pkg_no'),
      // Keep track of promises
      promises = [],
      // And the performances to go with them
      performances = [];

    // We need to stick an item from package in first as the parent
    var async = Object.keys(groupedPerformances).reduce(function (promiseChain, package) {
      // .finally to run regardless of success or failure
      return promiseChain.finally(function () {
        // Grab first performance
        var performance = groupedPerformances[package].shift();

        // We need this promise
        var reservePromise = reservePerformance(performance);

        // Keep track of the promise
        promises.push(reservePromise);

        // And the performance for lookup
        performances.push(performance);

        // Resolve for next
        return reservePromise;
      });
    }, $q.resolve(true));

    async.then(function () {
      return $q.all(promises);
    })
    .then(function (responses) {

      // Presume first seats are successfully added
      return Cart.getCart(true).then(function (response) {
        var
          result = response,
          packageLineItems = arrayWrap(result.PackageLineItem);

        var addedLineItems = {};

        // We need to find the line item numbers of the performances just added
        performances.forEach(function (performance) {
          var foundItem = findAddedPackageLineItem(packageLineItems, performance);

          if (foundItem) {
            addedLineItems[ foundItem.pkg_no ] = foundItem.pkg_li_no;
          }
        });

        // Reset
        promises = [];

        // Now add the rest of the items
        angular.forEach(groupedPerformances, function (items, package) {
          // Individual performance
          items.forEach(function (performance) {
            // Same call, but include package line item number
            promises.push( reservePerformance(performance, addedLineItems[package]) );
          });
        });

        return $q.all(promises)
        .then(function () {
          // Presume everything was fine
          return nextIncrementingPackage();
        })
        .then(function (count) {
          // Smoke and mirros - add order comments
          return Object.keys(addedLineItems).map(function (key) {
            return TessituraSDK.AddOrderCommentsEx2({
              Comment: notePrefix + count,
              LineItemID: addedLineItems[key],
              LineItemType: 'L',
              CustomerNo: 0,
              CategoryNo: 0
            });
          });
        })
        .then(function (orderNoteResponses) {
          // Change the expiration time
          TessituraSDK.ResetTicketExpiration().then(function () {
            // Onwards
            $window.location = Router.getUrl('booking.basket');
          });
        });

      });
    })
    .catch(function (extraError) {
      return Cart.getCart(true).then(function (response) {
        var
          result = response,
          packageLineItems = arrayWrap(result.PackageLineItem);

        var addedLineItems = {};

        // We need to find the line item numbers of the performances just added
        performances.forEach(function (performance) {
          var foundItem = findAddedPackageLineItem(packageLineItems, performance);

          if (foundItem) {
            addedLineItems[ foundItem.pkg_no ] = foundItem.pkg_li_no;
          }
        });

        return removePackages(addedLineItems);
      })
      .finally(function () {
        $scope.addToCartError = angular.isString(extraError) ? extraError : true;
        $scope.addingToCart = false;
      });
    })
    .catch(function () {
      $scope.addToCartError = true;
      $scope.addingToCart = false;
    });
  };

  function reservePerformance(performance, packageLineItemNumber) {
    return TessituraSDK.AddNFSPackagePerformanceItem({
      NFSPackageLineItemId: packageLineItemNumber ? packageLineItemNumber : 0,
      PriceType: defaultPriceType,
      PackageNumber: performance.pkg_no,
      PerformanceGroupNumber: performance.perf_group_no,
      PerformanceNumber: performance.perf_no,
      NumberOfSeats: $scope.selectedQuantity,
      Zone: performance.selectedZone.zone_no,
      RequestedSeats: '',
      LeaveSingleSeats: 'true',
      SpecialRequests: 'ContiguousSeats=' + ($scope.selectedQuantity > 2 ? '2' : '0'),
      UnSeated: String(!performance.seated)
    })
    .then(function (response) {
      var result = parseInt(response.data.result[0], 10);

      if (result !== $scope.selectedQuantity) {
        return $q.reject(performance.title + ' does not have enough seats. Please select another date or zone.');
      }
    })
    .catch(function (error) {
      return $q.reject(performance.title + ' could not be added to the cart. Please select another date or zone.');
    });
  };

  function removePackageItem(package, lineItemNumber) {
    return TessituraSDK.RemoveNFSPackage({
      NFSPackageLineItemId: lineItemNumber,
      PerformanceLineItemId: 0,
      PerformanceNumber: 0
    });
  };

  function removePackages(lineItems) {
    var promises = [];

    angular.forEach(lineItems, function (lineItem, package) {
      promises.push( removePackageItem(package, lineItem) );
    });

    return $q.all(promises);
  };

  function findAddedPackageLineItem(packageLineItems, performance) {
    var findPackages = packageLineItems.filter(function (packageLineItem) {
      return (
        // Same performance number?
        packageLineItem.perf_no == performance.perf_no &&
        // Same performance group?
        packageLineItem.perf_group_no == performance.perf_group_no &&
        // Same package number?
        packageLineItem.pkg_no == performance.pkg_no &&
        // Same zone number?
        packageLineItem.zone_no == performance.selectedZone.zone_no
      );
    });

    return findPackages.length ? findPackages[0] : null;
  };

  function findSelectedPerformances() {
    var performances = [];

    angular.forEach($scope.selectedPerformances, function (performance, key) {
      if (performance === null) {
        delete $scope.selectedPerformances[key];

        return false;
      }

      if (performance.selectedZone) {
        performances.push(performance);
      }
    });

    return performances;
  };

  function nextIncrementingPackage() {
    return Cart.getCart(true).then(function (response) {
      var
        result = response,
        packageLineItems = arrayWrap(result.PackageLineItem);

      var packageLineItemNotes = packageLineItems.filter(function (packageLineItem) {
        return 'notes' in packageLineItem && packageLineItem.li_no == 0;
      })
      .map(function (packageLineItem) {
        return packageLineItem.notes;
      });

      return $filter('unique')(packageLineItemNotes).length + 1;
    });
  };

  $scope.$watch('selectedPerformances', function (newValue, oldValue) {
    var
      selectedPerformances = findSelectedPerformances(),
      totalSelected = selectedPerformances.length;

    $scope.miniBasket = selectedPerformances;

    $scope.totalSelected = totalSelected;
    $scope.totalRequired = minimumPerformances - totalSelected;

    if (maximumPerformances) {
      $scope.limitReached = totalSelected == maximumPerformances;
      $scope.maxReached = totalSelected > maximumPerformances;
    }
  }, true);
}]);
