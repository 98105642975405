/* global moment */
app.controller('CalendarController', ['$scope', '$filter', '$timeout', '$uibModal', '$sce', '$window', 'appConfig', 'Calendar', 'MosSwitcher', function ($scope, $filter, $timeout, $uibModal, $sce, $window, appConfig, Calendar, MosSwitcher) {
  var
    position = null,
    placeholder = document.querySelector('.mm_calendar_placeholder');

  var VENUE_CATEGORY_ID = 9;

  placeholder.style.display = 'none';

  var
    columnCount = 3,
    facetFilters = [],
    mmWindow = angular.element($window),
    body = document.querySelectorAll('body')[0],
    mmHeader = document.getElementById('mm_calendar_header'),
    mmWidget = document.querySelectorAll('.mm_widget')[0],
    mmCalendar = document.getElementById('mm_calendar'),
    mmWidgetInner = document.querySelector('.mm_widget_inner'),
    mmScrollArea = document.getElementById('mm_calendar_scrollarea'),
    timer = null,
    scrolling = false;

  $scope = angular.extend($scope, {
    eventDisplayLimit: 4,
    loading: true,
    previousMonth: previousMonth,
    today: today,
    nextMonth: nextMonth,
    isCollapsed: true,
    facetFilters: null,
    columns: [],
    weekDays: Calendar.getWeekDays(),
    weeks: null,
    event: null,
    events: null,
    displayEvents: displayEvents,
    currentMonth: null,
    disablePreviousMonth: true,
    disableNextMonth: false,
    useSimplified: false,
    visibleMonth: null,
    setFacetFilters: setFacetFilters,
    containerLineInView: containerLineInView,
    containerMobileLineInView: containerMobileLineInView,
    fixedHeader: false,
    weeksGroupedByStartIndex: []
  });

  mmWindow.bind('resize', adjustSize);

  function updateFixedHeader() {
    var calendarTop = mmWidgetInner.offsetTop;

    if (!$scope.fixedHeader && this.pageYOffset >= calendarTop) {
      placeholder.style.display = 'block';
      mmHeader.className += ' l-constrained';
      mmWidgetInner.className += ' fixedHeader';
      $scope.fixedHeader = true;
    } else if ($scope.fixedHeader && this.pageYOffset < calendarTop) {
      placeholder.style.display = 'none';
      mmHeader.className = mmHeader.className.replace(/l\-constrained/, '');
      mmWidgetInner.className = mmWidgetInner.className.replace(/fixedHeader/, '');
      $scope.fixedHeader = false;
    }

    $timeout.cancel(timer);
    timer = $timeout(function () {
      scrolling = false;
    }, 500);
  }

  mmWindow.on('scroll', updateFixedHeader);
  updateFixedHeader();

  function getDimensions() {
    return {
      width: mmWidget.clientWidth,
      height: mmWidget.clientHeight
    };
  };

  function adjustSize() {
    if (getDimensions().width < 700) {
      $scope.useSimplified = true;
    } else {
      $scope.useSimplified = false;
    }

    clearEvents();

    $timeout(function () {
      $scope.$apply();
    });
  };

  Calendar.loadEvents().then(function (responses) {
    $scope.weeks = Calendar.getWeeks(true, moment());
    $scope.weeksGroupedByStartIndex = $filter('groupBy')($scope.weeks, 'startWeek');

    var now = moment();
    $scope.currentMonth = moment([now.year(), now.month()]).valueOf();
    $scope.visibleMonth = moment($scope.currentMonth);

    getFacetFilters();

    $scope.loading = false;

    adjustSize();

    $timeout(function () {
      position = mmHeader.getBoundingClientRect();

      placeholder.style.width = position.width + 'px';
      placeholder.style.height = position.height + 'px';
    });
  });

  function getFacetFilters() {
    $scope.facetFilters = Calendar.getFacetFilters();

    var venues = [],
        nonVenues = [];

    $scope.facetFilters.forEach(function(facet){
      if(facet.cat === VENUE_CATEGORY_ID){
        venues.push(facet);
      }else{
        nonVenues.push(facet);
      }
    });

    var venuesLength = venues.length;
    var nonVenuesLength = nonVenues.length;
    var columns = [];

    columns.push({
      start : 0,
      end : venuesLength
    });
    
    var itemsPerColumn = Math.ceil(nonVenuesLength / columnCount);

    for (var i = 0; i < nonVenuesLength; i += itemsPerColumn) {
      var col = {
        start: i + venuesLength,
        end: Math.min(i + itemsPerColumn + venuesLength, $scope.facetFilters.length)
      };
      columns.push(col);
    }

    $scope.columns = columns;
  };

  function setFacetFilters(values) {
    if (values.vals.length < 1) {
      facetFilters = $filter('removeWith')(facetFilters, {
        index: values.index
      });
    } else {
      facetFilters.push({
        value: values.vals,
        index: values.index
      });
    }

    $timeout(muteByFacets);
  };

  function muteByFacets () {
    var selectedFilters = [];

    facetFilters.forEach(function (filters) {
      selectedFilters = selectedFilters.concat(filters.value);
    });
    $scope.weeks.forEach(function (day) {
      day.events.forEach(function (event) {
        var
          eventKeywords = event.keywords,
          matchedKeywords = eventKeywords.filter(function (i) {
            return selectedFilters.indexOf(i) != -1
          });

          // Disable highlighted and muted styles
          event.highlight = false;
          event.mute = false;

          // Do nothing if theres no filters
          if (selectedFilters.length === 0) {
            return;
          }

          // If the event matches the filter, highlight it
          if (matchedKeywords.length) {
            event.highlight = true;
          } else {
            event.mute = true;
          }
      });
    });
  };

  function displayEvents(date) {
    clearEvents();

    if (date.events.length > 0) {
      if ($scope.useSimplified) {
        showEvents(date);
      } else {
        openModal(date);
      }
    }
  };

  function showEvents(date) {
    $scope.event = date;
    $scope.events = $filter('groupBy')(date.events, 'type');
  };

  function openModal(date) {
    $uibModal.open({
      animation: true,
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'calendar-pop-up.html'),
      controller: 'ModalInstanceController',
      resolve: {
        date: function () {
          return date;
        },
        weeks: function () {
          return $scope.weeks;
        }
      }
    });
  };

  function doScroll() {
    var
      monthElement = document.querySelectorAll('#month-' + $scope.currentMonth)[0],
      mm_calendar_header = document.querySelectorAll('#mm_calendar_header')[0], 
      coords = getCoords(monthElement);

    var mm_calendar_header_height = mm_calendar_header.getBoundingClientRect().height;
    var yOffset = (mm_calendar_header_height!== undefined) ? mm_calendar_header_height : 0;

    if ($scope.useSimplified) {
      angular.element(mmCalendar).scrollToElement( angular.element(monthElement), 14, 1000);
    } else {
      angular.element(body).scrollTo( coords.left,  coords.top - yOffset, 1000);
    }

    scrolling = true;
  };

  function getCoords(elem) {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return {
      top: Math.round(top),
      left: Math.round(left)
    };
  };

  function timestamp(date) {
    return moment([
      date.year(), date.month()
    ]).valueOf();
  };

  function updateCurrentMonthClass() {
    $scope.weeks.forEach(function (day) {
      day.currentMonth = day.monthTimestamp == $scope.currentMonth;
    });
  };

  function clearEvents() {
    $scope.event = null;
    $scope.events = null;
  };

  function previousMonth() {
    var date = moment($scope.currentMonth).subtract(1, 'month');

    $scope.currentMonth = timestamp( date );
    $scope.visibleMonth = moment( $scope.currentMonth );

    updateCurrentMonthClass();
    doScroll();

    clearEvents();
    updateButtons();
  };

  function nextMonth() {
    var date = moment($scope.currentMonth).add(1, 'month');

    $scope.currentMonth = timestamp( date );
    $scope.visibleMonth = moment( $scope.currentMonth );

    updateCurrentMonthClass();
    doScroll();

    clearEvents();
    updateButtons();
  };

  function today() {
    var date = moment();

    $scope.currentMonth = timestamp( date );
    $scope.visibleMonth = moment( $scope.currentMonth );

    updateCurrentMonthClass();
    doScroll();

    clearEvents();
    updateButtons();
  };

  function updateButtons() {
    var
      previousMonth = timestamp(moment($scope.currentMonth).subtract(1, 'month')),
      nextMonth = timestamp(moment($scope.currentMonth).add(1, 'month'));

    $scope.disablePreviousMonth = false;
    $scope.disableNextMonth = false;

    if (!document.querySelectorAll('#month-' + previousMonth).length) {
      $scope.disablePreviousMonth = true;
    }

    if (!document.querySelectorAll('#month-' + nextMonth).length) {
      $scope.disableNextMonth = true;
    }
  };

  function containerMobileLineInView(index, inView, inViewPart, inEvent, value, allDates) {
    if ($scope.loading || scrolling) {
      return false;
    }

    $timeout(function () {
      $filter('map')(allDates, function (date) {
        date.focusable = inView;
      });
    });
  };

  function containerLineInView(index, inView, inViewPart, inEvent, value, allDates) {
    if ($scope.loading || scrolling || !inEvent.type || inView !== true || inViewPart !== 'bottom') {
      return false;
    }

      if (!value.day.isSame($scope.visibleMonth, 'month')) {
        $scope.currentMonth = value.monthTimestamp;
        $scope.visibleMonth = moment($scope.currentMonth);

        updateCurrentMonthClass();

        clearEvents();
        updateButtons();
      }
  };
}]);

app.controller('ModalInstanceController', ['$scope', '$uibModalInstance', '$filter', 'date', 'weeks', function ($scope, $uibModalInstance, $filter, date, weeks) {
  var
    groupBy = $filter('groupBy'),
    currentIndex = weeks.indexOf(date);

  $scope = angular.extend($scope, {
    weeks: weeks,
    currentIndex: currentIndex,
    currentDay: weeks[currentIndex],
    events: groupBy(date.events, 'type'),
    disablePrevious: false,
    disableNext: false,
    cancel: cancel,
    nextDay: nextDay,
    previousDay: previousDay,
    nextDayDisabled: false,
    previousDayDisabled: false
  });

  updateButtons();

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  };

  function nextDay() {
    $scope.currentIndex++;

    $scope.currentDay = $scope.weeks[$scope.currentIndex];
    $scope.events = groupBy($scope.currentDay.events, 'type');

    updateButtons();
  };

  function previousDay() {
    $scope.currentIndex--;

    $scope.currentDay = $scope.weeks[$scope.currentIndex];
    $scope.events = groupBy($scope.currentDay.events, 'type');

    updateButtons();
  };

  function updateButtons() {
    $scope.previousDayDisabled = false;
    $scope.nextDayDisabled = false;

    if ($scope.currentIndex == 0) {
      $scope.previousDayDisabled = true;
    }

    if ($scope.currentIndex + 1 == $scope.weeks.length) {
      $scope.nextDayDisabled = true;
    }
  };
}]);
