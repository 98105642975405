app.service('AuthenticationService', ['$window', '$q', '$filter', '$cookies', 'TessituraSDK', 'CacheStorage', 'facebookLoginUrl', 'toQuerystring', 'appConfig', 'Router', 'LocalStorage', function ($window, $q, $filter, $cookies, TessituraSDK, CacheStorage, facebookLoginUrl, toQuerystring, appConfig, Router, LocalStorage){
  var
    facebookLoginCompletePromise,
    rerequest = false,
    constituencyModeOfSales = appConfig.constituencyModeOfSales;

  var getLoginInfoCache = null;

  $window.addEventListener("message", onWindowMessage, false);

  return {
    loggedIn: loggedIn,
    getLoginInfo: getLoginInfo,
    getEmailAddress: getEmailAddress,
    logout: logout,
    transferSession: transferSession,
    getMos: getMos,

    facebookConnect: facebookConnect,
    facebookDisconnect: facebookDisconnect,
    facebookHasConnected: facebookHasConnected,

    requireLogin: requireLogin,
    isUserRegistered: isUserRegistered,
    isUserGuest: isUserGuest,

    subscriberShift: subscriberShift
  };

  function onWindowMessage(event) {
    try {
      var data = JSON.parse(event.data);
      // Check we are reading the right event
      if (data.action !== 'oauth_login' ) {
        return;
      }

      // If it required a sessionKey transfer, update it here
      if (data.result && data.result.newSessionKey) {
        $cookies.put('sessionkey', data.result.newSessionKey, {
          path: '/'
        });
      }

      // Clear all caches because logged in
      CacheStorage.removeRegex(/^tsdk-id=.+/);

      if (data.error) {
        // This is due to the email scope being un-checked by the user
        if (data.code === 101) {
          rerequest = true;
        }
        facebookLoginCompletePromise.reject(data.error);
      } else {
        facebookLoginCompletePromise.resolve();
      }
    } catch(e) {}
  }

  function getMos() {
    return getLoginInfo()
    .then(function (loginInfo) {
      return loginInfo.MOS;
    });
  }

  function loggedIn() {
    return TessituraSDK.UserLoggedIn().then(function (response) {
      if (response.data.result) {
        return getLoginInfo();
      }

      return $q.when(false);
    });
  }

  function getEmailAddress(){
    return  TessituraSDK.GetAccountInfo().then(function (response) {
      var info = response.data.result.GetAccountInfoResults.AccountInformation;
      var email = info.email;
      return $q.resolve(email);
    });
  }


  function transferSession(options){
    var shiftToSubscriber = options && 'shiftToSubscriber' in options ? options.shiftToSubscriber : true;

    return TessituraSDK.GetNewSessionKeyEx()
      .then(function (response) {
        CacheStorage.remove('syosDonation');
        CacheStorage.remove('selectedVipSeats');

        return TessituraSDK.TransferSession({
          sNewKey: response.data.result,
          sSessionKey: $cookies.get('sessionkey')
        });
      })
      .then(function() {
        return TessituraSDK.DeleteVariable({
          sName: 'cart-credit-usage'
        });
      })
      .then(function () {
        return TessituraSDK.RemovePromoCode();
      })
      .then(function () {
        if (shiftToSubscriber) {
          return subscriberShift();
        }

        return $q.resolve(true);
      });
  }

  function getLoginInfo() {
    if (getLoginInfoCache === null) {
        getLoginInfoCache = TessituraSDK.LoginInfoEx()
            .then(function (response) {
                getLoginInfoCache = null;

                if (!response.data.result.LoginInfoResults) {
                    return $q.reject();
                }

                return response.data.result.LoginInfoResults.LoginInformation;
            });

        getLoginInfoCache.then(function(data) {
            if (data.user_type !== 'anonymous') {
                // Used for tracking user in GA4
                window.dataLayer.push({user_id: data.customer_no})
            }
        });
    }

    return getLoginInfoCache;
  }

  function logout(){
    return TessituraSDK.Logout()
      .then(function () {
        LocalStorage.clear();

        $cookies.remove('sessionkey', {
          path: '/'
        });

        $window.location = Router.getUrl('auth.login');
      });
  }

  // Facebook

  function facebookConnect(params) {
    facebookLoginCompletePromise = $q.defer();
    params = params || {};
    params = angular.extend(params, {
      'state': {
        'sessionKey': $cookies.get('sessionkey')
      }
    });
    if (rerequest) {
      params.rerequest = '1';
    }
    var querystring = toQuerystring(params);

    $window.open(facebookLoginUrl + '?' + querystring, 'Facebook Login',  'width=580,height=400,modal=yes,alwaysRaised=yes');
    return facebookLoginCompletePromise.promise;
  }

  /**
   * Disconnects the facebook login type
   * from the users account
   * @return {[type]} [description]
   */
  function facebookDisconnect() {
    return TessituraSDK.DisconnectFacebook();
  }

  /**
   * Checks to see if the user has a facebook
   * login type connected to the account
   * @return {[type]} [description]
   */
  function facebookHasConnected() {
    return TessituraSDK.HasFacebookConnected()
    .then(function (response) {
      return response.data.result;
    });
  }

  function requireLogin() {
    return TessituraSDK.UserLoggedIn()
    // Check the user is logged in first
    .then(function (response) {
      // If they arent forward to the login page
      if (!response.data.result) {
        $window.location = Router.getUrl('auth.login', {
          'redirect-to': $window.location.toString()
        });

        return $q.defer().promise;
      }
    });
  }

  function isUserRegistered() {
    return getLoginInfo()
    .then(function (loginInfo) {
      if ('customer_no' in loginInfo && (loginInfo.customer_no == '2188558' || loginInfo.customer_no == '2793123')) {
        return logout();
      }

      return loginInfo.user_type && loginInfo.user_type != 'guest' && loginInfo.user_type != 'anonymous';
    });
  }

  function isUserGuest() {
    return getLoginInfo()
    .then(function (loginInfo) {
      return loginInfo.user_type && loginInfo.user_type == 'guest';
    });
  }

  function subscriberShift() {
    return getModeOfSaleForSubscriber()
    .then(function (subscriberModeOfSale) {
      if (subscriberModeOfSale !== false) {
        return TessituraSDK.ChangeModeOfSaleEx({
          NewModeOfSale: subscriberModeOfSale
        });
      }

      return $q.when(true);
    })
    // This may fail if they have MOS specific stuff in the cart
    // Just let the user keep going
    .catch(function(){});
  }

  function getModeOfSaleForSubscriber() {
    var modeOfSale = appConfig.generalMos;

    return isUserRegistered().then(function (response) {
      if (response) {
        return TessituraSDK.GetConstituentInfoEx()
      }

      return $q.reject();
    }).then(function (response) {
      var
        result = response.data.result.GetConstituentInfoExResults,
        constituencies = $filter('arrayWrap')(result.Constituency);

      if (!constituencies.length) {
        return $q.when(false);
      }

      constituencies.forEach(function (constituency) {
        var
          constituencyId = parseInt(constituency.id, 10),
          newModeOfSale = getModeOfSaleForConstituency(constituencyId);

        if (newModeOfSale !== false) {
          modeOfSale = newModeOfSale;
        }
      });

      return $q.when(modeOfSale);
    })
    .catch(function () {
      return $q.resolve(modeOfSale);
    });
  }

  function getModeOfSaleForConstituency(userConstituency) {
    var newModeOfSale = false;

    angular.forEach(constituencyModeOfSales, function (constituencies, modeOfSale) {
      if (!newModeOfSale && constituencies.indexOf(userConstituency) !== -1) {
        newModeOfSale = modeOfSale;
      }
    });

    return newModeOfSale;
  }
}]);
