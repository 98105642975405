app.filter('toString', function () {
  return function (possibleString) {
    if (typeof(possibleString) === 'undefined') {
      return '';
    }

    if (Object.prototype.toString.call(possibleString) === '[object Object]') {
        if (!Object.keys(possibleString).length) {
            return '';
        }

        if (possibleString.hasOwnProperty(0)) {
            return possibleString[0];
        }
    }

    return possibleString;
  };
});
