/* global moment */
app.service(
  'UpcomingEventsService',
  ['$q', '$filter', '$http', '$modal', '$sce', '$window', 'OrderService', 'InventoryService', 'Exchange', 'appConfig', 'Router', 'proxyEndpoint', function (
      $q,
      $filter,
      $http,
      $modal,
      $sce,
      $window,
      OrderService,
      InventoryService,
      Exchange,
      appConfig,
      Router,
      proxyEndpoint
    ) {
  'use strict';

  var validSliStatusCodes = [
    3,  // Seated Paid
    6,  // Unseated Paid
    12  // Ticketed Paid
  ];

  var
    arrayWrap = $filter('arrayWrap'),
    filter = $filter('filter'),
    unique = $filter('unique'),
    orderBy = $filter('orderBy'),
    groupBy = $filter('groupBy'),
    find = $filter('find'),
    performanceLongTitle = $filter('performanceLongTitle'),
    concatDateTime = $filter('concatDateTime'),
    now = moment();

  return {
    getUserUpcomingEvents: getUserUpcomingEvents
  }

  function getUserUpcomingEvents(loginInfo, limit) {
    var endpoint = Router.getUrl('cms.digital_events');

    // Grab all upcoming events in the future
    return $q.all([
      OrderService.GetCustomOrdersEx({
        renewals_only: 'N',
        // Want to go back 3 months to pick up past virtual events
        perf_start_dt: now.clone().subtract(3, 'month').format('YYYY-MM-DDTHH:00:00'),
        print_only: 'N',
        customer_no: loginInfo.customer_no
      }),
      $http.get(endpoint),
    ]).then(function (ret) {
      var orders = ret[0];
      var cmsDigitalData = ret[1].data;
      // No orders? No problem.
      if (!orders || !orders.length) {
        return false;
      }

      // Only get unique orders
      orders = unique(orders, 'order_no');

      // Make a call to fetch order details for each order user has
      var upcomingOrders = orders.map(function (order) {
        return OrderService.GetCustomOrderDetails({
          order_no: order.order_no
        });
      });

      return $q.all(upcomingOrders).then(function (response) {
        var upcomingEvents = response.reduce(function (carry, order) {

          var lineItems = order.LineItem.filter(function (lineItem) {
            return parseInt(lineItem.zmap_no, 10) !== 48;
          }).filter(function (lineItem) {
            if (appConfig.digital_prod_type_ids.indexOf(parseInt(lineItem.perf_type, 10)) !== -1) {
              // Is digital event
              var digitalPerf = find(cmsDigitalData, function (data) {
                return data.perf_no === parseInt(lineItem.perf_no, 10);
              });
              // Digital events check the dates from CMS data (if end_date is null then it has no end date)
              return digitalPerf && digitalPerf.end_date !== undefined && (digitalPerf.end_date === null || moment.parseZone(digitalPerf.end_date) > now);
            }
            // Non-digital events just need to be in the future
            return moment.parseZone(lineItem.perf_dt) > now;
          }).map(function (lineItem) {
            var sublineItems = order.SubLineItem.filter(function (sublineItem) {
              return (
                // Part of this line item
                parseInt(sublineItem.li_seq_no, 10) === parseInt(lineItem.li_seq_no) &&
                // Don't include returned items
                validSliStatusCodes.indexOf(parseInt(sublineItem.sli_status, 10)) !== -1
              );
            }).map(function (sublineItem) {
              var qr = null;

              if (sublineItem.sli_nscan_barcode) {
                  qr = proxyEndpoint + '/generateqr?text=' + sublineItem.sli_nscan_barcode + '&format=svg';
              }

              return {
                perf_no: parseInt(sublineItem.perf_no, 10),
                sli_no: sublineItem.sli_no,
                sli_status: sublineItem.sli_status,
                due_amt: sublineItem.due_amt,
                qr_code: qr,
                seat_row: sublineItem.seat_row,
                seat_num: sublineItem.seat_num,
                section_add_text: sublineItem.section_add_text,
                section_add_text2: sublineItem.section_add_text2,
                section_desc: sublineItem.section_desc,
                section_print_desc: sublineItem.section_print_desc,
                section_short_desc: sublineItem.section_short_desc,
                ticket_no: sublineItem.ticket_no,
                sli_nscan_barcode: sublineItem.sli_nscan_barcode,
                zone_desc: sublineItem.zone_desc,
                zone_no: sublineItem.zone_no
              };
            });

            var isPrinted = sublineItems.some(function (sublineItem) {
                return sublineItem.ticket_no;
            });

            return {
              li_seq_no: lineItem.li_seq_no,
              perf_no: parseInt(lineItem.perf_no, 10),
              perf_dt: lineItem.perf_dt,
              print_ind: lineItem.print_ind,
              prod_season_no: parseInt(lineItem.prod_season_no, 10),
              facility: lineItem.facility_desc,
              facility_no: lineItem.facility_no,
              is_digital: (appConfig.digital_prod_type_ids.indexOf(parseInt(lineItem.perf_type, 10)) !== -1),
              digital_event: find(cmsDigitalData, function (digitalData) {
                return digitalData.perf_no === parseInt(lineItem.perf_no, 10);
              }),
              customer_no: order.Order.customer_no,
              orderNo: order.Order.order_no,
              order_dt: order.Order.order_dt,
              delivery: parseInt(order.Order.delivery, 10),
              seated: 'seated' in order.Order && order.Order.seated === 'Yes',
              sublineItems: sublineItems,
              isPrinted: isPrinted
            };
          });

          // Skip events in past or those without subline items
          lineItems = lineItems.filter(function (lineItem) {
            return (
              (
                moment.utc(lineItem.perf_dt).isAfter(now.clone()) ||
                lineItem.is_digital
              ) &&
              lineItem.sublineItems.length
            );
          });

          return carry.concat(lineItems);
        }, []);

        // Somehow there are duplicates here. Deal with them.
        upcomingEvents = unique(upcomingEvents, 'li_seq_no');

        // No orders? No problem.
        if (!upcomingEvents.length) {
          return false;
        }

        // Order it nicely
        upcomingEvents = orderBy(upcomingEvents, function (lineItem) {
          return moment.utc(lineItem.perf_dt).unix();
        });

        // Only need top three
        if (limit && upcomingEvents.length > limit) {
          upcomingEvents = upcomingEvents.slice(0, limit);
        }

        // Grab unique performance numbers
        var performanceIds = unique(upcomingEvents.map(function (event) {
          return event.perf_no;
        }));

        // Now get the performances from the server
        return InventoryService.GetPerformancesEx4({
          iModeOfSale: loginInfo.MOS,
          sPerformanceIds: performanceIds.join(',')
        })
        .then(function (response) {
          upcomingEvents = upcomingEvents.reduce(function (carry, event) {
            var performance = simplifyPerformance(event, response.Performance, response.WebContent);

            if (performance) {
              carry.push(performance);
            }

            return carry;
          }, []);

          return orderBy(upcomingEvents, ['+perf_dt', '-order_dt']);
        });
      });
    });
  }

  function simplifyPerformance(event, performances, webContents) {
    var performance = find(performances, function (performance) {
      return parseInt(performance.perf_no, 10) === event.perf_no;
    });

    if (!performance) {
      return false;
    }

    performance.webContent = filter(webContents, function (webContent) {
      return parseInt(webContent.orig_inv_no, 10) === event.perf_no;
    });

    var tbaText = false;

    var tbaTextContent = find(performance.webContent, function (webContent) {
      return parseInt(webContent.content_type, 10) === parseInt(appConfig.webContentTypes.dateTba, 10);
    });

    if (tbaTextContent) {
      tbaText = tbaTextContent.content_value;
    }

    var maskSeatingLocations = performance.webContent.some(function (webContent) {
      return parseInt(webContent.content_type, 10) === parseInt(appConfig.webContentTypes.maskSeatingLocations, 10) && webContent.content_value === 'Y';
    });

    var dateStr;
    if (event.is_digital) {
      var digitalStartDate = moment.utc(event.digital_event.start_date);
      var digitalEndDate = moment.utc(event.digital_event.end_date);

      if (event.digital_event.start_date && digitalStartDate.isAfter(now)) {
        // Starts in future "Available from ..."
        dateStr = concatDateTime(event.digital_event.start_date, '\'Available from\' EEEE, MMMM d, yyyy', {
          showtime: true,
          tbaText: tbaText
        });
      } else if (event.digital_event.end_date && digitalEndDate.isAfter(now)) {
        // Ends in future "Available until ..."
        dateStr = concatDateTime(event.digital_event.end_date, '\'Available until\' EEEE, MMMM d, yyyy', {
          showtime: true,
          tbaText: tbaText
        });
      } else {
        dateStr = 'Currently Available';
      }
    } else {
      dateStr = concatDateTime(event.perf_dt, 'EEEE, MMMM d, yyyy', {
        showtime: true,
        tbaText: tbaText
      });
    }

    return angular.extend(event, {
      title: performanceLongTitle(performance),
      date: dateStr,
      seatingDetails: getSeatingDetails(event, maskSeatingLocations),
      orderDetailsUrl: Router.getUrl('orders.details', {
        order_no: event.orderNo
      }),

      eventPageUrl: event.is_digital ?
        Router.getUrl('cms.digital_link', {perf_no: event.perf_no})
        : Router.getUrl('cms.link', {prod_no: event.prod_season_no}),

      eventImageUrl: Router.getUrl('cms.image2', {
        prod_no: event.prod_season_no,
        perf_no: event.perf_no
      }),
      canPrint: event.print_ind.toLowerCase() === 'y' && moment.utc(event.perf_dt).subtract(1, 'hour').isAfter(now) && (event.delivery === appConfig.print_at_home_delivery_method || event.delivery === appConfig.digital_tickets_delivery_method),
      canEmail: event.print_ind.toLowerCase() === 'y' && moment.utc(event.perf_dt).subtract(1, 'hour').isAfter(now) && (event.delivery === appConfig.print_at_home_delivery_method || event.delivery === appConfig.digital_tickets_delivery_method),
      canExchange: event.seated && event.print_ind.toLowerCase() === 'y' && moment.utc(event.perf_dt).subtract(1, 'day').isAfter(now),
      exchange: function () {
        return exchangeTickets(event);
      },
      donate: function () {
        return donateTickets(event);
      },
      email: function () {
        return sendEmail(event.orderNo);
      },
      passbooks: function () {
        return downloadPassbooks(event);
      }
    });
  };

  function getSeatingDetails(event, maskSeatingLocations) {
    var sectionsString = [];

    // Loop through subline items, grouped by section_desc
    angular.forEach(groupBy(event.sublineItems, 'section_desc'), function (zones, section_desc) {
      if (event.is_digital) {
        sectionsString.push('Digital Stage');
        return;
      }
      if (section_desc == 'Unassigned') {
        sectionsString.push('Unseated');

        return false;
      }

      if (maskSeatingLocations) {
        return false;
      }

      var zonesString = [];

      // Loop through zones, grouped by zone_desc
      angular.forEach(groupBy(zones, 'zone_desc'), function (rows, zone_desc) {
        var zone = [];

        // Loop through rows, grouped by seat_row
        angular.forEach(groupBy(rows, 'seat_row'), function (seats, seat_row) {
          var row = 'Row ' + seat_row + ' ';

          // Just grab the seat numbers
          row += seats.map(function (s) {
            return s.seat_num;
          }).join(', ');

          zone.push(row);
        });

        sectionsString.push(section_desc + ': ' + zone_desc + ', ' + zone.join(', '));
      });
    });

    return sectionsString;
  };

  function exchangeTickets(event) {
    $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'sublineitem-selector.html'),
      controller: 'SubLineItemSelectorModalController',
      size: 'md',
      resolve: {
        title: function () {
          return 'Exchange';
        },
        subLineItems: function () {
          return event.sublineItems;
        },
        action: function () {
          return function (subLineItems) {
            return Exchange.exchangeSubLineItems(event.orderNo, null, subLineItems);
          }
        }
      }
    });
  };

  function donateTickets(event) {
    $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'sublineitem-selector.html'),
      controller: 'SubLineItemSelectorModalController',
      size: 'md',
      resolve: {
        title: function () {
          return 'Donate';
        },
        subLineItems: function () {
          return event.sublineItems;
        },
        action: function () {
          return function (sublineItems) {
            return OrderService.DonateTickets({
              field: 'sSubLineItems',
              tickets: sublineItems.map(function (sublineItem) {
                return sublineItem.sli_no;
              }).join(',')
            })
            .then(function () {
              $window.location.reload();
            });
          }
        }
      }
    });
  };

  function downloadPassbooks(event) {
    $modal.open({
      templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'passbook.html'),
      controller: 'Passbook2Controller',
      size: 'md',
      resolve: {
        event: function () {
          return event;
        }
      }
    });
  };

  function sendEmail(orderNo) {
    return OrderService.RequestReprint(orderNo)
    .then(function (response) {
      alert('Your tickets have been emailed to you.');
    })
    .catch(function (errors) {
      alert('We could not send your tickets at this time. Please try again later.');
    });
  };
}]);
