(function () {
  /**
   * @see http://codepen.io/SebL/post/angularjs-how-to-create-a-custom-form-input-and-custom-validations-with-a-directive
   */
  'use strict';

  app.directive('multiDateSelector', ['$filter', function ($filter) {
    return {
      restrict: 'E',
      require: '?ngModel',
      scope: {
        'ngDisabled': '='
      },
      template: [
        '<select ng-model="date.month" ng-options="monthNames[month-1] for month in selects.months()" class="form-control" ng-disabled="ngDisabled"><option value="" disabled>Month</option></select>',
        '<select ng-model="date.day" ng-options="day for day in selects.days()" class="form-control" ng-disabled="ngDisabled"><option value="" disabled>Day</option></select>',
        '<select ng-model="date.year" ng-options="year for year in selects.years() | orderBy: year:yearOrder" class="form-control" ng-disabled="ngDisabled"><option value="" disabled>Year</option></select>'
      ].join(''),
      link: function (scope, element, attrs, ngModel) {
        if (!ngModel) {
          return;
        }

        var date = $filter('date');

        // GET FROM NG MODEL AND PUT IT IN LOCAL SCOPE
        ngModel.$render = function() {
          scope.date = {
            day: date(ngModel.$viewValue, 'dd'),
            month: date(ngModel.$viewValue, 'MM'),
            year: date(ngModel.$viewValue, 'yyyy')
          };

          scope.monthNames = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');
        };

        // ATTRIBUTES (with default values if not set)
        scope.yearOrder = (attrs.yearOrder && attrs.yearOrder === 'asc') ? false : true; // year order: 'asc' or 'desc', default: desc

        var endYear = attrs.endYear || new Date().getFullYear(); // default: this year
        var startYear = attrs.startYear || startYear - 100; // default: this year - 100

        // INIT YEARS, MONTHS AND DAYS NUMBER
        scope.selects = {
          days: function() {
            // Get number of days based on month + year 
            // (January = 31, February = 28, April = 30, February 2000 = 29) or 31 if no month selected yet
            var
              nbDays = new Date(scope.date.year, scope.date.month, 0).getDate() || 31,
              daysList = [];

            for (var i = 1; i <= nbDays; i++) {
              var iS = i.toString();
              daysList.push((iS.length < 2) ? '0' + iS : iS); // Adds a leading 0 if single digit
            }

            return daysList;
          },

          months: function() {
            var monthList = [];

            for (var i = 1; i <= 12; i++) {
              var iS = i.toString();
              monthList.push((iS.length < 2) ? '0' + iS : iS); // Adds a leading 0 if single digit
            }

            return monthList;
          },

          years: function() {
            var yearsList = [];

            for (var i = endYear; i >= startYear; i--) {
              yearsList.push(i.toString());
            }

            return yearsList;
          }
        };

        // WATCH FOR scope.date CHANGES
        scope.$watch('date', function (date) {
          // IF REQUIRED
          if (attrs.required) {

            // VALIDATION RULES
            var yearIsValid = !!date.year && parseInt(date.year) <= endYear && parseInt(date.year) >= startYear;
            var monthIsValid = !!date.month;
            var dayIsValid = !!date.day;

            // SET INPUT VALIDITY
            ngModel.$setValidity('required', yearIsValid || monthIsValid || dayIsValid ? true : false);
            ngModel.$setValidity('yearRequired', yearIsValid ? true : false);
            ngModel.$setValidity('monthRequired', monthIsValid ? true : false);
            ngModel.$setValidity('dayRequired', dayIsValid ? true : false);

            // UPDATE NG MODEL
            if (yearIsValid && monthIsValid && dayIsValid) {
              ngModel.$setViewValue(new Date(date.year, date.month - 1, date.day));
            }
          }

          // IF NOT REQUIRED (still need the 3 values filled to update the model)
          else if (date.year && date.month && date.day) {
            ngModel.$setViewValue(new Date(date.year, date.month - 1, date.day));
          }

          // IF NOT REQUIRED (still need the 3 values filled to update the model)
          else if (date.year && date.month && date.day) {
            ngModel.$setViewValue(new Date(date.year, date.month - 1, date.day));
          }
        }, true);
      }
    }
  }]);
})();
