app.filter('currency', ['$injector', '$locale', function($injector, $locale) {

  var isBoolean = function(obj) {
    return obj === true || obj === false || Object.prototype.toString.call(obj) === '[object Boolean]';
  };

  var $filter = $injector.get('$filter'),
    numberFilter = $filter('number'),
    formats = $locale.NUMBER_FORMATS,
    pattern = formats.PATTERNS[1];

  // https://github.com/angular/angular.js/pull/3642
  formats.DEFAULT_PRECISION = angular.isUndefined(formats.DEFAULT_PRECISION) ? 2 : formats.DEFAULT_PRECISION;

  return function(amount, currencySymbol, fractionSize, suffixSymbol, useParensForNegative) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return '';
    }

    if (angular.isUndefined(currencySymbol)) {
      currencySymbol = formats.CURRENCY_SYM;
    }

    var isNegative = amount < 0,
        parts = [];

    suffixSymbol = isBoolean(fractionSize) ? fractionSize : suffixSymbol;
    fractionSize = isBoolean(fractionSize) ? formats.DEFAULT_PRECISION : fractionSize;
    fractionSize = angular.isUndefined(fractionSize) ? formats.DEFAULT_PRECISION : fractionSize;

    amount = Math.abs(amount);

    var number = numberFilter(amount, fractionSize);

    // default to using '-' prefix for negative numbers
    if (!useParensForNegative) {
      pattern.negPre = '-';
      pattern.negSuf = '';
    }

    parts.push(isNegative ? pattern.negPre : pattern.posPre);
    parts.push(!suffixSymbol ? currencySymbol : number);
    parts.push(suffixSymbol ? currencySymbol : number);
    parts.push(isNegative ? pattern.negSuf : pattern.posSuf);

    return parts.join('').replace(/\u00A4/g, '');
  };
}]);
