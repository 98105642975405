app.controller('ParkingController', ['$q', '$scope', 'TessituraSDK', '$window', '$filter', 'GetParameters', 'AuthenticationService', 'Router', 'appConfig', 'MosSwitcher', function ($q, $scope, TessituraSDK, $window, $filter, GetParameters, AuthenticationService, Router, appConfig, MosSwitcher) {
	'use strict';

	$scope = angular.extend($scope, {
		noSeasonNumber: false,
		loading: false,
		invalidSeason: false,
		packages: [],
		selectedPackage: null,
		zones: [],
		selectedZone: null,
		wrongMos: false,
		addingToCart: false,
		addToCartError: false,
		// functions
		addToCart: addToCart,
		loadZones: loadZones
	});

	// grab widget config off the page
	var widgetConfig = document.getElementById('widget-config');

	if (widgetConfig) {
		widgetConfig = JSON.parse(widgetConfig.innerHTML);
	} else {
		widgetConfig = {};
	}

	// restrict to a single price type
	var
		allowedModesOfSales = [appConfig.fixedSubscriberMos, appConfig.flexSubscriberMos],
		modeOfSale = null,
		fixedPackagePriceType = 2,
		flexPackagePriceType = 83,
		seasonNumber = GetParameters.seasonNumber || false,
		lineItem = GetParameters.lineItem || false,
		pageTitle = widgetConfig.PageTitle || 'Parking',
		defaultQuantity = 1,
		defaultPriceType = fixedPackagePriceType;

	// tools
	var
		arrayWrap = $filter('arrayWrap'),
		filter = $filter('filter');

	// bail if no season number
	if (!seasonNumber || isNaN(seasonNumber)) {
		$scope.noSeasonNumber = true;
		return false;
	}

	AuthenticationService.getLoginInfo().then(function (loginInfo) {
		modeOfSale = parseInt(loginInfo.MOS, 10);

		if (allowedModesOfSales.indexOf(modeOfSale) === -1) {
			$scope.wrongMos = true;
			$scope.wrongMosReason = 'Sorry, parking is only available for Season Ticket Members purchasing a subscription.';
		} else {
			if (modeOfSale === appConfig.flexSubscriberMos) {
				defaultPriceType = flexPackagePriceType;
			}
		}

		loadPackage();
	});

	function loadPackage() {
		$scope.loading = true;

		return TessituraSDK.GetPackagesEx3({
			iModeOfSale: modeOfSale,
			iSeason: seasonNumber
		}).then(function (response) {
			// wrap it grrr
			var
				packages = arrayWrap(response.data.result.GetPackagesEx3Result.Package),
				webContents = arrayWrap(response.data.result.GetPackagesEx3Result.WebContent);

			// find those that are on sale (maybe?)
			// var onSalePackages = filter(packages, {
				// on_sale_ind: 'Y'
			// });

			var onSalePackages = packages;

			onSalePackages.forEach(function (pkg) {
				pkg.webContent = filter(webContents, {
					orig_inv_no: pkg.pkg_no
				});
			});

			$scope.packages = onSalePackages;
		}).catch(function () {
			$scope.invalidSeason = true;
		}).finally(function () {
			$scope.loading = false;
		});
	}

	function loadZones() {
		$scope.zones = null;
		$scope.selectedZone = null;

		return TessituraSDK.GetPackageDetailWithDiscountingEx ({
			'PackageID': $scope.selectedPackage.pkg_no,
			'ModeOfSale': modeOfSale
		}).then(function (response) {
			// Copy data to scope
			var GetPackageDetailWithDiscountingExResult = response.data.result.GetPackageDetailWithDiscountingExResult;

			// grab zones
			$scope.zones = groupPrices( GetPackageDetailWithDiscountingExResult.AllPrice );

			// sort em
			$scope.zones = $filter('orderBy')($scope.zones, '+avail_count');

			// selected zone
			$scope.selectedZone = $scope.zones[0];
			$scope.selectedPackage.seat_ind = GetPackageDetailWithDiscountingExResult.Package.seat_ind;
		});
	}

	function groupPrices(allPrices) {
		// Firsty, array wrap it all
		allPrices = arrayWrap(allPrices);

		// Find available zones for the sub price
		var availableZones = allPrices.filter(function (price) {
			return price.price_type == defaultPriceType;
		});

		// Store zones to use on front end
		var priceZones = [];

		// Keep track of zones we've already added with the same name
		var existingZones = [];

		// Group by description - really heavy, only should be done in JS
		var sections = $filter('groupByFirstWord')(availableZones, 'description');

		// Because group by first word actually groups, we need to flatten this out
		angular.forEach(sections, function (zones, section) {
			// Which means looping throug things a few times *sigh*
			zones.forEach(function (zone) {
				var zoneDesc = zone.description_orig.toLowerCase();

				// Skip any unavailable, or if we already have one of the same name
				if (zone.available == 'N' || existingZones.indexOf(zoneDesc) !== -1) {
					return;
				}

				zone.section = section;
				zone.label = zone.description + ' - ' + $filter('currency')(zone.price);

				priceZones.push(zone);

				existingZones.push(zoneDesc);
			});
		});

		return priceZones;
	};

	function addToCart() {
		$scope.addingToCart = true;
		$scope.addToCartError = false;

		// should we add a default donation after?
		var addDonationAfter = false;

		var promise = TessituraSDK.GetCart().then(function (response) {
			if (!response.data.result || !response.data.result.GetCartResults || !response.data.result.GetCartResults.Order) {
				addDonationAfter = true;
			}
		});

		// add vouchers to cart
		if ($scope.selectedPackage.seat_ind === 'Y') {
			promise = promise.then(function () {
				return TessituraSDK.AddPackageItemSeated({
					PriceType: defaultPriceType,
					PackageNumber: $scope.selectedPackage.pkg_no,
					NumberOfSeats: defaultQuantity,
					Zone: $scope.selectedZone.zone_no,
					RequestedSeats: '',
					LeaveSingleSeats: 'true'
				});
			});
		} else {
			promise = promise.then(function () {
				return TessituraSDK.AddPackageItem({
					ParentPackageLineItemId: 0,
					PriceType: defaultPriceType,
					PackageNumber: $scope.selectedPackage.pkg_no,
					NumberOfSeats: defaultQuantity,
					Zone: $scope.selectedZone.zone_no
				});
			});
		}

		if (lineItem) {
			promise = promise.then(function () {
				// Presume first seats are successfully added
				return TessituraSDK.GetCart().then(function (response) {
					var
						result = response.data.result.GetCartResults,
						packageLineItems = arrayWrap(result.PackageLineItem);

					return packageLineItems.filter(function (packageLineItem) {
						return (
							packageLineItem.perf_no == 0 &&
							packageLineItem.pkg_no == $scope.selectedPackage.pkg_no &&
							packageLineItem.zone_no == $scope.selectedZone.zone_no &&
							packageLineItem.season_no == seasonNumber &&
							!('notes' in packageLineItem)
						);
					});
				});
			});

			promise = promise.then(function (addedLineItems) {
				if (!addedLineItems.length) {
					return $q.resolve(true);
				}

				return TessituraSDK.AddOrderCommentsEx2({
					Comment: 'Parking for ' + lineItem,
					LineItemID: addedLineItems[0].li_seq_no,
					LineItemType: 'L',
					CustomerNo: 0,
					CategoryNo: 0
				});
			});
		}

		promise
		.then(function () {
			return TessituraSDK.ResetTicketExpiration();
		})
		.then(function () {
			$window.location = Router.getUrl('booking.basket');
		}).catch(function (error) {
			$scope.addToCartError = true;
			$scope.addingToCart = false;
		});
	}
}]);
