/* global moment */
app.controller('TicketHistoryController', ['$scope', '$q', '$filter', 'MosSwitcher', 'AuthenticationService', 'TessituraSDK', function ($scope, $q, $filter, MosSwitcher, AuthenticationService, TessituraSDK) {
  // Tools
  var
    arrayWrap = $filter('arrayWrap'),
    groupBy = $filter('groupBy');

  // Local variables
  var
    date = moment.utc(),
    loginInfo;

  // Scope variables
  $scope = angular.extend($scope, {
    loading: true,
    isGuest: false,
    events: null,
    availableYears: [],
    currentYear: null
  });

  // Ask user to login if required
  AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (!isUserRegistered) {
      // This user is not registered
      $scope.isGuest = true;

      // No longer need to display loading
      $scope.loading = false;

      // No need to continue
      return $q.resolve(true);
    }

    // Grab login info
    return AuthenticationService.getLoginInfo();
  })
  .then(function (loginInfoEx) {
    loginInfo = loginInfoEx;

    return ticketHistory();
  })
  .finally(function () {
    $scope.loading = false;
  });

  function ticketHistory() {
    return TessituraSDK.GetTicketHistory().then(function (response) {
      var
        result = response.data.result.ExecuteLocalProcedureResults,
        tickets = arrayWrap(result.LocalProcedure);

      if (!tickets.length) {
        $scope.events = false;

        return $q.resolve(false);
      }

      tickets = tickets.filter(function (ticket) {
        return (
          moment.utc(ticket.perf_dt).isBefore(date) &&
          angular.isString(ticket.description)
        );
      });

      tickets = tickets.map(function (ticket) {
        var numSeats = parseInt(ticket.num_seats, 10);

        // Add a year for grouping
        ticket.year = moment.utc(ticket.perf_dt).format('YYYY');

        // Add a performance key for grouping
        ticket.perfKey = moment.utc(ticket.perf_dt).format('YYYYMMDD') + '' + ticket.perf_no;

        // Add a rate for multiple items
        ticket.rate_amt = numSeats > 1 ? (ticket.tck_amt / numSeats) : ticket.tck_amt;

        // Make the location a bit prettier
        ticket.location = ticket.location.split(' ').map(function (block) {
          if (block.indexOf(':') !== -1) {
            return block.split(':')[1].replace(',', ', ');
          }

          return block.replace(',', ', ');
        }).join(', ');

        return ticket;
      });

      // Sort by performance date descending
      tickets = $filter('orderBy')(tickets, '-perf_dt');

      // Group by year
      tickets = groupBy(tickets, 'year');

      // Good for render
      $scope.events = tickets;

      // Grab a list of available years, sort it descending
      $scope.availableYears = Object.keys(tickets).sort().reverse();

      // Set the current year to be the first item in the years array
      $scope.currentYear = $scope.availableYears[0];
    });
  };
}]);
