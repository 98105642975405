app.filter('packageDYOName', ['appConfig', function (appConfig) {
  return function (package, options) {
    options = options || {};
    if (!package) {
      return '';
    }

    if (angular.isArray(package.webContent) == true) {
      for (var i in package.webContent) {
        var webContent = package.webContent[i];
        if (webContent.content_type == appConfig.webContentTypes.dyoSeasonName) {
          return webContent.content_value;
        }
      }
    }

    return package.prod_desc || package.description || options.fallback;
  };
}]);
