
app.directive('ngLoading', function () {
  return {
    transclude: true,
    template: '<span ng-show="ngLoading">{{loadingText}}</span><span ng-hide="ngLoading && loadingText" ng-transclude></span>',
    priority: 10,
    scope: {
      ngDisabled: '=',
      ngLoading: '=',
      loadingText: '@',
    },
    link: function(scope, element, attr) {
      // Set button to disabled or not
      scope.$watchGroup(['ngLoading', 'ngDisabled'], function () {
        if (scope.ngLoading || scope.ngDisabled) {
          element[0].setAttribute('disabled', 'disabled');
        } else {
          element[0].removeAttribute('disabled');
        }
      });


    }
  };
});
