app.controller('ToolbarController', ['$rootScope', '$scope', 'AuthenticationService', 'TessituraSDK', '$q', 'md5', '$http', 'CacheStorage', 'Cart', '$filter', 'UserMessagingService', 'MosSwitcher', function ($rootScope, $scope, AuthenticationService, TessituraSDK, $q, md5, $http, CacheStorage, Cart, $filter, UserMessagingService, MosSwitcher) {
  $scope.loading = true;
  $scope.isLoggedIn = false;

  $scope.notificationsQty = 0;
  $scope.cartQty = 0;

  var toolbar = document.querySelector('#toolbar-loader');

  // Get if the user is logged in
  // Set the isLoggedIn flag and
  // get their account details
  var authChain = $q.resolve();

  if (CacheStorage.has('Toolbar-userData') === false || true) {
    authChain = $q.all([
      AuthenticationService.isUserRegistered(),
      AuthenticationService.isUserGuest()
    ])
    .then(function (responses) {
      var isLoggedIn = responses[0];
      var isGuest = responses[1];

      $scope.user = {
        avatar: null,
        useInitials: false,
        isLoggedIn: isLoggedIn,
        isGuest: isGuest
      };

      if (isLoggedIn) {
        return loadAccountDetails();
      }

      if (isGuest) {
        return loadGuestDetails();
      }

      return $q.resolve(false);
    })
    .finally(function () {
      CacheStorage.set('Toolbar-userData', $scope.user);
    });
  } else {
    $scope.user = CacheStorage.get('Toolbar-userData', $scope.user);
  }

  function loadGuestDetails() {
    return AuthenticationService.getLoginInfo()
    .then(function (loginInfo) {
      var urlHash = md5.createHash(loginInfo.UID || '');

      $scope.user.fullName = loginInfo.UID;
      $scope.user.displayName = loginInfo.UID;
      $scope.user.isGuest = true;

      return getFacebookDetails(urlHash);
    });
  };

  function loadAccountDetails() {
    return TessituraSDK.GetAccountInfo()
    .then(function(response){
      var
        info = response.data.result.GetAccountInfoResults.AccountInformation,
        urlHash = md5.createHash(info.email || '');

      $scope.user.fullName = info.fname + ' ' + info.lname;
      $scope.user.displayName = info.fname;

      return getFacebookDetails(urlHash);
    });
  };

  function getFacebookDetails(urlHash) {
    return TessituraSDK.GetFacebookAccountDetails()
    .then(function (response) {
      if (response.data.result) {
        $scope.user.avatar = 'https://avatars.io/facebook/' + response.data.result.id;
      } else {
        return $http.jsonp('https://www.gravatar.com/' + urlHash + '.json?callback=JSON_CALLBACK')
        .then(function (response) {
          if (response.status == 200) {
            $scope.user.avatar = response.data.entry[0].thumbnailUrl;
          } else {
            return $q.reject();
          }
        });
      }
    })
    .catch(function () {
      $scope.user.useInitials = true;
    });
  };

  // Get the number of unread notifications
  function updateNotificationsQantity () {
    return UserMessagingService.initNotificationMessages()
    .then(function (messages) {
      $scope.notificationsQty = messages.reduce(function (messageCount, message) {
        if (message.read_ind === 'N') {
          return messageCount + 1;
        }

        return messageCount;
      }, 0);
    });
  };

  // Get the count of the items in the cart
  function updateCartQuantity() {
    return Cart.getCart(true).then(function (cartDetails) {
      $scope.cartQty = 0;

      $scope.cartQty += cartDetails.Payment.length;

      $scope.cartQty += cartDetails.SubLineItem.filter(function (subLineItem) {
        return parseInt(subLineItem.due_amt) >= 0;
      }).length;

      $scope.cartQty += cartDetails.Contribution.length;

      $scope.cartQty += cartDetails.PackageSubLineItem.filter(function (subpackageLineItem) {
        return subpackageLineItem.perf_no != '0';
      }).length;
    });
  };

  $scope.$on('_tjsdk.api.TransferSession.completed', updateCartQuantity);
  $rootScope.$on('cart.updated', updateCartQuantity);
  $rootScope.$on('notification.updated', updateNotificationsQantity);

  // When all data is loaded, everything
  $q.all([authChain, updateCartQuantity(), updateNotificationsQantity(), MosSwitcher.notifyOfMos()]).finally(function () {
    if (toolbar) {
      angular.element(toolbar).addClass('hidden');
    }

    $scope.loading = false;
  });

  /**
   * Logs the user out
   * Logout first to all reserved tickets and released.
   * Then clear all local storage and reload.
   */
  $scope.logout = AuthenticationService.logout;
}]);
