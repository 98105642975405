
app.controller('PasswordResetController', ['$scope', '$location', '$attrs', 'TessituraSDK', 'GetParameters', function ($scope, $location, $attrs, TessituraSDK, GetParameters) {

  $scope.step = 1;
  $scope.error = "";
  $scope.token = '';
  $scope.processing = false;

  if (GetParameters.email) {
    $scope.confirmEmail = GetParameters.email;
    $scope.email = GetParameters.email;
  }

  if (GetParameters.token && GetParameters.email) {
    $scope.step = 3;
    $scope.token = GetParameters.token;
  }

  $scope.sendCredentials = function () {
    $scope.error = "";
    if ($scope.email !== '') {
      $scope.processing = true;
      TessituraSDK.SendCredentials({
        "sEmail": $scope.email,
        "iTemplateID": $attrs.templateId
      }).then(function (response) {
        if (response.data.result && response.data.result == true) {
          $scope.step = 2;
        } else {
          $scope.error = 'No login was found for that user.';
        }
        $scope.processing = false;
      })
      .catch(function () {
        $scope.error = 'No login was found for that user.';
        $scope.processing = false;
      });
    } else {
      $scope.error = 'Please enter your email address.';
    }
  };

  $scope.recoverAccount = function () {
    $scope.error = "";
    if ($scope.confirmEmail !== '') {
      $scope.processing = true;
      TessituraSDK.LoginEx2({
        "sUID": $scope.confirmEmail,
        "sPwd": "",
        "sEmail": $scope.confirmEmail,
        "forgotLoginToken": $scope.token
      }).then(function (response) {
        if (response.data.result == 1) {
          TessituraSDK.UpdateLoginWithPromoCode({
            "sUID": $scope.confirmEmail,
            "sNewUID": $scope.confirmEmail,
            "sNewPwd": $scope.newPassword,
            "sEmail": $scope.confirmEmail,
            "sNewEmail": $scope.confirmEmail
          }).then(function (response) {
            if (response.data.result == 1) {
              $scope.step = 4;
              $scope.processing = false;
            } else {
              $scope.error = 'Sorry, your password could not be reset.';
              $scope.processing = false;
            }
          }).catch(function () {
            $scope.error = 'Sorry, your password could not be reset';
            $scope.processing = false;
          });
        } else {
          $scope.error = 'Your email/token combination is incorrect. Please try again.';
          $scope.processing = false;
        }
      }).catch(function (error) {
        $scope.error = 'Your email/token combination is incorrect';
        $scope.processing = false;
      });
    }
  };

}]);
