/* global moment */
app.controller('OrdersAndTicketsController', ['$q', '$scope', '$window', '$filter', '$attrs', 'TessituraSDK', 'Router', 'AuthenticationService', 'appConfig', '$location', function ($q, $scope, $window, $filter, $attrs, TessituraSDK, Router, AuthenticationService, appConfig, $location) {

  var loadingPromises = [];
  var arrayWrap = $filter('arrayWrap');
  
  $scope.loading = true;
  $scope.shouldShowPastOrders = ($window.location.hash === '#past-orders');
  $scope.userIsGuest = false;

  $scope.openOrders = undefined;
  $scope.pastOrders = undefined;

  var now_string = moment().startOf('hour').toISOString();
  var openOrderParams = {
    renewals_only: 'N',
    perf_start_dt: now_string,
    print_only: 'N'

  };
  var pastOrderParams = {
    renewals_only: 'N',
    perf_end_dt: now_string,
    print_only: 'N'
  };

  // User must be registered
  var p = AuthenticationService.requireLogin()
  .then(AuthenticationService.isUserRegistered)
  // Check the user is logged in first
  .then(function (isUserRegistered) {
    if (isUserRegistered) {
      if($location.hash() === 'past-orders'){
        loadPastOrders(pastOrderParams);
      }else{
        loadOpenOrders(openOrderParams);
      }
    } else {
      $scope.userIsGuest = true;
    }
  });
  loadingPromises.push(p);


  function loadOrders(GetOrdersExParams) {
    return AuthenticationService.getLoginInfo().then(function (result) {
        return GetOrdersEx(angular.extend({}, {
          customer_no: parseInt(result.customer_no)
        }, GetOrdersExParams))
        .then(function (response) {
          return response.filter(function (order) {
            return !((
              // order has a note
              'notes' in order &&
              // note is a string
              angular.isString(order.notes) &&
              // the note is yap flag
              order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
            ) || (
              // order has a custom_6 value
              'custom_6' in order &&
              // custom_6 is a string
              angular.isString(order.custom_6) &&
              // value doesn't matter, so long as it exists
              order.custom_6.length
            ));
          });
        });
      }
    )
  }


  function loadOpenOrders(){
    if(!$scope.openOrders){
      var openOrders = loadOrders(openOrderParams);
      openOrders.then(
        function(orders){
          $scope.openOrders = [];
          var grouped = $filter('groupBy')(orders, 'order_no');
          for(var i in grouped){
            var orderObject = {
              order_no : $filter('first')(grouped[i]).order_no,
              prod_titles : grouped[i].map(function(o){
                return o.prod_title;
              }),
              tot_due_amt : $filter('first')(grouped[i]).tot_due_amt,
              order_dt : $filter('first')(grouped[i]).order_dt,
            }
            $scope.openOrders.push(orderObject);
          }

          $scope.openOrders = $filter('orderBy')($scope.openOrders, 'order_no', true);
        }
      );
    }
  }

  
  function loadPastOrders(){
    if(!$scope.pastOrders){
      var pastOrders = loadOrders(pastOrderParams);
      var years = [];
      pastOrders.then(
        function(orders){
          $scope.pastOrders = orders.map(function (order) {
            order.year = moment(order.order_dt).year();
            years.push(order.year);
            return order;
          });

          years = $filter('unique')(years);
          if(years.length > 0){
            $scope.pastOrdersFilter = years[years.length-1];
          }

        }
      );
    }
  }
  
  function GetOrdersEx(GetOrdersExParams){
    return TessituraSDK.GetCustomOrdersEx(GetOrdersExParams)
    .then(function (response) {
      return arrayWrap(response.data.result.ExecuteLocalProcedureResults.LocalProcedure)
      .filter(function (order) {
        return !!order.order_dt;
      });
    });
  }

  $scope.showOpenOrders = function () {
    $scope.shouldShowPastOrders = false;
    $window.location.hash = 'open-orders';
    loadOpenOrders();
  }

  $scope.showPastOrders = function () {
    $scope.shouldShowPastOrders = true;
    $window.location.hash = 'past-orders';
    loadPastOrders();
  }


  $q.all(loadingPromises)
    .finally(function () {
      $scope.loading = false;
    });

}]);
