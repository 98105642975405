/**
 * Loops over an array over object and returns
 * the sum of the attribute provided as the
 * first parameter.
 */
app.filter('sumOfValue', function () {
    return function (data, key) {
        if (typeof (data) === 'undefined' && typeof (key) === 'undefined') {
            return 0;
        }
        var sum = 0;
        for (var i = 0; i < data.length; i++) {
            sum = sum + Number(data[i][key]);
        }
        return sum;
    }
});
