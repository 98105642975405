app.service('AddressService', ['$q', '$http', 'qasEndpoint', 'proxyClient', function ($q, $http, qasEndpoint, proxyClient) {
  return {
    verify: verify,
    get: get
  };

  function verify(params) {
    return $http.post(qasEndpoint + '/' + proxyClient, rpc('verify', params))
    .then(function (response) {
      var result = response.data.result;

      // If it's valid, sure, fine.
      if (result && result.verifyLevel === 'Verified') {
        return $q.resolve(true);
      }

      // If anything other than verified then prompt them
      try {
        return $q.resolve({
          prompt: result.picklist.prompt,
          total: result.picklist.total,
          items: result.picklist.items
        });
      } catch (e) {
        return $q.resolve(true);
      }
    })
    .catch(function () {
      // If QAS fails, give them benefit of the doubt
      return $q.resolve(true);
    });
  }

  function get(params) {
    return $http.post(qasEndpoint + '/' + proxyClient, rpc('get', params))
    .then(function (response) {
      var result = response.data.result;

      // Convert it to Tessitura key'd object
      try {
        return $q.when({
          sStreet1: result.addressLines[0].Line,
          sStreet2: result.addressLines[1].Line,
          sCity: result.addressLines[3].Line,
          sStateProv: result.addressLines[4].Line,
          sPostalCode: result.addressLines[5].Line
        });
      } catch (e) {
        return $q.resolve(false);
      }
    })
    .catch(function () {
      // If QAS fails, give them benefit of the doubt
      return $q.resolve(false);
    });
  }

  function rpc(method, params) {
    return {
      id: proxyClient,
      method: method,
      params: params
    };
  }
}]);
