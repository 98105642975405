app.filter('productionLongTitle', ['appConfig', function (appConfig) {
  return function (production, options) {
    options = options || {};
    if (!production) {
      return '';
    }

    if (angular.isArray(production.webContent) == true) {
      for (var i in production.webContent) {
        var webContent = production.webContent[i];
        if (webContent.content_type == appConfig.webContentTypes.longTitle) {
          return webContent.content_value;
        }
      }
    }

    return production.prod_desc || production.description || options.fallback;
  };
}]);
