
app.directive('orderSummary', ['$sce', 'appConfig', function ($sce, appConfig) {
  return {
    controller: 'OrderSummeryController',
    templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'order-summary.html'),
    scope: {
      loading: '=',
      reloadData: '='
    }
  };
}]);


app.controller('OrderSummeryController', ['$scope', '$q', '$filter', 'TessituraSDK', 'AuthenticationService', 'AccountCredit', 'appConfig', 'Cart', 'CacheStorage', function ($scope, $q, $filter, TessituraSDK, AuthenticationService, AccountCredit, appConfig, Cart, CacheStorage) {

  // Promises to wait for to consider the page "loaded"
  var loadingPromises = [];
  // Used for syntactic sugar bellow
  var promise;

  $scope.packageHandlingDiscount = 0;

  var arrayWrap = $filter('arrayWrap');

  var performances = {};
  $scope.performances = performances;

  var cartData = {};
  $scope.cartData = cartData;

  $scope.accountCredit = [];

  $scope.yapApplication = false;

  loadingPromises.push(reloadData());
  loadingPromises.push(reloadAccountCredit());

  /**
   * Reloads all of the cart data and runs any checks
   * to make sure the cart is correct, only 1 donation
   * gift card value isnt to high, etc...
   */
  function reloadData() {
    $scope.reloadingTotal++;

    return Cart.getCart(true)
      .then(function(_cartData){

        cartData = _cartData;
        $scope.cartData = cartData;

        // Check if this is a YAP application
        if ('Order' in cartData) {
          if ('notes' in cartData.Order && angular.isString(cartData.Order.notes)) { // Check for a note
            $scope.yapApplication = cartData.Order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1;
          } else if ('custom_6' in cartData.Order && angular.isString(cartData.Order.custom_6)) { // Check for a custom value
            $scope.yapApplication = cartData.Order.custom_6.length;
          }
        }

        // Get all the subLineItems into one array
        var subLineItems = cartData.PackageSubLineItem.concat(cartData.SubLineItem);
        // Load the performances for the lineItems

        // calculate the package subtotal discount
        var packageLineItems= $filter('groupBy')(cartData.PackageLineItem, 'pkg_li_no');
        var nonDYOSublineItems = [];
        var nonDYOLineItems = [];

        for(var k in packageLineItems){
          var packages = packageLineItems[k];

          packages.forEach(function (package) {
            var result = cartData.PackageSubLineItem.filter(function (subLineItem) {
              // A different check of upgrade/renwals
              if (package.alt_upgrd_ind == 'U') {
                return (
                  // subline item correlates to package
                  subLineItem.li_seq_no === package.li_seq_no &&
                  // ignore first entry
                  package.perf_no !== '0' &&
                  // and is a primary package
                  package.primary_ind == 'Y' &&
                  // and not a flex package
                  package.flex_ind == 'N'
                );
              }

              return (
                // subline item correlates to package
                subLineItem.li_seq_no === package.li_seq_no &&
                // ignore first entry
                package.perf_no !== '0' &&
                // and is a primary package
                (('primary_ind' in package && package.primary_ind == 'Y') || (!('primary_ind' in package) && package.flex_ind == 'Y'))
              );
            });

            nonDYOSublineItems = nonDYOSublineItems.concat(result);
          });

          nonDYOLineItems = cartData.PackageSubLineItem.filter(function (subLineItem) {
            return !nonDYOSublineItems.some(function (nonDYOSli) {
              return subLineItem.li_seq_no === nonDYOSli.li_seq_no;
            });
          });

          // Remove all parking subline items
          nonDYOSublineItems = nonDYOSublineItems.filter(function (subLineItem) {
            // Looks like facility 13 is Self Parking so lets use that
            return parseInt(subLineItem.facility_no, 10) !== 13;
          });

          var packageItems = $filter('toArray')($filter('groupBy')(nonDYOSublineItems, 'li_seq_no'));
          var sublineitemFees = cartData.SubLineItemFee;

          if(packageItems.length > 0){
            $scope.packageHandlingDiscount = packageItems.reduce(function (acc, packageItem) {
              var fee = sublineitemFees.filter(function (f) {
                return (
                  parseInt(f.li_seq_no, 10) === parseInt(packageItem[0].li_seq_no, 10) &&
                  parseInt(f.sli_no, 10) === parseInt(packageItem[0].sli_no, 10)
                );
              });

              if (fee.length) {
                fee = parseFloat(fee[0].fee_amt);
              } else {
                fee = 0;
              }

              acc += packageItem.length * fee;
              return acc;
            }, 0);

            // New fee calculations -.-
            if ($scope.packageHandlingDiscount === 0) {
              $scope.packageHandlingDiscount = nonDYOLineItems.reduce(function (acc, subLineItem) {
                var fee = sublineitemFees.filter(function (f) {
                  return (
                    parseInt(f.li_seq_no, 10) === parseInt(subLineItem.li_seq_no, 10) &&
                    parseInt(f.sli_no, 10) === parseInt(subLineItem.sli_no, 10)
                  );
                });

                if (fee.length) {
                  fee = parseFloat(fee[0].fee_amt);
                } else {
                  fee = 0;
                }

                return acc + fee;
              }, 0);
            }
          }
        }

        return loadPerformancesForSubLineItems(subLineItems);
      })
      .then(addLongTitleToPackages)
      // Remove the loading icon after any promises
      // in the cartReload have ran
      .then(function () {
        $scope.reloadingTotal--;
      });
  }
  $scope.reloadData = reloadData;

  function reloadAccountCredit() {
    $scope.reloadingTotal++;

    return AuthenticationService.isUserRegistered()
    .then(function (isUserRegistered) {
      if (isUserRegistered) {
        return TessituraSDK.GetOnAccountInfo();
      } else {
        return $q.resolve(false);
      }
    })
    .then(function (response) {
      if (response !== false) {
        $scope.accountCredit = arrayWrap(response.data.result.GetOnAccountResults.OnAccountItem);
      }
    })
    .catch(function () {
      $scope.accountCredit = [];
    })
    .finally(function () {
      $scope.reloadingTotal--;
    });
  }

  $scope.isAccountCreditInUse = AccountCredit.isAccountCreditInUse;

  $scope.getSubTotal = function(){
    var subTotal;
    if (cartData.Order) {
      subTotal = parseInt(cartData.Order.SubTotal,10) + $scope.packageHandlingDiscount;
    }
    return subTotal;
  }


  /**
   * Replaces the pkg_desc for all of the parent packages
   * with the data from the long title web content field
   */
  function addLongTitleToPackages() {
    // For each line item, get the web content
    // Then try to override the line items pkg_desc with the long title
    var lineItems = $scope.getAllParentPackageLineItems();
    var packageWebContentPromises = lineItems.map(function (lineItem) {
      return TessituraSDK.GetWebContent({
        iPackageNumber: lineItem.pkg_no
      })
      .then(function (response) {

        var webContents = arrayWrap(response.data.result.GetWebContentResults.WebContent);

        lineItem.webContent = webContents;

        webContents.forEach(function (webContent) {
          if (webContent.content_type == appConfig.webContentTypes.longTitle) {
            lineItem.pkg_desc = webContent.content_value;
          }
        });

        return $q.resolve();
      });
    });

    // Ignore errors because the real name isnt that important
    return $q.all(packageWebContentPromises).catch(function(){});
  }

  /**
   * Takes in sub line items and loads all of the
   * performances with its pricing data locally so
   * it can be used to get the base pricing.
   *
   * @param  Array   subLineItems  Array of SubLineItem elements from Tessi
   * @return Promise               Promise for all the http requests
   */
  function loadPerformancesForSubLineItems(subLineItems) {
    return AuthenticationService.getLoginInfo()
    .then(function (loginInfo) {

      var promises = [];
      // Clear the current performances cache
      performances = {};

      // Loop over each sub line item and get its performance
      for(var i = 0; i < subLineItems.length; i++) {
        var subLineItem = subLineItems[i];

        // Dont load a number of 0 (happens in subPackageLineitems)
        // Dont get this performance if we already have it
        if (subLineItem.perf_no == "0" || performances[subLineItem.perf_no] !== undefined) {
          continue;
        }

        // Set it in the object so we dont make multiple requests
        performances[subLineItem.perf_no] = null;

        // Make the request to tessi to get the data
        var GetPerformanceDetailWithDiscountingExParams = {
          iPerf_no: subLineItem.perf_no,
          iModeOfSale: parseInt(loginInfo.MOS)
        };
        var promise = TessituraSDK.GetPerformanceDetailWithDiscountingEx(GetPerformanceDetailWithDiscountingExParams)
        .then(function (response) {
          var performance = response.data.result.GetPerformanceDetailWithDiscountingExResult;
          performance.AllPrice = arrayWrap(performance.AllPrice);
          performances[performance.Performance.inv_no] = performance;

          attachWebContentToPerformance(
            response.data.result.GetPerformanceDetailWithDiscountingExResult.Performance,
            response.data.result.GetPerformanceDetailWithDiscountingExResult.WebContent
          );

          return $q.resolve();
        });
        promises.push(promise);
      }

      return $q.all(promises);
    });
  }

  /**
   * Takes in a sublineitem and returns its base price
   *
   * @param  SubLineItem subLineItem  Sublineitem from Tessi
   * @return String
   */
  $scope.getBasePrice = function (subLineItem) {
    var performance = performances[subLineItem.perf_no];

    if (!(performance && performance.AllPrice)) {
      return -1;
    }

    for(var i = 0; i < performance.AllPrice.length; i++) {
      var price = performance.AllPrice[i];
      if (price.zone_no == subLineItem.zone_no && price.price_type == subLineItem.price_type) {
        return parseFloat(price.base_price);
      }
    }

    return -1;
  };

  /**
   * Calculates the total discount of all items
   * @return int
   */
  $scope.getDiscountAmount = function () {
    var subLineItems = cartData.SubLineItem;
    var amount = 0;

    if (angular.isArray(subLineItems)) {
      subLineItems.forEach(function (subLineItem) {
        var basePrice = $scope.getBasePrice(subLineItem);
        if (basePrice > -1) {
          amount -= (basePrice - subLineItem.due_amt);
        }
      });
    }

    return (amount > 0 ? false : amount);
  };

  function getDYOGroups() {
    var dyoPackages = arrayWrap(cartData.PackageLineItem).filter(function (packageLineItem) {
      return 'notes' in packageLineItem && packageLineItem.notes.indexOf('Part of ') === 0 && packageLineItem.li_no == 0 && packageLineItem.notes.indexOf('Parking') === -1;
    });

    dyoPackages = $filter('groupBy')(dyoPackages, 'notes');

    return Object.keys(dyoPackages).reduce(function (carry, key) {
      var lineItemNumbers = dyoPackages[key].map(function (item) {
        return parseInt(item.li_seq_no, 10);
      });

      carry.push(lineItemNumbers);

      return carry;
    }, []);
  }

  /**
   * Is the package part of a DYO group
   * @param  {Object}  package PackageLineItem
   * @return {Boolean}
   */
  $scope.isDYOPackage = function(package) {
    var groups = getDYOGroups();

    if (!Array.isArray(groups)) {
      return false;
    }

    // Loop over the groups and simple package numbers
    // Do any of the numbers match the package number of
    // the package to check
    return groups.some(function (packgeNoGroup) {
      return packgeNoGroup.some(function (packageNo) {
        return packageNo == package.pkg_li_no;
      });
    });
  }

  /**
   * Is the package the start of a DYO group.
   * If DYOGroups is [[1], [5,6]]
   * This will return true for a package with line item number of 5 or 1
   *
   * @param  {Object}  package PackageLineItem
   * @return {Boolean}
   */
  function isRootDYOPackage(package) {
    var groups = getDYOGroups();

    if (!Array.isArray(groups)) {
      return false;
    }

    // Loop over the groups and simple package numbers
    // Do any of the numbers match the package number of
    // the package to check
    return groups.some(function (packgeNoGroup) {
      return packgeNoGroup.length && packgeNoGroup[0] == package.pkg_li_no;
    });
  }

  /**
   * Returns the group that a DYO package is in
   * If DYOGroups is [[1], [5,6]]
   * If the user pass in a package will line number 6 it will return [5,6]
   *
   * @param  {Object}  package PackageLineItem
   * @return {Array}
   */
  $scope.getDYOPackageGroup = function (package) {
    var groups = getDYOGroups();

    if (!Array.isArray(groups)) {
      return [];
    }

    for (var groupIndex in groups) {
      var packgeNoGroup = groups[groupIndex];
      for (var packageNoIndex in packgeNoGroup) {
        var packageNo = packgeNoGroup[packageNoIndex];
        if (packageNo == package.pkg_li_no) {
          return packgeNoGroup;
        }
      }
    }
  }

  /**
   * Does the same as $scope.getDYOPackageGroup
   * BUT returns the actual package objects, not the ids
   *
   * @param  {Object}  package PackageLineItem
   * @return {Array}
   */
  $scope.getDYOPackageGroupExpanded = function (parentPackageLineItem) {
    var packageIdsInGroup = $scope.getDYOPackageGroup(parentPackageLineItem);

    return packageIdsInGroup
    // Convert the ids in to the the parentPackageLineItems
    .map(function (packageLineNumber) {
      for (var i in cartData.PackageLineItem) {
        var package = cartData.PackageLineItem[i];
        if (package.pkg_li_no == packageLineNumber) {
          return package;
        }
      }
    })
  }

  /**
   * Returns an array of all Packages
   */
  $scope.getAllParentPackageLineItems = function () {
    if (cartData.PackageLineItem) {
      return cartData.PackageLineItem
        .filter(function (packageLineItem) {
          return packageLineItem.pkg_li_no === packageLineItem.li_seq_no;
        });
    }
  };

  /**
   * Returns an array of all Packages
   */
  $scope.getParentPackageLineItems = function () {
    if (cartData.PackageLineItem) {
      return cartData.PackageLineItem
        .filter(function (packageLineItem) {
          return packageLineItem.pkg_li_no === packageLineItem.li_seq_no &&
            (!$scope.isDYOPackage(packageLineItem) || isRootDYOPackage(packageLineItem));
        });
    }
  };


  /**
   * Returns an array of all Packages
   */
  $scope.getPackageLineItemsForParentPackage = function (parentPackageLineItem) {

    if (!cartData.PackageLineItem) {
      return;
    }

    if ($scope.isDYOPackage(parentPackageLineItem)) {

      var packages = $scope.getDYOPackageGroupExpanded(parentPackageLineItem)
      // Now get the package line items for our parent packages
      .map(getPackageLineItemsForParentPackage)

      // Finally join this array together
      return Array.prototype.concat.apply([], packages);

    } else {
      return getPackageLineItemsForParentPackage(parentPackageLineItem);
    }

  };

  /**
   * Returns an array of all Packages
   */
  $scope.getAllPackageLineItemsForParentPackage = function (parentPackageLineItem) {
    if (!cartData.PackageLineItem) {
      return;
    }

    return getPackageLineItemsForParentPackage(parentPackageLineItem);
  };

  function getPackageLineItemsForParentPackage(parentPackageLineItem) {
    return cartData.PackageLineItem
      .filter(function (packageLineItem) {
        // We group flex and non flex packages differently
        if (parentPackageLineItem.flex_ind == "Y") {
          return packageLineItem.li_no === parentPackageLineItem.li_seq_no;
        } else {
          return packageLineItem.pkg_li_no  === parentPackageLineItem.li_seq_no && packageLineItem.perf_no !== '0';
        }
      });
  }

  /**
   * Returns if this is "super package" (identified by web content at the moment)
   */
  $scope.isSuperPackage = function (parentPackageLineItem) {
    return parentPackageLineItem.webContent.some(function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.webSYOS;
    });
  };

  /**
   * Returns an array of all Packages
   */
  $scope.getPackageSubLineItemsForPackage = function (package) {
    return cartData.PackageSubLineItem
      .filter(function (subPackage) {
        return subPackage.li_seq_no == package.li_seq_no;
      });
  };

  /**
   * Takes in a parent package line item and returns the
   * number of subscriptions that each sub package has
   */
  $scope.numberOfSubscriptionsInParentPackage = function (parentPackageLineItem) {
    var packageLineItems = $scope.getPackageLineItemsForParentPackage(parentPackageLineItem);
    var firstPackageLineItem = packageLineItems[0];
    var packageSubLineItems = $scope.getPackageSubLineItemsForPackage(firstPackageLineItem);
    return packageSubLineItems.length;
  };

    /**
   * Takes in a parent package line item and returns the
   * number of subscriptions that each sub package has
   */
  $scope.numberOfYAPAttendeesInParentPackage = function (parentPackageLineItem) {
    var packageLineItems = $scope.getPackageLineItemsForParentPackage(parentPackageLineItem);
    var firstPackageLineItem = packageLineItems[0];
    var packageSubLineItems = $scope.getPackageSubLineItemsForPackage(firstPackageLineItem);

    var priceTypes = cartData.PriceType.map(function (priceType) {
      return {
        description: priceType.description,
        quantity: packageSubLineItems.filter(function (subLineItem) {
          return parseInt(subLineItem.price_type, 10) === parseInt(priceType.price_type, 10);
        }).length
      };
    });

    // Remove 0 quantity values
    priceTypes = priceTypes.filter(function (priceType) {
      return priceType.quantity;
    });

    // Nice display
    priceTypes = priceTypes.map(function (priceType) {
      return priceType.quantity + ' x ' + priceType.description;
    });

    return priceTypes.join('<br>');
  };

  /**
   * Takes in a parent package line item and returns the total price
   * by summing up all the sub packages
   */
  $scope.totalPriceOfParentPackage = function (parentPackageLineItem) {
    if (isRootDYOPackage(parentPackageLineItem)) {
      return totalPriceOfDYOParentPackage(parentPackageLineItem);
    }

    return totalPriceOfParentPackage(parentPackageLineItem);
  };

  function totalPriceOfDYOParentPackage(parentPackageLineItem) {
    var parentLineItems = $scope.getDYOPackageGroupExpanded(parentPackageLineItem);

    return parentLineItems.reduce(function (carry, parentLineItem) {
      return carry += totalPriceOfParentPackage( parentLineItem );
    }, 0);
  }

  function totalPriceOfParentPackage(parentLineItem) {
    var addition = (parseInt(parentLineItem.facil_no, 10) === 13) ? 0 : packageSublineItemCost(parentLineItem);

    var packageLineItems = $scope.getAllPackageLineItemsForParentPackage(parentLineItem);

    var subLineItems = [];

    packageLineItems.forEach(function (packageLineItem) {
      subLineItems = subLineItems.concat( $scope.getPackageSubLineItemsForPackage(packageLineItem) );
    });

    return subLineItems.reduce(function (total, subLineItem) {
      return total + parseFloat(subLineItem.due_amt) + addition;
    }, 0);
  }

  /**
   * Returns an array of all the LineItems from the cart
   */
  $scope.getLineItems = function () {
    return cartData.LineItem;
  };

  /**
   * Returns all the subline items that are for a lineitem
   */
  $scope.getSubLineItemsForLineItem  = function (lineItem) {
    return cartData.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no;
    });
  };

  /**
   * Returns all the PriceType objects for LineItem
   * that the user has brought tickets for
   */
  $scope.getPriceTypesForLineItem = function (lineItem) {
    var priceTypeIds = cartData.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no;
    }).map(function(subLineItem){
      return subLineItem.price_type;
    });

    return cartData.PriceType.filter(function (priceType) {
      return priceTypeIds.indexOf(priceType.price_type) > -1;
    });
  };

  /**
   * Returns the sublineitems that are for a lineitem
   * and a pricetype
   */
  $scope.getSubLineItemsForPriceTypes = function (lineItem, priceType) {
    return cartData.SubLineItem.filter(function (subLineItem) {
      return subLineItem.li_seq_no == lineItem.li_seq_no && subLineItem.price_type == priceType.price_type;
    });
  };


  /**
   * Takes in a lineitem that has a performance and returns
   * an actual Performance object for it
   */
  $scope.performanceFromLineitem = function (performanceLineItem) {
    if( performances[performanceLineItem.perf_no] != null ) {
      return performances[performanceLineItem.perf_no].Performance;
    }
    return false;
  };


  function attachWebContentToPerformance(performances, webContents) {
    angular.forEach(arrayWrap(performances), function (performance) {
      performance.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == performance.inv_no;
      });

      var foundTba = performance.webContent.filter(function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.dateTba;
      });

      if (foundTba.length) {
        performance.dateTba = foundTba[0].content_value;
      }
    });
  }

  /**
   * Takes in a line item or sub line item,
   * joins the seat row and column together.
   * Returns null if seat_* isnt set
   * @return {[type]} [description]
   */
  $scope.seatFromSubline = function (sublineItem) {
    if (sublineItem.seat_row && sublineItem.seat_num) {
      return sublineItem.seat_row + sublineItem.seat_num;
    } else {
      return null;
    }
  }


  /**
   * Returns true if this package line item is being upgraded.
   * Not if it IS the upgrade.
   * @param  {PackageLineItem}  PackageLineItem object
   * @return {Boolean}
   */
  $scope.isPackageBeingUpgraded = function (packageLineItem) {
    if (packageLineItem.alt_upgrd_ind !== 'U') {
      return false;
    }

    return !$scope.isPackageUpgade(packageLineItem);
  }

  /**
   * Returns the ADA data for the given lineitem
   */
  $scope.getADADetailsForLineItem = function (lineItem, isPackage) {
    if (!lineItem) {
      return [];
    }

    var details = [];

    if (isPackage) {
      var adaCacheKey = 'PackageADAMeta-' + lineItem.pkg_no;

      if (CacheStorage.has(adaCacheKey)) {
        details = [].concat(details, CacheStorage.get(adaCacheKey).Notes.split("\n"));
      }
    } else {
      var cacheKey = 'PerformanceADAMeta-' + lineItem.perf_no;

      if (CacheStorage.has(cacheKey)) {
        var
          notes = JSON.parse(CacheStorage.get(cacheKey).Notes),
          options = 'options' in notes ? Object.keys(notes.options) : [],
          other = 'other' in notes && angular.isString(notes.other) ? notes.other : null;

        if (options.length) {
          details = [].concat(details, options);
        }

        if (other) {
          details.push( 'Other special request: ' + other );
        }
      }
    }

    return details.join(", ");
  };

  function packageSublineItemCost (parentPackageLineItem) {
    if (!parentPackageLineItem) {
      return 0;
    }

    if (!parentPackageLineItem.webContent) {
      return 0;
    }

    var foundCost = parentPackageLineItem.webContent.filter(function (webContent) {
      return webContent.content_type == appConfig.webContentTypes.webPackageItemCost;
    });

    return foundCost.length ? parseFloat(foundCost[0].content_value) : 0;
  }


  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
    });

}]);
