/* global moment */
app.controller('TicketDetailsController', ['$scope', '$q', '$location', '$filter', 'appConfig', 'MosSwitcher', 'AuthenticationService', 'UpcomingEventsService', 'Exchange', 'TessituraSDK', function ($scope, $q, $location, $filter, appConfig, MosSwitcher, AuthenticationService, UpcomingEventsService, Exchange, TessituraSDK) {
    var find = $filter('find');

    // Local variables
    var loginInfo;
    var searchParams = $location.search();
    var orderNo = searchParams && searchParams.order_no ? parseInt(searchParams.order_no, 10) : null;
    var perfNo = searchParams && searchParams.perf_no ? parseInt(searchParams.perf_no, 10) : null;

    // Scope variables
    $scope = angular.extend($scope, {
        loading: true,
        isGuest: false,
        canExchange: false,
        canDonate: false,
        error: false,
        event: null
    });

    if (!orderNo || !perfNo) {
        $scope.error = 'Invalid order or performance number.';
        $scope.loading = false;
        return false;
    }

    // Ask user to login if required
    AuthenticationService.requireLogin()
    .then(AuthenticationService.isUserRegistered)
    // Check the user is logged in first
    .then(function (isUserRegistered) {
        if (!isUserRegistered) {
            // This user is not registered
            $scope.isGuest = true;

            // No longer need to display loading
            $scope.loading = false;

            // No need to continue
            return $q.resolve(true);
        }

        // Try to gracefully switch to the default mode of sale for the user
        return MosSwitcher.defaultMos(true);
    })
    .then(function () {
        // Get the default sale mode for the user
        return MosSwitcher.getSaleMode();
    })
    .then(function (saleMode) {
        // Is the same mode subscriber?
        $scope.canExchange = saleMode.canExchange;
        $scope.canDonate = saleMode.canDonate;

        // Grab login info
        return AuthenticationService.getLoginInfo();
    })
    .then(function (loginInfoEx) {
        loginInfo = loginInfoEx;

        return upcomingEvents();
    })
    .finally(function () {
        $scope.loading = false;
    });

    function upcomingEvents() {
        return UpcomingEventsService.getUserUpcomingEvents(loginInfo)
        .then(function (upcomingEvents) {
            $scope.event = find(upcomingEvents, function (item) {
                return parseInt(item.orderNo, 10) === orderNo && item.perf_no === perfNo;
            });
        })
        .catch(function () {
            $scope.event = null;
        });
      };
  }]);
