app.service('DigestService', ['$q', '$filter', 'AuthenticationService', 'TessituraSDK', 'CacheStorage', function ($q, $filter, AuthenticationService, TessituraSDK, CacheStorage) {
  var arrayWrap = $filter('arrayWrap');

  return {
    getProfile: getProfile,
    updateProfile: updateProfile
  };

  function getProfile() {
    return AuthenticationService.loggedIn().then(function (loggedIn) {
      var userProfile = CacheStorage.get('userProfile');

      if (!userProfile) {
        userProfile = {
          synced: false,
          count: 0,
          keywords: {}
        };
      }

      if (loggedIn === false) {
        return userProfile;
      }

      if (userProfile.synced) {
        return userProfile;
      }

      return TessituraSDK.GetUserProfile().then(function (response) {
        var
          result = response.data.result,
          keywords = arrayWrap( result.ExecuteLocalProcedureResults.LocalProcedure );

        userProfile.synced = true;
        userProfile.keywords = {};

        angular.forEach(keywords, function (keyword) {
          userProfile.keywords[keyword.id] = keyword;
        });

        CacheStorage.set('userProfile', userProfile);

        return userProfile;
      });
    });
  };

  function updateProfile(keywords) {
    getKeywords().then(function (keywordsEx) {
      keywords = arrayWrap(keywords);
      // Grab userProfile we currently have
      getProfile().then(function (userProfile) {
        // Loop through productions keywords
        angular.forEach(keywords, function (keyword) {
          var found = keywordsEx[ (keyword.keyword+keyword.category).toLowerCase() ];

          // We have keyword in local storage
          if (userProfile.keywords.hasOwnProperty( found.id )) {
            userProfile.keywords[found.id].weight = Number(userProfile.keywords[found.id].weight) + 1;
          } else {
            userProfile.keywords[found.id] = {
              category: found.category,
              description: found.description,
              id: found.id,
              weight: 1
            };
          }
        });

        // Increment views
        userProfile.count++;

        CacheStorage.set('userProfile', userProfile);

        return true;

        // // Makey key => value pair of ID and weight
        // var weights = [];

        // angular.forEach(userProfile.keywords, function (keyword) {
        //   weights.push( keyword.id + '=' + keyword.weight );
        // });

        // // Many counts?
        // if (userProfile.count > 9) {
        //   // Update it in Tessitura, only if logged in
        //   AuthenticationService.loggedIn().then(function (loggedIn) {
        //     if (loggedIn) {
        //       TessituraSDK.UpdateUserProfile({
        //         keywords: weights.join(',')
        //       });

        //       // No need for local storage anymore - will regenerate soon
        //       CacheStorage.remove('userProfile');
        //     } else {
        //       // Update local storage
        //       CacheStorage.set('userProfile', userProfile);
        //     }
        //   });
        // } else {
        //   // Update local storage
        //   CacheStorage.set('userProfile', userProfile);
        // }
      });
    });
  };

  function getKeywords() {
    // Grab all keywords, we need IDs as GetProductionsEx3 doesn't provide one
    return TessituraSDK.GetKeywordsEx({
      iKeywordCategory: 0,
      sUsedIn: ''
    }).then(function (response) {
      var keywordsEx = {};
      response = arrayWrap( response.data.result.GetKeywordsExResult.Keyword );

      // Store it as a key => value pair
      angular.forEach(response, function (keyword) {
        keywordsEx[ (keyword.description+keyword.category_desc).toLowerCase() ] = keyword;
      });

      return keywordsEx;
    });
  };
}]);
