app.controller('FixedPackageController', ['$scope', '$attrs', '$window', '$q', '$filter', 'TessituraSDK', 'AuthenticationService', 'Router', 'Donation', 'GetParameters', 'appConfig', 'MosSwitcher', 'Cart', 'CacheStorage', function ($scope, $attrs, $window, $q, $filter, TessituraSDK, AuthenticationService, Router, Donation, GetParameters, appConfig, MosSwitcher, Cart, CacheStorage) {
  var promise;
  var loadingPromises = [];
  var arrayWrap = $filter('arrayWrap');
  var widgetConfig = document.getElementById('widget-config');

  if (widgetConfig) {
    widgetConfig = JSON.parse(widgetConfig.innerHTML);
  } else {
    widgetConfig = {};
  }

  var now = new Date();

  // max seats in the dropdown
  var maxPurchasableSeats = parseInt($attrs.maxPurchasableSeats) || 10;

  // parse config values
  var packageNumberFromConfig = [];
  var seasonNumberFromConfig = null;

  if (widgetConfig.PackageNo && widgetConfig.PackageNo != 0) {
    packageNumberFromConfig = widgetConfig.PackageNo.split(',');
    if (packageNumberFromConfig && packageNumberFromConfig.constructor !== Array) {
      packageNumberFromConfig = [packageNumberFromConfig];
    }
  } else {
    if ($attrs.packageNumber) {
      packageNumberFromConfig = $attrs.packageNumber.split(',');
    } else if (GetParameters.packageNumber) {
      packageNumberFromConfig = GetParameters.packageNumber.split(',');
    } else if (GetParameters.packageId) {
      packageNumberFromConfig = GetParameters.packageId.split(',');
    }
  }

  if (widgetConfig.SeasonNo && widgetConfig.SeasonNo != 0) {
    seasonNumberFromConfig = widgetConfig.SeasonNo;
  }

  var defaultPriceType = parseInt(widgetConfig.DefaultPriceType || $attrs.defaultPriceType) || 2;
  var packageNoIsNan = true;

  // Get the package and season id
  var packageNumber = packageNumberFromConfig.map(function (pkgNo) {
    if (isNaN(pkgNo) == false) {
      packageNoIsNan = false;
    }
    return parseInt(pkgNo);
  });
  var seasonNumber = parseInt(seasonNumberFromConfig || $attrs.seasonNumber || GetParameters.seasonNumber);
  var dateSelectionMode = packageNoIsNan || packageNumberFromConfig.length > 1;

  // Test if a package or season number is provided
  // Bail out if not
  if (packageNoIsNan && isNaN(seasonNumber)) {
    $scope.noPackageOrSeasonNumber = true;
    return;
  }

  // Mode of sale to get package
  var modeOfSale = appConfig.fixedSubscriberMos;

  // Maps package ID to a full day of the week name
  var daysOfTheWeek = {
    "mon": "Monday",
    "tue": "Tuesday",
    "wed": "Wednesday",
    "thu": "Thursday",
    "fri": "Friday",
    "sat": "Saturday",
    "sun": "Sunday"
  };

  // Maps package ID to a time of day
  var timesOfDay = {
    "mat": "Matinee",
    "eve": "Evening"
  };

  // Additional CSI field name
  var additionalRequestsSelector = '.additional-requests';

  var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Is the initial page loading
  $scope.loading = true;
  $scope.loadingPackage = false;

  $scope.facilities = null;

  // Is is being used for parking
  $scope.parking = $attrs.parkingPage === 'true' || false;

  // Add to cart flags
  $scope.addingToCart = false;
  $scope.addToCartError = false;

  // Renewing subscriptions
  $scope.renewing = GetParameters['renew'] ? true:false;
  $scope.renew = {

    // The original number of seats in use
    originalNumberOfSeats: 2,

    // Original zone number
    originalPrice: null,

    // Original zone number
    originalPackageNumber: null,

    // Map of the dayTimes the user are willing
    // to have flexible dates on
    flexibleDates: {}
  };

  $scope.renewCSI = {
    // Does the user want to move their seats, or change package
    // switchPackages|improveSeats
    seatState: "improveSeats",

    // Where should the users new seats be put in seatState is improveSeats
    // center|font
    newSeatPosition: "center",

    // If the user needs extra seats, where should they go
    // placeMySeatsElsewhere | moveMySeats
    extraSeatPosition: "placeMySeatsElsewhere",

    // Extra notes
    additionalNotes: ""
  };

  // List of number of buyable subscriptions
  $scope.subscriptionLimits = [];

  // Are we in data selection mode
  $scope.dateSelectionMode = dateSelectionMode;
  $scope.dateSelectionSeasonTitle = "";

  // Mos issues
  $scope.wrongMos = false;
  $scope.wrongMosReason = "";

  // Errors
  $scope.invalidPackage = false;
  $scope.invalidSeason = false;
  $scope.invalidRenewOrderId = false;

  // Form data
  $scope.selectedPackage = "";
  $scope.form = {
    price: null,
    numberOfSeats: 2,
    specialRequests: {},
    toggleSpecialRequests: {}
  };

  // Build array for number of buyable subscriptions
  for (var i = 1; i <= maxPurchasableSeats; i++) {
    $scope.subscriptionLimits.push(i);
  }

  // Check the user is logged in
  AuthenticationService.requireLogin()
  .then(function () {
    // Try switching the user to mode of sale for this page
    return MosSwitcher.subscriberMos();
  })
  .then(function (response) {
    // Failed to switch MoS
    if (response.status === false || 'promoCode' in response) {
      $scope.wrongMos = true;
      if ('promoCode' in response) {
        $scope.wrongMosReason = 'Please complete your current transaction purchase or remove the promo code to add this item to the cart.';
      } else {
        $scope.wrongMosReason = response.reason;
      }
    }

    if (GetParameters['renew']) {
      loadingPromises.push(renewOrder(GetParameters['renew']));
    } else {
      // Get the users mode of sale and then
      // trigger the loading of the package or season
      if (dateSelectionMode) {
        loadingPromises.push(loadPackageDates(isNaN(seasonNumber) ? undefined : seasonNumber, packageNumber));
      } else {
        packageNumber.forEach(function (pkgNo) {
          loadingPromises.push(loadPackage(pkgNo));
        });
      }
    }
  });

  /**
   * 1. Check to see if the orderId is already loaded
   *   1.1 If its not, load it into the session
   * 2. Load the package details used in the order
   * 3. Restore the page attributes, quantity + price_type
   *
   * @param  {int}     orderId  Order ID which has the package to edit
   * @return {Promise}
   */
  function renewOrder(orderId) {
    var cartData;

    // 1. Load the cart
    return Cart.loadOrder(orderId, true)
    .then(function () {
      return Cart.getCart(true);
    })

    // 2. With the cart contents, read the package and load the attributes
    .then(function (_cartData) {
      cartData = _cartData;

      // If theres no packages in this order, return error
      if (cartData.Order.solicitor !== 'RollOver') {
        return $q.reject();
      }

      // If theres no packages in this order, return error
      if (cartData.PackageLineItem.length == 0) {
        return $q.reject();
      }

      // Find the package number were renewing
      var pkg_no = cartData.PackageLineItem[0].pkg_no;
      var season_no = cartData.PackageLineItem[0].season_no;

      // Load the package data
      return $q.all([
        loadPackage(pkg_no),
        loadPackageDates(season_no, packageNumberFromConfig)
      ]);
    })

    // 3. Once the package is loaded restore the quantity and price type
    .then(function () {
      // Set the number of seats
      var numberOfSeats = cartData.PackageSubLineItem.length / cartData.PackageLineItem.length;
      $scope.form.numberOfSeats = numberOfSeats;
      $scope.renew.originalNumberOfSeats = numberOfSeats;

      var packageNumber = cartData.PackageLineItem[0].pkg_no;
      $scope.renew.originalPackageNumber = packageNumber;

      var originalParentPackageLineItemId = cartData.PackageLineItem[0].pkg_li_no;
      $scope.renew.originalParentPackageLineItemId = originalParentPackageLineItemId;

      // Set the price/zone selector
      var priceToMatch = cartData.PackageSubLineItem[0];
      $scope.prices.forEach(function (price) {
        if (priceToMatch.price_type == price.price_type && priceToMatch.zone_no == price.zone_no) {
          $scope.form.price = price;
          $scope.renew.originalPrice = price;
          return false;
        }
      });
    })

    // If we error at any time, say the orderId is bad
    .catch(function () {
      $scope.invalidRenewOrderId = true;

      // Try and clear the Order, in case it was loaded
      TessituraSDK.ClearCart({
        iOrderNumber: orderId
      });
    });
  }


  /**
   * Loads all the package dates from tessi
   * Once data is in scope the table then builds
   */
  function loadPackageDates(seasonNumber, packageNumbers) {
    return TessituraSDK.GetPackagesEx3({
      iModeOfSale: modeOfSale,
      iSeason: parseInt(seasonNumber)
    })
    .then(function (response) {
      var packages = arrayWrap(response.data.result.GetPackagesEx3Result.Package);

      if (packageNumbers && packageNumbers.constructor === Array && packageNumbers.length) {
        packageNumbers = packageNumbers.map(function (pkgNo) {
          return parseInt(pkgNo, 10);
        });

        packages = packages.filter(function (pkg) {
          return packageNumbers.indexOf( parseInt(pkg.pkg_no, 10) ) !== -1;
        });
      }

        var packagesMap = {};

        // Map out the packages pkg_description to get
        // the week, day and time of day
        packages = packages.filter(function (package) {
          if (package.description == 'KDT D1 PV1') {
            package.description = 'KDT D1 Sun Eve';
          } else if (package.description == 'KDT D1 PV2') {
            package.description = 'KDT D1 Tue Eve';
          } else if (package.description == 'KDT D1 PV3') {
            package.description = 'KDT D1 Wed Eve';
          } else if (package.description == 'KDT D1 PV4') {
            package.description = 'KDT D1 Thu Eve';
          } else if (package.description == 'KDT D1 PV5') {
            package.description = 'KDT D1 Fri Eve';
          }

          package.description = package.description.toLowerCase();

          var match = package.description.match(/[a-z]([0-9]+) (mon|tue|wed|thu|fri|sat|sun) (mat|eve)/);

          if (!match) {
            return false;
          }

          // Breakdown of pkg code
          package.pkgDescriptionBreakdown = {
            dayOfWeek: match[2],
            timeOfDay: match[3],
            weekNum: match[1],
            dayTimeWeek: match[2] + match[3] + match[1]
          };

          // Build map for easy access in scope later
          if (packagesMap[package.pkgDescriptionBreakdown.dayTimeWeek] === undefined) {
            packagesMap[package.pkgDescriptionBreakdown.dayTimeWeek] = [];
          }

          packagesMap[package.pkgDescriptionBreakdown.dayTimeWeek].push(package);

          return true;
        });

        // Sorts packages by first_dt
        var sortPackageArray = function (a, b) {
          return new Date(a.first_dt) > new Date(b.first_dt);
        };

        // Convert the mapped arrays into nice, named objects
        for (var packagesMapIndex in packagesMap) {
          var packageSetArray = packagesMap[packagesMapIndex];
          var packageSetObject = {
            allPackages: packageSetArray,
            lastPackage: packageSetArray[ packageSetArray.length - 1 ],
            indatePackage: null,
            isLastPackageInDate: false
          };

          // Sort the array
          packageSetArray.sort(sortPackageArray);

          // Find a set the package thats indate to use
          for (var packageSetArrayIndex in packageSetArray) {
            var pkg = packageSetArray[packageSetArrayIndex];

            if (!packageSetObject.indatePackage && new Date(pkg.first_dt) > now) {
              packageSetObject.indatePackage = pkg;
            }
          }

          // Is the main package the package we are using.
          packageSetObject.isLastPackageInDate = !!packageSetObject.indatePackage;

          // Overwrite the array with our new object
          packagesMap[packagesMapIndex] = packageSetObject;
        }

        // Error if no valid packages
        if (packages.length === 0) {
          $scope.invalidSeason = true;
          return false;
        }

        // Set the season title
        $scope.dateSelectionSeasonTitle = widgetConfig.PageTitle || packages[0].season_desc;

        // Work out what headers and weeks we need
        var headers = [];
        var headersAdded = {};
        var weekNumbers = [];

        for (var dayOfWeek in daysOfTheWeek) {
          for (var timeOfDay in timesOfDay) {
            for (var packageIndex in packages) {
              var package = packages[packageIndex];
              if (package.pkgDescriptionBreakdown.dayOfWeek == dayOfWeek && package.pkgDescriptionBreakdown.timeOfDay == timeOfDay) {
                // Add the needed if not already added
                if (!headersAdded.hasOwnProperty(dayOfWeek+timeOfDay)){
                  headersAdded[dayOfWeek+timeOfDay] = true;
                  headers.push({
                    dayOfWeek: capitalizeFirstLetter(dayOfWeek),
                    dayOfWeekText: daysOfTheWeek[dayOfWeek],
                    timeOfDay: capitalizeFirstLetter(timeOfDay),
                    timeOfDayText: timesOfDay[timeOfDay],
                    dayTime: dayOfWeek + timeOfDay
                  });
                }

                // Add the week number if not already added
                if (weekNumbers.indexOf(package.pkgDescriptionBreakdown.weekNum) == -1) {
                  weekNumbers.push(package.pkgDescriptionBreakdown.weekNum);
                }

                continue;
              }
            }
          }
        }

        // Map the table data to scope
        $scope.packagesMap = packagesMap;
        $scope.packagesTableHeaders = headers;
        $scope.packagesWeekNumbers = $filter('orderBy')(weekNumbers);
      });
  }

  /**
   * Loads a package from tessi and puts the
   * data into scope.
   */
  function loadPackage(_packageNumber) {
    $scope.loadingPackage = true;
    $scope.form.selectedPackageNo = _packageNumber;
    packageNumber = parseInt(_packageNumber);

    return TessituraSDK.GetPackageDetailWithDiscountingEx({
      'PackageID': packageNumber,
      'ModeOfSale': modeOfSale
    })
      .then(function (response) {
        // Copy data to scope
        var GetPackageDetailWithDiscountingExResult = response.data.result.GetPackageDetailWithDiscountingExResult;

        // Check this package matches the packageNumber
        // If it doesnt drop this response
        if (GetPackageDetailWithDiscountingExResult.Package.pkg_no != packageNumber) {
          return;
        }

        $scope.package = GetPackageDetailWithDiscountingExResult.Package;
        $scope.performances = arrayWrap(GetPackageDetailWithDiscountingExResult.Performance);

        // Attach web content fields to the package
        attachWebContentToPackage(
          $scope.package,
          arrayWrap(GetPackageDetailWithDiscountingExResult.WebContent)
        );

        // Attach web content fields to the performances
        attachWebContentToPerformance(
          $scope.performances,
          GetPackageDetailWithDiscountingExResult.PerformanceWebContent
        );

        $scope.prices = groupPrices( GetPackageDetailWithDiscountingExResult.AllPrice );

        // Set the default selected price
        $scope.form.price = $scope.prices[0];

        // Package is mini package?
        $scope.isMiniPackage = (
          'pkg_type' in GetPackageDetailWithDiscountingExResult.Package &&
          GetPackageDetailWithDiscountingExResult.Package.pkg_type === 'Mini - Renewable'
        );

        // Find a venue override
        $scope.package.webContent.forEach(function (webContent) {
            if (webContent.content_type == appConfig.webContentTypes.maskVenue) {
                $scope.package.facility_override = webContent.content_value;
            }
        });

        // Find the dateTba web content
        $scope.performances.forEach(function (performance) {
          performance.webContent.forEach(function (webContent) {
            if (webContent.content_type == appConfig.webContentTypes.dateTba) {
              performance.dateTba = webContent.content_value;
              return false;
            }
          });
        });

        // Next we need to get the prod_season_no for the image !

        var promises = $scope.performances.map(appendProdSeasonNo);

        return $q.all(promises);

      })
      .catch(function () {
        $scope.invalidPackage = true;
      })
      .finally(function () {
        $scope.loadingPackage = false;
      });
  }
  $scope.loadPackage = loadPackage;

  function appendProdSeasonNo(performance) {

    // Get the get perf request
    var GetPerformanceDetailExParams = {
      iModeOfSale: appConfig.generalMos,
      iPerf_no: parseFloat(performance.inv_no)
    };

    // Make request
    return TessituraSDK.GetPerformanceDetailEx(GetPerformanceDetailExParams)
      .then(function (response) {
        var fullPerformance = response.data.result.GetPerformanceDetailExResult.Performance;
        var webContent = arrayWrap(response.data.result.GetPerformanceDetailExResult.WebContent);
        var mainProdSeasonNoWebContent = $filter('find')(webContent, function (webContent) {
          return webContent.content_type_desc === 'MAIN_PRODUCTION_SEASON_NO';
        });

        performance.prod_season_no = fullPerformance.prod_season_no;
        var imageProdSeasonNo = mainProdSeasonNoWebContent && mainProdSeasonNoWebContent.content_value ? mainProdSeasonNoWebContent.content_value : performance.prod_season_no;

        performance.imageURL = $filter('route')('cms.image', { prod_no: imageProdSeasonNo }) + '/small';
      });
  }

  function groupPrices(allPrices) {
    // Firsty, array wrap it all
    allPrices = arrayWrap(allPrices);

    // Find available zones for the sub price
    var availableZones = allPrices.filter(function (price) {
      return price.price_type == defaultPriceType;
    });

    // Store zones to use on front end
    var priceZones = [];

    // Keep track of zones we've already added with the same name
    var existingZones = [];

    // Group by description - really heavy, only should be done in JS
    var sections = $filter('groupByFirstWord')(availableZones, 'description');

    // Because group by first word actually groups, we need to flatten this out
    angular.forEach(sections, function (zones, section) {
      // Which means looping throug things a few times *sigh*
      zones.forEach(function (zone) {
        zone.price = parseInt(zone.price, 10) + ($scope.package.fee * $scope.performances.length);

        var zoneDesc = zone.description + ' - ' + $filter('currency')(zone.price);
        var zoneDescKey = section + zoneDesc;

        // Skip any unavailable, or if we already have one of the same name
        if (zone.available == 'N' || existingZones.indexOf(zoneDescKey) !== -1) {
          return;
        }

        zone.section = section;
        zone.label = zoneDesc;

        priceZones.push(zone);

        existingZones.push(zoneDescKey);
      });
    });

    return priceZones;
  };

  function attachWebContentToPerformance(performances, webContents) {
    angular.forEach(arrayWrap(performances), function (performance) {
      performance.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == performance.inv_no;
      });
    });
  }


  function attachWebContentToPackage(packages, webContents) {
    angular.forEach(arrayWrap(packages), function (package) {
      package.webContent = arrayWrap(webContents).filter(function (webContent) {
        return webContent.orig_inv_no == package.pkg_no;
      });

      var foundSeated = $filter('find')(package.webContent, function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.webSeated;
      });

      package.seated = package.seat_ind.toLowerCase() === 'y';

      if (foundSeated) {
        package.seated = foundSeated.content_value.toLowerCase() !== 'n';
      }

      var foundFee = $filter('find')(package.webContent, function (webContent) {
        return webContent.content_type == appConfig.webContentTypes.webPackageItemCost;
      });

      package.fee = 10;

      if (foundFee) {
        package.fee = parseFloat(foundFee.content_value);
      }
    });
  }

  /**
   * Finds the package line item id for the
   * last package added to the cart with the given packageNumber
   */
  function findLineItemIdForPackageNumber(packageNumber) {
    return TessituraSDK.GetCart()
      .then(function (response) {
        // The the package line item(s)
        var PackageLineItem = $filter('arrayWrap')(response.data.result.GetCartResults.PackageLineItem);

        // Find the correct line item
        for (var i = PackageLineItem.length - 1; i > 0; i--) {
          var lineItem = PackageLineItem[i];
          if (lineItem.li_no !== "0" && lineItem.pkg_no == packageNumber) {
            return $q.resolve(lineItem.li_no);
          }
        }

        return $q.reject();
      });
  }

  function getADAMessages() {

    var specialRequests = $scope.form.specialRequests;
    var toggleSpecialRequests = $scope.form.toggleSpecialRequests;

    var messages = [];
    var message;
    var i;

    // Build an array of the service issues
    for (i in specialRequests) {
      if (toggleSpecialRequests.hasOwnProperty(i) && toggleSpecialRequests[i] === false || specialRequests[i] === false) {
        continue;
      }

      message = i;
      if (typeof specialRequests[i] !== 'boolean') {
        message += ": " + specialRequests[i];
      }

      messages.push(message);
    }

    return messages;
  }

  function getRenewalMessages() {

    var messages = [];

    if ($scope.renewing) {
      messages.push("\nPackage renew settings");

      messages.push("Package: " + packageNumber);

      if (packageNumber != $scope.renew.originalPackageNumber) {
        messages.push("Original package: " + $scope.renew.originalPackageNumber);
      }

      messages.push("Seat state: " + $scope.renewCSI.seatState);
      if ($scope.renewCSI.seatState == 'improveSeats') {
        messages.push("New seat position: " + $scope.renewCSI.newSeatPosition);
      }
      if ($scope.form.numberOfSeats == $scope.renew.originalNumberOfSeats) {
        messages.push("Extra seat position: " + $scope.renewCSI.extraSeatPosition);
      }

      messages.push("Notes: " + $scope.renewCSI.additionalNotes);

      messages.push('Flexible dates: ' + Object.keys($scope.renew.flexibleDates).join(', '));
    }

    return messages;
  }

  /**
   * Builds a CSI request
   * @param  {String} base     Key of the csi template to copy
   * @param  {Array}  messages Array of message strings
   * @return {[type]}          [description]
   */
  function buildCSI(base, messages) {
    return angular.extend({
        Notes: messages.join("\n"),
        PerformanceNumber: 0,
        PackageNumber: packageNumber,
      }, appConfig.CSISettings[base]);
  }

  /**
   * Adds the customer service issues to the order
   */
  function addCustomerServiceIssue(){

    var adaMessages = getADAMessages();
    var renewalMessages = getRenewalMessages();

    // If theres at least one, add it to the order
    if (adaMessages.length > 0) {
      CacheStorage.set('PackageADAMeta-' + packageNumber, buildCSI('fixedPackageADACSI', adaMessages));
    }

    if (renewalMessages.length > 0) {
      CacheStorage.set('PackageRenewMeta-' + packageNumber, buildCSI('fixedPackageRenewCSI', renewalMessages));
    }
  }

  /**
   * Grabs the additional CSI messages
   */
  function addAdditionalMessages() {
    var additionalRequestElements = angular.element(
      document.querySelectorAll(additionalRequestsSelector)
    );

    angular.forEach(additionalRequestElements, function (element) {
      var contactMethod = element.getAttribute('data-contact-method');
      var category = element.getAttribute('data-category');
      var activityType = element.getAttribute('data-activity-type');
      var origin = element.getAttribute('data-origin');

      if (!contactMethod || !category || !activityType || !origin) {
        return;
      }

      var value = null;

      if ('type' in element && (element.type == 'checkbox' || element.type == 'radio') && element.checked) {
        value = element.value === 'on' ? 'Yes' : element.value;
      } else if ('value' in element && element.type != 'checkbox' && element.type != 'radio') {
        value = element.value;
      }

      if (value !== null && value !== '') {
        CacheStorage.append('AdditionalMeta', {
          Notes: value,
          PerformanceNumber: 0,
          PackageNumber: packageNumber,
          ContactMethod: contactMethod,
          Category: category,
          ActivityType: activityType,
          Origin: origin
        });
      }
    });
  }

  /**
   * Add the current selected package to the cart
   */
  function addToCart() {
    $scope.addingToCart = true;
    $scope.addToCartError = false;

    var packageLineItemId;
    var promise;
    var addDonationAfter = false;

    // Should we add a default donation after?
    promise = TessituraSDK.GetCart()
      .then(function (response) {
        if (!response.data.result || !response.data.result.GetCartResults || !response.data.result.GetCartResults.Order) {
          addDonationAfter = true;
        }
      });

    // Dont add anything to cart if the user hasnt changed
    // the package date, seat number, or zone
    if ($scope.renewing &&
        $scope.form.price == $scope.renew.originalPrice &&
        $scope.form.numberOfSeats == $scope.renew.originalNumberOfSeats &&
        packageNumber == $scope.renew.originalPackageNumber) {

      // Pass

    } else {

      // Add tickets to cart, seated and unseated
      // packages use different calls
      if ($scope.package.seated && !$scope.renewing) {
        promise = promise
          .then(function () {
            return TessituraSDK.AddPackageItemSeated({
              PriceType: parseInt($scope.form.price.price_type),
              PackageNumber: packageNumber,
              NumberOfSeats: parseInt($scope.form.numberOfSeats),
              Zone: parseInt($scope.form.price.zone_no),
              RequestedSeats: '',
              LeaveSingleSeats: 'false'
            });
          });

        // Check that the required amount of tickets where added
        promise = promise
          .then(function (response) {
            if (response.data.result[0] != $scope.form.numberOfSeats) {
              return $q.reject('There are not enough seats on that date. Please change your day or week.');
            }
          });
      } else {
        promise = promise
          .then(function () {
            return TessituraSDK.AddPackageItem({
              ParentPackageLineItemId: $scope.renew.originalParentPackageLineItemId ? $scope.renew.originalParentPackageLineItemId:0,
              PriceType: parseInt($scope.form.price.price_type),
              PackageNumber: packageNumber,
              NumberOfSeats: parseInt($scope.form.numberOfSeats),
              Zone: parseInt($scope.form.price.zone_no)
            });
          });
      }
    }

    // Manual add to cart check

    promise = promise
      .then(function () {
        return Cart.getCart(true);
      })
      .then(function (cartDetails) {
        if (cartDetails.PackageLineItem.length == 0) {
          return $q.reject();
        }
        var comments = [].concat(getADAMessages(), getRenewalMessages());
        if (comments.length) {
          var packageLineItem = $filter('find')(cartDetails.PackageLineItem, function (packageLineItem) {
            var check = packageLineItem.pkg_li_no === packageLineItem.li_seq_no;

            if ($scope.renewing && parseInt($scope.renew.originalPackageNumber, 10) !== packageNumber) {
              // This is an upgrade i.e. another package
              return check && packageLineItem.alt_upgrd_ind === 'U' && packageLineItem.primary_ind === 'Y';
            }
            return check && parseInt(packageLineItem.pkg_no, 10) === packageNumber;
          });
          return TessituraSDK.AddOrderCommentsEx2({
            Comment: comments.join('\n'),
            LineItemID: packageLineItem.li_seq_no,
            LineItemType: 'L',  // Special Request
            CustomerNo: 0,
            CategoryNo: 0,
          });
        }
      });

    promise = promise.then(function () {
        return TessituraSDK.ResetTicketExpiration();
      });

    // --- DISABLED UNTILL WE GET THE FUND ID FOR LIVE.
    // If we need, add the default donation
    if (addDonationAfter && false) {
      promise = promise
        .then(Donation.addDefaultDonationAmount);
    }

    // Finally
    promise
      .then(function () {
        // Add the customer service issue
        addCustomerServiceIssue();

        // Adds the additional customer service issues
        addAdditionalMessages();

        // Success, take them to the cart
        $window.location = Router.getUrl('booking.basket');
      })
      .catch(function (err) {
        // If theres an error clean up and remove everything
        $scope.addToCartError = angular.isString(err) ? err:true;
        $scope.addingToCart = false;

        // Dont clear the existing cart if we are renewing
        if (!$scope.renewing) {
          TessituraSDK.GetCart()
            .then(function (response) {
              // The the package line item(s)
              var PackageLineItem = $filter('arrayWrap')(response.data.result.GetCartResults.PackageLineItem);
              // Remove all of the line items!
              PackageLineItem.forEach(function (packageLineItem) {
                if (packageLineItem.perf_no === "0") {
                  TessituraSDK.RemovePackageItem({
                    PackageNumber: packageNumber,
                    PackageLineItemID: parseInt(packageLineItem.li_seq_no)
                  });
                }
              });
            });
          }
      });
  }
  $scope.addToCart = addToCart;

  // After everything has loaded
  $q.all(loadingPromises)
    .then(function () {
      $scope.loading = false;
    });
}]);
