app.controller('PreferencesController', ['$q', '$filter', '$scope', '$document', 'TessituraSDK', 'AuthenticationService', 'Notifications', function ($q, $filter, $scope, $document, TessituraSDK, AuthenticationService, Notifications) {
    $scope.loading = true;
    $scope.userIsGuest = false;
    $scope.savingChanges = false;
    $scope.generalKeywords = [];
    $scope.otherKeywords = [];
    $scope.userKeywords = {};
    $scope.originalUserKeywords = {};

    var arrayWrap = $filter('arrayWrap');
    var groupBy = $filter('groupBy');
    var find = $filter('find');

    var allowedGeneralKeywords = (window.GlobalWidgetConfig || []).filter(function (config) {
        return config.Type === 'General Communication Keyword';
    });

    var allowedGeneralKeywordIDs = allowedGeneralKeywords.map(function (category) {
        return parseInt(category.Value, 10);
    });

    var allowedOtherCategories = (window.GlobalWidgetConfig || []).filter(function (config) {
        return config.Type === 'Other Communication Category';
    });

    var allowedOtherCategoryIDs = allowedOtherCategories.map(function (category) {
        return parseInt(category.Value, 10);
    });

    AuthenticationService.requireLogin().then(function () {
        return AuthenticationService.isUserRegistered();
    }).then(function (isUserRegistered) {
        if (!isUserRegistered) {
            $scope.userIsGuest = true;

            return $q.reject();
        }

        return TessituraSDK.GetKeywordsEx();
    }).then(function (response) {
        var keywords = response.data.result.GetKeywordsExResult.Keyword;

        var generalKeywords = keywords.filter(function (keyword) {
            return allowedGeneralKeywordIDs.indexOf(parseInt(keyword.id, 10)) !== -1;
        });

        generalKeywords = generalKeywords.map(function (keyword) {
            keyword.description = find(allowedGeneralKeywords, function (item) {
                return parseInt(keyword.id, 10) === parseInt(item.Value, 10);
            }).Attribute;

            keyword.hint = find(allowedGeneralKeywords, function (item) {
                return parseInt(keyword.id, 10) === parseInt(item.Value, 10);
            }).Hint;

            return keyword;
        });

        generalKeywords.forEach(function (keyword) {
            $scope.originalUserKeywords[keyword.id] = false;
            $scope.userKeywords[keyword.id] = false;
        });

        $scope.generalKeywords = generalKeywords;

        var otherKeywords = keywords.filter(function (keyword) {
            return allowedOtherCategoryIDs.indexOf(parseInt(keyword.category, 10)) !== -1;
        });

        otherKeywords.forEach(function (keyword) {
            $scope.originalUserKeywords[keyword.id] = false;
            $scope.userKeywords[keyword.id] = false;
        });

        $scope.otherKeywords = groupBy(otherKeywords, 'category_desc');

        return TessituraSDK.GetConstituentInfoEx();
    }).then(function (response) {
        var results = response.data.result.GetConstituentInfoExResults;

        if (!results) {
            return;
        }

        arrayWrap(results.Interests).forEach(function (interest) {
            if (interest.id in $scope.userKeywords) {
                $scope.originalUserKeywords[interest.id] = interest.selected === 'Y';
                $scope.userKeywords[interest.id] = interest.selected === 'Y';
            }
        });
    }).finally(function () {
        $scope.loading = false;
    });

    $scope.checkKeywords = function (keywords) {
        keywords.forEach(function (keyword) {
            $scope.userKeywords[keyword.id] = true;
        });
    }

    $scope.uncheckKeywords = function (keywords) {
        keywords.forEach(function (keyword) {
            $scope.userKeywords[keyword.id] = false;
        });
    }

    $scope.saveChanges = function () {
        $scope.savingChanges = true;

        var toUpdate = {};
        var id;

        for (id in $scope.userKeywords) {
            if ($scope.originalUserKeywords[id] !== $scope.userKeywords[id]) {
                toUpdate[id] = $scope.userKeywords[id];
            }
        }

        var preferences = [];

        for (id in toUpdate) {
            preferences.push(
                id + '=' + (toUpdate[id] ? 'Y' : 'N')
            );
        }

        var promise = $q.resolve();

        if (preferences.length) {
            promise = TessituraSDK.UpdateConstituentInterestsEx({
                sPreferences: preferences.join(',')
            });
        }

        promise.then(function () {
            Notifications.create({
                message: 'Your preferences have been updated successfully.',
                container: 'prefs'
            });

            $document.scrollTop(0);

            $scope.savingChanges = false;
        });
      }
}]);
