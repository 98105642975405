app.filter('isPositive', function () {
  return function (arr, prop, includeZero) {
    if (!angular.isArray(arr)) {
      return [];
    }
    return arr.filter(function (item) {
      if (includeZero) {
        return parseFloat(item[prop]) >= 0;
      } else {
        return parseFloat(item[prop]) > 0;
      }
    });
  };
});
